<template>
  <b-container>
    <RenderSpinner v-if="$store.state.loading" />
    <h2 class="card-title">
      <div class="d-flex">
        <span class="text-nowrap">
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('TRANSACTION') }}
        </span>
        <b-form-group
          :label="$t('MANAGER_SHORT') + ':'"
          label-for="transaction-query-manager"
          class="form-group-manager position-relative mb-0"
        >
          <b-form-select
            @change="changeManager()"
            :disabled="true"
            id="transaction-query-manager"
            v-model="value.managerId"
            class="form-control number number-lg query-manager"
          >
            <option
              v-for="(partner, index) in inHousePartners"
              :key="index"
              :value="partner.accountUserId"
              >{{ partner.fullName }}
            </option>
          </b-form-select>
        </b-form-group>
      </div>

      <div class="d-flex">
        <b-form-group
          :label="$t('CONTRACT_NR')"
          label-for="contractnr"
          v-if="this.value.lendifyRefno"
        >
          <b-form-input
            readonly
            size="sm"
            type="text"
            style="width: 110px;"
            id="contractnr"
            name="contractnr"
            v-model.trim="this.value.lendifyRefno"
          >
          </b-form-input>
        </b-form-group>

        <Limit
          v-if="this.partnerSettings.lendifyLimit"
          :availableLimit.sync="availableLimit"
          :maxContractAmount.sync="maxContractAmount"
          :regno="value.custRegCode"
          :limitBlocked.sync="limitBlocked"
          :queryStatus="value.status"
          :isSuretyRequired.sync="value.isSuretyRequired"
        />

        <Kickback
          :partner="partnerSettings"
          :contractAmount="new Number(totalQassetPmt)"
          :contractFee="new Number(value.contractFee)"
        />
      </div>
    </h2>

    <TemplatesModal
      :show="showTemplatesModal"
      :partnerSettings="partnerSettings"
      :disableOnStatus="disableOnStatus"
      @hide="toggleTemplatesModal"
      @saveQuery="saveQuery"
      @updateTermInputs="updateTermInputs"
      v-model="value"
    />

    <ShopAssetForm
      v-model="value"
      :partnerSettings="partnerSettings"
      :viewName="viewName"
      :disableOnStatus="disableOnStatus"
      :disableDeliveryAndSellerInfo="disableDeliveryAndSellerInfo"
      @calculatePmt="calculatePmt()"
      @saveQuery="saveQuery"
      @assetUpdated="assetUpdated"
    />

    <ShopTerms
      v-model="value"
      ref="terms"
      :partnerSettings="partnerSettings"
      :viewName="viewName"
      :disableOnStatus="disableOnStatus"
      :lendifyInsurance="lendifyInsurance"
      :computedKickback="computedKickback"
      :computedCommission="computedCommission"
      :totalPmt="totalPmt"
      :interestChanged="interestChanged"
      @calculatePmt="calculatePmt"
      @changeInterestChanged="changeInterestChanged()"
      @saveQuery="saveQuery"
    />

    <template v-if="!['SELFSERVE', 'WEBSHOP'].includes(value.product)">
      <div
        v-if="this.hasHandoverWhenSigned"
        class="mb-5 mt-5"
        style="text-align: center; color: #822460"
      >
        <b>Tehtud valik: Vara antakse kohe peale allkirjastamist üle!</b>
      </div>

      <div
        v-if="!this.hasHandoverWhenSigned"
        class="mb-5 mt-5"
        style="text-align: center; color: #822460"
      >
        <b
          >Tehtud valik: Vara antakse üle tulevikus üleandmise-vastuvõtu akti
          alusel!</b
        >
      </div>

      <div
        class="mb-5 mt-4"
        v-if="
          (!limitBlocked && this.partnerSettings.lendifyLimit) ||
            !this.partnerSettings.lendifyLimit
        "
      >
        <b-row align-h="center">
          <b-col md="6">
            <b-button
              v-if="
                canCreateContract &&
                  !canSendForSigning &&
                  !['HO'].includes(this.value.status)
              "
              size="lg"
              variant="primary"
              block
              @click="createShopContract()"
              :disabled="value.status === 'N' || !!errors.items[0]"
            >
              {{ $t('CREATE_CONTRACT') }}
            </b-button>
          </b-col>
        </b-row>

        <div
          align-h="center"
          v-if="
            !canCreateContract && ['P'].includes(this.value.status) && hasAsset
          "
        >
          <b-row align-h="center">
            <b-col md="12">
              <b-alert show>Limiit ületatud. Vajab lisakontrollli.</b-alert>
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-col md="6">
              <b-button
                size="lg"
                variant="primary"
                block
                @click="
                  $emit('changeStatus', {
                    status: 'SM',
                    alert: $t('QUERYSTATUS.SM'),
                    queryId: value.queryId,
                  })
                "
                :disabled="value.status === 'N' || !!errors.items[0]"
              >
                {{ $t('SAVE_AND_SEND_TO_MANAGER') }}
              </b-button>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="this.value.status !== 'N'">
          <b-col v-if="!['YS', 'SN'].includes(this.value.status)">
            <b-button
              v-if="canSendForSigning"
              v-b-modal.modal-3
              size="lg"
              variant="primary"
              block
            >
              {{ $t('SEND_FOR_SIGNING') }}

              <b-icon id="tt-3" icon="question-circle" />
              <b-tooltip target="tt-3" variant="dark" triggers="hover">
                <span
                  >Kliendile saadetakse leping e-mailile digitaalseks
                  allkirjastamiseks. Teavitame Sind kui klient on lepingu
                  allkirjastanud.</span
                >
              </b-tooltip>
            </b-button>

            <b-modal
              id="modal-3"
              title="Edastan digitaalseks allkirjastamiseks?"
              @ok="sendForSigning()"
              ok-title="Jah"
              cancel-title="Ei"
            >
              <p class="my-4">
                Kinnitan, et soovin saata kliendile lepingut digitaalseks
                allkirjastamiseks.
              </p>
            </b-modal>
          </b-col>

          <b-col v-if="this.partnerSettings.manualSigning">
            <b-button
              v-if="canSendForSigning"
              size="lg"
              variant="primary"
              block
              @click="printShopContract()"
            >
              {{ $t('PRINT_CONTRACT') }}
              <b-icon id="tt-4" icon="question-circle" />
              <b-tooltip target="tt-4" variant="dark" triggers="hover">
                <span
                  >Koostatakse PDF formaadis leping kohapeal paberkandjal
                  allkirjastamiseks.</span
                >
              </b-tooltip>
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="['CR', 'YS'].includes(this.value.status)" class="mt-4">
          <b-col>
            <b-button
              v-b-modal.modal-2
              v-if="canSendForSigning"
              size="lg"
              variant="secondary"
              block
            >
              {{ $t('CANCEL_CONTRACT') }}

              <b-icon id="tt-7" icon="question-circle" />
              <b-tooltip target="tt-7" variant="dark" triggers="hover">
                <span
                  >Klient ei soovi lepingut allkirjastada või annab muul moel
                  teada taganemise soovist.</span
                >
              </b-tooltip>
            </b-button>

            <b-modal
              id="modal-2"
              title="Tühistan lepingu?"
              @ok="cancelShopContract()"
              ok-title="Jah"
              cancel-title="Ei"
            >
              <p class="my-4">Kinnitan, et soovin lepingut tühistada.</p>
            </b-modal>
          </b-col>
          <b-col
            v-if="
              !['P', 'YS', 'SN'].includes(this.value.status) &&
                this.partnerSettings.manualSigning
            "
          >
            <b-button
              v-b-modal.modal-1
              v-if="canSendForSigning"
              size="lg"
              variant="primary"
              block
            >
              {{ $t('MAKE_SIGNED') }}
              <b-icon id="tt-5" icon="question-circle" />
              <b-tooltip target="tt-5" variant="dark" triggers="hover">
                <span
                  >Märgi allkirjastatuks siis kui klient on allkirjastanud
                  paberkandjal lepingu.</span
                >
              </b-tooltip>
            </b-button>

            <b-modal
              id="modal-1"
              title="Märgi allkirjastatuks?"
              @ok="confirmSigned()"
              ok-title="Jah"
              cancel-title="Ei"
            >
              <p class="my-4">
                Kinnitan, et klient on allkirjastanud lepingu paberkandjal.
              </p>
            </b-modal>
          </b-col>
        </b-row>
      </div>
    </template>
    <template>
      <b-row
        v-if="
          ['HO'].includes(this.value.status) &&
            !this.hasDeliveryDateSet &&
            !this.hasHandoverWhenSigned
        "
        class="mt-4"
      >
        <b-col>
          <b-button v-b-modal.modal-1 size="lg" variant="primary" block>
            {{ $t('HANDOVER_TO_CUSTOMER') }}
            <b-icon id="tt-5" icon="question-circle" />
            <b-tooltip target="tt-5" variant="dark" triggers="hover">
              <span
                >Kui vara on kliendile üle antud (või saadetud postiga
                kliendile)</span
              >
            </b-tooltip>
          </b-button>
          <b-modal
            id="modal-1"
            title="Märgi vara üleantuks kliendile"
            @ok="setDeliveryDate"
          >
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('DELIVERY_DATE')"
                  label-for="deliveryDate"
                >
                  <DatePicker
                    v-model="deliveryDate"
                    format="dd.MM.yyyy"
                    name="deliveryDate"
                  ></DatePicker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>
        </b-col>
      </b-row>
      <br />
    </template>
  </b-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ALERT } from '../../store/alert';
import { UPLOAD_DOCUMENTS, DELETE_DOCUMENT } from '../../store/document';
import { SAVE_MESSAGE } from '../../store/message';
import DatePicker from 'vuejs-datepicker';
import {
  CALCULATE_PAYMENT,
  CHANGE_MANAGER,
  CREATE_SHOP_CONTRACT,
  GET_QUICK_CONTRACT_STATUS,
  SET_DELIVERY_DATE,
} from '../../store/query';
import { replaceComma, numerify, numerifyWithDecimals } from '@/utils';
import { RenderSpinner, TemplatesModal } from '@/components/common';

import ShopAssetForm from './ShopAssetForm';
import ShopTerms from './ShopTerms';

import validateState from '../../mixins/validateState';
import Limit from '@/components/common/Limit';
import Kickback from '@/components/common/Kickback';

export default {
  name: 'ShopTransactionTab',
  props: {
    viewName: String,
    value: Object,
    partnerSettings: Object,
    inHousePartners: Array,
    canCreateQuickContract: Boolean,
  },
  components: {
    RenderSpinner,
    ShopAssetForm,
    ShopTerms,
    TemplatesModal,
    Limit,
    Kickback,
    DatePicker,
  },
  mixins: [validateState],
  data: function() {
    return {
      availableLimit: null,
      limitBlocked: false,
      maxContractAmount: null,
      newService: {},
      qassetSum: 0,
      sliderColor: 'red',
      formattedDeliveryDate: '',
      showTemplatesModal: false,
      interestChanged: false,
      showAssetForm: true,
      canCreateContract: false,
      deliveryDate: new Date(),
    };
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    ...mapActions('document', [UPLOAD_DOCUMENTS, DELETE_DOCUMENT]),
    ...mapActions('message', [SAVE_MESSAGE]),
    ...mapActions('query', [
      CHANGE_MANAGER,
      CALCULATE_PAYMENT,
      CREATE_SHOP_CONTRACT,
      GET_QUICK_CONTRACT_STATUS,
      SET_DELIVERY_DATE,
    ]),

    async setDeliveryDate() {
      this.SET_DELIVERY_DATE({
        queryId: this.value.queryId,
        deliveryDate: this.deliveryDate,
      }).then(() => {
        this.ALERT({ message: 'Üleandmise kuupäev määratud' });
      });
      this.value.qassets.forEach(qasset => {
        qasset.deliveryDate = this.deliveryDate;
      });
    },
    cancelShopContract() {
      this.$emit('saveQuery', () => {
        this.$emit('changeStatus', {
          status: 'N',
          alert: this.$t('CONTRACT_CANCELLED'),
          queryId: this.value.queryId,
        });
      });
    },
    async createShopContract() {
      const validContract = await this.$validator.validate();
      if (!validContract) {
        return false;
      }

      this.$emit('saveQuery', () => {
        this.CREATE_SHOP_CONTRACT(this.value.queryId);
      });
    },
    printShopContract() {
      this.$store.dispatch('printShopContract', {
        queryId: this.value.queryId,
      });
    },
    confirmSigned: function() {
      this.$store.state.loading = true;
      this.$store
        .dispatch('confirmSigned', {
          queryId: this.value.queryId,
        })
        .then(response => {
          if (response.status === 200) {
            this.$emit('changeStatus', {
              status: 'HO',
              alert: this.$t('SIGNING_CONFIRMED'),
              queryId: this.value.queryId,
            });
          }
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },
    sendForSigning: function() {
      this.$store.state.loading = true;
      this.$store
        .dispatch('sendForShopSigning', {
          containers: this.toSigningContainers,
          queryId: this.value.queryId,
        })
        .then(response => {
          if (response.status === 200) {
            this.$emit('changeStatus', {
              status: 'YS',
              alert: this.$t('SENT_FOR_SIGNING'),
              queryId: this.value.queryId,
            });
          }
        })
        .then(() => {
          for (const container of this.toSigningContainers) {
            container.sentForSigning = true;
            container.isSelected = false;
          }
          this.toSigningContainers = [];
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },
    async saveQuery(cb) {
      await this.$emit('saveQuery', cb);
    },
    async assetUpdated() {},
    async checkLimit() {
      if (this.value.queryId) {
        if (['YS', 'SN', 'SM'].includes(this.value.status)) {
          this.canCreateContract = false;
          return;
        }

        if (this.totalPmt === 0) {
          this.canCreateContract = false;
          return;
        }

        this.GET_QUICK_CONTRACT_STATUS(this.value.queryId).then(r => {
          this.canCreateContract = r.data;
        });
      } else {
        this.canCreateContract = false;
      }
    },
    async calculatePmt() {
      this.$nextTick(async () => {
        await this.CALCULATE_PAYMENT({
          pmtObject: {
            assets: this.value.qassets,
            services: this.value.services,
            advancePmt: this.value.advance,
            advnPmtType: this.value.advnPmtType,
            rvValue: this.value.residual,
            intrTotal: this.value.interest,
            leasePeriod: this.value.paymentPeriod,
            pmtDate: this.value.pmtDate,
            contractValue: replaceComma(this.value.contractFee),
            insuranceValue: replaceComma(this.lendifyInsurance),
            interestMatrix: this.value.interestMatrix,
          },
          currentQuery: this.value,
        });
        this.interestChanged = true;
      });
    },
    async saveMessage() {
      if (this.newMessage.content.length > 0) {
        const savedMessage = await this.SAVE_MESSAGE({
          message: this.newMessage,
          queryId: this.value.queryId,
        });
        this.value.messages.push(savedMessage);
        this.newMessage.content = '';
      }
    },
    getInitials: function(name) {
      return name
        .split(' ')
        .map(function(item) {
          return item[0];
        })
        .join('');
    },
    async changeManager() {
      this.$nextTick(async () => {
        await this.CHANGE_MANAGER({
          queryId: this.value.queryId,
          managerId: this.value.managerId,
        });
      });
    },
    toggleTemplatesModal(payload) {
      this.showTemplatesModal = !!payload;
    },
    async updateTermInputs() {
      await this.calculatePmt();
      this.$refs.terms.updateTermInputs();
    },
    changeInterestChanged() {
      this.interestChanged = false;
    },
  },
  mounted: function() {
    this.checkLimit();
    if (this.value && this.partnerSettings && this.viewName === 'shop') {
      this.$set(this.value, 'servicesCost', 0);
      this.$set(this.value, 'contractFee', this.partnerSettings.contractFee);
      if (!this.value.insurance) {
        this.$set(this.value, 'insurance', this.partnerSettings.insurance);
      }
      this.$set(
        this.value,
        'offerValidTime',
        this.partnerSettings.offerValidTime,
      );
    }
  },
  watch: {
    'value.qassets': function(val) {
      this.checkLimit();
    },
    'value.custRegCode': function(newVal, oldVal) {
      this.checkLimit();
    },
    totalPmt(val) {
      this.checkLimit();
    },
    availableLimit(val) {
      this.checkLimit();
    },
  },
  computed: {
    ...mapState('alert', ['alert']),
    ...mapState('document', ['documents']),
    ...mapState('message', ['newMessage']),
    totalQassetPmt: function() {
      if (this.value.qassets) {
        return this.value.qassets
          .reduce((acc, item) => acc + item.qassetPrice * item.quantity, 0)
          .toFixed(2);
      }
      return undefined;
    },
    hasAsset: function() {
      return this.value.qassets.length > 0;
    },
    hasDeliveryDateSet: function() {
      if (this.hasAsset) {
        return this.value.qassets[0].deliveryDate !== null;
      }
      return false;
    },
    hasHandoverWhenSigned: function() {
      if (this.hasAsset) {
        return this.value.qassets[0].handoverWhenSigned;
      }
      return false;
    },
    canSendForSigning: function() {
      return (
        this.value.lendifyRefno !== null &&
        !['CA', 'HO', 'SN'].includes(this.value.status)
      );
    },
    totalPmt: function() {
      return this.totalQassetPmt;
    },
    computedCommission: function() {
      return (
        ((this.lendifyInsurance - parseFloat(this.value.servicesCost)) /
          this.value.paymentPeriod) *
        12
      ).toFixed(2);
    },
    computedKickback: function() {
      return (
        ((parseFloat(this.value.kickback) / 100) * this.totalPmt).toFixed(2) / 1
      );
    },
    computedInsurance: function() {
      if (this.value.insuranceValType === 1) {
        return (
          this.totalPmt *
          (+replaceComma(this.value.insurance) / 100) *
          this.value.paymentPeriod
        );
      } else {
        return (
          this.totalPmt *
          (+replaceComma(this.value.insurance) / 100 / 12) *
          this.value.paymentPeriod
        );
      }
    },
    lendifyInsurance: function() {
      return (this.computedKickback + this.computedInsurance).toFixed(2);
    },
    sortedMessages: function() {
      if (this.value.messages) {
        return this.value.messages.slice().sort(function(a, b) {
          var dateA = new Date(a.createdDtime);
          var dateB = new Date(b.createdDtime);
          return dateA > dateB ? 1 : -1;
        });
      }
      return undefined;
    },
    disableOnStatus() {
      return !['P'].includes(this.value.status);
    },
    disableDeliveryAndSellerInfo: function() {
      return this.value.status === 'CA';
    },
    numerify() {
      return numerify;
    },
    numerifyWithDecimals() {
      return numerifyWithDecimals;
    },
  },
};
</script>
