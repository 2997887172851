<template>
  <b-container>
    <h2 class="card-title">
      <div class="d-flex">
        <span class="text-nowrap">
          <i class="card-title-icon">+</i>
          {{ $t('ADD_CLIENT') }}
        </span>
        <b-form-group
          :label="$t('MANAGER_SHORT')"
          label-for="client-query-manager"
          class="form-group-manager position-relative mb-0"
        >
          <b-form-select
            @change="changeManager()"
            :disabled="true"
            id="client-query-manager"
            v-model="value.managerId"
            class="form-control number number-lg query-manager"
          >
            <option
              v-for="(partner, index) in inHousePartners"
              :key="index"
              :value="partner.accountUserId"
              >{{ partner.fullName }}</option
            >
          </b-form-select>
        </b-form-group>
      </div>
      <Limit
        v-if="this.partnerSettings && this.partnerSettings.lendifyLimit"
        :regno="value.custRegCode"
        :queryStatus="value.status"
        :isSuretyRequired.sync="value.isSuretyRequired"
        :startNewLimitRequest.sync="startNewLimitRequest"
        :lastResult.sync="lastResult"
        :lastResultAt.sync="lastResultAt"
        :sureties.sync="sureties"
        :representatives.sync="representatives"
        :limitBlocked.sync="limitBlocked"
        @suretyRequiredCallback="addSurety"
        @timeoutCallback="timeoutAlert"
      />
    </h2>

    <b-card class="card-padding-md">
      <b-form>
        <b-row>
          <b-col>
            <div v-if="this.lastResult == 'approved'">
              <b-alert show variant="success" class="text-center">{{
                decision
              }}</b-alert>
            </div>

            <b-alert
              show
              variant="success"
              class="text-center"
              v-if="
                (value.custName || value.custRegCode) &&
                  this.lastResult !== 'rejected'
              "
            >
              {{ $t('CLIENT_LIMIT_QUERY_INFO') }}
            </b-alert>
            <b-alert
              show
              variant="danger"
              class="text-center"
              v-if="this.lastResult === 'rejected'"
            >
              {{ decision }}
            </b-alert>
            <b-alert
              show
              variant="warning"
              class="text-center"
              v-if="this.lastResult === 'not_available'"
            >
              {{ $t('AUTO_LIMIT_NOT_AVAILABLE') }}
            </b-alert>
          </b-col>
          <b-col md="12">
            <h3>{{ $t('CLIENT') }}</h3>
          </b-col>
          <b-col md="6">
            <b-form-group
              icon="question-circle"
              label-for="custName"
              :invalid-feedback="$t('VALIDATION.REQUIRED')"
              :state="validateState('custName')"
            >
              <label for="custName" class="d-block" id="__BVID__106__BV_label_"
                >{{ $t('BUSINESS_NAME') + ':' }}
                <b-icon class="pl-0" id="tt-6" icon="question-circle" />
                <b-tooltip target="tt-6" variant="dark" triggers="hover">
                  {{ $t('ENTER_REGNO_IF_NAME_MISSING') }}
                </b-tooltip>
              </label>

              <multiselect
                v-model="selected"
                id="custName"
                :options="companies"
                :loading="isLoading"
                label="name"
                @search-change="getCompaniesByName"
                @close="setCompanyParams"
                :placeholder="$t('SEARCH')"
                :disabled="disableOnStatus || customerInputStatus !== 0"
                :show-labels="false"
              >
                <template slot="noResult">
                  {{ $t('NO_ELEMENTS_FOUND') }}
                </template>
              </multiselect>
              <b-form-input
                class="mt-2"
                id="custName"
                name="custName"
                v-validate="'required'"
                v-if="customerInputStatus !== 0"
                v-model.trim="value.custName"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
            <b-btn
              size="sm"
              @click="showManualInputs()"
              :disabled="disableOnStatus"
              class="mb-3"
            >
              {{ manualInputBtnText }}
            </b-btn>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('REGISTRY_CODE') + ':'"
              label-for="custRegCode"
              :invalid-feedback="$t('VALIDATION.REQUIRED')"
              :state="validateState('custRegCode')"
            >
              <multiselect
                v-model="selected"
                id="custReqCode"
                :options="companies"
                :loading="isLoading"
                label="companyCode"
                @search-change="getCompaniesByCode"
                @close="setCompanyParams"
                :placeholder="$t('SEARCH')"
                :disabled="disableOnStatus || customerInputStatus !== 0"
                :show-labels="false"
              >
                <template slot="noResult">
                  {{ $t('NO_ELEMENTS_FOUND') }}
                </template>
              </multiselect>
              <b-form-input
                class="mt-2"
                id="custRegCode"
                name="custRegCode"
                v-validate="'required'"
                v-if="customerInputStatus !== 0"
                v-model.trim="value.custRegCode"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
            <b-btn
              size="sm"
              v-if="value.custRegCode && value.custRegCode.length > 7"
              @click="startLimitRequest()"
              :disabled="disableOnStatus || startNewLimitRequest"
              class="mb-3"
            >
              <span v-if="!startNewLimitRequest">
                {{ 'Esita limiidipäring' }}
              </span>
              <span v-if="startNewLimitRequest">
                {{ progressText }}
              </span>
            </b-btn>

            <b-form-group
              v-if="$store.state.role !== 'SHOP'"
              :label="$t('VAT_CODE') + ':'"
              label-for="vatNumber"
            >
              <b-form-input
                id="vatNumber"
                v-model.trim="value.vatNumber"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6"> </b-col>
        </b-row>

        <div v-if="!startNewLimitRequest">
          <b-row
            class="mt-5"
            v-if="$store.state.role === 'SHOP' && !limitBlocked"
          >
            <b-col md="12">
              <h3>{{ $t('CONTACT') }}</h3>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('BILLING_EMAIL') + ':'"
                label-for="billingEmail"
                :invalid-feedback="$t('VALIDATION.INVALID_EMAIL')"
                :state="validateState('billingEmail')"
              >
                <b-form-input
                  id="billingEmail"
                  name="billingEmail"
                  type="text"
                  v-validate="'required|email'"
                  v-model.trim="value.billingEmail"
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-5" v-if="!limitBlocked">
            <b-col md="12">
              <h3>{{ $t('REPRESENTATIVE') }}</h3>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('NAME') + ':'"
                v-b-tooltip.hover
                title="Esindusõigusega isik"
                label-for="contactName"
                :invalid-feedback="$t('VALIDATION.REQUIRED')"
                :state="validateState('contactName')"
              >
                <b-form-input
                  v-if="representatives.length === 0"
                  id="contactName"
                  v-model.trim="value.contactName"
                  type="text"
                  :disabled="disableOnStatus"
                  name="contactName"
                  v-validate="'required'"
                >
                </b-form-input>

                <b-form-select
                  v-if="representatives.length > 0"
                  id="contactName"
                  class="form-control"
                  v-model="value.contactName"
                  :disabled="disableOnStatus || disabled"
                  @change="copyRepresentativeFields()"
                >
                  <option
                    v-for="(s, index) in representatives"
                    :key="index"
                    :value="s.name"
                    >{{ s.name }}</option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label-for="contactPersCode"
                :label="$t('ID_CODE') + ':'"
                :invalid-feedback="$t('VALIDATION.INVALID_ID_CODE')"
                :state="validateState('contactPersCode')"
              >
                <b-form-input
                  id="contactPersCode"
                  :disabled="disableOnStatus || representatives.length > 0"
                  v-validate="'required|idcode'"
                  v-model.trim="value.contactPersCode"
                  name="contactPersCode"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('PHONE') + ':'"
                label-for="contactPhone"
                :state="validateState('contactPhone')"
              >
                <b-form-input
                  id="contactPhone"
                  name="contactPhone"
                  type="text"
                  v-validate="'required|integer'"
                  v-model.trim="value.contactPhone"
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('EMAIL') + ':'"
                label-for="contactEmail"
                v-b-tooltip.hover
                title="Sellele aadressile saadetakse leping digitaalseks allkirjastamiseks"
                :invalid-feedback="$t('VALIDATION.INVALID_EMAIL')"
                :state="validateState('contactEmail')"
              >
                <b-form-input
                  id="contactEmail"
                  name="contactEmail"
                  type="text"
                  v-model.trim="value.contactEmail"
                  v-validate="'required|email'"
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            class="mt-5"
            v-if="
              value.isSuretyRequired &&
                !limitBlocked &&
                this.partnerSettings.lendifyLimit
            "
          >
            <b-col md="12">
              <h3>{{ $t('SURETY') }}</h3>
            </b-col>
            <b-col class="mb-2" md="6">
              <b-form-group
                :label="$t('FULL_NAME')"
                label-for="client-surety"
                class="position-relative mb-0"
                v-if="value.sureties[0]"
              >
                <b-form-select
                  id="client-surety"
                  class="form-control"
                  v-model="value.sureties[0].personalCode"
                  @change="copySuretyFields()"
                >
                  <option
                    v-for="(s, index) in sureties"
                    :key="index"
                    :value="s.identity_code"
                    >{{ s.name }}</option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('EMAIL')"
                label-for="client-surety-email"
                class="position-relative mb-0"
                v-if="value.sureties[0]"
              >
                <b-form-input
                  id="suretyEmail"
                  name="suretyEmail"
                  type="text"
                  v-validate="'required|email'"
                  v-model.trim="value.sureties[0].email"
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="6" v-if="value.sureties[0]">
              <b-form-group
                :label="$t('PHONE') + ':'"
                label-for="client-surety-phone"
              >
                <b-form-input
                  id="client-surety-phone"
                  type="text"
                  v-model.trim="value.sureties[0].phone"
                  required
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="value.sureties[0]">
              <b-form-group
                :label="$t('ADDRESS') + ':'"
                label-for="client-surety-address"
              >
                <b-form-input
                  id="client-surety-address"
                  type="text"
                  v-model.trim="value.sureties[0].address"
                  required
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="value.sureties[0]">
              <b-form-group
                :label="$t('COMMENT_OPTIONAL') + ':'"
                label-for="client-surety-comment"
              >
                <b-form-input
                  id="client-surety-comment"
                  type="text"
                  v-model.trim="value.sureties[0].comment"
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </b-card>

    <div
      class="mb-5 mt-4"
      v-if="
        !startNewLimitRequest &&
          !(value.product === 'SELFSERVE' || value.product === 'WEBSHOP')
      "
    >
      <b-row align-h="center">
        <b-col md="6">
          <b-button
            variant="primary"
            :disabled="!formsAreValid"
            size="lg"
            @click="submitForm"
            v-if="!limitBlocked"
            block
            >{{ $t('SAVE_AND_GO_NEXT') }}</b-button
          >
          <!-- temporarily hidden -->
          <b-button
            variant="secondary"
            v-if="false"
            size="lg"
            @click="qualifyCustomer()"
            block
            >{{ $t('QUALIFY_CUSTOMER') }}</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import snakeCase from 'lodash.snakecase';
import { getData as getCountriesList } from 'country-list';
import Multiselect from 'vue-multiselect';
import { ALERT, LONG_ALERT } from '../../store/alert';
import { CHANGE_MANAGER } from '../../store/query';
import { mapActions, mapState } from 'vuex';
import validateState from '../../mixins/validateState';
import { SAVE_SURETIES, DELETE_SURETY } from '../../store/surety';
import { GET_BY_NAME, GET_BY_COMPANY_CODE } from '../../store/company';
import Limit from '@/components/common/Limit';

import axios from 'axios';

export default {
  name: 'ClientTab',
  components: { Limit, Multiselect },
  mixins: [validateState],
  props: [
    'value',
    'viewName',
    'partnerSettings',
    'inHousePartners',
    'isInhouse',
    'isShop',
  ],
  data: function() {
    return {
      disabled: false,
      decision: null,
      progressTextTimer: null,
      progressText: 'Pärin andmeid...',
      progressDots: '',
      progressTextIndex: 0,
      isLoading: false,
      selected: [],
      sureties: [],
      representatives: [],
      startNewLimitRequest: false,
      lastResult: null,
      lastResultAt: null,
      limitBlocked: false,
      options: ['Law', 'Power of Attorney'],
      companyNameInput: '',
      companyCodeInput: '',
      manualInputBtnText: this.$t('ENTER_MANUALLY'),
      customerInputStatus: 0,
      disableAddSurety: false,
      PublicPersonOptions: [
        'no',
        'yes_in_estonia',
        'yes_in_european_union',
        'yes_in_third_country',
      ],
      documentTypes: [
        'id_card',
        'drivers_license',
        'passport',
        'foreigner_passport',
        'residence_permit',
        'commercial_registry_statement',
      ],
      countries: getCountriesList(),
      countyList: [
        'Harju maakond',
        'Hiiu maakond',
        'Ida – viru maakond',
        'Jõgeva maakond',
        'Järva maakond',
        'Lääne maakond',
        'Lääne viru maakond',
        'Põlva maakond',
        'Pärnu maakond',
        'Rapla maakond',
        'Saare maakond',
        'Tartu maakond',
        'Valga maakond',
        'Viljandi maakond',
        'Võru maakond',
      ],
      progressTextValues: [
        'Laen ettevõtte andmeid',
        'Andmete kontroll maksuametist',
        'Taotluste ja otsuste kontroll',
        'Võlakontroll',
        'Esindajate võlakontroll',
        'Aastaaruanne',
        'Krediidilimiidi soovitus',
        'Palun oota natuke veel :)',
      ],
    };
  },
  mounted() {
    if (this.value) {
      this.selected = {
        name: this.value.custName,
        companyCode: this.value.custRegCode,
      };
    }
  },
  watch: {
    'value.custRegCode': function(ov) {
      this.decision = null;
      this.progressTextIndex = 0;
      clearInterval(this.progressTextTimer);
    },
    lastResultAt(nv, ov) {
      if (nv && nv !== ov) {
        if (this.lastResult === 'approved') {
          this.decision = 'Krediidiotsus positiivne.';
        } else {
          this.decision = 'Krediidiotsus negatiivne.';
        }
      } else {
        this.decision = null;
      }
    },
    custName(nv, ov) {
      if (nv && nv !== ov) {
        this.decision = null;
        this.progressTextIndex = 0;
        clearInterval(this.progressTextTimer);
      }
    },
    startNewLimitRequest(val) {
      if (val) {
        this.progressText = 'Alustan otsuse tegemist ...';

        this.progressTextTimer = setInterval(() => {
          this.progressTextIndex += 1;
          this.progressText =
            this.progressTextValues[
              Math.min(
                this.progressTextIndex - 1,
                this.progressTextValues.length - 1,
              )
            ] + ' ...';
        }, 5000);
      } else {
        this.progressTextIndex = 0;
        clearInterval(this.progressTextTimer);
      }
    },
  },
  methods: {
    ...mapActions('alert', [ALERT, LONG_ALERT]),
    ...mapActions('query', [CHANGE_MANAGER]),
    ...mapActions('surety', [SAVE_SURETIES, DELETE_SURETY]),
    ...mapActions('company', [GET_BY_NAME, GET_BY_COMPANY_CODE]),
    async changeManager() {
      this.$nextTick(async () => {
        await this.CHANGE_MANAGER({
          queryId: this.value.queryId,
          managerId: this.value.managerId,
        });
      });
    },
    async getCompaniesByName(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_NAME(query);
        this.isLoading = false;
      }
    },
    async getCompaniesByCode(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_COMPANY_CODE(query);
        this.isLoading = false;
      }
    },
    async timeoutAlert() {
      this.decision =
        'Limiiti ei suudetud hetkel automaatselt määrata. Pöördume otsusega peatselt tagasi!';
      this.lastResult = 'not_available';
      this.lastResultAt = new Date().toLocaleString();
    },
    async addSurety() {
      this.disableAddSurety = true;
      if (this.value.sureties.length === 0) {
        this.value.sureties.push({});
      }
      this.disableAddSurety = false;
    },
    async deleteSurety(payload) {
      if (typeof payload.id === 'undefined') {
        this.value.sureties.splice(payload.index, 1);
      } else {
        await this.DELETE_SURETY(payload.id);
        this.value.sureties.splice(payload.index, 1);
      }
    },
    async saveIsSuretyRequired(event) {
      const isSuretyRequired = event.target.checked;
      this.$store.dispatch('saveIsSuretyRequired', {
        queryId: this.value.queryId,
        isSuretyRequired,
      });
      this.value.isSuretyRequired = isSuretyRequired;
    },

    copySuretyFields() {
      this.sureties.forEach(key => {
        if (key['identity_code'] == this.value.sureties[0].personalCode) {
          this.$set(this.value.sureties[0], 'name', key.name);
        }
      });
    },

    copyRepresentativeFields() {
      this.representatives.forEach(key => {
        if (key['name'] == this.value.contactName) {
          this.value.contactPersCode = key['identity_code'];
        }
      });
    },

    copyFieldsFromRepresentativeToSurety(index) {
      this.$set(this.value.sureties[index], 'name', this.value.contactName);
      this.$set(
        this.value.sureties[index],
        'personalCode',
        this.value.contactPersCode,
      );
      this.$set(this.value.sureties[index], 'email', this.value.contactEmail);
      this.$set(this.value.sureties[index], 'phone', this.value.contactPhone);
    },
    copyFieldsFromRepresentativeToActualBeneficiary() {
      this.$set(this.value.actualBeneficiary, 'name', this.value.contactName);
      this.$set(
        this.value.actualBeneficiary,
        'personalCode',
        this.value.contactPersCode,
      );
    },
    setCompanyParams() {
      this.$set(this.value, 'custName', this.selected.name);
      this.$set(this.value, 'custRegCode', this.selected.companyCode);
      this.$set(this.value, 'vatNumber', this.selected.vatNumber);
      this.$set(this.value, 'street', this.selected.address);
      this.$set(this.value, 'zipCode', this.selected.postalCode);
      this.$set(this.value, 'city', this.selected.city);
      this.$emit('input', this.value);
    },
    qualifyCustomer() {
      this.$store.dispatch('qualifyCustomer', '10000219').then(response => {
        this.$set(this.value, 'creditInfo', response.data);
      });
    },
    startLimitRequest() {
      this.startNewLimitRequest = true;
      axios.post('/limits/' + this.value.custRegCode, {});
    },
    showManualInputs() {
      if (this.customerInputStatus === 0) {
        this.manualInputBtnText = this.$t('SEARCH');
        this.customerInputStatus = 1;
        this.selected = [];
      } else {
        this.manualInputBtnText = this.$t('ENTER_MANUALLY');
        this.customerInputStatus = 0;
      }

      this.$set(this.value, 'custName', '');
      this.$set(this.value, 'custRegCode', '');
      this.$set(this.value, 'vatNumber', '');
      this.$set(this.value, 'street', '');
      this.$set(this.value, 'zipCode', '');
      this.$set(this.value, 'city', '');
    },
    async submitForm() {
      const validAssetGroup = await this.$validator.validate();
      if (!validAssetGroup) {
        return false;
      }
      this.$emit('next', 1);
    },
  },
  computed: {
    ...mapState('company', ['companies']),
    disableOnActivation: function() {
      if (this.viewName && this.partnerSettings && this.value) {
        return this.value.status === 'CA';
      }
    },
    disableOnStatus: function() {
      return !['P'].includes(this.value.status);
    },
    formsAreValid() {
      return !this.errors.items[0];
    },
    snakeCase() {
      return snakeCase;
    },
  },
};
</script>

<style scoped></style>
