<template>
  <div>
    <Header :header="$t('ACTIVATE_ACCOUNT')" noLogOut />

    <b-alert
      id="alert"
      variant="success"
      dismissible
      :show="!!alert"
      @dismissed="alert = null"
    >
      <p class="mt-2 mb-2">{{ alert }}</p>
    </b-alert>

    <b-container v-if="passwordSet">
      <b-row align-h="center">
        <b-col md="4">
          <b-btn
            type="button"
            class="mt-5"
            variant="primary"
            size="lg"
            block
            exact
            to="/"
            >{{ $t('LOGIN') }}</b-btn
          >
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else class="mt-5">
      <h2 class="card-title">{{ $t('CHOOSE_A_PASSWORD') }}</h2>

      <b-form @submit.prevent="savePassword">
        <b-card>
          <b-form-group
            :label="`${$t('NEW_PASSWORD')}:`"
            :state="validateState('password')"
            :invalid-feedback="$t('PASSWORD_LENGTH_ERROR')"
          >
            <b-input-group>
              <b-form-input
                id="password"
                v-model.trim="password"
                name="password"
                :type="passwordType"
                :placeholder="$t('ENTER_PASSWORD')"
                v-validate="'required|min:6'"
              />
              <b-input-group-append>
                <b-btn type="button" @click="changeType('password')">
                  <img height="20" src="../../assets/eye-black.svg" />
                </b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label="`${$t('REPEAT_PASSWORD')}:`"
            :state="validateRepeatPassword"
            :invalid-feedback="$t('PASSWORD_MATCH_ERROR')"
          >
            <b-input-group>
              <b-form-input
                id="repeatPassword"
                v-model.trim="repeatPassword"
                name="repeatPassword"
                :type="repeatPasswordType"
                :placeholder="$t('ENTER_PASSWORD')"
                v-validate="'required|min:6'"
              />
              <b-input-group-append>
                <b-btn type="button" @click="changeType('repeatPassword')">
                  <img height="20" src="../../assets/eye-black.svg" />
                </b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card>

        <div class="mb-5 mt-4">
          <b-row align-h="center">
            <b-col md="6">
              <b-btn
                type="submit"
                variant="primary"
                size="lg"
                block
                :disabled="!validateRepeatPassword"
                >{{ $t('SAVE') }}</b-btn
              >
            </b-col>
          </b-row>
        </div>
      </b-form>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { Header } from '@/components/common';
import validateState from '../../mixins/validateState';
import { ALERT } from '../../store/alert';
import { mapActions } from 'vuex';

export default {
  name: 'CreatePassword',
  components: { Header },
  mixins: [validateState],
  data() {
    return {
      password: null,
      repeatPassword: null,
      passwordSet: false,
      alert: null,
      passwordType: 'password',
      repeatPasswordType: 'password',
    };
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    savePassword: function() {
      this.$store
        .dispatch('createPassword', {
          password: this.password,
          uuid: this.$route.params.id,
        })
        .then(() => {
          this.alert = this.$t('PASSWORD_SAVED');
          this.passwordSet = true;
        })
        .catch(error => {
          this.ALERT({ message: error.response.data });
        });
    },
    changeType: function(type) {
      this[`${type}Type`] =
        this[`${type}Type`] === 'password' ? 'text' : 'password';
    },
  },
  computed: {
    validateRepeatPassword() {
      if (this.password && this.repeatPassword) {
        return this.repeatPassword === this.password;
      }
    },
  },
};
</script>
