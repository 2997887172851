import axios from 'axios';
import store from '../store';

export const GET_QUERY_MESSAGES = 'GET_QUERY_MESSAGES';
export const SAVE_MESSAGE = 'SAVE_MESSAGE';

const state = {
  newMessage: {},
};

export const actions = {
  async [GET_QUERY_MESSAGES]({ commit }, payload) {
    const response = await axios.get(
      '/message/' + payload + '/find-by-query-id',
    );
    return response.data;
  },
  async [SAVE_MESSAGE]({ commit }, payload) {
    store.state.loading = true;
    const response = await axios.post(
      '/message/' + payload.queryId + '/save',
      payload.message,
    );
    store.state.loading = false;
    return response.data;
  },
};

export const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
