<template>
  <b-card class="mt-4 card-padding-md d-flex">
    <h2 class="card-title card-title-pulled-left flex">
      <span>
        <figure class="card-title-icon">
          <img
            src="../../assets/package-icon.svg"
            class="card-title-image"
            alt="terms"
          />
        </figure>
        {{ $t('TERMS') }}
      </span>
      <div class="card-title-pulled-right">
        <b-icon id="tooltip-target-1" icon="question-circle" />
      </div>
    </h2>

    <b-list-group class="terms">
      <b-tooltip
        target="tooltip-target-1"
        boundary-padding="0"
        placement="bottom"
        variant="dark"
        triggers="hover"
      >
        <b-table
          striped
          :items="refTable"
          head-variant="dark"
          dark
          :fields="['Periood', '12', '24', '36', '48']"
        ></b-table>
      </b-tooltip>

      <b-list-group-item v-if="this.partnerSettings.regCode === '10069046'">
        <b-row align-v="center">
          <b-col md="3" class="text-right">
            <span>0% kampaania</span>
          </b-col>
          <b-col md="9">
            <div class="btn-group">
              <b-form-checkbox
                v-model="zeroInterestCampaign"
                :value="true"
                :unchecked-value="false"
                :disabled="disableOnStatus"
                @change="toggle0Interest()"
                class="mt-0"
              >
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="3" class="text-right">
            <span>{{ $t('PERIOD') }} ({{ $t('MONTHS') }})</span>
          </b-col>
          <b-col md="9">
            <b-button-group>
              <b-button
                @click="
                  changeOption(paymentPeriodOptions, option, 'paymentPeriod'),
                    calculatePmt()
                "
                v-for="(option, index) in paymentPeriodOptions"
                :key="index"
                :class="{ active: option.isSelected }"
                :disabled="disableOnStatus"
              >
                <span>{{ option.value }}</span>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="3" class="text-right">
            <span>{{ $t('RESIDUAL') }} (%)</span>
          </b-col>
          <b-col md="9">
            <b-button-group>
              <b-button
                @click="
                  changeOption(residualOptions, option, 'residual'),
                    calculatePmt()
                "
                v-for="(option, index) in residualOptions"
                :key="index"
                :class="{ active: option.isSelected }"
                :disabled="disableOnStatus"
              >
                <span>{{ option.value }}</span>
              </b-button>
              <input
                @focusout="
                  updateActive(
                    residualOptions,
                    residualInput,
                    'residual',
                    partnerSettings.residualOptions,
                  ),
                    calculatePmt()
                "
                v-model.trim="residualInput"
                type="text"
                placeholder="..."
                @keyup="numerifyWithDecimals('residualInput')"
                :class="[
                  'terms-input',
                  { active: !updateActive(residualOptions, value.residual) },
                ]"
                :disabled="disableOnStatus"
              />
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="3" class="text-right">
            <span>{{ $t('CONTRACT_FEE') }} (€)</span>

            <b-icon class="pl-0 mb-2 ml-1" id="tt-12" icon="question-circle" />
            <b-tooltip target="tt-12" variant="dark" triggers="hover">
              {{ $t('CONTRACT_FEE_TOOLTIP') }}
            </b-tooltip>
          </b-col>
          <b-col md="9">
            <b-button-group>
              <b-button
                @click="
                  changeOption(contractFeeOptions, option, 'contractFee'),
                    calculatePmt()
                "
                v-for="(option, index) in contractFeeOptions"
                :key="index"
                :class="{ active: option.isSelected }"
                :disabled="disableOnStatus"
              >
                <span>{{ option.value }}</span>
              </b-button>

              <input
                @focusout="
                  updateActive(
                    contractFeeOptions,
                    contractFeeInput,
                    'contractFee',
                    partnerSettings.contractFeeOptions,
                  ),
                    calculatePmt()
                "
                v-model.trim="contractFeeInput"
                type="text"
                placeholder="..."
                @keyup="numerifyWithDecimals('contractFeeInput')"
                :class="[
                  'terms-input',
                  {
                    active: !updateActive(
                      contractFeeOptions,
                      value.contractFee,
                    ),
                  },
                ]"
                v-if="partnerSettings.editContractFee"
                :disabled="disableOnStatus"
              />
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>

    <div class="card-footer">
      <div class="total-terms-badge-group">
        <div class="total-terms-badge">
          <span class="total-terms-text">{{ $t('TOTAL_MONTHLY') }}</span>
          <div class="total-terms-tag total-terms-tag-white">
            {{ paymentWithoutVat() }}&nbsp;€
          </div>
        </div>

        <div class="total-terms-badge">
          <span class="total-terms-text">{{ $t('VAT') }}</span>
          <div class="total-terms-tag total-terms-tag-white">
            {{ paymentVat() }}&nbsp;€
          </div>
        </div>

        <div class="total-terms-badge">
          <span class="total-terms-text">{{ $t('TOTAL') }}</span>
          <div class="total-terms-tag total-terms-tag-white">
            {{ paymentWithVat() }}&nbsp;€
          </div>
        </div>
      </div>
    </div>

    <PaymentTableModal
      :show="isPaymentModalOpen"
      @hide="togglePaymentModal"
      :value="value"
      :lendifyInsurance="lendifyInsurance"
    />
  </b-card>
</template>

<script>
import { replaceComma, numerify, numerifyWithDecimals } from '@/utils';
import { PaymentTableModal } from '@/components/common';
import { checkIfValueIsInOptions } from '../../utils';

export default {
  name: 'Terms',
  components: {
    PaymentTableModal,
  },
  props: {
    value: Object,
    partnerSettings: Object,
    viewName: String,
    disableOnStatus: Boolean,
    lendifyInsurance: String,
    computedKickback: Number,
    computedCommission: String,
    interestChanged: Boolean,
  },
  data() {
    return {
      zeroInterestCampaign: this.value.interest === 0,
      isPaymentModalOpen: false,
      showTerms: false,
      paymentPeriodOptions: [],
      residualOptions: [],
      kickbackOptions: [],
      advanceOptions: [],
      interestOptions: [],
      contractFeeOptions: [],
      paymentPeriodInput: '',
      residualInput: '',
      kickbackInput: '',
      advanceInput: '',
      interestInput: '',
      contractFeeInput: '',
      interestMatrixPeriods: [12, 24, 36, 48, 60],
      offerType: '23',
      refTable: [
        {
          Periood: 'Seadmete maksimaalsed jäägid',
          '12': '30%',
          '24': '20%',
          '36': '10%',
          '48': '5%',
        },
      ],
    };
  },
  mounted: function() {
    const zeroContractFee = this.value.contractFee === 0;
    (this.contractFeeOptions = [
      { value: this.partnerSettings.contractFee, isSelected: !zeroContractFee },
      { value: 0, isSelected: zeroContractFee },
    ]),
      (this.paymentPeriodOptions = this.calculatedPeriodOptions(
        this.partnerSettings.paymentPeriodOptions[0],
        this.partnerSettings.paymentPeriodOptions[1],
      ));
    this.residualOptions = this.calculatedOptions(
      this.partnerSettings.residualOptions[0],
      this.partnerSettings.residualOptions[1],
    );
    this.kickbackOptions = this.calculatedOptions(
      this.partnerSettings.kickbackOptions[0],
      this.partnerSettings.kickbackOptions[1],
    );
    this.interestOptions = this.calculatedOptions(
      this.partnerSettings.interestOptions[0],
      this.partnerSettings.interestOptions[1],
    );
    this.advanceOptions = this.calculatedOptions(
      this.partnerSettings.advanceOptions[0],
      this.partnerSettings.advanceOptions[1],
    );

    if (this.value && this.partnerSettings) {
      this.checkDefaultSettingValue('kickback');
      this.checkDefaultSettingValue('advance');

      if (this.value.interest === undefined) {
        this.checkDefaultSettingValue('interest');
      }

      if (this.value.residual === undefined) {
        this.checkDefaultSettingValue('residual');
      }

      if (this.value.paymentPeriod === undefined) {
        this.checkDefaultSettingValue('paymentPeriod');
      }
    }

    this.updateActive(this.paymentPeriodOptions, this.value.paymentPeriod);
  },
  watch: {
    interestChanged() {
      if (this.interestChanged) {
        this.interestInput = this.value.interest;
        this.updateActive(
          this.interestOptions,
          this.value.interest,
          'interest',
          this.partnerSettings.interestOptions,
          true,
        );
        this.$emit('changeInterestChanged');
      }
    },
  },
  methods: {
    toggle0Interest() {
      if (this.zeroInterestCampaign) {
        this.value.interest = 0;
      } else {
        this.value.interest = this.partnerSettings.interestOptions[1];
      }
      this.calculatePmt();
    },
    togglePaymentModal() {
      this.isPaymentModalOpen = !this.isPaymentModalOpen;
    },
    paymentWithVat() {
      return (this.value.pmtAmount * 1.22).toFixed(2);
    },

    paymentWithoutVat() {
      return this.value.pmtAmount.toFixed(2);
    },
    paymentVat() {
      return (this.paymentWithVat() - this.paymentWithoutVat()).toFixed(2);
    },
    calculatePmt() {
      this.$emit('calculatePmt');
    },
    generateOffer(offerType) {
      this.$emit('saveQuery', () => {
        this.$store.dispatch('generateOffer', {
          queryId: this.value.queryId,
          offerType,
        });
      });
    },
    calculatedPeriodOptions: function(min, max) {
      const options = [];
      for (
        let i = 0;
        i < this.partnerSettings.allPmtPeriodOptions.length;
        i++
      ) {
        if (
          min <= parseFloat(this.partnerSettings.allPmtPeriodOptions[i]) &&
          parseFloat(this.partnerSettings.allPmtPeriodOptions[i]) <= max
        ) {
          options.push({
            value: parseFloat(this.partnerSettings.allPmtPeriodOptions[i]),
            isSelected: false,
          });
        }
      }
      return options;
    },
    calculatedOptions: function(min, max) {
      const options = [];
      let maxIndex = 5;
      const minValue = parseFloat(min);
      const maxValue = parseFloat(max);
      const gapSize = maxValue - minValue;
      if (!gapSize) {
        if (minValue) {
          options.push({ value: minValue, isSelected: false });
        } else {
          options.push({ value: maxValue, isSelected: false });
        }
        return options;
      }
      const stepSize = gapSize / 5;
      for (let i = 0; i < 5; i++) {
        if (gapSize === 0) {
          options.push({ value: minValue, isSelected: false });
          break;
        }
        if (i === 0) {
          options.push({ value: minValue, isSelected: false });
          continue;
        }
        const x = parseInt(minValue) + i * stepSize;
        let value = 0;
        if (stepSize >= 8) {
          value = Math.ceil(x / 10) * 10;
        } else if (stepSize < 8 && stepSize >= 3) {
          value = Math.ceil(x / 5) * 5;
        } else if (stepSize < 3 && stepSize >= 1) {
          value = Math.ceil(x / 2) * 2;
        } else if (stepSize < 1) {
          value = Math.ceil(x / 0.5) * 0.5;
        }
        if (
          value === options[options.length - 1].value ||
          value < minValue ||
          value === maxValue
        ) {
          maxIndex -= 1;
          continue;
        }
        options.push({ value: value, isSelected: false });
      }
      if (gapSize !== 0) {
        options.splice(maxIndex, 1, { value: maxValue, isSelected: false });
      }
      return options;
    },
    checkDefaultSettingValue: function(setting) {
      if (
        this.partnerSettings[
          `default${setting.charAt(0).toUpperCase() + setting.slice(1)}`
        ]
      ) {
        this.$set(
          this.value,
          setting,
          this.partnerSettings[
            `default${setting.charAt(0).toUpperCase() + setting.slice(1)}`
          ],
        );
      } else {
        if (setting !== 'paymentPeriod') {
          this.$set(
            this.value,
            setting,
            this.partnerSettings[`${setting}Options`][0],
          );
        } else {
          this.$set(this.value, setting, 12);
        }
      }
      if (
        this[`${setting}Options`]
          .map(option => option.value)
          .includes(parseFloat(this.value[`${setting}`]))
      ) {
        this[`${setting}Input`] = '';
      } else {
        this[`${setting}Input`] = this.value[`${setting}`];
      }
    },
    changeOption(options, option, setting) {
      if (option.value !== '') {
        if (setting !== 'interest') {
          this.$set(this.value, setting, option.value);
        }
        option.isSelected = true;
        this[`${setting}Input`] = '';
      }
      this.updateActive(options, option.value, setting);
    },
    updateActive(
      options,
      value,
      setting,
      partnerOptions,
      changedAutomatically,
    ) {
      value = replaceComma(value);
      if (isNaN(+value)) {
        this[`${setting}Input`] = '';
        return false;
      }

      if (setting === 'contractFee' && value !== '') {
        this.value.contractFee = value;
      }

      if (
        (setting === 'interest' &&
          value !== '' &&
          this.value.interest !== +value &&
          !changedAutomatically) ||
        (setting === 'paymentPeriod' &&
          value !== '' &&
          !this.interestMatrixPeriods.includes(+value))
      ) {
        this.value.interestMatrix = null;
      }

      if (setting !== 'interest') {
        if (partnerOptions) {
          if (+value < partnerOptions[0] && value !== '') {
            value = +partnerOptions[0];
            this[`${setting}Input`] = value;
          } else if (+value > partnerOptions[1] && value !== '') {
            value = +partnerOptions[1];
            this[`${setting}Input`] = value;
          }
        }
      }

      let count = 0;
      if (options && options.length > 0) {
        for (const option of options) {
          const isSelect = option.value === +value;
          option.isSelected = isSelect;
          count = isSelect ? count + 1 : count;
        }
      }

      if (value !== '') {
        this.$set(this.value, setting, +value);
      } else {
        if (checkIfValueIsInOptions(options, this.value[setting])) {
          count = 0;
        } else {
          count = 0;
          this[`${setting}Input`] = this.value[setting];
        }
      }

      if (count === 0) {
        return false;
      } else {
        this[`${setting}Input`] = '';
        return true;
      }
    },
    updateOneValueTerm(term) {
      if (this.value[term] !== '') return;
      this.value[term] = this.partnerSettings[term];
    },
    updateTermInputs() {
      this.paymentPeriodInput = this.value.paymentPeriod;
      this.advanceInput = this.value.advance;
      this.residualInput = this.value.residual;
      this.kickbackInput = this.value.kickback;
      if (
        this.checkIfValueIsInOptions(
          this.paymentPeriodOptions,
          this.value.paymentPeriod,
        )
      ) {
        this.paymentPeriodInput = '';
        this.updateActive(this.paymentPeriodOptions, this.value.paymentPeriod);
      }
      if (this.checkIfValueIsInOptions(this.advanceOptions, this.value.advance))
        this.advanceInput = '';
      if (
        this.checkIfValueIsInOptions(this.residualOptions, this.value.residual)
      )
        this.residualInput = '';
      if (
        this.checkIfValueIsInOptions(this.kickbackOptions, this.value.kickback)
      )
        this.kickbackInput = '';
    },
  },
  computed: {
    numerify() {
      return numerify;
    },
    numerifyWithDecimals() {
      return numerifyWithDecimals;
    },
    checkIfValueIsInOptions() {
      return checkIfValueIsInOptions;
    },
  },
};
</script>
