<template>
  <b-container>
    <b-card class="mt-5">
      <b-row align-h="center">
        <b-col md="6" class="text-center">
          <img
            src="../assets/logo-teenusliising.png"
            alt="Fairown"
            height="110"
            width="137"
          />
          <b-alert
            :show="!!message"
            variant="danger"
            class="text-center mt-4"
            >{{ message }}</b-alert
          >
          <b-form @submit.prevent="login" class="text-center mt-4">
            <b-form-group
              :invalid-feedback="$t('VALIDATION.INVALID_EMAIL')"
              :state="validateState('email')"
            >
              <b-form-input
                class="text-center"
                autocomplete="email"
                id="email"
                name="email"
                v-validate="'email'"
                :placeholder="$t('EMAIL')"
                v-model.trim="email"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                class="text-center"
                autocomplete="current-password"
                id="password"
                type="password"
                name="password"
                v-validate="'required'"
                :placeholder="$t('PASSWORD')"
                v-model.trim="password"
              />
            </b-form-group>
            <b-btn variant="primary" type="submit">{{ $t('LOGIN') }}</b-btn>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import validateState from '../mixins/validateState';
import { mapState } from 'vuex';

export default {
  name: 'Login',
  mixins: [validateState],
  data: function() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    login: function() {
      this.$store
        .dispatch('login', {
          user: {
            username: this.email,
            password: this.password,
          },
        })
        .then(() => {
          this.email = this.$store.state.alert ? '' : this.email;
          this.password = '';
        });
    },
  },
  computed: {
    ...mapState('alert', ['message']),
  },
};
</script>

<style scoped></style>
