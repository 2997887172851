<template>
  <div>
    <b-navbar toggleable="md" variant="info">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand to="/">
        <img src="../../assets/home.png" alt="home" />
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav class="icon-box">
          <li>
            <b-img class="header-icon" v-if="logo !== ''" :src="logo" fluid />
            <b-img
              class="header-icon"
              v-else
              src="../../assets/logo.png"
              fluid
            />
          </li>
        </b-navbar-nav>

        <b-alert
          :variant="type === 'error' ? 'danger' : 'success'"
          dismissible
          :show="!!message"
          @dismissed="RESET_ALERT"
          class="custom-alert"
        >
          {{ message }}
        </b-alert>

        <div class="d-flex ml-auto align-items-center mr-3">
          <b-dropdown :text="currentLanguage" class="language-dropdown">
            <b-dropdown-item
              v-for="lang in languages"
              :key="lang"
              @click="changeLanguage(lang)"
              >{{ $t(`LANGUAGES.${lang}`) }}</b-dropdown-item
            >
          </b-dropdown>

          <b-dropdown
            :text="user.fullName"
            variant="primary"
            class="profile-dropdown"
            right
          >
            <template #button-content>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                fill="#fff"
                width="24"
              >
                <path
                  d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z"
                />
              </svg>
              {{ user.fullName }}
            </template>

            <b-dropdown-item :to="{ name: 'Settings' }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path
                  d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm112-260q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Z"
                />
              </svg>
              <span class="ml-0">
                {{ $t('SETTINGS') }}
              </span>
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="logout()" v-if="!noLogOut">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path
                  d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"
                />
              </svg>
              {{ $t('LOGOUT') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-collapse>
    </b-navbar>

    <b-jumbotron :header="header">
      <div>
        <b-btn
          v-if="this.role === 'SHOP'"
          class="btn-shadow header-button"
          href="/static/pdf/Vara üleadmise _ vastuvõtu ankeet.pdf"
          target="_blank"
          exact
        >
          {{ $t('RETURN') }}
        </b-btn>

        <template v-for="action in actions">
          <b-btn
            v-if="action.to"
            :key="action.text"
            class="btn-shadow header-button"
            :to="action.to"
            exact
          >
            {{ action.text }}
          </b-btn>

          <b-dropdown
            v-if="action.children"
            :key="action.text"
            :text="action.text"
            toggle-class="btn-shadow"
            right
          >
            <b-dropdown-item
              v-for="child in action.children"
              :key="child.text"
              :to="child.to"
              exact
            >
              <span> {{ child.text }} </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </div>
    </b-jumbotron>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { RESET_ALERT } from '../../store/alert';

export default {
  name: 'Header',
  props: {
    role: String,
    header: String,
    showSettings: Boolean,
    noLogOut: Boolean,
  },
  data() {
    return {
      featureSelfServe: false,
      featureOnSpot: false,
      user: Object,
    };
  },
  methods: {
    ...mapActions('alert', [RESET_ALERT]),
    logout: function() {
      this.$store.dispatch('logout');
    },
    changeLanguage: function(locale) {
      if (this.isPublicRoute)
        this.$store.dispatch('changeLocaleLocally', locale);
      else this.$store.dispatch('changeLocale', locale);
    },
    showContacts() {
      return this.$store.state.role === 'SHOP';
    },
  },
  async mounted() {
    if (!this.logo && !this.noLogOut) this.$store.dispatch('getPartnerLogo');
    await this.$store
      .dispatch('getPartnerDetails')
      .then(response => {
        const partner = response.data;
        this.featureSelfServe = partner.featureSelfServe;
        this.featureOnSpot = partner.featureOnSpot;
      })
      .catch(error => {
        console.log(error);
      });

    this.$store.dispatch('getLoggedInUser').then(response => {
      this.user = response.data;
    });
  },
  computed: {
    ...mapState(['logo']),
    ...mapState('alert', ['message', 'type']),
    actions() {
      switch (this.role) {
        case 'PARTNER':
          return [
            {
              text: this.$t('CREATE_QUERY'),
              to: { name: 'PartnerCreateQuery' },
            },
            {
              text: this.$t('TEMPLATES'),
              to: { name: 'Templates' },
            },
          ];
        case 'SHOP': {
          const items = [];

          if (this.featureSelfServe && !this.featureOnSpot) {
            items.push({
              text: this.$t('CREATE_OFFER'),
              to: { name: 'SelfServeOffer' },
            });
            items.push({
              text: this.$t('CREATE_SELFSERVE_QUERY_TO_CUSTOMER'),
              to: { name: 'SelfServeApplication' },
            });
          }

          if (this.featureOnSpot && this.featureSelfServe) {
            items.push({
              text: this.$t('CREATE_OFFER'),
              to: { name: 'SelfServeOffer' },
            });

            items.push({
              text: this.$t('CREATE_APPLICATION'),
              children: [
                {
                  text: this.$t('CREATE_APPLICATION_QUERY'),
                  to: { name: 'ShopCreateQuery' },
                },
                {
                  text: this.$t('CREATE_SELFSERVE_QUERY'),
                  to: { name: 'SelfServeApplication' },
                },
              ],
            });
          }

          if (this.featureOnSpot && !this.featureSelfServe) {
            items.push({
              text: this.$t('CREATE_OFFER'),
              to: { name: 'SelfServeOffer' },
            });

            items.push({
              text: this.$t('NEW_APPLICATION'),
              to: { name: 'ShopCreateQuery' },
            });
          }

          return items;
        }
        case 'COMPLIANCE':
          return [
            {
              text: this.$t('ASSET_GROUPS'),
              to: { name: 'ManageAssetGroups' },
            },
            {
              text: this.$t('PARTNERS'),
              to: { name: 'ManagePartners' },
            },
            {
              text: this.$t('USERS'),
              to: { name: 'ManageUsers' },
            },
          ];
        default:
          return null;
      }
    },
    languages() {
      return this.$store.state.languages;
    },
    currentLanguage() {
      const locale = localStorage.getItem('locale') || 'en';
      return this.$t('LANGUAGES.' + locale);
    },
    isPublicRoute() {
      return !this.$route.meta.requiresAuth;
    },
  },
};
</script>
