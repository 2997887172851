<template>
  <b-container>
    <h2 class="card-title">
      <div class="d-flex">
        <span class="text-nowrap">
          <i class="card-title-icon">+</i>
          {{ $t('ADD_CLIENT') }}
        </span>
        <b-form-group
          :label="$t('MANAGER_SHORT')"
          label-for="client-query-manager"
          class="form-group-manager position-relative mb-0"
        >
          <b-form-select
            @change="changeManager()"
            :disabled="
              !isInhouse && viewName !== 'credit' && viewName !== 'compliance'
            "
            id="client-query-manager"
            v-model="value.managerId"
            class="form-control number number-lg query-manager"
          >
            <option
              v-for="(partner, index) in inHousePartners"
              :key="index"
              :value="partner.accountUserId"
              >{{ partner.fullName }}</option
            >
          </b-form-select>
        </b-form-group>
      </div>

      <div class="switch" v-if="isInhouse">
        <span class="switch-text">{{ $t('BUSINESS') }}</span>
        <input type="checkbox" class="switch-input" id="switch" />
        <label for="switch" class="switch-label">Toggle</label>
        <span class="switch-text">{{ $t('CONSUMER') }}</span>
      </div>

      <Limit />
    </h2>
    <b-card class="card-padding-md">
      <b-form>
        <b-row>
          <b-col md="12">
            <h3>{{ $t('CLIENT') }}</h3>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('BUSINESS_NAME') + ':'"
              label-for="custName"
            >
              <multiselect
                v-model="selected"
                id="custName"
                :options="companies"
                :loading="isLoading"
                label="name"
                @search-change="getCompaniesByName"
                @close="setCompanyParams"
                :placeholder="$t('SEARCH')"
                :disabled="disableOnStatus || customerInputStatus !== 0"
                :show-labels="false"
              >
                <template slot="noResult">
                  {{ $t('NO_ELEMENTS_FOUND') }}
                </template>
              </multiselect>
              <b-form-input
                class="mt-2"
                v-if="customerInputStatus !== 0"
                v-model.trim="value.custName"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
            <b-btn
              size="sm"
              @click="showManualInputs()"
              :disabled="disableOnStatus"
              class="mb-3"
            >
              {{ manualInputBtnText }}
            </b-btn>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('REGISTRY_CODE') + ':'"
              label-for="custRegCode"
            >
              <multiselect
                v-model="selected"
                id="custReqCode"
                :options="companies"
                :loading="isLoading"
                label="companyCode"
                @search-change="getCompaniesByCode"
                @close="setCompanyParams"
                :placeholder="$t('SEARCH')"
                :disabled="disableOnStatus || customerInputStatus !== 0"
                :show-labels="false"
              >
                <template slot="noResult">
                  {{ $t('NO_ELEMENTS_FOUND') }}
                </template>
              </multiselect>
              <b-form-input
                class="mt-2"
                v-if="customerInputStatus !== 0"
                v-model.trim="value.custRegCode"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group :label="$t('VAT_CODE') + ':'" label-for="vatNumber">
              <b-form-input
                id="vatNumber"
                v-model.trim="value.vatNumber"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6"> </b-col>
        </b-row>
        <b-row class="mt-5" v-if="isInhouse">
          <b-col md="12">
            <h3>{{ $t('CONTACT') }}</h3>
          </b-col>
          <b-col md="5">
            <b-form-group :label="$t('STREET') + ':'" label-for="street">
              <b-form-input
                id="street"
                v-model.trim="value.street"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              :label="$t('HOUSE_NUMBER') + ':'"
              label-for="houseNumber"
            >
              <b-form-input
                id="houseNumber"
                v-model.trim="value.houseNumber"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              :label="$t('FLAT_NUMBER') + ':'"
              label-for="flatNumber"
            >
              <b-form-input
                id="flatNumber"
                v-model.trim="value.flatNumber"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group :label="$t('POSTAL_CODE') + ':'" label-for="zipCode">
              <b-form-input
                id="zipCode"
                v-model.trim="value.zipCode"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="$t('CITY') + ':'" label-for="city">
              <b-form-input
                id="city"
                v-model.trim="value.city"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="$t('COUNTY') + ':'" label-for="county">
              <multiselect
                id="county"
                v-model.trim="value.county"
                :options="countyList"
                :disabled="disableOnStatus"
                :show-labels="false"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="$t('COUNTRY') + ':'" label-for="country">
              <multiselect
                v-model="value.country"
                id="country"
                :options="countries.map(x => x.code)"
                :custom-label="
                  opt => (countries.find(x => x.code == opt) || {}).name
                "
                :disabled="disableOnStatus"
                :show-labels="false"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('BILLING_EMAIL') + ':'"
              label-for="billingEmail"
              :invalid-feedback="$t('VALIDATION.INVALID_EMAIL')"
              :state="validateState('billingEmail')"
            >
              <b-form-input
                id="billingEmail"
                name="billingEmail"
                type="text"
                v-validate="'required|email'"
                v-model.trim="value.billingEmail"
                :disabled="disableOnActivation"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('BANK_ACCOUNT_NUMBER') + ':'"
              label-for="bankAccountNumber"
            >
              <b-form-input
                id="bankAccountNumber"
                v-model="value.bankAccountNumber"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col md="12">
            <h3>{{ $t('REPRESENTATIVE') }}</h3>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('NAME') + ':'"
              label-for="contactName"
              :invalid-feedback="$t('VALIDATION.REQUIRED')"
              :state="validateState('contactName')"
            >
              <b-form-input
                id="contactName"
                v-model.trim="value.contactName"
                type="text"
                name="contactName"
                v-validate="'required'"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="isInhouse">
            <b-form-group
              :label="$t('SELLER_REPRS_REPR_TYPE') + ':'"
              label-for="contactPersType"
            >
              <b-form-radio-group
                id="contactPersType"
                button-variant="outline-secondary"
                v-model="value.contactPersType"
                :disabled="disableOnStatus"
              >
                <b-form-radio
                  v-for="option in options"
                  :key="option"
                  :value="option"
                  class="mt-2"
                >
                  {{ $t(snakeCase(option).toUpperCase()) }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('PHONE') + ':'" label-for="contactPhone">
              <b-form-input
                id="contactPhone"
                type="text"
                v-model.trim="value.contactPhone"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('EMAIL') + ':'"
              label-for="contactEmail"
              :invalid-feedback="$t('VALIDATION.INVALID_EMAIL')"
              :state="validateState('contactEmail')"
            >
              <b-form-input
                id="contactEmail"
                name="contactEmail"
                type="text"
                v-model.trim="value.contactEmail"
                v-validate="'required|email'"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="isInhouse">
            <b-form-group
              :label="$t('ID_CODE') + ':'"
              label-for="contactPersCode"
            >
              <b-form-input
                id="contactPersCode"
                v-model.trim="value.contactPersCode"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="isInhouse">
            <b-form-group
              :label="$t('DOCUMENT_TYPE') + ':'"
              label-for="representativeDocumentType"
            >
              <multiselect
                v-model="value.representativeDocumentType"
                id="representativeDocumentType"
                :options="documentTypes"
                :custom-label="opt => $t(opt.toUpperCase())"
                label="name"
                :disabled="disableOnStatus"
                :show-labels="false"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="isInhouse">
            <b-form-group
              :label="$t('DOCUMENT_NUMBER') + ':'"
              label-for="representativeDocumentNumber"
            >
              <b-form-input
                id="representativeDocumentNumber"
                type="text"
                v-model.trim="value.representativeDocumentNumber"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="isInhouse">
            <b-form-group
              :label="$t('VALID_UNTIL')"
              label-for="representativeDocumentValidUntil"
            >
              <RenderDatePicker
                @changeDate="
                  e => $set(value, 'representativeDocumentValidUntil', e)
                "
                :date="value.representativeDocumentValidUntil"
                id="representativeDocumentValidUntil"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-5" v-if="isInhouse">
          <b-col md="12">
            <h3>{{ $t('ACTUAL_BENEFICIARY') }}</h3>
          </b-col>
          <b-col md="12">
            <div class="mb-3">
              <b-btn
                size="sm"
                @click="copyFieldsFromRepresentativeToActualBeneficiary()"
              >
                {{ $t('COPY_FROM_REPRESENTATIVE_FIELDS') }}
              </b-btn>
            </div>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('NAME') + ':'" label-for="beneficiaryName">
              <b-form-input
                id="beneficiaryName"
                v-model.trim="value.actualBeneficiary.name"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('RESIDENCY') + ':'"
              label-for="beneficiaryResidency"
            >
              <multiselect
                v-model="value.actualBeneficiary.residency"
                id="beneficiaryResidency"
                :options="countries.map(x => x.code)"
                :custom-label="opt => countries.find(x => x.code == opt).name"
                :disabled="disableOnStatus"
                :show-labels="false"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('ID_CODE') + ':'"
              label-for="beneficiaryPersonalCode"
            >
              <b-form-input
                id="beneficiaryPersonalCode"
                v-model.trim="value.actualBeneficiary.personalCode"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('IS_PUBLIC_PERSON') + ' ?'"
              label-for="isPublicPerson"
            >
              <multiselect
                v-model="value.actualBeneficiary.isPublicPerson"
                id="isPublicPerson"
                :options="PublicPersonOptions"
                :custom-label="opt => $t(opt.toUpperCase())"
                :disabled="disableOnStatus"
                :show-labels="false"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mb-3">
            <b-form-checkbox
              id="beneficiaryNoPersonalCode"
              v-model="value.actualBeneficiary.beneficiaryNoPersonalCode"
              name="beneficiaryNoPersonalCode"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t('NO_PERSONAL_CODE') }}
            </b-form-checkbox>
          </b-col>
          <b-col
            md="4"
            v-if="value.actualBeneficiary.beneficiaryNoPersonalCode"
          >
            <b-form-group
              :label="$t('DATE_OF_BIRTH')"
              label-for="beneficiaryDateOfBirth"
            >
              <RenderDatePicker
                @changeDate="
                  e => $set(value.actualBeneficiary, 'dateOfBirth', e)
                "
                :date="value.actualBeneficiary.dateOfBirth"
                id="beneficiaryDateOfBirth"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            v-if="value.actualBeneficiary.beneficiaryNoPersonalCode"
          >
            <b-form-group
              :label="$t('PLACE_OF_BIRTH')"
              label-for="beneficiaryPlaceOfBirth"
            >
              <b-form-input
                id="beneficiaryPlaceOfBirth"
                v-model.trim="value.actualBeneficiary.placeOfBirth"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            v-if="value.actualBeneficiary.beneficiaryNoPersonalCode"
          >
            <b-form-group
              :label="$t('CURRENT_PLACE_OF_LIVING')"
              label-for="beneficiaryPlaceOfLiving"
            >
              <b-form-input
                id="beneficiaryPlaceOfLiving"
                v-model.trim="value.actualBeneficiary.currentPlaceOfLiving"
                type="text"
                :disabled="disableOnStatus"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-form-group>
            <span class="credit-info">{{ this.value.creditInfo }}</span>
          </b-form-group>
        </b-row>
      </b-form>
    </b-card>

    <h2 class="mt-4 card-title" v-if="isInhouse">
      <span>
        <i class="card-title-icon">+</i>
        {{ $t('ADD_SURETY') }}
      </span>
      <b-btn
        v-if="value.isSuretyRequired"
        variant="primary"
        @click="addSurety()"
        :disabled="disableOnStatus || !formsAreValid || disableAddSurety"
        >{{ $t('ADD_SURETY') }}</b-btn
      >
    </h2>

    <div class="switch my-3" v-if="isInhouse">
      <span>{{ $t('IS_SURETY_REQUIRED') }}</span>
      <span class="switch-text">{{ $t('NO') }}</span>
      <input
        type="checkbox"
        class="switch-input"
        id="includeSuretyInContraact"
        :v-model="value.isSuretyRequired"
        @change="saveIsSuretyRequired"
        :checked="value.isSuretyRequired"
      />
      <label for="includeSuretyInContraact" class="switch-label"> </label>
      <span class="switch-text">{{ $t('YES') }}</span>
    </div>

    <template v-if="isInhouse">
      <b-card v-for="(surety, index) in value.sureties" :key="index">
        <b-form>
          <div class="d-flex justify-content-between mb-3">
            <b-btn
              size="sm"
              @click="copyFieldsFromRepresentativeToSurety(index)"
            >
              {{ $t('COPY_FROM_REPRESENTATIVE_FIELDS') }}
            </b-btn>
            <b-btn
              size="sm"
              class="btn-close"
              v-if="!disableOnStatus"
              @click="deleteSurety({ id: surety.suretyId, index: index })"
            >
              <img src="../../assets/delete-icon-w.svg" class="img-fluid" />
            </b-btn>
          </div>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('FULL_NAME') + ':'"
                :label-for="`name-${index}`"
              >
                <b-form-input
                  :id="`name-${index}`"
                  type="text"
                  v-model.trim="surety.name"
                  required
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ID_CODE') + ':'"
                :label-for="`personalCode-${index}`"
                :invalid-feedback="$t('VALIDATION.INVALID_ID_CODE')"
                :state="validateState(`personalCode-${index}`)"
              >
                <b-form-input
                  :id="`personalCode-${index}`"
                  :name="`personalCode-${index}`"
                  v-validate="'idcode|required'"
                  v-model.trim="surety.personalCode"
                  type="text"
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('EMAIL') + ':'"
                :label-for="`email-${index}`"
                :invalid-feedback="$t('VALIDATION.INVALID_EMAIL')"
                :state="validateState('suretyEmail')"
              >
                <b-form-input
                  :id="`email-${index}`"
                  type="text"
                  name="suretyEmail"
                  v-validate="'email'"
                  v-model.trim="surety.email"
                  required
                  :disabled="disableOnActivation"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('PHONE') + ':'"
                :label-for="`phone-${index}`"
              >
                <b-form-input
                  :id="`phone-${index}`"
                  type="text"
                  v-model.trim="surety.phone"
                  required
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ADDRESS') + ':'"
                :label-for="`address-${index}`"
              >
                <b-form-input
                  :id="`address-${index}`"
                  type="text"
                  v-model.trim="surety.address"
                  required
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('COMMENT_OPTIONAL') + ':'"
                :label-for="`comment-${index}`"
              >
                <b-form-input
                  :id="`comment-${index}`"
                  type="text"
                  v-model.trim="surety.comment"
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </template>

    <Guarantor
      v-model="value"
      v-if="isInhouse"
      :disableOnStatus="disableOnStatus"
    />

    <div class="mb-5 mt-4">
      <b-row align-h="center">
        <b-col md="6">
          <b-button
            variant="primary"
            :disabled="!formsAreValid"
            size="lg"
            @click="submitForm"
            block
            >{{ $t('SAVE_AND_GO_NEXT') }}</b-button
          >
          <!-- temporarily hidden -->
          <b-button
            variant="secondary"
            v-if="false"
            size="lg"
            @click="qualifyCustomer()"
            block
            >{{ $t('QUALIFY_CUSTOMER') }}</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import snakeCase from 'lodash.snakecase';
import { getData as getCountriesList } from 'country-list';
import Multiselect from 'vue-multiselect';
import { ALERT } from '../../store/alert';
import { CHANGE_MANAGER } from '../../store/query';
import { mapActions, mapState } from 'vuex';
import validateState from '../../mixins/validateState';
import { RenderDatePicker, Guarantor } from '@/components/common';
import { SAVE_SURETIES, DELETE_SURETY } from '../../store/surety';
import { GET_BY_NAME, GET_BY_COMPANY_CODE } from '../../store/company';
import { disableOnStatus } from '../../utils';
import Limit from '@/components/common/Limit';

export default {
  name: 'ClientTab',
  components: { Limit, Multiselect, RenderDatePicker, Guarantor },
  mixins: [validateState],
  props: [
    'value',
    'viewName',
    'partnerSettings',
    'inHousePartners',
    'isInhouse',
  ],
  data: function() {
    return {
      isLoading: false,
      selected: [],
      options: ['Law', 'Power of Attorney'],
      companyNameInput: '',
      companyCodeInput: '',
      manualInputBtnText: this.$t('ENTER_MANUALLY'),
      customerInputStatus: 0,
      disableAddSurety: false,
      PublicPersonOptions: [
        'no',
        'yes_in_estonia',
        'yes_in_european_union',
        'yes_in_third_country',
      ],
      documentTypes: [
        'id_card',
        'drivers_license',
        'passport',
        'foreigner_passport',
        'residence_permit',
        'commercial_registry_statement',
      ],
      countries: getCountriesList(),
      countyList: [
        'Harju maakond',
        'Hiiu maakond',
        'Ida – viru maakond',
        'Jõgeva maakond',
        'Järva maakond',
        'Lääne maakond',
        'Lääne viru maakond',
        'Põlva maakond',
        'Pärnu maakond',
        'Rapla maakond',
        'Saare maakond',
        'Tartu maakond',
        'Valga maakond',
        'Viljandi maakond',
        'Võru maakond',
      ],
    };
  },
  mounted() {
    if (this.value) {
      this.selected = {
        name: this.value.custName,
        companyCode: this.value.custRegCode,
      };
    }
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    ...mapActions('query', [CHANGE_MANAGER]),
    ...mapActions('surety', [SAVE_SURETIES, DELETE_SURETY]),
    ...mapActions('company', [GET_BY_NAME, GET_BY_COMPANY_CODE]),
    async changeManager() {
      this.$nextTick(async () => {
        await this.CHANGE_MANAGER({
          queryId: this.value.queryId,
          managerId: this.value.managerId,
        });
      });
    },
    async getCompaniesByName(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_NAME(query);
        this.isLoading = false;
      }
    },
    async getCompaniesByCode(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_COMPANY_CODE(query);
        this.isLoading = false;
      }
    },
    async addSurety() {
      this.disableAddSurety = true;
      this.value.sureties.push({});
      this.disableAddSurety = false;
    },
    async deleteSurety(payload) {
      if (typeof payload.id === 'undefined') {
        this.value.sureties.splice(payload.index, 1);
      } else {
        await this.DELETE_SURETY(payload.id);
        this.value.sureties.splice(payload.index, 1);
      }
    },
    async saveIsSuretyRequired(event) {
      const isSuretyRequired = event.target.checked;
      this.$store.dispatch('saveIsSuretyRequired', {
        queryId: this.value.queryId,
        isSuretyRequired,
      });
      this.value.isSuretyRequired = isSuretyRequired;
    },
    copyFieldsFromRepresentativeToSurety(index) {
      this.$set(this.value.sureties[index], 'name', this.value.contactName);
      this.$set(
        this.value.sureties[index],
        'personalCode',
        this.value.contactPersCode,
      );
      this.$set(this.value.sureties[index], 'email', this.value.contactEmail);
      this.$set(this.value.sureties[index], 'phone', this.value.contactPhone);
    },
    copyFieldsFromRepresentativeToActualBeneficiary() {
      this.$set(this.value.actualBeneficiary, 'name', this.value.contactName);
      this.$set(
        this.value.actualBeneficiary,
        'personalCode',
        this.value.contactPersCode,
      );
    },
    setCompanyParams() {
      this.$set(this.value, 'custName', this.selected.name);
      this.$set(this.value, 'custRegCode', this.selected.companyCode);
      this.$set(this.value, 'vatNumber', this.selected.vatNumber);
      this.$set(this.value, 'street', this.selected.address);
      this.$set(this.value, 'zipCode', this.selected.postalCode);
      this.$set(this.value, 'city', this.selected.city);
      this.$emit('input', this.value);
    },
    qualifyCustomer() {
      this.$store.dispatch('qualifyCustomer', '10000219').then(response => {
        this.$set(this.value, 'creditInfo', response.data);
      });
    },
    showManualInputs() {
      if (this.customerInputStatus === 0) {
        this.manualInputBtnText = this.$t('SEARCH');
        this.customerInputStatus = 1;
        this.selected = [];
      } else {
        this.manualInputBtnText = this.$t('ENTER_MANUALLY');
        this.customerInputStatus = 0;
      }

      this.$set(this.value, 'custName', '');
      this.$set(this.value, 'custRegCode', '');
      this.$set(this.value, 'vatNumber', '');
      this.$set(this.value, 'street', '');
      this.$set(this.value, 'zipCode', '');
      this.$set(this.value, 'city', '');
    },
    async submitForm() {
      const validAssetGroup = await this.$validator.validate();
      if (!validAssetGroup) {
        return false;
      }
      this.$emit('next', 1);
    },
  },
  computed: {
    ...mapState('company', ['companies']),
    disableOnActivation: function() {
      if (this.viewName && this.partnerSettings && this.value) {
        return this.value.status === 'CA';
      }
    },
    disableOnStatus() {
      return disableOnStatus(
        this.viewName,
        this.partnerSettings,
        this.value,
        this.isInhouse,
      );
    },
    formsAreValid() {
      return !this.errors.items[0];
    },
    snakeCase() {
      return snakeCase;
    },
  },
};
</script>

<style scoped></style>
