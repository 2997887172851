<template>
  <b-form-group
    label-for="kickback"
    class="mb-0 pr-0 ml-2"
    v-if="kickbackAmount > 0 && partner.showKickback"
  >
    <label for="kickback" class="d-block"
      >{{ $t('KICKBACK') + ':' }}
      <b-icon class="pl-0" id="tt-11" icon="question-circle" />
      <b-tooltip target="tt-11" variant="dark" triggers="hover">
        {{ $t('KICKBACK_TOOLTIP') }}
      </b-tooltip>
    </label>
    <b-form-input
      readonly
      size="sm"
      type="text"
      style="width: 100px; background-color: darkseagreen;"
      id="kickbackAmount"
      name="kickbackAmount"
      v-model.trim="kickbackWithEur"
    >
    </b-form-input>
  </b-form-group>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Kickback',
  mixins: [],
  timer: null,
  props: {
    partner: Object,
    contractAmount: Number,
    contractFee: Number,
  },
  data: function() {
    return {};
  },
  computed: {
    show() {
      if (this.partner === undefined || this.partner === null) {
        return false;
      }
      if (this.kickbackAmount <= 0) {
        return false;
      }
      if (
        this.partner.kickbackCoefficient === undefined ||
        this.partner.kickbackCoefficient === null ||
        this.partner.kickbackCoefficient === 0
      ) {
        return false;
      }

      if (
        this.partner.defaultKickback === undefined ||
        this.partner.defaultKickback === null ||
        this.partner.defaultKickback === 0
      ) {
        return false;
      }

      if (
        this.contractAmount === undefined ||
        this.contractAmount === null ||
        this.contractAmount === 0
      ) {
        return false;
      }

      return this.partner.showKickback;
    },

    kickbackWithEur() {
      return this.kickbackAmount + ' €';
    },

    kickbackAmount() {
      if (this.partner === undefined || this.partner === null) {
        return 0;
      }
      if (
        this.contractAmount === undefined ||
        this.contractAmount === null ||
        this.contractFee === undefined ||
        this.contractFee === null
      ) {
        return 0;
      }

      const amount =
        (this.contractAmount * (this.partner.defaultKickback / 100) +
          this.contractFee) *
        this.partner.kickbackCoefficient;

      return Math.round((amount + Number.EPSILON) * 100) / 100;
    },
  },
};
</script>
