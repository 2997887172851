<template>
  <div>
    <Header :header="$t('PARTNERS')" :role="role" showSettings />
    <b-container class="container-wide compliance-partner">
      <b-row class="mt-4" align-h="end">
        <b-col md="4">
          <b-btn
            variant="primary"
            size="md"
            block
            :to="{ name: 'CreatePartner' }"
            exact
            >{{ $t('CREATE_PARTNER') }}</b-btn
          >
        </b-col>
      </b-row>
      <b-card class="mt-5">
        <b-form>
          <b-form-group class="mb-0 search-form">
            <b-input-group class="search-input">
              <b-form-input
                :autofocus="true"
                v-model="filter"
                :placeholder="$t('TYPE_TO_SEARCH')"
              />
              <b-input-group-append>
                <b-btn type="button" @click="filter = ''" :disabled="!filter">{{
                  $t('CLEAR')
                }}</b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>
        <b-table
          primary-key="partnerId"
          outlined
          :items="partners"
          :fields="fields"
          :filter="filter"
          class="mt-2"
          :busy="loading"
          responsive
          :filter-function="customFilter"
        >
          <template #cell(comment)="data">
            <div
              class="text-truncate cursor-pointer w-200"
              @click="e => e.target.classList.toggle('text-truncate')"
              role="button"
            >
              {{ data.value }}
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="actions">
              <router-link
                :to="`/compliance/partner/${row.item.partnerId}/edit`"
                v-b-tooltip.hover
                :title="$t('EDIT')"
              >
                <img alt="edit" src="../../assets/edit-icon.svg" />
              </router-link>
              <span v-b-tooltip.hover :title="$t('DELETE')">
                <a @click="openDeleteModal(row.item.partnerId)">
                  <img alt="delete" src="../../assets/delete-icon.svg" />
                </a>
              </span>
            </div>
          </template>
          <template #table-busy>
            <RenderSpinner :fixed="false" />
          </template>
        </b-table>
        <RenderDeleteModal
          :show="showDeleteModal"
          @ok="closeDeleteModal(true)"
          @cancel="closeDeleteModal()"
          :label="$t('ARE_YOU_SURE_DELETE_PARTNER')"
        />
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { Header, RenderDeleteModal, RenderSpinner } from '@/components/common';
import { mapState } from 'vuex';

export default {
  name: 'ManagePartners',
  components: {
    Header,
    RenderDeleteModal,
    RenderSpinner,
  },
  mounted() {
    this.fetchPartners();
  },
  data: function() {
    return {
      fields: [
        {
          key: 'partnerId',
          label: this.$t('PARTNER') + ' ID',
          sortable: true,
        },
        {
          key: 'businessName',
          label: this.$t('BUSINESS_NAME'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('EMAIL'),
          sortable: true,
        },
        {
          key: 'phoneNumber',
          label: this.$t('PHONE'),
        },
        {
          key: 'manager.fullName',
          label: this.$t('MANAGER_SHORT'),
        },
        {
          key: 'comment',
          label: this.$t('COMMENT'),
        },
        {
          key: 'formattedModifiedDtime',
          label: this.$t('MODIFIED'),
        },
        {
          key: 'actions',
          label: this.$t('EDIT'),
          sortable: false,
        },
      ],
      partners: [],
      filter: null,
      showDeleteModal: false,
      selectedPartner: 0,
    };
  },
  methods: {
    fetchPartners() {
      this.$store
        .dispatch('getPartners')
        .then(response => {
          this.partners = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    customFilter(item, criteria) {
      if (item.partnerId === +criteria) return true;
      if (
        item.manager &&
        item.manager.fullName.toLowerCase().includes(criteria)
      )
        return true;
      if (
        item.businessName &&
        item.businessName.toLowerCase().includes(criteria)
      )
        return true;
      if (item.email && item.email.toLowerCase().includes(criteria))
        return true;
      return false;
    },
    openDeleteModal(id) {
      this.showDeleteModal = true;
      this.selectedPartner = id;
    },
    closeDeleteModal(doDelete) {
      this.showDeleteModal = false;
      if (doDelete) {
        this.$store.dispatch('deletePartner', this.selectedPartner).then(() => {
          this.selectedPartner = 0;
          this.fetchPartners();
        });
      }
    },
  },
  computed: {
    ...mapState(['loading']),
    role() {
      return window.localStorage.getItem('role');
    },
  },
};
</script>
