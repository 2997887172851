import axios from 'axios';

export const SAVE_ACTUAL_BENEFICIARY = 'SAVE_ACTUAL_BENEFICIARY';

const state = {};

export const actions = {
  async [SAVE_ACTUAL_BENEFICIARY]({ commit }, payload) {
    const response = await axios.post('/actual-beneficiary/', payload);
    commit('query/SET_ACTUAL_BENEFICIARY', response.data, { root: true });
  },
};

export const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
