import axios from 'axios';
import i18n from '../../i18n';

export const SAVE_QASSETS = 'SAVE_QASSETS';
export const SAVE_QASSET = 'SAVE_QASSET';
export const DELETE_QASSET = 'DELETE_QASSET';
export const DELETE_ALL_BY_QUERY_ID = 'DELETE_ALL_BY_QUERY_ID';
export const GET_ALL_ASSET_GROUPS = 'GET_ALL_ASSET_GROUPS';
export const SET_ALL_ASSET_GROUPS = 'SET_ALL_ASSET_GROUPS';
export const SET_AVAILABLE_MAIN_ASSET_GROUPS =
  'SET_AVAILABLE_MAIN_ASSET_GROUPS';

const state = {
  allAssetGroups: [],
  availableMainAssetGroups: [],
};

export const actions = {
  async [SAVE_QASSETS]({ commit }, payload) {
    const response = await axios.post('/qasset/save-qassets', payload);
    commit('query/SET_QUERY_ASSETS', response.data, { root: true });
  },
  async [SAVE_QASSET]({ commit }, payload) {
    const response = await axios.post('/qasset/save', payload);
    return response.data;
  },
  async [DELETE_QASSET]({ dispatch }, payload) {
    await axios.post('/qasset/delete', payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      'alert/ALERT',
      { message: i18n.t('ASSET_DELETED'), type: 'success' },
      { root: true },
    );
  },
  async [DELETE_ALL_BY_QUERY_ID]({ commit }, payload) {
    await axios.post('/qasset/delete-all-by-query-id', payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },
  async [GET_ALL_ASSET_GROUPS]({ commit }) {
    const response = await axios.get('/asset-group', {
      dataType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    commit(SET_ALL_ASSET_GROUPS, response.data);
  },
};

export const mutations = {
  [SET_ALL_ASSET_GROUPS](state, payload) {
    state.allAssetGroups = payload;
  },
  [SET_AVAILABLE_MAIN_ASSET_GROUPS](state, payload) {
    state.availableMainAssetGroups = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
