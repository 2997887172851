<template>
  <b-container>
    <b-card class="mt-5">
      <b-row align-h="center">
        <b-col md="6" class="text-center">
          <img
            src="../assets/logo.png"
            alt="Fairown"
            height="110"
            width="137"
          />
          <b-alert
            :show="!!message"
            variant="danger"
            class="text-center mt-4"
            >{{ message }}</b-alert
          >
          <div class="text-center mt-4">
            You will be redirected shortly...
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'RemoteLogin',

  computed: {
    ...mapState('alert', ['message']),
  },
  mounted() {
    const ssoId = this.$route.query.ssoId;
    if (ssoId) {
      this.$store.dispatch('ssoLogin', ssoId);
    }
  },
};
</script>
