<template>
  <div>
    <b-list-group-item>
      <b-row align-v="center">
        <b-col md="4" class="text-right">
          <span>{{ $t('ALLOWED_ASSET_GROUPS') }}</span>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-row>
      <b-col>
        <div class="accordion" role="tablist">
          <b-card
            no-body
            v-for="assetGroup in partnerAssetGroups"
            :key="assetGroup.assetGroupId"
            class="mt-0"
          >
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button
                class=" w-100"
                block
                v-b-toggle="'accordion-' + assetGroup.assetGroupId"
                >{{ assetGroup.name }}</b-button
              >
            </b-card-header>
            <b-collapse
              :id="'accordion-' + assetGroup.assetGroupId"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body
                class="border-bottom"
                v-for="subgroup in assetGroup.subgroups"
                :key="subgroup.assetGroupId"
              >
                <b-row align-v="baseline">
                  <b-col md="4">
                    <b-card-text class="font-weight-bold">
                      {{ subgroup.name }}
                    </b-card-text>
                  </b-col>
                  <b-col md="2">
                    <b-form-checkbox v-model="subgroup.allowed">
                      {{ $t('ALLOWED') }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="4">
                    <b-form-checkbox
                      v-model="subgroup.autoDecisionEnabled"
                      :disabled="!generalAutomaticDecision"
                    >
                      {{ $t('AUTOMATIC_DECISION') }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="2">
                    <b-form-input v-model="subgroup.insurance"></b-form-input>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PartnerAssetGroupAccordion',
  props: ['partnerAssetGroups', 'generalAutomaticDecision'],
};
</script>
