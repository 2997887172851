<template>
  <div>
    <Header :header="$t('TEMPLATES')" />

    <b-container class="mt-5">
      <b-card>
        <b-row>
          <b-col />
          <b-col>
            <b-form>
              <b-form-group class="mb-0 search-form">
                <b-input-group class="search-input">
                  <b-form-input
                    v-model="filter"
                    :placeholder="$t('TYPE_TO_SEARCH')"
                  />
                  <b-input-group-append>
                    <b-btn
                      type="button"
                      variant="primary"
                      @click="filter = ''"
                      :disabled="!filter"
                      >{{ $t('CLEAR') }}</b-btn
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
        <b-table
          outlined
          show-empty
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :items="templates"
          :per-page="perPage"
          stacked="md"
          class="mt-2 mb-2"
          @filtered="onFiltered"
          :empty-text="$t('NO_RECORDS')"
        >
          <template #cell(actions)="row">
            <div class="actions">
              <router-link
                :to="`/templates/${row.item.id}/edit`"
                v-b-tooltip.hover
                :title="$t('EDIT')"
              >
                <img src="../../../assets/edit-icon.svg" />
              </router-link>
              <span v-b-tooltip.hover :title="$t('DELETE')">
                <a @click="openDeleteModal(row.item.id, row.index)">
                  <img src="../../../assets/delete-icon.svg" />
                </a>
              </span>
            </div>
          </template>
        </b-table>

        <b-row>
          <b-col>
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0"
            />
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0">
              <b-form-select :options="pageOptions" v-model="perPage" />
            </b-form-group>
          </b-col>
        </b-row>

        <RenderDeleteModal
          :show="showDeleteModal"
          @ok="closeDeleteModal(true)"
          @cancel="closeDeleteModal()"
          :label="$t('ARE_YOU_SURE_DELETE_TEMPLATE')"
        />
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Header, RenderDeleteModal } from '@/components/common';
import { GET_TEMPLATES, DELETE_TEMPLATE } from '../../../store/template';

export default {
  name: 'Templates',
  components: {
    Header,
    RenderDeleteModal,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('NAME'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('ACTIONS'),
          sortable: false,
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      rowsPerPage: [5, 10, 20, 50, 100],
      filter: null,
      showDeleteModal: false,
      selectedTemplate: 0,
    };
  },
  mounted() {
    this.GET_TEMPLATES();
  },
  methods: {
    ...mapActions('template', [GET_TEMPLATES, DELETE_TEMPLATE]),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openDeleteModal(id, index) {
      this.showDeleteModal = true;
      this.selectedTemplate = id;
    },
    closeDeleteModal(doDelete) {
      this.showDeleteModal = false;
      if (doDelete) {
        this.DELETE_TEMPLATE(this.selectedTemplate);
      }
      this.selectedTemplate = 0;
    },
  },
  computed: {
    ...mapState('template', ['templates']),
    pageOptions() {
      return this.rowsPerPage.map(i => ({
        text: this.$t('ROWS_PER_PAGE') + ': ' + i,
        value: i,
      }));
    },
  },
};
</script>
