<template>
  <div class="spinner" :class="{ 'spinner--fixed': fixed }">
    <div class="spinner__content">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RenderSpinner',
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.spinner {
  display: flex;
  border-radius: 3px;
  justify-content: center;

  &--fixed {
    position: fixed;
    top: -2px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
  }
}

.spinner__content {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.spinner__content div {
  -webkit-animation: roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
  transform-origin: 32px 32px;
}

.spinner__content div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #048c87;
  margin: -3px 0 0 -3px;
}

.spinner__content div:first-child {
  -webkit-animation-delay: -36ms;
  animation-delay: -36ms;
}

.spinner__content div:first-child:after {
  top: 50px;
  left: 50px;
}

.spinner__content div:nth-child(2) {
  -webkit-animation-delay: -72ms;
  animation-delay: -72ms;
}

.spinner__content div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.spinner__content div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
  animation-delay: -0.108s;
}

.spinner__content div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.spinner__content div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
  animation-delay: -0.144s;
}

.spinner__content div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.spinner__content div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
  animation-delay: -0.18s;
}

.spinner__content div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.spinner__content div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
  animation-delay: -0.216s;
}

.spinner__content div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.spinner__content div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
  animation-delay: -0.252s;
}

.spinner__content div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.spinner__content div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
  animation-delay: -0.288s;
}

.spinner__content div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@-webkit-keyframes roller {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes roller {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
</style>
