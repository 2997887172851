<template>
  <div>
    <Header :header="$t('APPLICATION_FOR_CUSTOMER')"> </Header>
    <b-container class="mt-4" v-if="submitSuccessful && this.user">
      <b-alert show variant="success">
        <h4 class="alert-heading">Taotlus edastatud!</h4>
        <p class="mt-4">
          Teie sisestatud taotluse link saadeti kliendile SMS või E-Maili teel.
          Saadame teate, kui klient on andmed edukalt täitnud ja lepingu
          allkirjastanud.
        </p>
      </b-alert>
    </b-container>

    <b-container class="mt-4" v-if="!submitSuccessful && this.user">
      <b-form>
        <h2 class="card-title">
          <div>
            <span class="text-nowrap">{{ $t('OFFER') }}</span>
          </div>
          <Kickback
            :partner="partner"
            :contractAmount="new Number(totalPrice)"
            :contractFee="new Number(contractFee)"
          />
        </h2>
        <b-card class="card-padding-md">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('SELLER_OFFER_NR') + ':'"
                label-for="offerNumber"
              >
                <b-form-input
                  id="offerNumber"
                  name="offerNumber"
                  type="text"
                  maxlength="30"
                  v-model.trim="offerNumber"
                  v-validate="'required'"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="this.partner.editContractFee">
              <b-form-group label-for="contractFee">
                <label for="contractFee" class="d-block"
                  >{{ $t('SET_CONTRACT_FEE') + ':' }}
                  <b-icon class="pl-0" id="tt-13" icon="question-circle" />
                  <b-tooltip target="tt-13" variant="dark" triggers="hover">
                    {{ $t('CONTRACT_FEE_TOOLTIP') }}
                  </b-tooltip>
                </label>
                <b-form-input
                  id="contractFee"
                  name="contractFee"
                  type="text"
                  v-model.trim="contractFee"
                  v-validate="'required'"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('OFFER_PRICE_WITH_VAT') + ':'"
                :invalid-feedback="$t('VALIDATION.QASSET_PRICE_REQUIRED')"
                label-for="qassetPriceWithVat"
                :hidden="user.hideVatFields"
              >
                <b-form-input
                  id="totalPriceWithVat"
                  name="totalPriceWithVat"
                  v-model.trim="totalPriceWithVat"
                  type="text"
                  @input="calculateWithoutVat"
                  v-validate="'required'"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('SET_OFFER_PRICE_NO_VAT') + ':'"
                label-for="qassetPrice"
                :invalid-feedback="$t('VALIDATION.QASSET_PRICE_REQUIRED')"
              >
                <b-form-input
                  id="totalPrice"
                  name="totalPrice"
                  v-model.trim="totalPrice"
                  v-validate="'required'"
                  type="text"
                  @input="calculateWithVat"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <div class="mt-3" v-if="this.totalMainAssetsAmount > this.totalPrice">
          <b-alert show variant="info"
            >Taotluse summa peab olema suurem või võrdne varade
            koguväärtusega.<br /><br />
            - taotluse summa: {{ this.totalPrice || 0 }}<br />
            - varade koguväärtus: {{ this.totalMainAssetsAmount }}</b-alert
          >
        </div>

        <h2 class="card-title mt-4">
          <span class="text-nowrap">{{ $t('MAIN_ASSETS') }}</span>
        </h2>
        <b-card class="card-padding-md">
          <b-row v-for="(asset, index) in assets" v-bind:key="asset.id">
            <b-col md="6">
              <b-form-group
                :label="$t('CHOOSE_ASSET_GROUP') + ':'"
                label-for="assetSubGroup"
              >
                <multiselect
                  v-model="asset.group"
                  :options="assetGroups"
                  :allow-empty="false"
                  :show-labels="false"
                  :searchable="false"
                  placeholder="Vali väärtus"
                >
                  <template #option="{ option }">
                    {{ option.name }}
                  </template>
                  <template #singleLabel="{ option }">
                    {{ option.name }}
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('SET_ASSET_PRICE_NO_VAT') + ':'"
                label-for="assetPrice"
                v-b-tooltip.hover
              >
                <div style="display: flex; bottom 0; ">
                  <b-form-input
                    id="assetPrice"
                    name="assetPrice"
                    type="text"
                    v-model.trim="asset.assetPrice"
                    v-validate="'required'"
                    @change="replaceAssetCommas"
                  />
                  <div class="ml-2 mt-1" v-if="index > 0">
                    <b-button
                      variant="secondary"
                      @click="removeMainAsset(index)"
                    >
                      <b-icon id="tt-1" icon="trash" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-button variant="secondary" @click="addMainAsset()">
                <b-icon id="tt-1" icon="plus" />
              </b-button>
              <span class="ml-1">
                Lisa uus rida
              </span>
            </b-col>
          </b-row>

          <b-row
            v-if="
              (additionalAssetsPrice > 0 && (totalMainAssetsAmount || 0) > 0) ||
                this.partner.editInsurance
            "
            class="mt-3"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('SET_INSURANCE_AMOUNT') + ':'"
                label-for="insuranceAmount"
                v-b-tooltip.hover
              >
                <div style="display: flex; bottom 0; ">
                  <b-form-input
                    id="insuranceAmount"
                    name="insuranceAmount"
                    v-model.trim="insuranceAmount"
                    type="text"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('SET_EXTENDED_WARRANTY') + ':'"
                label-for="extendedWarranty"
                v-b-tooltip.hover
              >
                <div style="display: flex; bottom 0; ">
                  <b-form-input
                    id="extendedWarranty"
                    name="extendedWarranty"
                    v-model.trim="extendedWarranty"
                    type="text"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6"></b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ADDITIONAL_ASSETS_COST') + ':'"
                label-for="additionalAssetPrice"
                v-b-tooltip.hover
              >
                <div style="display: flex; bottom 0; ">
                  <b-form-input
                    id="additionalAssetPrice"
                    name="additionalAssetPrice"
                    :value="additionalAssetsPrice"
                    type="text"
                    disabled
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <h2 class="card-title mt-4">
          <span class="text-nowrap">{{ $t('CLIENT') }}</span>
        </h2>
        <b-card class="card-padding-md">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('EMAIL') + ':'"
                label-for="contactEmail"
                v-b-tooltip.hover
              >
                <b-form-input
                  id="contactEmail"
                  name="contactEmail"
                  type="text"
                  v-model.trim="contactEmail"
                  v-validate="'required|email'"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group :label="$t('PHONE') + ':'" label-for="contactPhone">
                <div style="display: flex; bottom 0; ">
                  <b-input-group-prepend is-text>
                    +372
                  </b-input-group-prepend>

                  <b-form-input
                    id="contactPhone"
                    name="contactPhone"
                    type="text"
                    v-model.trim="contactPhone"
                    v-validate="'required'"
                    class="ml-1"
                  ></b-form-input>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-button
          class="mt-4 mb-8"
          v-b-modal.modal-1
          size="lg"
          :disabled="!validToSubmit"
          variant="primary"
          @click="submit()"
          block
          >Edasta taotlus kliendile täitmiseks
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            v-if="sendingApplicationLink"
          ></span>
        </b-button>
        <br />
      </b-form>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { Header, Footer } from '@/components/common';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import { replaceComma, numerify, numerifyWithDecimals } from '@/utils';
import { getPriceWithoutVat, getPriceWithVat } from '../../utils';
import Kickback from '@/components/common/Kickback';

export default {
  name: 'AppLink',
  components: {
    Header,
    Multiselect,
    Kickback,
  },
  async mounted() {
    axios.get('user/get-logged-in-user').then(({ data }) => {
      this.user = data;
      if (data.partner) {
        this.partner = data.partner;
        if (this.partner.editContractFee) {
          this.contractFee = this.partner.contractFee;
        }
        axios
          .get('/partner/' + data.partner.partnerId + '/asset-group/names')
          .then(({ data }) => {
            this.assetGroups = data;
          });
      }
    });
  },
  methods: {
    validateToSubmit() {},
    calculateWithoutVat(price) {
      this.totalPrice = getPriceWithoutVat(price);
      this.$validator
        .validateAll()
        .then(() => {})
        .catch(() => {});
    },
    calculateWithVat(price) {
      this.totalPriceWithVat = getPriceWithVat(price);
      this.$validator
        .validateAll()
        .then(() => {})
        .catch(() => {});
    },
    removeMainAsset(index) {
      this.assets.splice(index, 1);
    },
    addMainAsset() {
      this.assets.push({});
    },
    replaceAssetCommas() {
      this.assets = this.assets.map(asset => {
        return {
          ...asset,
          assetPrice: replaceComma(asset.assetPrice),
        };
      });
    },
    submit() {
      this.sendingApplicationLink = true;
      let optionalAssets = [];
      if (this.insuranceAmount > 0) {
        optionalAssets.push({
          name: 'Kindlustus',
          price: this.insuranceAmount,
          vatExempt: true,
        });
      }

      if (this.extendedWarranty > 0) {
        optionalAssets.push({
          name: 'Lisagarantii',
          price: this.extendedWarranty,
          vatExempt: true,
        });
      }

      if (this.additionalAssetsPrice > 0) {
        optionalAssets.push({
          name: 'Lisa',
          price: this.additionalAssetsPrice,
        });
      }

      let payload = {
        mainAssets: this.assets.map(asset => {
          return {
            groupId: asset.group.assetGroupId,
            price: asset.assetPrice,
            vatExempt: false,
          };
        }),
        optionalAssets: optionalAssets,
        totalAmount: this.totalPrice,
        phone: this.contactPhone,
        email: this.contactEmail,
        offerNumber: this.offerNumber,
        contractFee: this.contractFee,
      };

      axios.post('/selfserve/application', payload).then(({ data }) => {
        this.submitSuccessful = true;
        this.sendingApplicationLink = false;
      });
    },
  },
  data: function() {
    return {
      user: null,
      partner: null,
      totalPriceWithVat: null,
      totalPrice: null,
      contractFee: null,
      insuranceAmount: 0,
      extendedWarranty: 0,
      offerNumber: '',
      assets: [{}],
      assetGroups: [],
      newAsset: {},
      contactEmail: null,
      contactPhone: null,
      submitSuccessful: false,
      sendingApplicationLink: false,
    };
  },
  watch: {
    totalPriceWithVat(value) {
      this.totalPriceWithVat = replaceComma(value);
    },
    totalPrice(value) {
      this.totalPrice = replaceComma(value);
    },
  },
  computed: {
    additionalAssetsPrice() {
      const additionalAssetsPrice =
        (this.totalPrice || 0) -
        (this.totalMainAssetsAmount || 0) -
        (this.insuranceAmount || 0) -
        (this.extendedWarranty || 0);

      return Number(additionalAssetsPrice.toFixed(2));
    },
    validToSubmit() {
      if (this.totalPrice <= 0) {
        return false;
      }

      if (this.totalMainAssetsAmount > this.totalPrice) {
        return false;
      }
      // check if asset groups are selected
      if (this.assets.length === 0) {
        return false;
      }

      if (this.offerNumber.length === 0) {
        return false;
      }

      // check if all asset groups have group selected
      if (this.assets.some(asset => !asset.group)) {
        return false;
      }

      // check if any asset has no price or price is 0 or less
      if (
        this.assets.some(asset => !asset.assetPrice || asset.assetPrice <= 0)
      ) {
        return false;
      }

      return this.contactPhone || this.contactEmail;
    },
    numerifyWithDecimals() {
      return numerifyWithDecimals;
    },
    totalMainAssetsAmount() {
      // summary of all main assets
      return this.assets.reduce((acc, asset) => {
        return acc + parseFloat(asset.assetPrice);
      }, 0);
    },
  },
};
</script>
