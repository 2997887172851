import axios from 'axios';

export const SAVE_GUARANTOR = 'SAVE_GUARANTOR';

const state = {};

export const actions = {
  async [SAVE_GUARANTOR]({ commit }, payload) {
    const response = await axios.post('/guarantor/', payload);
    commit('query/SET_GUARANTOR', response.data, { root: true });
  },
};

export const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
