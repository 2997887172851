<template>
  <b-container>
    <h2 class="card-title">
      <span>
        <figure class="card-title-icon">
          <img src="../../assets/queries-icon.svg" class="card-title-image" />
        </figure>
        {{ $t('STATUS') }}
      </span>
      <span class="number number-lg"
        >{{ $t('YOUR_CONTRACT_NUMBER') }}:
        <strong>{{ value.queryId }}</strong></span
      >
    </h2>
    <b-card>
      <b-list-group class="separated accordion">
        <div role="tablist">
          <b-card no-body>
            <b-card-header header-tag="header" role="tab">
              <b-btn block href="#" v-b-toggle.accordion1>
                <b-list-group-item>
                  <span class="number"># {{ value.queryId }}</span>
                  <div class="edit-box ml-auto">
                    <div :class="'status ' + statusStyle(value.status)">
                      <span>{{ $t(`QUERYSTATUS.${value.status}`) }}</span>
                    </div>
                    <div class="actions">
                      <a href="#" v-b-tooltip.hover :title="$t('EDIT')"
                        ><img src="../../assets/down-arrow.svg"
                      /></a>
                    </div>
                  </div>
                </b-list-group-item>
              </b-btn>
            </b-card-header>
            <b-collapse
              id="accordion1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <div class="client-info">
                  <b-row>
                    <b-col>
                      <b-list-group>
                        <b-list-group-item
                          ><div>{{ $t('CLIENT') }}:</div>
                          <div>{{ value.custName }}</div></b-list-group-item
                        >
                        <b-list-group-item
                          ><div>{{ $t('REGISTRY_CODE') }}:</div>
                          <div>{{ value.custRegCode }}</div></b-list-group-item
                        >
                        <b-list-group-item
                          ><div>{{ $t('CONTACT_PERSON') }}:</div>
                          <div>{{ value.contactName }}</div></b-list-group-item
                        >
                      </b-list-group>
                    </b-col>
                    <b-col>
                      <b-list-group>
                        <b-list-group-item
                          ><div>{{ $t('PHONE') }}:</div>
                          <div>{{ value.contactPhone }}</div></b-list-group-item
                        >
                        <b-list-group-item
                          ><div>{{ $t('EMAIL') }}:</div>
                          <div>{{ value.contactEmail }}</div></b-list-group-item
                        >
                      </b-list-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-list-group>
    </b-card>

    <div class="table-box mt-3">
      <b-table
        striped
        hover
        :items="statusHistory"
        :fields="statusHistoryFields"
      >
        <template #cell(status)="data">
          <div :class="'status ' + statusStyle(data.value) + ' table-status'">
            {{ $t(`QUERYSTATUS.${data.value}`) }}
          </div>
        </template>
      </b-table>
    </div>
  </b-container>
</template>

<script>
import { queryStatusClass } from '@/utils';

export default {
  name: 'StatusTab',
  props: {
    value: Object,
    statusHistory: Array,
  },
  data: function() {
    return {
      statusHistoryFields: [
        {
          formattedCreatedDtime: {
            label: this.$t('CREATED'),
            sortable: true,
          },
          createdByName: {
            label: this.$t('CREATED_BY'),
            sortable: true,
          },
          status: {
            label: this.$t('STATUS'),
            sortable: true,
          },
          reason: {
            label: this.$t('REASON'),
            sortable: true,
          },
        },
      ],
    };
  },
  methods: {
    statusStyle(status) {
      return queryStatusClass(status);
    },
  },
};
</script>

<style scoped></style>
