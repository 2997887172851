<template>
  <div>
    <Header :header="$t('USERS')" :role="role" showSettings />
    <b-container class="container-wide compliance-partner">
      <b-row class="mt-4" align-h="end">
        <b-col md="4">
          <b-btn
            id=""
            variant="primary"
            size="md"
            block
            :to="{ name: 'CreateUser' }"
            exact
            >{{ $t('CREATE_USER') }}</b-btn
          >
        </b-col>
      </b-row>
      <b-card class="mt-4">
        <b-form>
          <b-form-group class="mb-0 search-form">
            <b-input-group class="search-input">
              <b-form-input
                v-model="filter"
                :autofocus="true"
                :placeholder="$t('TYPE_TO_SEARCH')"
              />
              <b-input-group-append>
                <b-btn type="button" @click="filter = ''" :disabled="!filter">{{
                  $t('CLEAR')
                }}</b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>
        <h5 class="mt-4">{{ $t('ACTIVE_USERS') }}</h5>
        <b-table
          outlined
          :items="activeUsers"
          :fields="fields"
          :current-page="currentPage"
          @filtered="onFiltered"
          :per-page="perPage"
          :filter="filter"
          :filter-function="customFilter"
          :busy="loading"
          class="mt-2"
        >
          >
          <template #cell(actions)="row">
            <div class="actions">
              <router-link
                :to="`/user/${row.item.accountUserId}/edit`"
                v-b-tooltip.hover
                :title="$t('EDIT')"
              >
                <img src="../../assets/edit-icon.svg" />
              </router-link>
            </div>
          </template>
          <template #table-busy>
            <RenderSpinner :fixed="false" />
          </template>
        </b-table>
        <b-row>
          <b-col>
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              class="mt-2"
            />
          </b-col>
          <b-col md="3">
            <b-form-group class="mb-0 mt-2">
              <b-form-select :options="pageOptions" v-model="perPage" />
            </b-form-group>
          </b-col>
        </b-row>
        <template v-if="inactiveUsers.length">
          <h5 class="mt-5">{{ $t('INACTIVE_USERS') }}</h5>
          <b-table
            outlined
            :items="inactiveUsers"
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
            :filter-function="customFilter"
            class="mt-2"
          >
            >
            <template #cell(actions)="row">
              <div class="actions">
                <router-link
                  :to="`/user/${row.item.accountUserId}/edit`"
                  v-b-tooltip.hover
                  :title="$t('EDIT')"
                >
                  <img src="../../assets/edit-icon.svg" />
                </router-link>
              </div>
            </template>
          </b-table>
        </template>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { Header, RenderSpinner } from '@/components/common';
import { mapState } from 'vuex';

export default {
  name: 'ManageUsers',
  components: {
    Header,
    RenderSpinner,
  },
  data() {
    return {
      users: [],
      filter: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      rowsPerPage: [5, 10, 20, 50, 100],
      fields: [
        {
          key: 'accountUserId',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'fullName',
          label: this.$t('NAME'),
          sortable: true,
        },
        {
          key: 'personalCode',
          label: this.$t('ID_CODE'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('EMAIL'),
          sortable: true,
        },
        {
          key: 'phone',
          label: this.$t('PHONE'),
          sortable: true,
        },
        {
          key: 'userRole',
          label: this.$t('ROLE'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('ACTIONS'),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store
      .dispatch('getAllUsers')
      .then(response => {
        this.users = response.data;
        this.totalRows = this.activeUsers.length;
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    onFiltered(filteredItems) {
      this.currentPage = 1;
    },
    customFilter(item, criteria) {
      if (item.accountUserId === +criteria) return true;
      if (item.fullName && item.fullName.toLowerCase().includes(criteria)) {
        return true;
      }
      if (item.email && item.email.toLowerCase().includes(criteria)) {
        return true;
      }
      if (
        item.personalCode &&
        item.personalCode.toLowerCase().includes(criteria)
      ) {
        return true;
      }
    },
  },
  computed: {
    ...mapState(['loading']),
    role() {
      return localStorage.getItem('role');
    },
    activeUsers() {
      return this.users.filter(user => !!user.active);
    },
    inactiveUsers() {
      return this.users.filter(user => !user.active);
    },
    pageOptions() {
      return this.rowsPerPage.map(i => {
        return { text: this.$t('ROWS_PER_PAGE') + ': ' + i, value: i };
      });
    },
  },
};
</script>
