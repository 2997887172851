<template>
  <b-modal
    v-model="isOpen"
    centered
    hide-header
    :ok-title="$t('YES')"
    :cancel-title="$t('NO')"
    @cancel="$emit('cancel')"
    @ok="$emit('ok')"
    @hide="e => $emit('cancel', e)"
  >
    <h5>{{ label }}</h5>
  </b-modal>
</template>

<script>
export default {
  name: 'RenderDeleteModal',
  props: {
    show: Boolean,
    label: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    show(value) {
      this.isOpen = this.show;
    },
  },
};
</script>
