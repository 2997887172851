<template>
  <b-card class="card-padding-md">
    <div class="client-info">
      <b-row>
        <b-col md="6">
          <b-list-group>
            <h4>{{ $t('CLIENT') }}</h4>
            <b-list-group-item>
              <div>{{ $t('CLIENT') }}:</div>
              <div>{{ value.custName }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('REGISTRY_CODE') }}:</div>
              <div>{{ value.custRegCode }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('VAT_CODE') }}:</div>
              <div>{{ value.vatNumber }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('ADDRESS') }}:</div>
              <div>
                {{ getClientAddress }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('BILLING_EMAIL') }}:</div>
              <div>{{ value.billingEmail }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('BANK_ACCOUNT_NUMBER') }}:</div>
              <div>{{ value.bankAccountNumber }}</div>
            </b-list-group-item>
          </b-list-group>
        </b-col>

        <b-col md="6">
          <b-list-group>
            <h4>{{ $t('REPRESENTATIVE') }}</h4>
            <b-list-group-item>
              <div>{{ $t('CONTACT_PERSON') }}:</div>
              <div>{{ value.contactName }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('PHONE') }}:</div>
              <div>{{ value.contactPhone }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('EMAIL') }}:</div>
              <div>{{ value.contactEmail }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('SELLER_REPRS_REPR_TYPE') }}:</div>
              <div>{{ value.contactPersType }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('ID_CODE') }}:</div>
              <div>{{ value.contactPersCode }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('DOCUMENT_NUMBER') }}:</div>
              <div>
                {{ value.representativeDocumentNumber }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('DOCUMENT_TYPE') }}:</div>
              <div>
                {{
                  value.representativeDocumentType
                    ? $t(value.representativeDocumentType.toUpperCase())
                    : null
                }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('VALID_UNTIL') }}:</div>
              <div>
                {{ value.representativeDocumentValidUntil }}
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col md="12">
          <b-list-group>
            <h4>{{ $t('ACTUAL_BENEFICIARY') }}</h4>
            <b-list-group-item>
              <div>{{ $t('NAME') }}:</div>
              <div>
                {{ value.actualBeneficiary.name }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('RESIDENCY') }}:</div>
              <div>
                {{ value.actualBeneficiary.residency }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('ID_CODE') }}:</div>
              <div>
                {{ value.actualBeneficiary.personalCode }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('IS_PUBLIC_PERSON') }}:</div>
              <div>
                {{
                  value.actualBeneficiary.isPublicPerson
                    ? $t(value.actualBeneficiary.isPublicPerson.toUpperCase())
                    : null
                }}
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="value.actualBeneficiary.dateOfBirth">
              <div>{{ $t('DATE_OF_BIRTH') }}:</div>
              <div>
                {{ value.actualBeneficiary.dateOfBirth }}
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="value.actualBeneficiary.placeOfBirth">
              <div>{{ $t('PLACE_OF_BIRTH') }}:</div>
              <div>
                {{ value.actualBeneficiary.placeOfBirth }}
              </div>
            </b-list-group-item>
            <b-list-group-item
              v-if="value.actualBeneficiary.currentPlaceOfLiving"
            >
              <div>{{ $t('CURRENT_PLACE_OF_LIVING') }}:</div>
              <div>
                {{ value.actualBeneficiary.currentPlaceOfLiving }}
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'ClientInfo',
  props: ['value', 'viewName'],
  computed: {
    getClientAddress() {
      const { street, houseNumber, flatNumber, zipCode, city } = this.value;
      return (
        street +
        ' ' +
        houseNumber +
        (flatNumber ? '-' + flatNumber : '') +
        ', ' +
        zipCode +
        ' ' +
        city
      );
    },
  },
};
</script>

<style scoped></style>
