import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { en, et } from './translations';
Vue.use(VueI18n);

const locale = localStorage.getItem('locale') || 'en';

export default new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages: { en, et },
});
