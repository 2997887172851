<template>
  <div>
    <Header :header="newUser ? $t('NEW_USER') : $t('EDIT_USER')" />

    <RenderSpinner v-if="loading" />

    <b-container class="mt-5">
      <b-form @submit.prevent="handleFormSubmit">
        <h2 class="card-title">
          {{ newUser ? $t('NEW_USER') : user.fullName }}
        </h2>
        <b-card>
          <b-list-group class="terms terms-settings">
            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('FIRST_NAME') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    class="mb-0"
                    :state="validateState('firstName')"
                    :invalid-feedback="$t('VALIDATION.REQUIRED')"
                  >
                    <b-form-input
                      id="firstName"
                      name="firstName"
                      v-validate="'required'"
                      v-model.trim="user.firstName"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('LAST_NAME') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    class="mb-0"
                    :state="validateState('lastName')"
                    :invalid-feedback="$t('VALIDATION.REQUIRED')"
                  >
                    <b-form-input
                      id="lastName"
                      name="lastName"
                      v-validate="'required'"
                      v-model.trim="user.lastName"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('ID_CODE') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    class="mb-0"
                    :state="validateState('personalCode')"
                    :invalid-feedback="$t('VALIDATION.INVALID_ID_CODE')"
                  >
                    <b-form-input
                      id="personalCode"
                      name="personalCode"
                      v-validate="'idcode|required'"
                      v-model.trim="user.personalCode"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('EMAIL') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    class="mb-0"
                    :state="validateState('email')"
                    :invalid-feedback="$t('VALIDATION.INVALID_EMAIL')"
                  >
                    <b-form-input
                      id="email"
                      name="email"
                      v-validate="'email|required'"
                      v-model.trim="user.email"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('PHONE') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-input
                    id="phone"
                    v-model.trim="user.phone"
                    type="text"
                  />
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('ROLE') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    class="mb-0"
                    :state="validateState('role')"
                    :invalid-feedback="$t('VALIDATION.REQUIRED')"
                  >
                    <b-form-select
                      class="setting-terms-select custom-select"
                      name="role"
                      v-validate="'required'"
                      id="role"
                      v-model="user.userRole"
                      type="text"
                    >
                      <option :value="null" disabled>{{
                        $t('SELECT_ROLE')
                      }}</option>
                      <option v-for="role in roles" :key="role" :value="role">
                        {{ $t(role) }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item
              v-if="user.userRole === 'PARTNER' || user.userRole === 'SHOP'"
            >
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('PARTNER') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    class="mb-0"
                    :state="validateState('partnerSelect')"
                    :invalid-feedback="$t('VALIDATION.REQUIRED')"
                  >
                    <multiselect
                      v-model="partner"
                      :options="partners"
                      :loading="loading"
                      :show-labels="false"
                      label="businessName"
                      track-by="partnerId"
                      v-validate="'required'"
                      name="partnerSelect"
                    >
                      <template slot="noResult">{{
                        $t('NO_ELEMENTS_FOUND')
                      }}</template>
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('LANGUAGE') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-select
                    class="setting-terms-select custom-select"
                    id="language"
                    v-model="user.language"
                    type="text"
                  >
                    <option v-for="lang in languages" :key="lang" :value="lang">
                      {{ $t('LANGUAGES.' + lang) }}
                    </option>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('LOGO') }}:</span>
                </b-col>
                <b-col md="4">
                  <label v-if="user.logo" for="logo">
                    <b-img thumbnail fluid :src="user.logo" alt="Logo" />
                  </label>
                  <input
                    :style="`${user.logo && 'display: none;'}`"
                    type="file"
                    accept="image/*"
                    id="logo"
                    @change="handleLogoChange"
                  />
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <RenderDeleteModal
          :show="showDeleteModal"
          @ok="closeDeleteModal(true)"
          @cancel="closeDeleteModal()"
          :label="$t('ARE_YOU_SURE_DELETE_USER')"
        />

        <div class="mb-5 mt-4">
          <b-row align-h="center">
            <b-col md="4">
              <b-btn
                id="backBtn"
                variant="secondary"
                size="lg"
                block
                :to="{ name: 'ManageUsers' }"
                >{{ $t('BACK') }}</b-btn
              >
            </b-col>
            <b-col v-if="!newUser" md="4">
              <b-btn
                id="deleteBtn"
                variant="outline-danger"
                size="lg"
                block
                @click="showDeleteModal = true"
                >{{ $t('DELETE_USER') }}</b-btn
              >
            </b-col>
            <b-col md="4">
              <b-btn
                type="submit"
                id="saveBtn"
                variant="primary"
                size="lg"
                block
                >{{ newUser ? $t('CREATE_USER') : $t('SAVE') }}</b-btn
              >
            </b-col>
          </b-row>
          <b-row v-if="!newUser && !user.active" align-h="center" class="mt-4">
            <b-col md="6">
              <b-btn
                id="resendBtn"
                variant="outline-primary"
                size="md"
                block
                @click="handleEmailClick"
                >{{ $t('RESEND_ACTIVATION_EMAIL') }}</b-btn
              >
            </b-col>
          </b-row>
        </div>
      </b-form>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { Header, RenderDeleteModal, RenderSpinner } from '@/components/common';
import validateState from '../../mixins/validateState';
import { ALERT } from '../../store/alert';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EditUser',
  components: { Header, RenderDeleteModal, RenderSpinner, Multiselect },
  mixins: [validateState],
  props: {
    newUser: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      user: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        personalCode: null,
        userRole: null,
        language: 'en',
        logo: null,
        partnerId: null,
      },
      roles: ['COMPLIANCE', 'CREDIT', 'PARTNER', 'SHOP'],
      partners: [],
      partner: null,
      showDeleteModal: false,
    };
  },
  mounted() {
    if (!this.newUser) {
      this.$store
        .dispatch('getUser', this.$route.params.id)
        .then(({ data: userResponse }) => {
          this.user = userResponse;
          this.partner = userResponse.partner;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    handleFormSubmit() {
      this.$validator.validate().then(isValid => {
        if (!isValid) return;
        if (this.partner) {
          this.user.partnerId = this.partner.partnerId;
        }
        this.newUser ? this.createUser() : this.updateUser();
      });
    },
    createUser() {
      window.scrollTo(0, 0);
      this.$store
        .dispatch('saveUser', this.user)
        .then(() => {
          this.ALERT({ message: this.$t('USER_CREATED'), type: 'success' });
          this.$router.push({ name: 'ManageUsers' });
        })
        .catch(error => {
          this.ALERT({ message: error.response.data });
        });
    },
    updateUser() {
      this.$store
        .dispatch('updateUser', this.user)
        .then(response => {
          this.user = response.data;
          this.ALERT({ message: this.$t('SAVED'), type: 'success' });
          this.$router.push({ name: 'ManageUsers' });
        })
        .catch(error => {
          this.ALERT({ message: error.response.data });
        });
    },
    closeDeleteModal(doDelete) {
      this.showDeleteModal = false;
      if (doDelete) return this.deleteUser();
    },
    deleteUser() {
      this.$store
        .dispatch('deleteUser', this.user.accountUserId)
        .then(response => {
          this.ALERT({ message: this.$t('USER_DELETED'), type: 'success' });
          this.$router.push({ name: 'ManageUsers' });
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleEmailClick() {
      this.$store
        .dispatch('resendConfirmEmail', this.user.accountUserId)
        .then(response => {
          this.ALERT({ message: this.$t('EMAIL_SENT'), type: 'success' });
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleLogoChange(e) {
      this.encodeImageFileAsURL(e.target);
    },
    encodeImageFileAsURL(element) {
      var that = this;
      var file = element.files[0];
      var reader = new FileReader();
      reader.onloadend = function() {
        that.user.logo = reader.result;
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    ...mapState(['loading']),
    languages() {
      return this.$store.state.languages;
    },
  },
  watch: {
    'user.userRole'(value) {
      if (['PARTNER', 'SHOP'].includes(value)) {
        this.$store.dispatch('getPartners').then(partnersResponse => {
          this.partners = partnersResponse.data;
        });
      }
    },
  },
};
</script>
