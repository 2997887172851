<template>
  <b-card class="mt-4 card-padding-md">
    <h2 class="card-title card-title-pulled-left">
      <span>
        <figure class="card-title-icon">
          <img
            src="../../assets/package-icon.svg"
            class="card-title-image"
            alt="terms"
          />
        </figure>
        {{ $t('TERMS') }}
      </span>
      <b-button
        :class="showTerms ? null : 'collapsed'"
        :aria-expanded="showTerms ? 'true' : 'false'"
        aria-controls="collapseTerms"
        @click="showTerms = !showTerms"
      >
        {{ showTerms ? $t('CLOSE') : $t('OPEN') }}
      </b-button>
    </h2>

    <b-collapse id="collapseTerms" v-model="showTerms" class="mt-2">
      <b-list-group class="terms">
        <b-list-group-item>
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('PERIOD') }} ({{ $t('MONTHS') }})</span>
            </b-col>
            <b-col md="9">
              <b-button-group>
                <b-button
                  @click="
                    changeOption(paymentPeriodOptions, option, 'paymentPeriod'),
                      calculatePmt()
                  "
                  v-for="(option, index) in paymentPeriodOptions"
                  :key="index"
                  :class="{ active: option.isSelected }"
                  :disabled="disableOnStatus"
                >
                  <span>{{ option.value }}</span>
                </b-button>
                <input
                  @focusout="
                    updateActive(
                      paymentPeriodOptions,
                      paymentPeriodInput,
                      'paymentPeriod',
                      partnerSettings.paymentPeriodOptions,
                    ),
                      calculatePmt()
                  "
                  v-model.trim="paymentPeriodInput"
                  type="text"
                  placeholder="..."
                  :class="[
                    'terms-input',
                    {
                      active: !updateActive(
                        paymentPeriodOptions,
                        value.paymentPeriod,
                      ),
                    },
                  ]"
                  :disabled="disableOnStatus"
                  @keyup="numerify('paymentPeriodInput')"
                />
              </b-button-group>
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('RESIDUAL') }} (%)</span>
            </b-col>
            <b-col md="9">
              <b-button-group>
                <b-button
                  @click="
                    changeOption(residualOptions, option, 'residual'),
                      calculatePmt()
                  "
                  v-for="(option, index) in residualOptions"
                  :key="index"
                  :class="{ active: option.isSelected }"
                  :disabled="disableOnStatus"
                >
                  <span>{{ option.value }}</span>
                </b-button>
                <input
                  @focusout="
                    updateActive(
                      residualOptions,
                      residualInput,
                      'residual',
                      partnerSettings.residualOptions,
                    ),
                      calculatePmt()
                  "
                  v-model.trim="residualInput"
                  type="text"
                  placeholder="..."
                  @keyup="numerifyWithDecimals('residualInput')"
                  :class="[
                    'terms-input',
                    { active: !updateActive(residualOptions, value.residual) },
                  ]"
                  :disabled="disableOnStatus"
                />
              </b-button-group>
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('KICKBACK') }} (%)</span>
            </b-col>
            <b-col md="9">
              <b-button-group>
                <b-button
                  @click="
                    changeOption(kickbackOptions, option, 'kickback'),
                      calculatePmt()
                  "
                  v-for="(option, index) in kickbackOptions"
                  :key="index"
                  :class="{ active: option.isSelected }"
                  :disabled="disableOnStatus"
                >
                  <span>{{ option.value }}</span>
                </b-button>
                <input
                  @focusout="
                    updateActive(
                      kickbackOptions,
                      kickbackInput,
                      'kickback',
                      partnerSettings.kickbackOptions,
                    ),
                      calculatePmt()
                  "
                  v-model.trim="kickbackInput"
                  type="text"
                  placeholder="..."
                  :class="[
                    'terms-input',
                    { active: !updateActive(kickbackOptions, value.kickback) },
                  ]"
                  :disabled="disableOnStatus"
                  @keyup="numerifyWithDecimals('kickbackInput')"
                />
              </b-button-group>
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item
          v-if="isInhouse || viewName === 'credit' || viewName === 'compliance'"
        >
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('INTEREST') }} (%)</span>
            </b-col>
            <b-col md="9">
              <b-button-group>
                <b-button
                  @click="
                    changeOption(interestOptions, option, 'interest'),
                      calculatePmt()
                  "
                  v-for="(option, index) in interestOptions"
                  :key="index"
                  :class="{ active: option.isSelected }"
                  :disabled="disableOnStatus"
                >
                  <span>{{ option.value }}</span>
                </b-button>
                <input
                  @focusout="
                    updateActive(
                      interestOptions,
                      interestInput,
                      'interest',
                      partnerSettings.interestOptions,
                    ),
                      calculatePmt()
                  "
                  v-model.trim="interestInput"
                  type="text"
                  placeholder="..."
                  :class="[
                    'terms-input',
                    { active: !updateActive(interestOptions, value.interest) },
                  ]"
                  :disabled="disableOnStatus"
                  @keyup="numerifyWithDecimals('interestInput')"
                />
              </b-button-group>
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('ADVANCE') }}</span>
            </b-col>
            <b-col md="9">
              <b-button-group>
                <b-button
                  @click="
                    changeOption(advanceOptions, option, 'advance'),
                      calculatePmt()
                  "
                  v-for="(option, index) in advanceOptions"
                  :key="index"
                  :class="{ active: option.isSelected }"
                  :disabled="disableOnStatus"
                >
                  <span>{{ option.value }}</span>
                </b-button>
                <input
                  @focusout="
                    updateActive(
                      advanceOptions,
                      advanceInput,
                      'advance',
                      partnerSettings.advanceOptions,
                    ),
                      calculatePmt()
                  "
                  v-model.trim="advanceInput"
                  type="text"
                  placeholder="..."
                  :class="[
                    'terms-input-advance',
                    { active: !updateActive(advanceOptions, value.advance) },
                  ]"
                  :disabled="disableOnStatus"
                  @keyup="numerifyWithDecimals('advanceInput')"
                />
                <select
                  class="terms-select"
                  v-model="value.advnPmtType"
                  @change="calculatePmt()"
                  :disabled="disableOnStatus"
                >
                  <option value="%">%</option>
                  <option value="#">€</option>
                </select>
              </b-button-group>
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('CONTRACT_FEE') }} (€)</span>
            </b-col>
            <b-col md="9">
              <div class="btn-group">
                <input
                  type="text"
                  class="terms-input terms-input-all-border"
                  @change="calculatePmt()"
                  v-model.trim="value.contractFee"
                  :disabled="
                    (!isInhouse &&
                      viewName !== 'credit' &&
                      viewName !== 'compliance') ||
                      disableOnStatus
                  "
                  @keyup="numerifyWithDecimals('value', 'contractFee')"
                  @focusout="updateOneValueTerm('contractFee')"
                />
              </div>
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item
          v-if="isInhouse || viewName === 'credit' || viewName === 'compliance'"
        >
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('SERVICES_COST') }} (€)</span>
            </b-col>
            <b-col md="9">
              <div class="btn-group">
                <input
                  type="text"
                  @change="calculatePmt()"
                  v-model.trim="value.servicesCost"
                  class="terms-input terms-input-all-border"
                  :disabled="disableOnStatus"
                  @keyup="numerifyWithDecimals('value', 'servicesCost')"
                  @focusout="updateOneValueTerm('servicesCost')"
                />
              </div>
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item v-if="isInhouse">
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('INSURANCE') }} (%)</span>
            </b-col>
            <b-col md="9">
              <b-row align-v="center">
                <b-col md="auto">
                  <input
                    type="text"
                    @change="calculatePmt()"
                    v-model.trim="value.insurance"
                    @keyup="numerifyWithDecimals('value', 'insurance')"
                    @focusout="updateOneValueTerm('insurance')"
                    :disabled="
                      (!isInhouse &&
                        viewName !== 'credit' &&
                        viewName !== 'compliance') ||
                        disableOnStatus
                    "
                    class="terms-input terms-input-all-border"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item
          v-if="isInhouse || viewName === 'credit' || viewName === 'compliance'"
        >
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('SPECIAL_TERMS') }}</span>
            </b-col>
            <b-col md="9">
              <b-form-textarea
                v-model="value.specialTerms"
                :rows="2"
                class="bg-info"
                :disabled="disableOnStatus"
              >
              </b-form-textarea>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item
          v-if="isInhouse || viewName === 'credit' || viewName === 'compliance'"
        >
          <b-row align-v="center">
            <b-col md="3" class="text-right">
              <span>{{ $t('OFFER_TYPE') }}</span>
            </b-col>
            <b-col md="9">
              <b-form-select
                v-if="isInhouse"
                v-model="offerType"
                class="form-control number query-manager"
                :options="offerTypes"
              >
              </b-form-select>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-collapse>

    <div class="card-footer">
      <div class="total-terms-badge-group">
        <div class="total-terms-badge">
          <span class="total-terms-text">{{ $t('TOTAL_MONTHLY') }}</span>
          <div class="total-terms-tag total-terms-tag-white">
            {{ value.pmtAmount }}&nbsp;€
          </div>
        </div>
        <div class="total-terms-badge" v-if="isInhouse">
          <span class="total-terms-text">{{ $t('KICKBACK') }}</span>
          <div class="total-terms-tag total-terms-tag-white">
            {{ computedKickback }}&nbsp;€
          </div>
        </div>
        <div
          class="total-terms-badge"
          v-if="isInhouse || viewName === 'credit' || viewName === 'compliance'"
        >
          <span class="total-terms-text">{{ $t('COMISSION') }}</span>
          <div class="total-terms-tag total-terms-tag-white">
            {{ computedCommission }}&nbsp;€
          </div>
        </div>
      </div>
      <b-collapse id="collapseTerms" v-model="showTerms" class="mt-2">
        <b-row align-h="center" class="mt-4">
          <b-col
            md="4"
            v-if="
              isInhouse || viewName === 'credit' || viewName === 'compliance'
            "
          >
            <b-btn
              @click="togglePaymentModal"
              v-b-modal.graphModal
              block
              class="btn-shadow-corner"
            >
              <img
                src="../../assets/small-calendar.svg"
                class="btn-image img-fluid"
                alt="payment schedule"
              />
              {{ $t('PAYMENT_SCHEDULE') }}
            </b-btn>
          </b-col>
          <b-col md="4">
            <b-btn
              @click="generateOffer(offerType)"
              block
              class="btn-shadow-corner"
              :disabled="
                partnerSettings.accountUserId === 20 ||
                  partnerSettings.accountUserId === 19
              "
            >
              <img
                src="../../assets/medal.svg"
                class="btn-image img-fluid"
                alt="generate offer"
              />
              {{ $t('GENERATE_OFFER') }}
            </b-btn>
          </b-col>
        </b-row>
      </b-collapse>
    </div>

    <PaymentTableModal
      :show="isPaymentModalOpen"
      @hide="togglePaymentModal"
      :value="value"
      :lendifyInsurance="lendifyInsurance"
    />
  </b-card>
</template>

<script>
import { replaceComma, numerify, numerifyWithDecimals } from '@/utils';
import { PaymentTableModal } from '@/components/common';
import { checkIfValueIsInOptions } from '../../utils';

export default {
  name: 'Terms',
  components: {
    PaymentTableModal,
  },
  props: {
    value: Object,
    partnerSettings: Object,
    viewName: String,
    disableOnStatus: Boolean,
    lendifyInsurance: String,
    computedKickback: Number,
    computedCommission: String,
    interestChanged: Boolean,
    isInhouse: Boolean,
  },
  data() {
    return {
      isPaymentModalOpen: false,
      showTerms: false,
      paymentPeriodOptions: [],
      residualOptions: [],
      kickbackOptions: [],
      advanceOptions: [],
      interestOptions: [],
      paymentPeriodInput: '',
      residualInput: '',
      kickbackInput: '',
      advanceInput: '',
      interestInput: '',
      interestMatrixPeriods: [12, 24, 36, 48, 60],
      offerType: '8',
      offerTypes: [
        { value: '8', text: 'Default' },
        { value: '17', text: 'iDeal' },
        { value: '19', text: 'Standard' },
      ],
    };
  },
  mounted: function() {
    this.paymentPeriodOptions = this.calculatedPeriodOptions(
      this.partnerSettings.paymentPeriodOptions[0],
      this.partnerSettings.paymentPeriodOptions[1],
    );
    this.residualOptions = this.calculatedOptions(
      this.partnerSettings.residualOptions[0],
      this.partnerSettings.residualOptions[1],
    );
    this.kickbackOptions = this.calculatedOptions(
      this.partnerSettings.kickbackOptions[0],
      this.partnerSettings.kickbackOptions[1],
    );
    this.interestOptions = this.calculatedOptions(
      this.partnerSettings.interestOptions[0],
      this.partnerSettings.interestOptions[1],
    );
    this.advanceOptions = this.calculatedOptions(
      this.partnerSettings.advanceOptions[0],
      this.partnerSettings.advanceOptions[1],
    );

    if (this.value && this.partnerSettings) {
      this.checkDefaultSettingValue('kickback');
      this.checkDefaultSettingValue('advance');
      this.checkDefaultSettingValue('interest');
      this.checkDefaultSettingValue('residual');
      this.checkDefaultSettingValue('paymentPeriod');
    }

    if (!this.isInhouse) {
      this.updateActive(this.paymentPeriodOptions, this.value.paymentPeriod);
    }
  },
  watch: {
    interestChanged() {
      if (this.interestChanged) {
        this.interestInput = this.value.interest;
        this.updateActive(
          this.interestOptions,
          this.value.interest,
          'interest',
          this.partnerSettings.interestOptions,
          true,
        );
        this.$emit('changeInterestChanged');
      }
    },
  },
  methods: {
    togglePaymentModal() {
      this.isPaymentModalOpen = !this.isPaymentModalOpen;
    },
    calculatePmt() {
      this.$emit('calculatePmt');
    },
    generateOffer(offerType) {
      this.$emit('saveQuery', () => {
        this.$store.dispatch('generateOffer', {
          queryId: this.value.queryId,
          offerType,
        });
      });
    },
    calculatedPeriodOptions: function(min, max) {
      const options = [];
      for (
        let i = 0;
        i < this.partnerSettings.allPmtPeriodOptions.length;
        i++
      ) {
        if (
          min <= parseFloat(this.partnerSettings.allPmtPeriodOptions[i]) &&
          parseFloat(this.partnerSettings.allPmtPeriodOptions[i]) <= max
        ) {
          options.push({
            value: parseFloat(this.partnerSettings.allPmtPeriodOptions[i]),
            isSelected: false,
          });
        }
      }
      return options;
    },
    calculatedOptions: function(min, max) {
      const options = [];
      let maxIndex = 5;
      const minValue = parseFloat(min);
      const maxValue = parseFloat(max);
      const gapSize = maxValue - minValue;
      if (!gapSize) {
        if (minValue) {
          options.push({ value: minValue, isSelected: false });
        } else {
          options.push({ value: maxValue, isSelected: false });
        }
        return options;
      }
      const stepSize = gapSize / 5;
      for (let i = 0; i < 5; i++) {
        if (gapSize === 0) {
          options.push({ value: minValue, isSelected: false });
          break;
        }
        if (i === 0) {
          options.push({ value: minValue, isSelected: false });
          continue;
        }
        const x = parseInt(minValue) + i * stepSize;
        let value = 0;
        if (stepSize >= 8) {
          value = Math.ceil(x / 10) * 10;
        } else if (stepSize < 8 && stepSize >= 3) {
          value = Math.ceil(x / 5) * 5;
        } else if (stepSize < 3 && stepSize >= 1) {
          value = Math.ceil(x / 2) * 2;
        } else if (stepSize < 1) {
          value = Math.ceil(x / 0.5) * 0.5;
        }
        if (
          value === options[options.length - 1].value ||
          value < minValue ||
          value === maxValue
        ) {
          maxIndex -= 1;
          continue;
        }
        options.push({ value: value, isSelected: false });
      }
      if (gapSize !== 0) {
        options.splice(maxIndex, 1, { value: maxValue, isSelected: false });
      }
      return options;
    },
    checkDefaultSettingValue: function(setting) {
      if (this.viewName === 'partnerCreate') {
        if (
          this.partnerSettings[
            `default${setting.charAt(0).toUpperCase() + setting.slice(1)}`
          ]
        ) {
          this.$set(
            this.value,
            setting,
            this.partnerSettings[
              `default${setting.charAt(0).toUpperCase() + setting.slice(1)}`
            ],
          );
        } else {
          if (setting !== 'paymentPeriod') {
            this.$set(
              this.value,
              setting,
              this.partnerSettings[`${setting}Options`][0],
            );
          } else {
            this.$set(this.value, setting, 12);
          }
        }
      }
      if (
        this[`${setting}Options`]
          .map(option => option.value)
          .includes(parseFloat(this.value[`${setting}`]))
      ) {
        this[`${setting}Input`] = '';
      } else {
        this[`${setting}Input`] = this.value[`${setting}`];
      }
    },
    changeOption(options, option, setting) {
      if (option.value !== '') {
        if (setting !== 'interest') {
          this.$set(this.value, setting, option.value);
        }
        option.isSelected = true;
        this[`${setting}Input`] = '';
      }
      this.updateActive(options, option.value, setting);
    },
    updateActive(
      options,
      value,
      setting,
      partnerOptions,
      changedAutomatically,
    ) {
      value = replaceComma(value);
      if (isNaN(+value)) {
        this[`${setting}Input`] = '';
        return false;
      }

      if (
        (setting === 'interest' &&
          value !== '' &&
          this.value.interest !== +value &&
          !changedAutomatically) ||
        (setting === 'paymentPeriod' &&
          value !== '' &&
          !this.interestMatrixPeriods.includes(+value))
      ) {
        this.value.interestMatrix = null;
      }

      if (setting !== 'interest') {
        if (this.viewName !== 'compliance' && this.viewName !== 'credit') {
          if (partnerOptions) {
            if (!this.isInhouse) {
              if (+value < partnerOptions[0] && value !== '') {
                value = +partnerOptions[0];
                this[`${setting}Input`] = value;
              } else if (+value > partnerOptions[1] && value !== '') {
                value = +partnerOptions[1];
                this[`${setting}Input`] = value;
              }
            }
          }
        }
      }

      let count = 0;
      if (options && options.length > 0) {
        for (const option of options) {
          const isSelect = option.value === +value;
          option.isSelected = isSelect;
          count = isSelect ? count + 1 : count;
        }
      }

      if (value !== '') {
        this.$set(this.value, setting, +value);
      } else {
        if (checkIfValueIsInOptions(options, this.value[setting])) {
          count = 0;
        } else {
          count = 0;
          this[`${setting}Input`] = this.value[setting];
        }
      }

      if (count === 0) {
        return false;
      } else {
        this[`${setting}Input`] = '';
        return true;
      }
    },
    updateOneValueTerm(term) {
      if (this.value[term] !== '') return;
      this.value[term] = this.partnerSettings[term];
    },
    updateTermInputs() {
      this.paymentPeriodInput = this.value.paymentPeriod;
      this.advanceInput = this.value.advance;
      this.residualInput = this.value.residual;
      this.kickbackInput = this.value.kickback;
      if (
        this.checkIfValueIsInOptions(
          this.paymentPeriodOptions,
          this.value.paymentPeriod,
        )
      ) {
        this.paymentPeriodInput = '';
        if (!this.isInhouse) {
          this.updateActive(
            this.paymentPeriodOptions,
            this.value.paymentPeriod,
          );
        }
      }
      if (this.checkIfValueIsInOptions(this.advanceOptions, this.value.advance))
        this.advanceInput = '';
      if (
        this.checkIfValueIsInOptions(this.residualOptions, this.value.residual)
      )
        this.residualInput = '';
      if (
        this.checkIfValueIsInOptions(this.kickbackOptions, this.value.kickback)
      )
        this.kickbackInput = '';
    },
  },
  computed: {
    numerify() {
      return numerify;
    },
    numerifyWithDecimals() {
      return numerifyWithDecimals;
    },
    checkIfValueIsInOptions() {
      return checkIfValueIsInOptions;
    },
  },
};
</script>
