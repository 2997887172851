<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div>
    <Header :header="$t('EDIT_TEMPLATE')" />

    <RenderSpinner v-if="$store.state.loading" />

    <b-container class="mt-5">
      <b-form @submit.prevent="handleFormSubmit">
        <h2 class="card-title">{{ template.name }}</h2>
        <b-card>
          <b-form-group
            :label="$t('TEMPLATE_NAME')"
            label-for="templateName"
            :invalid-feedback="$t('VALIDATION.TEMPLATE_NAME_REQUIRED')"
            :state="validateState('templateName')"
          >
            <b-form-input
              type="text"
              v-model="currentTemplate.name"
              name="templateName"
              id="templateName"
              v-validate="'required'"
            />
          </b-form-group>

          <div
            v-for="(assets, key) in computedTemplate"
            :key="key"
            class="mt-4"
          >
            <h6>
              {{ $t(key.toUpperCase()) }}
              <b-btn
                v-if="key === 'assets'"
                size="sm"
                class="ml-2"
                @click="addNewAsset"
              >
                {{ $t('ADD') }}
              </b-btn>
            </h6>
            <template v-if="key === 'assets'">
              <ul
                class="list-group terms border-bottom"
                v-for="(asset, assetKey) in assets"
                :key="assetKey"
              >
                <li
                  v-for="(prop, index) in asset"
                  :key="index"
                  v-if="assetFields.includes(index)"
                  class="list-group-item"
                >
                  <b-row align-v="center">
                    <b-col md="4" class="text-right">
                      <span>{{ $t(snakeCase(index).toUpperCase()) }}</span>
                    </b-col>
                    <b-col md="4">
                      <b-form-input
                        :id="`${index}-${assetKey}`"
                        :name="index"
                        v-model.trim="currentTemplate[key][assetKey][index]"
                        type="text"
                      />
                    </b-col>
                  </b-row>
                </li>
                <li class="list-group-item text-center">
                  <b-btn size="sm" @click="removeAsset(assetKey)">
                    {{ $t('DELETE') }}
                  </b-btn>
                </li>
              </ul>
            </template>
            <template v-if="key === 'terms'">
              <ul class="list-group terms">
                <b-row align-v="center">
                  <b-col md="4"></b-col>
                  <b-col md="8">
                    <b-button-group>
                      <span class="terms-header">MIN</span>
                      <span class="terms-header"></span>
                      <span class="terms-header">MAX</span>
                    </b-button-group>
                  </b-col>
                </b-row>
                <li
                  v-if="computedPartnerSettings"
                  v-for="(prop, index) in assets"
                  :key="index"
                  class="list-group-item"
                >
                  <b-row align-v="center">
                    <b-col md="4" class="text-right">
                      <span v-if="index === 'paymentPeriod'"
                        >{{ $t('PERIOD') }} ({{ $t('MONTHS') }})</span
                      >
                      <span v-else
                        >{{ $t(snakeCase(index).toUpperCase()) }} (%)</span
                      >
                    </b-col>
                    <b-col md="8" class="d-flex">
                      <b-form-input
                        class="terms-input terms-input-left-border terms-input-no-right-border"
                        type="text"
                        v-model.trim="
                          computedPartnerSettings[`${index + 'Options'}`][0]
                        "
                        disabled
                      />
                      <b-form-input
                        class="terms-input terms-input-no-right-border rounded-0"
                        v-if="index !== 'paymentPeriod'"
                        :id="index"
                        :name="index"
                        v-model.trim="currentTemplate[key][index]"
                        @keyup.native="
                          numerifyWithDecimals('currentTemplate', key, index)
                        "
                        @change="
                          controlGap(
                            currentTemplate[key][index],
                            partnerSettings[`${index + 'Options'}`],
                            index,
                          )
                        "
                        type="text"
                      />
                      <b-form-select
                        v-else
                        class="payment-period-terms-select template-payment-period border-left-0"
                        v-model.trim="currentTemplate[key][index]"
                        @input="
                          controlGap(
                            currentTemplate[key][index],
                            partnerSettings[`${index + 'Options'}`],
                            index,
                          )
                        "
                      >
                        <option
                          disabled
                          v-if="
                            !computedPaymentPeriodOptions.includes(
                              currentTemplate[key][index],
                            )
                          "
                          >{{ currentTemplate[key][index] }}</option
                        >
                        <option
                          v-for="(option,
                          index) in computedPaymentPeriodOptions"
                          :key="index"
                          :value="option"
                          >{{ option }}</option
                        >
                      </b-form-select>
                      <b-form-input
                        class="terms-input no-left-border-radius"
                        type="text"
                        v-model.trim="
                          computedPartnerSettings[`${index + 'Options'}`][1]
                        "
                        disabled
                      />
                    </b-col>
                  </b-row>
                </li>
              </ul>
            </template>
          </div>
        </b-card>

        <div class="mb-5 mt-4">
          <b-row align-h="center">
            <b-col md="4">
              <b-btn variant="secondary" size="lg" block @click="goBack">{{
                $t('BACK')
              }}</b-btn>
            </b-col>
            <b-col md="4">
              <b-btn
                variant="outline-danger"
                size="lg"
                block
                @click="showDeleteModal = true"
                >{{ $t('DELETE_TEMPLATE') }}</b-btn
              >
            </b-col>
            <b-col md="4">
              <b-btn
                type="submit"
                variant="primary"
                size="lg"
                block
                :disabled="errors.items.length > 0"
                >{{ $t('SAVE') }}</b-btn
              >
            </b-col>
          </b-row>
        </div>
      </b-form>
    </b-container>

    <RenderDeleteModal
      :show="showDeleteModal"
      @ok="closeDeleteModal(true)"
      @cancel="closeDeleteModal()"
      :label="$t('ARE_YOU_SURE_DELETE_TEMPLATE')"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  GET_TEMPLATE,
  DELETE_TEMPLATE,
  SAVE_TEMPLATE,
} from '../../../store/template';
import { Header, RenderSpinner, RenderDeleteModal } from '@/components/common';
import snakeCase from 'lodash.snakecase';
import validateState from '../../../mixins/validateState';
import {
  deleteTemplateProps,
  replaceObjectValuesComma,
  replaceComma,
  numerifyWithDecimals,
} from '../../../utils';

export default {
  name: 'EditTemplate',
  components: {
    Header,
    RenderSpinner,
    RenderDeleteModal,
  },
  mixins: [validateState],
  props: {
    id: String,
  },
  data() {
    return {
      currentTemplate: {},
      showDeleteModal: false,
      assetFields: ['qassetName', 'qassetPrice', 'quantity'],
      termsFields: ['advance', 'kickback', 'paymentPeriod', 'residual'],
      partnerSettings: null,
    };
  },
  watch: {
    template() {
      if (this.template) {
        this.currentTemplate = JSON.parse(JSON.stringify(this.template));
      }
    },
    isDeleted() {
      if (this.isDeleted) {
        // call with empty arguments to reset isDeleted in store
        this.DELETE_TEMPLATE();
        this.goBack();
      }
    },
  },
  mounted() {
    this.GET_TEMPLATE(this.id);
    this.$store
      .dispatch('getPartnerSettings')
      .then(response => {
        this.partnerSettings = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    ...mapActions('template', [GET_TEMPLATE, DELETE_TEMPLATE, SAVE_TEMPLATE]),
    handleFormSubmit() {
      this.replaceCommas();
      this.SAVE_TEMPLATE(this.currentTemplate);
    },
    closeDeleteModal(doDelete) {
      this.showDeleteModal = false;
      if (doDelete) return this.DELETE_TEMPLATE(this.id);
    },
    goBack() {
      this.$router.push({ name: 'Templates' });
    },
    addNewAsset() {
      this.currentTemplate.assets.push({
        qassetName: '',
        qassetPrice: '',
        quantity: '',
      });
    },
    removeAsset(key) {
      this.currentTemplate.assets = this.currentTemplate.assets.filter(
        (_, i) => i !== +key,
      );
    },
    controlGap: function(value, settings, setting) {
      if (parseFloat(value) < +settings[0]) {
        value = +settings[0];
      } else if (parseFloat(value) > +settings[1]) {
        value = +settings[1];
      }
      this.$set(this.currentTemplate.terms, setting, value);
    },
    replaceCommas() {
      this.currentTemplate.terms = replaceObjectValuesComma(
        this.currentTemplate.terms,
        this.termsFields,
      );
      this.currentTemplate.assets.forEach(asset => {
        asset.qassetPrice = +replaceComma(asset.qassetPrice);
      });
    },
  },
  computed: {
    ...mapState('template', ['template', 'isDeleted']),
    computedPaymentPeriodOptions() {
      let computedOptions = [...this.partnerSettings.allPmtPeriodOptions];
      let toRemoveOptions = [];
      computedOptions.forEach(option => {
        if (
          +option < +this.partnerSettings.paymentPeriodOptions[0] ||
          +option > +this.partnerSettings.paymentPeriodOptions[1]
        ) {
          toRemoveOptions.push(option);
        }
      });
      computedOptions = computedOptions.filter(
        element => !toRemoveOptions.includes(element),
      );
      return computedOptions;
    },
    computedTemplate() {
      if (this.currentTemplate.id) {
        const { id, name, ...props } = deleteTemplateProps(
          this.currentTemplate,
          'edit',
        );
        return props;
      }
    },
    snakeCase() {
      return snakeCase;
    },
    numerifyWithDecimals() {
      return numerifyWithDecimals;
    },
    computedPartnerSettings() {
      if (this.partnerSettings) {
        return this.partnerSettings;
      }
    },
  },
};
</script>
