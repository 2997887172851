<template>
  <div>
    <b-card class="card-padding-md">
      <b-form @submit.prevent="addQasset(newAsset)">
        <b-row>
          <b-col md="12" class="d-flex justify-content-between">
            <h3>{{ $t('ADD_ASSETS') }}</h3>
            <b-button
              :class="showAddAssetForm ? null : 'collapsed'"
              :aria-expanded="showAddAssetForm ? 'true' : 'false'"
              aria-controls="collapseAddAssetForm"
              @click="showAddAssetForm = !showAddAssetForm"
            >
              {{ showAddAssetForm ? $t('CLOSE') : $t('OPEN') }}
            </b-button>
          </b-col>
        </b-row>

        <b-collapse
          id="collapseAddAssetForm"
          v-model="showAddAssetForm"
          class="mt-2"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('ASSET_GROUP') + ':'"
                label-for="AssetGroup"
              >
                <multiselect
                  v-model="newAsset.selectedAssetGroup"
                  :options="
                    availableMainAssetGroups.filter(
                      group => group.superiorGroupId === null,
                    )
                  "
                  label="assetGroup"
                  :disabled="disableOnStatus"
                  :show-labels="false"
                >
                  <template #option="{ option }">
                    {{ option.name }}
                  </template>
                  <template #singleLabel="{ option }">{{
                    option.name
                  }}</template>
                  <template #noResult>{{ $t('NO_ELEMENTS_FOUND') }}</template>
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ASSET_SUB_GROUP') + ':'"
                label-for="assetSubGroup"
              >
                <multiselect
                  v-model="newAsset.selectedAssetSubGroup"
                  :options="
                    newAsset.selectedAssetGroup.assetGroupId
                      ? newAsset.selectedAssetGroup.subgroups
                      : []
                  "
                  label="Asset sub groups"
                  :disabled="disableOnStatus"
                  :show-labels="false"
                >
                  <template #option="{ option }">
                    {{ option.name }}
                  </template>
                  <template #singleLabel="{ option }">{{
                    option.name
                  }}</template>
                  <template #noResult>{{ $t('NO_ELEMENTS_FOUND') }}</template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('QASSET_NAME') + ':'"
                label-for="assetName"
                :invalid-feedback="$t('VALIDATION.QASSET_NAME_REQUIRED')"
                :state="validateState('assetName')"
              >
                <b-form-input
                  id="assetName"
                  name="assetName"
                  v-model.trim="newAsset.qassetName"
                  v-validate="'required'"
                  type="text"
                  :disabled="disableOnStatus"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('QASSET_PRICE_WITH_VAT') + ':'"
                label-for="qassetPriceWithVat"
              >
                <b-form-input
                  id="qassetPriceWithVat"
                  name="qassetPriceWithVat"
                  v-model.trim="newAsset.qassetPriceWithVat"
                  @keyup.native="
                    numerifyWithDecimals('newAsset', 'qassetPriceWithVat')
                  "
                  type="text"
                  @input="calculateWithoutVat"
                  :disabled="disableOnStatus"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('QASSET_PRICE_NO_VAT') + ':'"
                label-for="qassetPrice"
                :invalid-feedback="$t('VALIDATION.QASSET_PRICE_REQUIRED')"
                :state="validateState('qassetPrice')"
              >
                <b-form-input
                  id="qassetPrice"
                  name="qassetPrice"
                  v-model.trim="newAsset.qassetPrice"
                  v-validate="'required'"
                  @keyup.native="
                    numerifyWithDecimals('newAsset', 'qassetPrice')
                  "
                  type="text"
                  @input="calculateWithVat"
                  :disabled="disableOnStatus"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
              v-if="
                isInhouse || viewName === 'credit' || viewName === 'compliance'
              "
            >
              <b-form-group
                :label="$t('YEAR') + ':'"
                label-for="year"
                :invalid-feedback="$t('VALIDATION.QASSET_YEAR_REQUIRED')"
                :state="validateState('year')"
              >
                <b-form-input
                  id="year"
                  name="year"
                  v-model.trim="newAsset.year"
                  v-validate="'required'"
                  type="text"
                  @keyup.native="numerify('newAsset', 'year')"
                  :disabled="disableOnStatus"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('SELLER_OFFER_NR') + ':'"
                label-for="sellerOfferNr"
              >
                <b-form-input
                  id="sellerOfferNr"
                  v-model.trim="newAsset.sellerOfferNr"
                  :disabled="disableOnStatus"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('SELLER_OFFER_DATE') + ':'"
                label-for="sellerOfferDate"
              >
                <RenderDatePicker
                  @changeDate="e => $set(newAsset, 'sellerOfferDate', e)"
                  :date="newAsset.sellerOfferDate"
                  :viewName="viewName"
                  :partnerSettings="partnerSettings"
                  id="sellerOfferDate"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="isInhouse">
              <h3>{{ $t('SELLER') }}</h3>
            </b-col>
            <b-col md="12" v-if="isInhouse && value.qassets.length > 0">
              <div class="mb-3">
                <b-btn size="sm" @click="copyFieldsFromLastAsset()">
                  {{ $t('COPY_FROM_ADDED_ASSET') }}
                </b-btn>
              </div>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('SELLER_NAME') + ':'"
                label-for="custName"
              >
                <multiselect
                  v-model="selected"
                  :options="companies"
                  :loading="isLoading"
                  label="name"
                  @search-change="getCompaniesByName"
                  @input="setCompanyParams"
                  :placeholder="partnerSettings.businessName"
                  :disabled="
                    (disableOnStatus && disableDeliveryAndSellerInfo) ||
                      showManualInputs
                  "
                  :show-labels="false"
                >
                  <template #noResult>{{ $t('NO_ELEMENTS_FOUND') }}</template>
                </multiselect>
                <b-form-input
                  class="mt-2"
                  v-if="showManualInputs"
                  v-model.trim="newAsset.sellerName"
                  type="text"
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
              <b-btn
                size="sm"
                @click="toggleManualInputs()"
                :disabled="disableOnStatus"
                class="mb-3"
              >
                {{ manualInputBtnText }}
              </b-btn>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('REGISTRY_CODE') + ':'"
                label-for="custRegCode"
              >
                <multiselect
                  v-model="selected"
                  :options="companies"
                  :loading="isLoading"
                  label="companyCode"
                  @search-change="getCompaniesByCode"
                  @input="setCompanyParams"
                  :placeholder="partnerSettings.regCode"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                  :show-labels="false"
                >
                  <template #noResult>{{ $t('NO_ELEMENTS_FOUND') }}</template>
                </multiselect>
                <b-form-input
                  class="mt-2"
                  v-if="showManualInputs"
                  v-model.trim="newAsset.sellerRegCode"
                  type="text"
                  :disabled="disableOnStatus"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group :label="$t('VAT_CODE') + ':'" label-for="vatNumber">
                <b-form-input
                  v-model="newAsset.vatNumber"
                  id="vatNumber"
                  type="text"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('BANK_ACCOUNT_NUMBER') + ':'"
                label-for="bankAccountNumber"
              >
                <b-form-input
                  id="bankAccountNumber"
                  v-model="newAsset.bankAccountNumber"
                  type="text"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('STREET') + ':'"
                label-for="sellerAddress"
              >
                <b-form-input
                  v-model="newAsset.street"
                  id="street"
                  type="text"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('POSTAL_CODE') + ':'"
                label-for="zipCode"
              >
                <b-form-input
                  v-model="newAsset.zipCode"
                  id="zipCode"
                  type="text"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group :label="$t('CITY') + ':'" label-for="city">
                <b-form-input
                  v-model="newAsset.city"
                  id="city"
                  type="text"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('SELLER_PHONE') + ':'"
                label-for="contactPhone"
              >
                <b-form-input
                  id="contactPhone"
                  type="text"
                  v-model.trim="newAsset.sellerPhone"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('SELLER_EMAIL') + ':'"
                label-for="sellerEmail"
                :invalid-feedback="$t('VALIDATION.INVALID_EMAIL')"
                :state="validateState('sellerEmail')"
              >
                <b-form-input
                  id="sellerEmail"
                  name="sellerEmail"
                  v-validate="'email'"
                  type="text"
                  v-model.trim="newAsset.sellerEmail"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="isInhouse">
              <h3>{{ $t('SELLER_REPRESENTATIVE') }}</h3>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('REPRESENTATIVE_NAME') + ':'"
                label-for="representativeName"
              >
                <b-form-input
                  id="representativeName"
                  v-model.trim="newAsset.sellerRepresentative"
                  type="text"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('REPRESENTATIVE_PERSONAL_CODE') + ':'"
                label-for="representativePersonalCode"
              >
                <b-form-input
                  id="representativePersonalCode"
                  v-model.trim="newAsset.representativePersonalCode"
                  type="text"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('REPRESENTATIVE_PHONE') + ':'"
                label-for="representativePhone"
              >
                <b-form-input
                  id="representativePhone"
                  v-model.trim="newAsset.representativePhone"
                  type="text"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('REPRESENTATIVE_EMAIL') + ':'"
                label-for="representativeEmail"
              >
                <b-form-input
                  id="representativeEmail"
                  v-model.trim="newAsset.representativeEmail"
                  type="text"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="isInhouse">
              <b-form-group
                :label="$t('SELLER_REPRS_REPR_TYPE') + ':'"
                label-for="sellerReprsReprType"
                class="mb-2"
              >
                <b-form-radio-group
                  id="sellerReprsReprType"
                  button-variant="outline-secondary"
                  v-model.trim="newAsset.sellerReprsReprType"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                >
                  <b-form-radio
                    v-for="option in options"
                    :key="option"
                    :value="option"
                    >{{ $t(snakeCase(option).toUpperCase()) }}</b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="isInhouse">
              <h3>{{ $t('DELIVERY') }}</h3>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('DELIVERY_PLACE') + ':'"
                label-for="deliveryPlace"
              >
                <b-form-input
                  id="deliveryPlace"
                  type="text"
                  v-model.trim="newAsset.deliveryPlace"
                  :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="isInhouse">
              <b-form-group
                :label="$t('DELIVERY_DATE') + ':'"
                label-for="deliveryDate"
              >
                <RenderDatePicker
                  @changeDate="e => $set(newAsset, 'deliveryDate', e)"
                  :date="newAsset.deliveryDate"
                  :viewName="viewName"
                  :partnerSettings="partnerSettings"
                  id="add-qasset"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            type="submit"
            :disabled="!!errors.items[0] || disableOnStatus"
            variant="primary"
            block
            class="mt-1"
            >{{ $t('ADD_ASSET') }}</b-button
          >
        </b-collapse>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import snakeCase from 'lodash.snakecase';
import Multiselect from 'vue-multiselect';
import { mapActions, mapState } from 'vuex';
import validateState from '../../mixins/validateState';
import { RenderDatePicker } from '@/components/common';
import { replaceComma, numerify, numerifyWithDecimals } from '@/utils';
import { GET_BY_NAME, GET_BY_COMPANY_CODE } from '../../store/company';
import { SAVE_QASSET, GET_ALL_ASSET_GROUPS } from '../../store/qasset';
import { getPriceWithoutVat, getPriceWithVat } from '../../utils';

export default {
  name: 'AddAssetForm',
  mixins: [validateState],
  components: {
    Multiselect,
    RenderDatePicker,
  },
  props: {
    isInhouse: Boolean,
    partnerSettings: Object,
    value: Object,
    disableOnStatus: Boolean,
    disableDeliveryAndSellerInfo: Boolean,
    viewName: String,
  },
  data() {
    return {
      newAsset: {
        sellerReprsReprType: this.$t('LAW'),
        selectedAssetSubGroup: {},
        selectedAssetGroup: {},
        quantity: 1,
        year: new Date().getFullYear(),
      },
      selected: [],
      isLoading: false,
      showManualInputs: false,
      showAddAssetForm: false,
      manualInputBtnText: this.$t('ENTER_MANUALLY'),
      options: ['Law', 'Power of Attorney'],
      partnerAssetGroups: [],
    };
  },
  async mounted() {
    this.initializeAsset();
    const { data } = await this.$store.dispatch(
      'getPartnerAssetGroups',
      this.partnerSettings.partnerId,
    );
    this.partnerAssetGroups = data;

    await this.GET_ALL_ASSET_GROUPS();
    this.setAvailableMainAssetGroups();
  },
  watch: {
    valueArrivalPlaceholder: function() {
      if (this.value.custName) {
        this.selected = {
          name: this.value.custName,
          company_code: this.value.custRegCode,
        };
      }
    },
    'newAsset.selectedAssetGroup'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.newAsset.selectedAssetSubGroup = {};
      }
    },
  },
  methods: {
    ...mapActions('company', [GET_BY_NAME, GET_BY_COMPANY_CODE]),
    ...mapActions('qasset', [SAVE_QASSET, GET_ALL_ASSET_GROUPS]),
    async getCompaniesByName(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_NAME(query);
        this.isLoading = false;
      }
    },
    async getCompaniesByCode(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_COMPANY_CODE(query);
        this.isLoading = false;
      }
    },
    setCompanyParams() {
      this.$set(this.newAsset, 'sellerName', this.selected.name);
      this.$set(this.newAsset, 'sellerRegCode', this.selected.companyCode);
      this.$set(this.newAsset, 'vatNumber', this.selected.vatNumber);
      this.$set(this.newAsset, 'street', this.selected.address);
      this.$set(this.newAsset, 'zipCode', this.selected.postalCode);
      this.$set(this.newAsset, 'city', this.selected.city);
    },
    async addQasset(qasset) {
      this.$validator.validateAll().then(async isValid => {
        if (!isValid) return;
        qasset.queryId = this.value.queryId;
        qasset.qassetPrice = replaceComma(qasset.qassetPrice);
        this.value.qassets.push(
          await this.SAVE_QASSET({
            ...qasset,
            assetGroupId: this.newAsset.selectedAssetGroup.assetGroupId,
            assetSubgroupId: this.newAsset.selectedAssetSubGroup.assetGroupId,
          }),
        );
        this.$emit('calculatePmt');
        this.newAsset = {
          sellerReprsReprType: this.$t('LAW'),
          selectedAssetSubGroup: {},
          selectedAssetGroup: {},
          quantity: 1,
          year: new Date().getFullYear(),
        };
        this.initializeAsset();
        this.$store.dispatch(
          'alert/ALERT',
          { message: this.$t('ASSET_ADDED'), type: 'success' },
          { root: true },
        );
      });
    },
    initializeAsset() {
      this.$set(this.newAsset, 'sellerName', this.partnerSettings.businessName);
      this.$set(this.newAsset, 'sellerRegCode', this.partnerSettings.regCode);
      this.$set(this.newAsset, 'vatNumber', this.partnerSettings.vatNumber);
      this.$set(
        this.newAsset,
        'bankAccountNumber',
        this.partnerSettings.bankAccountNumber,
      );
      this.$set(this.newAsset, 'street', this.partnerSettings.street);
      this.$set(this.newAsset, 'zipCode', this.partnerSettings.zipCode);
      this.$set(this.newAsset, 'city', this.partnerSettings.city);
      this.$set(
        this.newAsset,
        'sellerPhone',
        '+372' + (this.partnerSettings.phoneNumber || ''),
      );
      this.$set(this.newAsset, 'sellerEmail', this.partnerSettings.email);
      this.$set(
        this.newAsset,
        'sellerRepresentative',
        this.partnerSettings.representativeName,
      );
      this.$set(
        this.newAsset,
        'representativePersonalCode',
        this.partnerSettings.representativePersonalCode,
      );
      this.$set(
        this.newAsset,
        'representativePhone',
        this.partnerSettings.representativePhone,
      );
      this.$set(
        this.newAsset,
        'representativeEmail',
        this.partnerSettings.representativeEmail,
      );
    },
    toggleManualInputs() {
      if (this.showManualInputs) {
        this.manualInputBtnText = this.$t('ENTER_MANUALLY');
        this.showManualInputs = false;
        this.selected = [];
      } else {
        this.manualInputBtnText = this.$t('SEARCH');
        this.showManualInputs = true;
      }
    },
    copyFieldsFromLastAsset() {
      const {
        assetGroupId,
        qassetId,
        assetSubgroupId,
        qassetName,
        qassetPrice,
        quantity,
        year,
        selectedAssetGroup,
        selectedAssetSubGroup,
      } = this.newAsset;

      this.newAsset = {
        ...this.value.qassets[0],
        assetGroupId,
        qassetId,
        assetSubgroupId,
        qassetName,
        qassetPrice,
        quantity,
        year,
        selectedAssetGroup,
        selectedAssetSubGroup,
      };
    },
    setAvailableMainAssetGroups() {
      let mainAssetIdList = this.partnerAssetGroups.map(
        subAssetGroup =>
          this.allAssetGroups.find(
            ({ assetGroupId }) => assetGroupId == subAssetGroup.assetGroupId,
          ).superiorGroupId,
      );
      const distinctMainAssetIdList = [...new Set(mainAssetIdList)];
      const partnerMainAssetGroups = distinctMainAssetIdList.map(mainAssetId =>
        this.allAssetGroups.find(
          ({ assetGroupId }) => assetGroupId == mainAssetId,
        ),
      );
      let availableMainAssetGroups = null;
      if (!this.isInhouse) {
        availableMainAssetGroups = partnerMainAssetGroups.map(
          mainAssetGroup => {
            mainAssetGroup.subgroups = mainAssetGroup.subgroups.filter(
              subgroup =>
                this.partnerAssetGroups.find(
                  partnerAssetGroup =>
                    partnerAssetGroup.assetGroupId == subgroup.assetGroupId,
                ),
            );
            return mainAssetGroup;
          },
        );
      } else {
        availableMainAssetGroups = this.allAssetGroups.filter(
          assetGroup => assetGroup.superiorGroupId === null,
        );
      }

      this.$store.commit(
        'qasset/SET_AVAILABLE_MAIN_ASSET_GROUPS',
        availableMainAssetGroups,
      );
    },
    calculateWithoutVat(price) {
      this.newAsset.qassetPrice = getPriceWithoutVat(price);
      this.$validator
        .validateAll()
        .then(() => {})
        .catch(() => {});
    },
    calculateWithVat(price) {
      this.newAsset.qassetPriceWithVat = getPriceWithVat(price);
      this.$validator
        .validateAll()
        .then(() => {})
        .catch(() => {});
    },
  },
  computed: {
    ...mapState('company', ['companies']),
    ...mapState('qasset', ['allAssetGroups', 'availableMainAssetGroups']),
    numerify() {
      return numerify;
    },
    numerifyWithDecimals() {
      return numerifyWithDecimals;
    },
    snakeCase() {
      return snakeCase;
    },
  },
};
</script>
