<template>
  <b-modal
    v-model="isOpen"
    @show="generateSchedule()"
    @hide="$emit('hide')"
    :title="$t('PAYMENT_SCHEDULE')"
    size="lg"
  >
    <b-table
      outlined
      responsive
      :items="pmtScheduleRows"
      :fields="tableFields"
    />
    <div slot="modal-footer">
      <b-btn @click="handlePrintClick">{{ $t('PRINT') }}</b-btn>
    </div>
  </b-modal>
</template>

<script>
import printJS from 'print-js';
import { renameKeys } from '@/utils';

export default {
  name: 'PaymentTableModal',
  props: {
    show: Boolean,
    value: Object,
    lendifyInsurance: String,
  },
  data() {
    return {
      isOpen: false,
      pmtScheduleRows: [],
      printPmtScheduleRows: [],
      tableFields: {},
    };
  },
  watch: {
    show() {
      this.isOpen = this.show;
    },
  },
  methods: {
    generateSchedule: function() {
      this.$store
        .dispatch('generatePmtSchedule', {
          assets: this.value.qassets,
          services: this.value.services,
          advancePmt: this.value.advance,
          advnPmtType: this.value.advnPmtType,
          rvValue: this.value.residual,
          intrTotal: this.value.interest,
          leasePeriod: this.value.paymentPeriod,
          pmtDate: this.value.pmtDate,
          contractValue: this.value.contractFee,
          insuranceValue: this.lendifyInsurance,
        })
        .then(response => {
          let keysMap = {};
          Object.keys(response.data[0]).forEach(key => {
            keysMap = {
              ...keysMap,
              [key]: this.$t(`PAYMENT_SCHEDULE_TABLE.${key.toUpperCase()}`),
            };
            this.tableFields = {
              ...this.tableFields,
              [key]: {
                label: this.$t(`PAYMENT_SCHEDULE_TABLE.${key.toUpperCase()}`),
              },
            };
          });
          this.pmtScheduleRows = response.data.map(row => {
            return this.formalizeRow(row);
          });
          this.printPmtScheduleRows = response.data.map(row => {
            return renameKeys(keysMap, this.formalizeRow(row));
          });
        });
    },
    handlePrintClick() {
      return printJS({
        printable: this.printPmtScheduleRows,
        properties: Object.keys(this.printPmtScheduleRows[0]),
        style: 'td { text-align: right; }',
        type: 'json',
      });
    },
    formalizeRow(row) {
      return {
        ...row,
        advancePmt:
          row.advancePmt === 0 ? '' : `${row.advancePmt.toFixed(2)} €`,
        contractFee:
          row.contractFee === 0 ? '' : `${row.contractFee.toFixed(2)} €`,
        pmtAmount: row.pmtAmount === 0 ? '' : `${row.pmtAmount.toFixed(2)} €`,
        pmtWithVAT:
          row.pmtWithVAT === 0 ? '' : `${row.pmtWithVAT.toFixed(2)} €`,
        ['vat' || 'VAT']: `${row['vat' || 'VAT'].toFixed(2)} €`,
      };
    },
  },
};
</script>
