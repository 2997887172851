<template>
  <div>
    <Header
      :header="newAssetGroup ? $t('NEW_ASSET_GROUP') : $t('EDIT_ASSET_GROUP')"
      showSettings
    />
    <RenderSpinner v-if="loading" />
    <b-container class="mt-5">
      <b-form @submit.prevent="handleFormSubmit">
        <h2 class="card-title">
          {{ newAssetGroup ? $t('NEW_ASSET_GROUP') : currentAssetGroupName }}
        </h2>
        <b-card>
          <b-list-group class="terms terms-settings">
            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('ASSET_NAME') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="$t('VALIDATION.REQUIRED')"
                  >
                    <b-form-input
                      id="name"
                      name="name"
                      v-validate="'required'"
                      :state="validateState('name')"
                      v-model.trim="currentAssetGroup.name"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item v-if="!newAssetGroup">
              <b-row align-v="center">
                <b-col md="4" class="text-right">
                  <span>{{ $t('ADD_SUBGROUPS') }}:</span>
                </b-col>
                <b-col md="4">
                  <b-form-group class="mb-0">
                    <b-dropdown
                      :text="$t('CHOOSE_SUBGROUPS')"
                      class="chat-item"
                      primary
                    >
                      <b-form-checkbox-group
                        stacked
                        v-model="currentAssetGroup.subgroups"
                      >
                        <div
                          v-for="assetGroup in allAssetGroups"
                          :key="assetGroup.assetGroupId"
                          class="dropdown-item filter-dropdown-item"
                        >
                          <b-form-checkbox :value="assetGroup">
                            {{ assetGroup.name }}
                          </b-form-checkbox>
                        </div>
                      </b-form-checkbox-group>
                    </b-dropdown>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <div class="mb-5 mt-4">
          <b-row align-h="center">
            <b-col md="4">
              <b-btn
                id="backBtn"
                variant="secondary"
                size="lg"
                block
                :to="{ name: 'ManageAssetGroups' }"
                >{{ $t('BACK') }}</b-btn
              >
            </b-col>
            <b-col md="4">
              <b-btn
                type="submit"
                id="saveBtn"
                variant="primary"
                size="lg"
                block
                >{{
                  newAssetGroup
                    ? $t('CREATE_ASSET_GROUP')
                    : $t('UPDATE_ASSET_GROUP')
                }}</b-btn
              >
            </b-col>
          </b-row>
        </div>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import { Header, RenderSpinner } from '@/components/common';
import { mapState, mapActions } from 'vuex';
import validateState from '../../mixins/validateState';
import { ALERT } from '../../store/alert';

export default {
  name: 'EditAssetGroup',
  components: {
    Header,
    RenderSpinner,
  },
  props: {
    newAssetGroup: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [validateState],
  computed: {
    ...mapState(['loading']),
  },
  data() {
    return {
      allAssetGroups: [],
      currentAssetGroupName: '',
      currentAssetGroup: {
        name: '',
        assetGroupId: null,
        subgroups: [],
      },
    };
  },
  methods: {
    ...mapActions('alert', [ALERT]),

    async handleFormSubmit() {
      const validAssetGroup = await this.$validator.validate();
      if (!validAssetGroup) {
        return false;
      }
      const messsage = this.newAssetGroup
        ? this.$t('ASSET_GROUP_CREATED')
        : this.$t('ASSET_GROUP_UPDATED');
      await this.saveAssetGroup(messsage);
    },
    saveAssetGroup(message) {
      this.$store
        .dispatch('saveAssetGroup', this.currentAssetGroup)
        .then(() => {
          this.ALERT({
            message,
            type: 'success',
          });
          this.$router.push({ name: 'ManageAssetGroups' });
        })
        .catch(error => {
          this.ALERT({ message: error.response.data });
        });
    },
    checkIfInRelationAlready(assetGroup, foundAssetGroupId) {
      // remove itself from assigning as available asset group
      if (assetGroup.assetGroupId === foundAssetGroupId) {
        return false;
      }
      // remove asset groups that are sub gruop to other asset groups
      if (
        assetGroup.superiorGroupId !== null &&
        assetGroup.superiorGroupId !== foundAssetGroupId
      ) {
        return false;
      }
      // remove asset groups that already have subgroups
      if (assetGroup.subgroups.length > 0) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    const { data: allAssetGroupsData } = await this.$store.dispatch(
      'findAllAssetGroups',
    );
    this.allAssetGroups = allAssetGroupsData;
    if (!this.newAssetGroup) {
      const { data: foundAssetGroupData } = await this.$store.dispatch(
        'findAssetGroupById',
        this.$route.params.id,
      );
      this.allAssetGroups = this.allAssetGroups.filter(assetGroup =>
        this.checkIfInRelationAlready(
          assetGroup,
          foundAssetGroupData.assetGroupId,
        ),
      );
      this.currentAssetGroup = {
        ...foundAssetGroupData,
        subgroups: this.allAssetGroups.filter(
          assetGroup =>
            assetGroup.superiorGroupId === foundAssetGroupData.assetGroupId,
        ),
      };
      this.currentAssetGroupName = foundAssetGroupData.name;
    }
  },
};
</script>

<style></style>
