<template>
  <div>
    <Header :role="role" :header="$t(role)" showSettings />
    <b-container class="container-wide compliance-home">
      <div class="mt-5" />
      <b-alert
        show
        variant="success"
        class="text-center"
        v-if="dashboardMessage || editDasboardMessage"
      >
        <div class="flex-row" v-if="!editDasboardMessage">
          {{ this.dashboardMessage }}
          <img
            class="ml-2"
            v-if="['COMPLIANCE'].includes(role)"
            @click="
              () => {
                editDasboardMessage = true;
              }
            "
            src="../../assets/edit-icon.svg"
            height="14px"
          />
        </div>

        <div
          class="d-flex justify-content-center"
          v-if="editDasboardMessage && ['COMPLIANCE'].includes(role)"
        >
          <b-form-input
            v-model="dashboardMessage"
            class="text-center"
            placeholder="Sisesta sõnum"
          ></b-form-input>
          <b-button
            variant="success"
            class="ml-2"
            size="sm"
            @click="saveDashboardMessage"
          >
            Salvesta
          </b-button>
          <b-button
            variant="danger"
            class="ml-2"
            size="sm"
            @click="deleteDashboardMessage"
          >
            Kustuta
          </b-button>
        </div>
      </b-alert>

      <div
        v-if="
          !dashboardMessage &&
            !editDasboardMessage &&
            ['COMPLIANCE'].includes(role)
        "
        class="d-flex flex-row-reverse "
      >
        <img
          class="ml-2 mb-4 mr-2"
          @click="
            () => {
              editDasboardMessage = true;
            }
          "
          src="../../assets/edit-icon.svg"
          height="14px"
        />
      </div>

      <b-card>
        <b-row>
          <b-col>
            <b-dropdown :text="$t('QUERY_FILTERS')" variant="primary">
              <div class="dropdown-item filter-dropdown-item">
                <b-form-checkbox v-model="allSelected" @change="toggleAll">
                  {{
                    allSelected
                      ? $t('STATUSES.ALL_SELECTED')
                      : selected && selected.length > 0
                      ? $t('STATUSES.SELECT_ALL')
                      : $t('STATUSES.NONE_SELECTED')
                  }}
                </b-form-checkbox>
              </div>
              <b-dropdown-divider></b-dropdown-divider>
              <b-form-checkbox-group stacked v-model="selected" name="filter">
                <div
                  v-for="(status, index) in getStatusOptions"
                  :key="index"
                  class="dropdown-item filter-dropdown-item"
                >
                  <b-form-checkbox :value="status.value">
                    {{ status.text }}
                  </b-form-checkbox>
                </div>
              </b-form-checkbox-group>
            </b-dropdown>
          </b-col>
          <b-col>
            <b-form>
              <b-form-group class="mb-0 search-form">
                <b-input-group class="search-input">
                  <b-form-input
                    v-model="filter"
                    debounce="300"
                    autofocus
                    :placeholder="$t('TYPE_TO_SEARCH')"
                  />
                  <b-input-group-append>
                    <b-btn
                      type="button"
                      variant="primary"
                      @click="filter = ''"
                      :disabled="!filter"
                      >{{ $t('CLEAR') }}</b-btn
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
        <b-table
          outlined
          show-empty
          :busy="loading"
          :current-page="currentPage"
          :fields="fields"
          :items="filteredQueries"
          stacked="md"
          class="mt-2 mb-2"
          :empty-text="$t('NO_RECORDS')"
        >
          <template v-slot:head(contractAmount)>
            <!-- translate AMOUNT and NO_VAT with separate rows -->
            <span class="custom-header">
              {{ $t('AMOUNT') }}
              <br />
              {{ $t('NO_VAT') }}
            </span>
          </template>

          <template #cell(queryId)="data">
            <span class="">#{{ data.value }}</span>
          </template>

          <template #cell(sellerOfferNr)="data">
            <div
              v-if="data.value"
              class="truncate"
              v-b-tooltip="{
                title: data.value,
                trigger: 'hover',
                container: 'body',
                disabled: data.value.length < 18,
              }"
            >
              {{ data.value }}
            </div>
          </template>

          <template #cell(custName)="data">
            <div v-if="data.value" class="last-edited">
              <b>{{ data.value }}</b>
            </div>
          </template>
          <template #cell(contractAmount)="data">
            <div v-if="data.value" class="float-right">{{ data.value }} €</div>
          </template>
          <template #cell(status)="data">
            <div :class="'status ' + statusStyle(data.value) + ' table-status'">
              {{ $t(`QUERYSTATUS.${data.value}`) }}
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="actions">
              <router-link
                :to="`/selfserve/offers/${row.item.queryId}`"
                v-if="
                  row.item.status === 'P' && row.item.product === 'SELFSERVE'
                "
                v-b-tooltip.hover
                :title="$t('EDIT')"
                ><img alt="edit" src="../../assets/edit-icon.svg"
              /></router-link>

              <router-link
                :to="`/${role.toLowerCase()}/query/${row.item.queryId}/edit`"
                v-else
                v-b-tooltip.hover
                :title="$t('EDIT')"
                ><img alt="edit" src="../../assets/edit-icon.svg"
              /></router-link>
              <span
                v-b-tooltip.hover
                :title="
                  !(
                    ['PARTNER', 'SHOP', 'COMPLIANCE'].includes(role) &&
                    ['P', 'YS'].includes(row.item.status)
                  )
                    ? `${$t('QUERY_IN_STATUS')} '${$t(
                        `QUERYSTATUS.${row.item.status}`,
                      )}' ${$t('CANNOT_BE_ANNULLED')}`
                    : $t('ANNUL')
                "
              >
                <a
                  @click="openAnnulModal(row.item.queryId, row.index)"
                  :class="{
                    'action--disabled': !(
                      ['PARTNER', 'SHOP', 'COMPLIANCE'].includes(role) &&
                      ['P', 'YS'].includes(row.item.status)
                    ),
                  }"
                >
                  <img src="../../assets/delete-icon.svg" />
                </a>
              </span>
            </div>
          </template>
          <template slot="table-busy">
            <RenderSpinner :fixed="false" />
          </template>
        </b-table>
        <RenderDeleteModal
          :show="showAnnulModal"
          @ok="closeAnnulModal(true)"
          @cancel="closeAnnulModal()"
          :label="$t('ARE_YOU_SURE_ANNUL_QUERY')"
        />
        <b-row>
          <b-col>
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              @change="changePage"
              class="my-0"
            />
          </b-col>
          <b-col md="3">
            <b-form-group class="mb-0">
              <b-form-select
                class="per-page-select"
                :options="pageOptions"
                v-model="perPage"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ALERT } from '../../store/alert';
import { Header, RenderDeleteModal, RenderSpinner } from '@/components/common';
import { queryStatusClass } from '@/utils';
import Footer from './Footer.vue';

export default {
  name: 'Home',
  components: {
    Header,
    RenderDeleteModal,
    RenderSpinner,
    Footer,
  },
  data: function() {
    if (['SHOP', 'COMPLIANCE'].includes(localStorage.getItem('role'))) {
      return {
        fields: [
          {
            key: 'queryId',
            label: 'ID',
            sortable: true,
          },
          {
            key: 'sellerOfferNr',
            label: this.$t('OFFER'),
            sortable: true,
          },
          {
            key: 'lendifyRefno',
            label: this.$t('CONTRACT_NUMBER_SHORT'),
            sortable: true,
          },
          {
            key: 'createdByName',
            label: this.$t('CREATED_BY'),
            sortable: true,
          },
          {
            key: 'custName',
            label: this.$t('CLIENT'),
            sortable: true,
          },
          {
            key: 'contractAmount',
            label: this.$t('CONTRACT_AMOUNT'),
            sortable: true,
          },
          {
            key: 'status',
            label: this.$t('STATUS'),
            sortable: true,
          },
          {
            key: 'actions',
            label: this.$t('ACTIONS'),
            sortable: false,
          },
        ],
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
        rowsPerPage: [5, 10, 20, 50, 100],
        filter: null,
        allSelected: this.selectedAll(),
        queries: [],
        selected: this.selectedByDefault(),
        statusKeys: Object.keys(this.$store.state.queryStatus),
        showAnnulModal: false,
        selectedQuery: 0,
        selectedQueryIndex: 0,
        dashboardMessage: null,
        editDasboardMessage: false,
      };
    } else {
      return {
        fields: [
          {
            key: 'queryId',
            label: 'ID',
            sortable: true,
          },
          {
            key: 'createdByName',
            label: this.$t('CREATED_BY'),
            sortable: true,
          },
          {
            key: 'custName',
            label: this.$t('CLIENT'),
            sortable: true,
          },
          {
            key: 'contractAmount',
            label: this.$t('CONTRACT_AMOUNT'),
            sortable: true,
          },
          {
            key: 'status',
            label: this.$t('STATUS'),
            sortable: true,
          },
          {
            key: 'actions',
            label: this.$t('ACTIONS'),
            sortable: false,
          },
        ],
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
        rowsPerPage: [5, 10, 20, 50, 100],
        filter: null,
        allSelected: this.selectedAll(),
        queries: [],
        selected: this.selectedByDefault(),
        statusKeys: Object.keys(this.$store.state.queryStatus),
        showAnnulModal: false,
        selectedQuery: 0,
        selectedQueryIndex: 0,
        dashboardMessage: null,
        editDasboardMessage: false,
      };
    }
  },
  created: function() {
    this.getDashboardMessage();
    this.fetchQueries({
      newPage: this.currentPage,
      pageSize: this.perPage,
      searchParam: this.filter,
      queryfilters: this.selected,
    });
  },
  computed: {
    ...mapState(['loading']),
    pageOptions() {
      return this.rowsPerPage.map(i => {
        return { text: this.$t('ROWS_PER_PAGE') + ': ' + i, value: i };
      });
    },
    getStatusOptions() {
      return this.statusKeys.map(s => {
        return { text: this.$t(`QUERYSTATUS.${s}`), value: s };
      });
    },
    filteredQueries() {
      return this.queries.filter(q => this.selected.includes(q.status));
    },
    role() {
      return localStorage.getItem('role');
    },
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    statusStyle(status) {
      return queryStatusClass(status);
    },
    selectedByDefault: function() {
      if (['SHOP'].includes(localStorage.getItem('role'))) {
        return Object.keys(this.$store.state.queryStatus);
      } else {
        return Object.keys(this.$store.state.queryStatus).filter(function(
          item,
        ) {
          return !['P'].includes(item);
        });
      }
    },
    selectedAll: function() {
      const selected = this.selectedByDefault();
      return Object.keys(this.$store.state.queryStatus).every(v =>
        selected.includes(v),
      );
    },
    toggleAll(checked) {
      this.selected = checked ? this.statusKeys.slice() : [];
    },
    annulQuery: function(payload) {
      let query = this.filteredQueries[payload.index];
      this.$store
        .dispatch('annulQuery', payload.id)
        .then(() => {
          this.ALERT({ message: this.$t('QUERY_ANNULLED'), type: 'success' });
          query.status = 'N';
        })
        .catch(() => {
          this.queries.splice(payload.index, 1, query);
        });
    },
    openAnnulModal(id, index) {
      this.showAnnulModal = true;
      this.selectedQuery = id;
      this.selectedQueryIndex = index;
    },
    closeAnnulModal(doAnnul) {
      this.showAnnulModal = false;
      if (doAnnul) {
        this.annulQuery({
          id: this.selectedQuery,
          index: this.selectedQueryIndex,
        });
      }
      this.selectedQuery = 0;
      this.selectedQueryIndex = 0;
    },
    changePage(page) {
      this.currentPage = page;
      this.fetchQueries({
        newPage: this.currentPage,
        pageSize: this.perPage,
        searchParam: this.filter,
        queryfilters: this.selected,
      });
    },
    async getDashboardMessage() {
      await this.$store
        .dispatch('getDashboardMessage')
        .then(response => {
          this.dashboardMessage = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async deleteDashboardMessage() {
      await this.$store
        .dispatch('saveDashboardMessage', { text: null })
        .then(response => {
          this.dashboardMessage = null;
          this.editDasboardMessage = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async saveDashboardMessage() {
      // encodeURIComponent is needed to convert special characters
      // to be used in URL
      await this.$store
        .dispatch('saveDashboardMessage', { text: this.dashboardMessage })
        .then(response => {
          this.editDasboardMessage = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchQueries({ newPage, pageSize, searchParam, queryfilters }) {
      const action = ['PARTNER', 'SHOP'].includes(this.role)
        ? 'getPartnerQueriesByStatus'
        : 'findAllQueries';

      const args = {
        statuses: queryfilters,
        params: { page: newPage - 1, pageSize, searchParam },
      };

      this.$store
        .dispatch(action, args)
        .then(response => {
          this.queries = response.data.content.map(obj => ({
            ...obj,
            actions: { edit: 'edit', delete: 'delete' },
          }));
          this.totalRows = response.data.totalElements;
        })
        .catch(error => {
          console.log(error);
        });

      if (this.role === 'CREDIT') {
        this.fields.splice(1, 0, {
          key: 'formattedCreatedDtime',
          label: this.$t('CREATED'),
          sortable: true,
        });
      }
    },
  },
  watch: {
    selected(newVal, oldVal) {
      this.allSelected = newVal.length === this.statusKeys.length;
      this.currentPage = 1;
      this.fetchQueries({
        newPage: this.currentPage,
        pageSize: this.perPage,
        searchParam: this.filter,
        queryfilters: this.selected,
      });
    },
    perPage(newPerPage) {
      this.perPage = newPerPage;
      this.currentPage = 1;
      this.fetchQueries({
        newPage: this.currentPage,
        pageSize: this.perPage,
        searchParam: this.filter,
        queryfilters: this.selected,
      });
    },
    filter(newVal) {
      this.currentPage = 1;
      this.fetchQueries({
        newPage: this.currentPage,
        pageSize: this.perPage,
        searchParam: newVal,
        queryfilters: this.selected,
      });
    },
  },
};
</script>

<style scoped>
.truncate {
  max-width: 125px; /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
