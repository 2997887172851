<template>
  <b-card>
    <b-list-group class="terms terms-settings">
      <b-col md="12">
        <h3>{{ $t('CONTACT') }}</h3>
      </b-col>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('BUSINESS_NAME') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0">
              <multiselect
                v-model="selected"
                :options="companies"
                :loading="isLoading"
                label="name"
                @search-change="getCompaniesByName"
                @input="setCompanyParams"
                :placeholder="value.businessName"
                :show-labels="false"
              >
                <template slot="noResult">{{
                  $t('NO_ELEMENTS_FOUND')
                }}</template>
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('REGISTRY_CODE') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0">
              <multiselect
                v-model="selected"
                :options="companies"
                :loading="isLoading"
                label="companyCode"
                @search-change="getCompaniesByCode"
                @input="setCompanyParams"
                :placeholder="value.regCode"
                :show-labels="false"
              >
                <template slot="noResult">{{
                  $t('NO_ELEMENTS_FOUND')
                }}</template>
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('VAT_CODE') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0">
              <b-form-input v-model.trim="value.vatNumber" type="text" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('BANK_ACCOUNT_NUMBER') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0">
              <b-form-input
                id="bankAccountNumber"
                v-model.trim="value.bankAccountNumber"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('PHONE') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0">
              <b-form-input
                id="phoneNumber"
                v-model.trim="value.phoneNumber"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('EMAIL') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0">
              <b-form-input id="email" v-model.trim="value.email" type="text" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item class="border-bottom">
        <b-row align-v="center">
          <b-col md="4" class="offset-md-2">
            <span
              ><h5>{{ $t('ADDRESS') }}</h5>
            </span>
          </b-col>
        </b-row>
        <b-row align-v="center" class="mt-2">
          <b-col md="4" class="offset-md-2">
            <b-form-group class="mb-0 f-75" :label="$t('STREET')">
              <b-form-input v-model.trim="value.street" type="text" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group class="mb-0 f-75" :label="$t('POSTAL_CODE')">
              <b-form-input v-model.trim="value.zipCode" type="text" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-v="center" class="py-4">
          <b-col md="3" class="offset-md-2">
            <b-form-group class="mb-0 f-75" :label="$t('CITY')">
              <b-form-input v-model.trim="value.city" type="text" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group class="mb-0 f-75" :label="$t('COUNTY')">
              <b-form-input
                id="county"
                v-model.trim="value.county"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group class="mb-0 f-75" :label="$t('COUNTRY')">
              <b-form-input
                id="country"
                v-model.trim="value.country"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-col md="12" class="pt-4">
        <h3>{{ $t('REPRESENTATIVE') }}</h3>
      </b-col>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right f-75">
            <span>{{ $t('REPRESENTATIVE_NAME') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0">
              <b-form-input
                id="representativeName"
                v-model.trim="value.representativeName"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('REPRESENTED_BY') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0 f-75">
              <b-form-radio-group
                id="representedBy"
                button-variant="outline-secondary"
                v-model.trim="value.representedBy"
              >
                <b-form-radio
                  v-for="option in reprTypeOptions"
                  :key="option"
                  :value="option"
                  >{{ option }}</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('REPRESENTATIVE_PERSONAL_CODE') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0 f-75">
              <b-form-input
                id="representativePersonalCode"
                v-model.trim="value.representativePersonalCode"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('REPRESENTATIVE_PHONE') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0 f-75">
              <b-form-input
                id="representativePhone"
                v-model.trim="value.representativePhone"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item class="border-bottom pb-4">
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('REPRESENTATIVE_EMAIL') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0 f-75">
              <b-form-input
                id="representativeEmail"
                v-model.trim="value.representativeEmail"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-col md="12" class="pt-4">
        <h3>{{ $t('NOTIFICATIONS') }}</h3>
      </b-col>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('NOTIFY_ON_DECISION') }}</span>
          </b-col>
          <b-col md="4">
            <b-button-group>
              <b-form-checkbox
                v-model="value.notifyOnDecision"
              ></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item class="pb-4">
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('NOTIFY_ON_DECISION_EMAIL') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0 f-75">
              <b-form-input
                id="notifyOnDecisionEmail"
                v-model.trim="value.notifyOnDecisionEmail"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-col md="12" class="border-top pt-4">
        <h3>{{ $t('ALLOWED_PRODUCT_FEATURES') }}</h3>
      </b-col>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('FEATURE_ONSPOT') }}</span>
          </b-col>
          <b-col md="8">
            <b-button-group>
              <b-form-checkbox v-model="value.featureOnSpot"></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item class="pb-4">
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('FEATURE_SELFSERVE') }}</span>
          </b-col>
          <b-col md="8">
            <b-button-group>
              <b-form-checkbox
                v-model="value.featureSelfServe"
              ></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-col md="12" class="border-top pt-4">
        <h3>{{ $t('ECOMMERCE') }}</h3>
      </b-col>
      <b-list-group-item class="border-bottom pb-4">
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('APIKEY') }}</span>
          </b-col>
          <b-col md="6">
            <b-form-group class="mb-0 f-75">
              <b-form-input
                id="apiKey"
                :disabled="true"
                v-model.trim="value.apiKey"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-col md="12" class="pt-4">
        <h3>{{ $t('SETTINGS') }}</h3>
      </b-col>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4"></b-col>
          <b-col md="8">
            <b-button-group>
              <span class="terms-header">MIN</span>
              <span class="terms-header terms-header-center">DEFAULT</span>
              <span class="terms-header">MAX</span>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('ADVANCE') }} (%)</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="
                validateState('minAdvance') && validateState('maxAdvance')
              "
              :invalid-feedback="$t('VALIDATION.MIN_MAX_REQUIRED')"
            >
              <b-button-group>
                <input
                  name="minAdvance"
                  v-validate="'required'"
                  class="terms-input terms-input-left-border terms-input-no-right-border"
                  type="text"
                  @keyup="numerifyWithDecimals('advanceOptions', 0)"
                  v-model.trim="value.advanceOptions[0]"
                />
                <input
                  class="terms-input terms-input-no-right-border"
                  type="text"
                  v-model.trim="value.defaultAdvance"
                  @keyup="numerifyWithDecimals('defaultAdvance')"
                  @change="
                    controlGap(
                      value.defaultAdvance,
                      value.advanceOptions,
                      'defaultAdvance',
                    )
                  "
                />
                <input
                  name="maxAdvance"
                  v-validate="'required'"
                  class="terms-input"
                  type="text"
                  @keyup="numerifyWithDecimals('advanceOptions', 1)"
                  v-model.trim="value.advanceOptions[1]"
                />
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('INTEREST') }} (%)</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="
                validateState('minInterest') && validateState('maxInterest')
              "
              :invalid-feedback="$t('VALIDATION.MIN_MAX_REQUIRED')"
            >
              <b-button-group>
                <input
                  name="minInterest"
                  v-validate="'required'"
                  class="terms-input terms-input-left-border terms-input-no-right-border"
                  type="text"
                  @keyup="numerifyWithDecimals('interestOptions', 0)"
                  v-model.trim="value.interestOptions[0]"
                />
                <input
                  class="terms-input terms-input-no-right-border"
                  type="text"
                  v-model.trim="value.defaultInterest"
                  @keyup="numerifyWithDecimals('defaultInterest')"
                  @change="
                    controlGap(
                      value.defaultInterest,
                      value.interestOptions,
                      'defaultInterest',
                    )
                  "
                />
                <input
                  name="maxInterest"
                  v-validate="'required'"
                  class="terms-input"
                  type="text"
                  @keyup="numerifyWithDecimals('interestOptions', 1)"
                  v-model.trim="value.interestOptions[1]"
                />
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('INTEREST_MATRIX') }}</span>
          </b-col>
          <b-col md="8">
            <b-form-group class="mb-0">
              <b-button-group>
                <b-form-select
                  name="financier"
                  class="setting-terms-select custom-select"
                  v-model="value.interestMatrix"
                >
                  <option :value="null"></option>
                  <option
                    v-for="(financier, index) in financiers"
                    :key="index"
                    :value="financier.financierId"
                    >{{ financier.name }}</option
                  >
                </b-form-select>
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('KICKBACK') }} (%)</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="
                validateState('minKickback') && validateState('maxKickback')
              "
              :invalid-feedback="$t('VALIDATION.MIN_MAX_REQUIRED')"
            >
              <b-button-group>
                <input
                  name="minKickback"
                  type="text"
                  v-validate="'required'"
                  class="terms-input terms-input-left-border terms-input-no-right-border"
                  @keyup="numerifyWithDecimals('kickbackOptions', 0)"
                  v-model.trim="value.kickbackOptions[0]"
                />
                <input
                  class="terms-input terms-input-no-right-border"
                  type="text"
                  v-model.trim="value.defaultKickback"
                  @keyup="numerifyWithDecimals('defaultKickback')"
                  @change="
                    controlGap(
                      value.defaultKickback,
                      value.kickbackOptions,
                      'defaultKickback',
                    )
                  "
                />
                <input
                  name="maxKickback"
                  v-validate="'required'"
                  class="terms-input"
                  type="text"
                  @keyup="numerifyWithDecimals('kickbackOptions', 1)"
                  v-model.trim="value.kickbackOptions[1]"
                />
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('KICKBACK_COEFFICIENT') }}</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="validateState('kickbackCoefficient')"
              :invalid-feedback="$t('VALIDATION.REQUIRED')"
            >
              <b-button-group>
                <input
                  name="kickbackCoefficient"
                  v-validate="'required'"
                  class="terms-input terms-input-all-border"
                  type="text"
                  @keyup="numerifyWithDecimals('kickbackCoefficient')"
                  v-model.trim="value.kickbackCoefficient"
                />
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('SHOW_KICKBACK') }}</span>
          </b-col>
          <b-col md="8">
            <b-button-group>
              <b-form-checkbox v-model="value.showKickback"></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('PAYMENT_PERIOD') }} ({{ $t('MONTHS') }})</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="validateState('minPeriod') && validateState('maxPeriod')"
              :invalid-feedback="$t('VALIDATION.MIN_MAX_REQUIRED')"
            >
              <b-button-group>
                <input
                  name="minPeriod"
                  v-validate="'required'"
                  class="terms-input terms-input-left-border terms-input-no-right-border"
                  type="text"
                  @keyup="numerify('paymentPeriodOptions', 0)"
                  v-model.trim="value.paymentPeriodOptions[0]"
                />
                <b-form-select
                  class="payment-period-terms-select"
                  v-model.trim="value.defaultPaymentPeriod"
                  @input="
                    controlGap(
                      value.defaultPaymentPeriod,
                      value.paymentPeriodOptions,
                      'defaultPaymentPeriod',
                    )
                  "
                >
                  <option :value="null"></option>
                  <option
                    v-for="(option, index) in computedPaymentPeriodOptions"
                    :key="index"
                    :value="option"
                    >{{ option }}</option
                  >
                </b-form-select>
                <input
                  name="maxPeriod"
                  v-validate="'required'"
                  class="terms-input"
                  type="text"
                  @keyup="numerify('paymentPeriodOptions', 1)"
                  v-model.trim="value.paymentPeriodOptions[1]"
                />
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('RESIDUAL') }} (%)</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="
                validateState('minResidual') && validateState('maxResidual')
              "
              :invalid-feedback="$t('VALIDATION.MIN_MAX_REQUIRED')"
            >
              <b-button-group>
                <input
                  name="minResidual"
                  v-validate="'required'"
                  class="terms-input terms-input-left-border terms-input-no-right-border"
                  type="text"
                  @keyup="numerifyWithDecimals('residualOptions', 0)"
                  v-model.trim="value.residualOptions[0]"
                />
                <input
                  class="terms-input terms-input-no-right-border"
                  type="text"
                  v-model.trim="value.defaultResidual"
                  @keyup="numerifyWithDecimals('defaultResidual')"
                  @change="
                    controlGap(
                      value.defaultResidual,
                      value.residualOptions,
                      'defaultResidual',
                    )
                  "
                />
                <input
                  name="maxResidual"
                  v-validate="'required'"
                  class="terms-input"
                  type="text"
                  @keyup="numerifyWithDecimals('residualOptions', 1)"
                  v-model.trim="value.residualOptions[1]"
                />
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('INSURANCE') }} (%)</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="validateState('insurance')"
              :invalid-feedback="$t('VALIDATION.REQUIRED')"
            >
              <b-button-group>
                <input
                  name="insurance"
                  v-validate="'required'"
                  class="terms-input terms-input-all-border"
                  type="text"
                  @keyup="numerifyWithDecimals('insurance')"
                  v-model.trim="value.insurance"
                />
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('CONTRACT_FEE') }} (€)</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="validateState('contractFee')"
              :invalid-feedback="$t('VALIDATION.REQUIRED')"
            >
              <b-button-group>
                <input
                  name="contractFee"
                  v-validate="'required'"
                  class="terms-input terms-input-all-border"
                  type="text"
                  @keyup="numerifyWithDecimals('contractFee')"
                  v-model.trim="value.contractFee"
                />
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('OFFER_VALID') }} ({{ $t('MONTHS') }})</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="validateState('offerValidTime')"
              :invalid-feedback="$t('VALIDATION.REQUIRED')"
            >
              <b-button-group>
                <input
                  name="offerValidTime"
                  v-validate="'required'"
                  class="terms-input terms-input-all-border"
                  type="text"
                  @keyup="numerify('offerValidTime')"
                  v-model.trim="value.offerValidTime"
                />
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('INHOUSE') }}</span>
          </b-col>
          <b-col md="8">
            <b-button-group>
              <b-form-checkbox v-model="value.inhouse"></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('QUERY_MANAGER') }}</span>
          </b-col>
          <b-col md="8">
            <b-form-group
              class="mb-0"
              :state="validateState('manager')"
              :invalid-feedback="$t('VALIDATION.REQUIRED')"
            >
              <b-button-group>
                <b-form-select
                  name="manager"
                  v-validate="'required'"
                  class="setting-terms-select custom-select"
                  v-model="value.managerId"
                >
                  <option
                    v-for="(partner, index) in inHousePartners"
                    :key="index"
                    :value="partner.accountUserId"
                    >{{ partner.fullName }}</option
                  >
                </b-form-select>
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('COMMENT') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-textarea
              class="textarea-secondary"
              :rows="2"
              :max-rows="4"
              v-model="value.comment"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('CREDIT_LIMIT') }}</span>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-0 f-75">
              <b-form-input
                id="creditLimit"
                v-model="value.creditLimit"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('AUTOMATIC_DECISION') }}</span>
          </b-col>
          <b-col md="8">
            <b-button-group>
              <b-form-checkbox
                v-model="value.automaticDecision"
                @change="handleAutomaticDecision"
              ></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('LENDIFY_LIMIT') }}</span>
          </b-col>
          <b-col md="8">
            <b-button-group>
              <b-form-checkbox v-model="value.lendifyLimit"></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('ALLOW_MANUAL_SIGNING') }}</span>
          </b-col>
          <b-col md="8">
            <b-button-group>
              <b-form-checkbox v-model="value.manualSigning"></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('EDIT_CONTRACT_FEE') }}</span>
          </b-col>
          <b-col md="8">
            <b-button-group>
              <b-form-checkbox
                v-model="value.editContractFee"
              ></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <b-list-group-item>
        <b-row align-v="center">
          <b-col md="4" class="text-right">
            <span>{{ $t('EDIT_INSURANCE') }}</span>
          </b-col>
          <b-col md="8">
            <b-button-group>
              <b-form-checkbox v-model="value.editInsurance"></b-form-checkbox>
            </b-button-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <PartnerAssetGroupAccordion
        :partnerAssetGroups="value.partnerAssetGroups"
        :generalAutomaticDecision="value.automaticDecision"
      />
    </b-list-group>
    <RenderDeleteModal
      :show="showWarningModal"
      @ok="closeWarningModal(true)"
      @cancel="e => closeWarningModal(false, e)"
      label="Are you sure? This will remove automatic decision from every asset"
    />
  </b-card>
</template>

<script>
import Multiselect from 'vue-multiselect';
import validateState from '../../mixins/validateState';
import { replaceComma } from '@/utils';
import PartnerAssetGroupAccordion from './PartnerAssetGroupAccordion';
import { mapActions, mapState } from 'vuex';
import { GET_BY_NAME, GET_BY_COMPANY_CODE } from '../../store/company';
import { RenderDeleteModal } from '@/components/common';

export default {
  name: 'PartnerSettingsForm',
  components: { Multiselect, PartnerAssetGroupAccordion, RenderDeleteModal },
  mixins: [validateState],
  props: {
    inHousePartners: Array,
    value: Object,
  },
  data() {
    return {
      selected: [],
      isLoading: false,
      showWarningModal: false,
      reprTypeOptions: ['Law', 'Power of Attorney'],
      financiers: [],
    };
  },
  created() {
    this.$store.dispatch('getFinanciers').then(response => {
      this.financiers = response.data;
    });
  },
  methods: {
    ...mapActions('company', [GET_BY_NAME, GET_BY_COMPANY_CODE]),
    controlGap: function(value, settings, setting) {
      if (parseFloat(value) < +settings[0]) {
        value = +settings[0];
      } else if (parseFloat(value) > +settings[1]) {
        value = +settings[1];
      }
      this.$set(this.value, setting, value);
    },
    async getCompaniesByName(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_NAME(query);
        this.isLoading = false;
      }
    },
    async getCompaniesByCode(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_COMPANY_CODE(query);
        this.isLoading = false;
      }
    },
    setCompanyParams() {
      this.$set(this.value, 'businessName', this.selected.name);
      this.$set(this.value, 'regCode', this.selected.companyCode);
      this.$set(this.value, 'street', this.selected.address);
      this.$set(this.value, 'zipCode', this.selected.postalCode);
      this.$set(this.value, 'city', this.selected.city);
      this.$set(this.value, 'vatNumber', this.selected.vatNumber);
    },
    numerifyWithDecimals(field, index = null) {
      index === null
        ? typeof this.value[field] !== 'undefined' &&
          this.$set(
            this.value,
            field,
            replaceComma(this.value[field].replace(/[^\d,.]+/g, '')),
          )
        : typeof this.value[field][index] !== 'undefined' &&
          this.$set(
            this.value[field],
            index,
            replaceComma(this.value[field][index].replace(/[^\d,.]+/g, '')),
          );
    },
    numerify(field, index = null) {
      index === null
        ? typeof this.value[field] !== 'undefined' &&
          this.$set(this.value, field, this.value[field].replace(/[^\d]+/g, ''))
        : typeof this.value[field][index] !== 'undefined' &&
          this.$set(
            this.value[field],
            index,
            this.value[field][index].replace(/[^\d]+/g, ''),
          );
    },
    handleAutomaticDecision(autoDecisionEnabled) {
      let assetsHaveAutoDecision = false;
      for (const mainGroup of this.value.partnerAssetGroups) {
        assetsHaveAutoDecision = mainGroup.subgroups.some(
          subgroup => subgroup.autoDecisionEnabled,
        );
        if (assetsHaveAutoDecision) {
          break;
        }
      }

      if (!autoDecisionEnabled && assetsHaveAutoDecision) {
        this.showWarningModal = true;
      }
    },
    closeWarningModal(removeAutoDecisions, event) {
      this.showWarningModal = false;
      if (removeAutoDecisions) {
        this.value.partnerAssetGroups.forEach(mainGroup => {
          mainGroup.subgroups.forEach(
            subgroup => (subgroup.autoDecisionEnabled = false),
          );
        });
        this.value.automaticDecision = false;
      } else {
        if (event && event.trigger === 'ok') {
          this.value.automaticDecision = false;
        } else {
          this.value.automaticDecision = true;
        }
      }
    },
  },
  computed: {
    ...mapState('company', ['companies']),
    computedPaymentPeriodOptions() {
      if (this.value.allPmtPeriodOptions) {
        let computedOptions = [...this.value.allPmtPeriodOptions];
        let toRemoveOptions = [];
        computedOptions.forEach(option => {
          if (
            +option < +this.value.paymentPeriodOptions[0] ||
            +option > +this.value.paymentPeriodOptions[1]
          ) {
            toRemoveOptions.push(option);
          }
        });
        computedOptions = computedOptions.filter(
          element => !toRemoveOptions.includes(element),
        );
        return computedOptions;
      }
    },
  },
  watch: {
    valueArrivalPlaceholder: function() {
      this.selected = {
        name: this.value.businessName,
        company_code: this.value.regCode,
      };
    },
  },
};
</script>
