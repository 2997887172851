<template>
  <b-container>
    <RenderSpinner v-if="$store.state.loading" />
    <h2 class="card-title">
      <div class="d-flex">
        <span class="text-nowrap">
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('TRANSACTION') }}
        </span>
        <b-form-group
          :label="$t('MANAGER_SHORT') + ':'"
          label-for="transaction-query-manager"
          class="form-group-manager position-relative mb-0"
        >
          <b-form-select
            @change="changeManager()"
            :disabled="
              !isInhouse && viewName !== 'credit' && viewName !== 'compliance'
            "
            id="transaction-query-manager"
            v-model="value.managerId"
            class="form-control number number-lg query-manager"
          >
            <option
              v-for="(partner, index) in inHousePartners"
              :key="index"
              :value="partner.accountUserId"
              >{{ partner.fullName }}</option
            >
          </b-form-select>
        </b-form-group>
      </div>
      <b-btn
        v-if="$store.state.role === 'PARTNER'"
        @click="toggleTemplatesModal"
      >
        {{ $t('TEMPLATES') }}
      </b-btn>
    </h2>

    <TemplatesModal
      :show="showTemplatesModal"
      :partnerSettings="partnerSettings"
      :disableOnStatus="disableOnStatus"
      @hide="toggleTemplatesModal"
      @saveQuery="saveQuery"
      @updateTermInputs="updateTermInputs"
      v-model="value"
    />

    <AddAssetForm
      v-model="value"
      :partnerSettings="partnerSettings"
      :viewName="viewName"
      :isInhouse="isInhouse"
      :disableOnStatus="disableOnStatus"
      :disableDeliveryAndSellerInfo="disableDeliveryAndSellerInfo"
      @calculatePmt="calculatePmt()"
    />

    <AssetsTable
      v-model="value"
      :partnerSettings="partnerSettings"
      :viewName="viewName"
      :isInhouse="isInhouse"
      :disableOnStatus="disableOnStatus"
      :saveQuery="saveQuery"
      :disableDeliveryAndSellerInfo="disableDeliveryAndSellerInfo"
      :totalQassetPmt="totalQassetPmt"
      @calculatePmt="calculatePmt()"
    />

    <Terms
      v-model="value"
      ref="terms"
      :partnerSettings="partnerSettings"
      :viewName="viewName"
      :disableOnStatus="disableOnStatus"
      :lendifyInsurance="lendifyInsurance"
      :computedKickback="computedKickback"
      :computedCommission="computedCommission"
      :totalPmt="totalPmt"
      :interestChanged="interestChanged"
      :isInhouse="isInhouse"
      @calculatePmt="calculatePmt"
      @changeInterestChanged="changeInterestChanged()"
      @saveQuery="saveQuery"
    />

    <b-card class="mt-4 card-padding-md">
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('CLIENT_INFORMATION') }}
        </span>
      </h2>

      <div class="card-footer">
        <b-form-textarea
          v-model="value.clientInfo"
          :placeholder="$t('ENTER_SOMETHING')"
          :rows="2"
          :max-rows="12"
          :disabled="disableOnStatus"
        >
        </b-form-textarea>
        <b-row align-h="center" class="mt-3">
          <b-col md="4">
            <b-btn
              type="button"
              class="btn-shadow-corner"
              :disabled="!!errors.items[0]"
              block
              @click="$emit('saveQuery')"
              >{{ $t('SAVE_INFORMATION') }}</b-btn
            >
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card class="card-padding-md mt-4">
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('ATTACH_FILES') }}
        </span>
      </h2>

      <div class="drop-box">
        <label
          for="fileInput"
          :class="['drop-box-holder', { 'drop-box-holder-dragging': dragged }]"
          @dragover.prevent="toggleDragging"
          @dragleave="toggleDragging"
          @drop.prevent="uploadFiles"
        >
          <input
            type="file"
            id="fileInput"
            multiple
            class="file-input"
            @change.prevent="uploadFiles"
            :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
          />
          <img src="../../assets/upload.svg" />
          {{ $t('DRAG_AND_DROP') }} <span>{{ $t('OR') }}</span>
          <span class="btn btn-secondary">{{ $t('CHOOSE_FILES') }}</span>
        </label>
      </div>

      <b-table
        class="table-documents mt-3"
        v-if="documents.length"
        outlined
        :fields="documentFields"
        :items="documents"
      >
        <template #cell(fileName)="row">
          <img src="../../assets/docs1.svg" /><span>{{
            row.item.fileName
          }}</span>
        </template>
        <template #cell(delete)="row">
          <div class="actions">
            <a
              @click="deleteDoc({ id: row.item.docFileId, index: row.index })"
              title="Delete"
              ><img src="../../assets/delete-icon.svg"
            /></a>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-card class="mt-4" v-if="isInhouse">
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('CHAT') }}
        </span>
      </h2>

      <div
        class="chat-box"
        :class="{ right: index % 2 === 1 }"
        v-for="(message, index) in sortedMessages"
        :key="index"
      >
        <div class="chat-item">
          <div class="icon-box">
            <div class="icon">
              <span>{{ getInitials(message.createdBy) }}</span>
            </div>
            <div class="name">{{ message.createdBy }}</div>
            <div class="time-box">
              <div class="calender">
                <img src="../../assets/small-calendar.svg" />{{
                  message.createdDate
                }}
              </div>
              <div class="time">
                <img src="../../assets/clock.svg" />{{ message.createdTime }}
              </div>
            </div>
          </div>

          <div class="text-box">
            <div class="text">{{ message.content }}</div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <b-form-textarea
          v-model="newMessage.content"
          :placeholder="$t('ENTER_SOMETHING')"
          :rows="2"
          :max-rows="4"
        >
        </b-form-textarea>
        <b-row align-h="center" class="mt-3">
          <b-col md="4">
            <b-btn
              type="button"
              class="btn-shadow-corner"
              block
              @click="saveMessage()"
              >{{ $t('SEND_MESSAGE') }}</b-btn
            >
          </b-col>
        </b-row>
      </div>
    </b-card>

    <div class="mb-5 mt-4">
      <b-row align-h="center">
        <b-col md="6">
          <b-button
            v-if="
              (viewName === 'partner' || viewName === 'partnerCreate') &&
                isInhouse &&
                (value.status === 'P' || value.status === 'SM')
            "
            size="lg"
            variant="primary"
            block
            @click="
              $emit('changeStatus', {
                status: 'SCR',
                alert: $t('SENT_TO_CREDIT_COMMITTEE'),
                queryId: value.queryId,
              })
            "
            :disabled="
              value.status === 'N' ||
                creditStatuses.includes(value.status) ||
                complianceStatuses.includes(value.status) ||
                !!errors.items[0]
            "
          >
            {{ $t('SAVE_AND_SEND_TO_CREDIT') }}
          </b-button>
          <b-button
            v-if="
              (viewName === 'partner' || viewName === 'partnerCreate') &&
                !isInhouse &&
                value.status === 'P'
            "
            size="lg"
            variant="primary"
            block
            @click="
              $emit('changeStatus', {
                status: 'SM',
                alert: $t('QUERYSTATUS.SM'),
                queryId: value.queryId,
              })
            "
            :disabled="
              value.status === 'N' ||
                creditStatuses.includes(value.status) ||
                complianceStatuses.includes(value.status) ||
                !!errors.items[0]
            "
          >
            {{ $t('SAVE_AND_SEND_TO_MANAGER') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ALERT } from '../../store/alert';
import { UPLOAD_DOCUMENTS, DELETE_DOCUMENT } from '../../store/document';
import { SAVE_MESSAGE } from '../../store/message';
import { CALCULATE_PAYMENT, CHANGE_MANAGER } from '../../store/query';
import {
  replaceComma,
  numerify,
  numerifyWithDecimals,
  disableOnStatus,
} from '@/utils';
import {
  RenderSpinner,
  AddAssetForm,
  AssetsTable,
  Terms,
  TemplatesModal,
} from '@/components/common';
import validateState from '../../mixins/validateState';

export default {
  name: 'TransactionTab',
  props: {
    viewName: String,
    value: Object,
    partnerSettings: Object,
    inHousePartners: Array,
    isInhouse: Boolean,
  },
  components: {
    RenderSpinner,
    AddAssetForm,
    AssetsTable,
    Terms,
    TemplatesModal,
  },
  mixins: [validateState],
  data: function() {
    return {
      newService: {},
      qassetSum: 0,
      sliderColor: 'red',
      documentFields: [
        {
          key: 'fileName',
          label: this.$t('FILE_NAME'),
          sortable: true,
        },
        {
          key: 'delete',
          label: this.$t('DELETE'),
        },
      ],
      partnerStatuses: ['P', 'MICR', 'MICO'],
      creditStatuses: ['SCR', 'QA', 'QAC'],
      complianceStatuses: ['SCO'],
      dragged: false,
      formattedDeliveryDate: '',
      showTemplatesModal: false,
      interestChanged: false,
    };
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    ...mapActions('document', [UPLOAD_DOCUMENTS, DELETE_DOCUMENT]),
    ...mapActions('message', [SAVE_MESSAGE]),
    ...mapActions('query', [CHANGE_MANAGER, CALCULATE_PAYMENT]),
    async saveQuery(cb) {
      await this.$emit('saveQuery', cb);
    },
    async uploadFiles(event) {
      if (this.disableOnStatus && this.disableDeliveryAndSellerInfo) {
        return;
      }
      const uploadFiles = event.target.files || event.dataTransfer.files;
      await this.UPLOAD_DOCUMENTS({
        files: uploadFiles,
        queryId: this.value.queryId,
      });
      event.target.value = '';
    },
    async deleteDoc(payload) {
      if (this.disableOnStatus && this.disableDeliveryAndSellerInfo) {
        return;
      }
      await this.DELETE_DOCUMENT({ id: payload.id, index: payload.index });
    },
    async calculatePmt() {
      this.$nextTick(async () => {
        await this.CALCULATE_PAYMENT({
          pmtObject: {
            assets: this.value.qassets,
            services: this.value.services,
            advancePmt: this.value.advance,
            advnPmtType: this.value.advnPmtType,
            rvValue: this.value.residual,
            intrTotal: this.value.interest,
            leasePeriod: this.value.paymentPeriod,
            pmtDate: this.value.pmtDate,
            contractValue: replaceComma(this.value.contractFee),
            insuranceValue: replaceComma(this.lendifyInsurance),
            interestMatrix: this.value.interestMatrix,
          },
          currentQuery: this.value,
        });
        this.interestChanged = true;
      });
    },
    async saveMessage() {
      if (this.newMessage.content.length > 0) {
        const savedMessage = await this.SAVE_MESSAGE({
          message: this.newMessage,
          queryId: this.value.queryId,
        });
        this.value.messages.push(savedMessage);
        this.newMessage.content = '';
      }
    },
    getInitials: function(name) {
      return name
        .split(' ')
        .map(function(item) {
          return item[0];
        })
        .join('');
    },
    toggleDragging() {
      this.dragged = !this.dragged;
    },
    async changeManager() {
      this.$nextTick(async () => {
        await this.CHANGE_MANAGER({
          queryId: this.value.queryId,
          managerId: this.value.managerId,
        });
      });
    },
    toggleTemplatesModal(payload) {
      this.showTemplatesModal = !!payload;
    },
    async updateTermInputs() {
      await this.calculatePmt();
      this.$refs.terms.updateTermInputs();
    },
    changeInterestChanged() {
      this.interestChanged = false;
    },
  },
  mounted: function() {
    if (
      this.value &&
      this.partnerSettings &&
      this.viewName === 'partnerCreate'
    ) {
      this.$set(this.value, 'servicesCost', 0);
      this.$set(this.value, 'contractFee', this.partnerSettings.contractFee);
      this.$set(this.value, 'insurance', this.partnerSettings.insurance);
      this.$set(
        this.value,
        'offerValidTime',
        this.partnerSettings.offerValidTime,
      );
    }
  },
  computed: {
    ...mapState('alert', ['alert']),
    ...mapState('document', ['documents']),
    ...mapState('message', ['newMessage']),
    totalQassetPmt: function() {
      if (this.value.qassets) {
        return this.value.qassets
          .reduce((acc, item) => acc + item.qassetPrice * item.quantity, 0)
          .toFixed(2);
      }
      return undefined;
    },
    totalPmt: function() {
      return this.totalQassetPmt;
    },
    computedCommission: function() {
      return (
        ((this.lendifyInsurance - parseFloat(this.value.servicesCost)) /
          this.value.paymentPeriod) *
        12
      ).toFixed(2);
    },
    computedKickback: function() {
      return (
        ((parseFloat(this.value.kickback) / 100) * this.totalPmt).toFixed(2) / 1
      );
    },
    computedInsurance: function() {
      if (this.value.insuranceValType === 1) {
        return (
          this.totalPmt *
          (+replaceComma(this.value.insurance) / 100) *
          this.value.paymentPeriod
        );
      } else {
        return (
          this.totalPmt *
          (+replaceComma(this.value.insurance) / 100 / 12) *
          this.value.paymentPeriod
        );
      }
    },
    lendifyInsurance: function() {
      return (this.computedKickback + this.computedInsurance).toFixed(2);
    },
    sortedMessages: function() {
      if (this.value.messages) {
        return this.value.messages.slice().sort(function(a, b) {
          var dateA = new Date(a.createdDtime);
          var dateB = new Date(b.createdDtime);
          return dateA > dateB ? 1 : -1;
        });
      }
      return undefined;
    },
    disableOnStatus() {
      return disableOnStatus(
        this.viewName,
        this.partnerSettings,
        this.value,
        this.isInhouse,
      );
    },
    disableDeliveryAndSellerInfo: function() {
      return this.value.status === 'CA';
    },
    numerify() {
      return numerify;
    },
    numerifyWithDecimals() {
      return numerifyWithDecimals;
    },
  },
};
</script>
