<template>
  <div>
    <div class="text-right mb-3">
      <b-btn
        size="sm"
        class="btn-close"
        @click.stop="row.toggleDetails"
        :disabled="errors.items.length > 0"
      >
        <img src="../../assets/delete-icon-w.svg" class="img-fluid" />
      </b-btn>
    </div>
    <b-row align-v="center" class="mb-2">
      <b-col sm="12" class="ml-3">
        <h3>{{ $t('ASSET') }}</h3>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="viewName !== 'shop'">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('ASSET_GROUP') }}:</b>
      </b-col>
      <b-col>
        <b-form-group
          :invalid-feedback="$t('VALIDATION.REQUIRED')"
          :state="validateState('editAssetMainGroup')"
          label-sr-only
          class="mb-0"
        >
          <multiselect
            v-model="row.item.assetGroupId"
            :options="mainAssetGroups.map(group => group.assetGroupId)"
            :show-labels="false"
            :custom-label="assetGroupCustomLabel"
          >
            <template #noResult>{{ $t('NO_ELEMENTS_FOUND') }}</template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="viewName !== 'shop'">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('ASSET_SUB_GROUP') }}:</b>
      </b-col>
      <b-col>
        <b-form-group
          :invalid-feedback="$t('VALIDATION.REQUIRED')"
          :state="validateState('editAssetSubGroup')"
          label-sr-only
          class="mb-0"
        >
          <multiselect
            v-model="row.item.assetSubgroupId"
            :options="subAssetGroups"
            :show-labels="false"
            :custom-label="assetGroupCustomLabel"
          >
            <template #noResult>{{ $t('NO_ELEMENTS_FOUND') }}</template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row align-v="center" class="mb-2" v-if="viewName === 'shop'">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('HANDOVER_LOCALLY') }}:</b>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('HANDOVER_LOCALLY') + ':'"
          label-sr-only
          class="mb-0"
        >
          <b-form-checkbox
            checked="true"
            unchecked-value="false"
            v-model="row.item.handoverWhenSigned"
            :disabled="disableOnStatus"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row align-v="center" class="mb-2">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('QASSET_NAME') }}:</b>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('QASSET_NAME') + ':'"
          :invalid-feedback="$t('VALIDATION.QASSET_NAME_REQUIRED')"
          :state="validateState('editAssetName')"
          label-sr-only
          class="mb-0"
        >
          <b-form-input
            name="editAssetName"
            v-model.trim="row.item.qassetName"
            v-validate="'required'"
            :disabled="disableOnStatus"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      align-v="center"
      class="mb-2"
      v-if="isInhouse || viewName === 'credit' || viewName === 'compliance'"
    >
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('QUANTITY') }}:</b>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('QUANTITY') + ':'"
          :invalid-feedback="$t('VALIDATION.QASSET_QUANTITY_REQUIRED')"
          :state="validateState('editQuantity')"
          label-sr-only
          class="mb-0"
        >
          <b-form-input
            name="editQuantity"
            v-model.trim="row.item.quantity"
            v-validate="'required'"
            type="text"
            :disabled="disableOnStatus"
            @keyup.native="
              numerifyOnEdit('value', 'qassets', row.index, 'quantity')
            "
            @change="$emit('calculatePmt')"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('QASSET_PRICE_WITH_VAT') }}:</b>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('QASSET_PRICE_WITH_VAT') + ':'"
          label-sr-only
          class="mb-0"
        >
          <b-form-input
            v-model="qassetPriceWithVat"
            :disabled="disableOnStatus"
            @input="calculateWithoutVat"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('QASSET_PRICE_NO_VAT') }}:</b>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('QASSET_PRICE_NO_VAT') + ':'"
          :invalid-feedback="$t('VALIDATION.QASSET_PRICE_REQUIRED')"
          :state="validateState('editPrice')"
          label-sr-only
          class="mb-0"
        >
          <b-form-input
            name="editPrice"
            v-model.trim="row.item.qassetPrice"
            v-validate="'required'"
            :disabled="disableOnStatus"
            @keyup.native="
              numerifyOnEditWithDecimals(
                'value',
                'qassets',
                row.index,
                'qassetPrice',
              )
            "
            @input="calculateWithVat"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('YEAR') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.year"
          :disabled="disableOnStatus"
          @keyup.native="numerifyOnEdit('value', 'qassets', row.index, 'year')"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('SELLER_OFFER_NR') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          id="sellerOfferNr"
          v-model="row.item.sellerOfferNr"
          :disabled="disableOnStatus"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('SELLER_OFFER_DATE') }}:</b>
      </b-col>
      <b-col>
        <RenderDatePicker
          @changeDate="e => $set(row.item, 'sellerOfferDate', e)"
          :query="value"
          :date="row.item.sellerOfferDate"
          :viewName="viewName"
          :partnerSettings="partnerSettings"
          :id="'editSellerOfferDate'"
        />
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="12" class="ml-3" v-if="isInhouse">
        <h3>{{ $t('SELLER') }}</h3>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('SELLER_NAME') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.sellerName"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('SELLER_REG_CODE') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.sellerRegCode"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('VAT_CODE') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.vatNumber"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('BANK_ACCOUNT_NUMBER') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.bankAccountNumber"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('STREET') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.street"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('POSTAL_CODE') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.zipCode"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('CITY') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.city"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('SELLER_PHONE') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.sellerPhone"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('SELLER_EMAIL') }}:</b>
      </b-col>
      <b-col>
        <b-form-group
          label-for="qassetSellerEmail"
          :invalid-feedback="$t('VALIDATION.INVALID_EMAIL')"
          :state="validateState('qassetSellerEmail')"
          class="mb-0"
        >
          <b-form-input
            id="qassetSellerEmail"
            name="qassetSellerEmail"
            v-model.trim="row.item.sellerEmail"
            v-validate="'email'"
            :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="12" v-if="isInhouse" class="ml-3">
        <h3>{{ $t('SELLER_REPRESENTATIVE') }}</h3>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('REPRESENTATIVE_NAME') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.sellerRepresentative"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('REPRESENTATIVE_PERSONAL_CODE') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.representativePersonalCode"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('REPRESENTATIVE_PHONE') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.representativePhone"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('REPRESENTATIVE_EMAIL') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.representativeEmail"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('SELLER_REPRESENTATIVE_TYPE') }}:</b>
      </b-col>
      <b-col>
        <b-form-radio-group
          button-variant="outline-secondary"
          v-model.trim="row.item.sellerReprsReprType"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        >
          <b-form-radio
            v-for="option in options"
            :key="option"
            :value="option"
            >{{ $t(snakeCase(option).toUpperCase()) }}</b-form-radio
          >
        </b-form-radio-group>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="12" class="ml-3" v-if="isInhouse">
        <h3>{{ $t('DELIVERY') }}</h3>
      </b-col>
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('DELIVERY_PLACE') }}:</b>
      </b-col>
      <b-col>
        <b-form-input
          v-model.trim="row.item.deliveryPlace"
          :disabled="disableOnStatus && disableDeliveryAndSellerInfo"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-2" v-if="isInhouse">
      <b-col sm="3" class="text-sm-right">
        <b>{{ $t('DELIVERY_DATE') }}:</b>
      </b-col>
      <b-col>
        <RenderDatePicker
          @changeDate="e => $set(row.item, 'deliveryDate', e)"
          :query="value"
          :date="row.item.deliveryDate"
          :viewName="viewName"
          :partnerSettings="partnerSettings"
          :id="'edit-qasset'"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mx-4 my-3">
        <b-button
          variant="primary"
          size="md"
          @click="$emit('saveQuery')"
          block
          >{{ $t('SAVE') }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import snakeCase from 'lodash.snakecase';
import {
  numerifyOnEdit,
  numerifyOnEditWithDecimals,
  getPriceWithVat,
  getPriceWithoutVat,
} from '@/utils';
import validateState from '../../mixins/validateState';
import { RenderDatePicker } from '@/components/common';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';

export default {
  name: 'EditAssetForm',
  mixins: [validateState],
  components: {
    Multiselect,
    RenderDatePicker,
  },
  props: {
    row: Object,
    value: Object,
    partnerSettings: Object,
    viewName: String,
    disableOnStatus: Boolean,
    disableDeliveryAndSellerInfo: Boolean,
    isInhouse: Boolean,
  },
  data() {
    return {
      options: ['Law', 'Power of Attorney'],
      qassetPriceWithVat: getPriceWithVat(this.row.item.qassetPrice),
    };
  },
  methods: {
    assetGroupCustomLabel(opt) {
      return this.allAssetGroups.find(x => x.assetGroupId === opt).name;
    },
    calculateWithoutVat(price) {
      this.row.item.qassetPrice = getPriceWithoutVat(price);
      this.$validator
        .validateAll()
        .then(() => {})
        .catch(() => {});
      this.$emit('calculatePmt');
    },
    calculateWithVat(price) {
      this.qassetPriceWithVat = getPriceWithVat(price);
      this.$emit('calculatePmt');
    },
  },
  computed: {
    ...mapState('qasset', ['allAssetGroups', 'availableMainAssetGroups']),
    numerifyOnEdit() {
      return numerifyOnEdit;
    },
    numerifyOnEditWithDecimals() {
      return numerifyOnEditWithDecimals;
    },
    snakeCase() {
      return snakeCase;
    },
    mainAssetGroups() {
      if (this.isInhouse) {
        return this.allAssetGroups.filter(
          group => group.superiorGroupId === null,
        );
      } else {
        return this.availableMainAssetGroups;
      }
    },
    subAssetGroups() {
      return (
        this.mainAssetGroups
          .find(x => x.assetGroupId == this.row.item.assetGroupId)
          ?.subgroups.map(group => group.assetGroupId) ?? []
      );
    },
  },
  watch: {
    'row.item.assetGroupId'() {
      this.row.item.assetSubgroupId = null;
    },
  },
};
</script>
