<template>
  <div>
    <Header :header="$t('EDIT_APPLICATION')" />

    <b-tabs v-model="tabIndex">
      <b-tab :disabled="tabIndex !== 0 && hasErrorFields">
        <template slot="title">
          <div @click="saveQuery()" id="clientTabNav" class="tab-nav-button">
            <figure class="nav-figure">
              <svg
                class="nav-image"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <path
                  d="M256,253.7c70.1,0.6,127.3-55.7,127.9-125.8C384.5,57.9,328.2,0.6,258.1,0C188-0.6,130.8,55.7,130.2,125.8  c0,0.3,0,0.7,0,1C130,196.6,186.3,253.3,256,253.7z M256,25.7c55.9-0.5,101.5,44.4,102,100.3c0.5,55.9-44.4,101.5-100.3,102  c-55.9,0.5-101.5-44.4-102-100.3c0-0.3,0-0.6,0-0.9C155.5,71.3,200.4,26,256,25.7L256,25.7z M256,305.1  c101.5,0.5,185.3,79.6,191.7,180.9H153.1c-7.1,0.3-12.6,6.3-12.3,13.4c0.3,6.7,5.6,12.1,12.3,12.3h307.8c7.1,0,12.8-5.8,12.7-12.9  c0,0,0,0,0,0c0-120.2-97.5-217.7-217.7-217.7S38.3,378.7,38.3,498.9c0,7.1,5.7,12.8,12.7,12.9l0,0h51c7.1-0.3,12.6-6.3,12.3-13.4  c-0.3-6.7-5.6-12.1-12.3-12.3H64.2C70.6,384.7,154.4,305.7,256,305.1L256,305.1L256,305.1z"
                  class="st0"
                ></path>
              </svg>
            </figure>
            <span class="nav-text">{{ $t('CLIENT') }}</span>
          </div>
          <b-tooltip
            v-if="hasErrorFields"
            class="error-tooltip"
            target="clientTabNav"
            :title="$t('VALIDATION.FIX_ERRORS')"
          />
        </template>
        <ShopClientTab
          v-model="currentQuery"
          v-if="partnerSettings !== null && inHousePartners !== null"
          @next="saveAndGoNext"
          @saveQuery="saveQuery"
          :partnerSettings="partnerSettings"
          :inHousePartners="inHousePartners"
          :viewName="viewName"
          :isInhouse="isInhouse"
        />
      </b-tab>

      <b-tab :disabled="tabIndex !== 1 && hasErrorFields">
        <template slot="title">
          <div
            @click="saveQuery()"
            id="transactionTabNav"
            class="tab-nav-button"
          >
            <figure class="nav-figure">
              <svg
                class="nav-image"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <path
                  id="Shape_5_copy"
                  d="M503.9,400.3c0-7.1-5.7-12.8-12.7-12.9c0,0,0,0,0,0H55.7l76.5-77c5-5,5-13.1,0-18.1  c-4.9-5-12.9-5-17.9-0.1c0,0-0.1,0.1-0.1,0.1l-98.3,98.9c-5,5-5,13.1,0,18.1l98.3,98.9c4.9,5,13,5,18,0.1c0,0,0.1-0.1,0.1-0.1  c5-5,5-13.1,0-18.1l-76.5-77h435.5C498.3,413,503.9,407.3,503.9,400.3L503.9,400.3z M500.1,102.6L401.8,3.8c-4.9-5-12.8-5-17.8-0.2  c-0.1,0.1-0.1,0.1-0.2,0.2c-5,5-5,13.1,0,18.1c0,0,0,0,0,0l76.5,77H24.9c-7.1,0.3-12.6,6.3-12.4,13.4c0.3,6.7,5.6,12.1,12.4,12.4  h435.5l-76.5,76.8c-5,5-5,13.1,0,18.1c0,0,0,0,0,0c4.9,5,13,5,18,0.1c0,0,0.1-0.1,0.1-0.1l98.3-98.9  C505.1,115.6,505.1,107.6,500.1,102.6z"
                  class="st0"
                ></path>
              </svg>
            </figure>
            <span class="nav-text">{{ $t('TRANSACTION') }}</span>
          </div>
          <b-tooltip
            v-if="hasErrorFields"
            class="error-tooltip"
            target="transactionTabNav"
            :title="$t('VALIDATION.FIX_ERRORS')"
          />
        </template>
        <ShopTransactionTab
          v-model="currentQuery"
          v-if="
            tabIndex === 1 &&
              partnerSettings !== null &&
              inHousePartners !== null
          "
          @next="saveAndGoNext"
          @saveQuery="saveQuery"
          @changeStatus="changeStatus"
          :partnerSettings="partnerSettings"
          :viewName="viewName"
          :canCreateQuickContract.sync="canCreateQuickContract"
          :inHousePartners="inHousePartners"
        />
      </b-tab>

      <b-tab
        :title-item-class="{ 'hide-contract-title': !isInhouse }"
        :disabled="tabIndex !== 2 && hasErrorFields"
      >
        <template slot="title">
          <div @click="saveQuery()" id="creditTabNav" class="tab-nav-button">
            <figure class="nav-figure">
              <svg
                class="nav-image"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M53,301c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10S58.5,301,53,301z"
                    class="st0"
                  ></path>
                </g>
                <g>
                  <path
                    d="M495.6,334.1c-10.3-7.2-23.4-9-35.3-4.7c-0.1,0-0.2,0.1-0.3,0.1l-94.5,37.1c0.3-1.8,0.4-3.7,0.4-5.6    c0-22.1-17.9-40-40-40h-64.8c-1.7,0-3.4-0.4-4.9-1.3L203.5,290c-10.4-5.9-22.3-9-34.3-9H103v-10c0-5.5-4.5-10-10-10H10    c-5.5,0-10,4.5-10,10v180c0,5.5,4.5,10,10,10h83c5.5,0,10-4.5,10-10v-10h7.5l95.4,38.6c18.7,7.5,38.3,11.4,58.5,11.4    c22.4,0,44.1-4.7,64.5-14c0.1,0,0.1,0,0.2-0.1l159-75.6c14.5-6,23.9-19.9,23.9-35.7C512,353.1,505.9,341.3,495.6,334.1z M83,441    H20V281h63V441z M480.3,382.9c-0.2,0.1-0.4,0.2-0.6,0.3l-159.2,75.7c-17.8,8.1-36.6,12.2-56.2,12.2c-17.5,0-34.7-3.3-51-9.9    l-97.2-39.4c-1.2-0.5-2.5-0.7-3.8-0.7H103V301h66.2c8.6,0,17.1,2.2,24.5,6.4l52.8,29.7c4.5,2.5,9.6,3.9,14.7,3.9H326    c11,0,20,9,20,20c0,6.9-3.6,13.4-9.5,17l-0.1,0c-0.8,0.3-1.5,0.7-2.1,1.1c-2.6,1.2-5.4,1.8-8.3,1.8H224.3c-16.5,0-30,13.5-30,30    c0,5.5,4.5,10,10,10s10-4.5,10-10c0-5.5,4.5-10,10-10H326c6.7,0,13.3-1.7,19.1-4.9l122.1-47.9c5.7-2,11.9-1.2,16.9,2.3    c5,3.5,7.9,9.1,7.9,15.2C492,373.3,487.4,380.1,480.3,382.9z"
                    class="st0"
                  ></path>
                </g>
                <g>
                  <path
                    d="M53,341c-5.5,0-10,4.5-10,10v60c0,5.5,4.5,10,10,10s10-4.5,10-10v-60C63,345.5,58.5,341,53,341z"
                    class="st0"
                  ></path>
                </g>
                <g>
                  <path
                    d="M396,181c-33.1,0-60,26.9-60,60s26.9,60,60,60s60-26.9,60-60S429.1,181,396,181z M396,281    c-22.1,0-40-17.9-40-40s17.9-40,40-40s40,17.9,40,40S418.1,281,396,281z"
                    class="st0"
                  ></path>
                </g>
                <g>
                  <path
                    d="M356,21c-33.1,0-60,26.9-60,60s26.9,60,60,60s60-26.9,60-60S389.1,21,356,21z M356,121c-22.1,0-40-17.9-40-40    s17.9-40,40-40s40,17.9,40,40S378.1,121,356,121z"
                    class="st0"
                  ></path>
                </g>
                <g>
                  <path
                    d="M236,141c-33.1,0-60,26.9-60,60s26.9,60,60,60s60-26.9,60-60S269.1,141,236,141z M236,241    c-22.1,0-40-17.9-40-40s17.9-40,40-40s40,17.9,40,40S258.1,241,236,241z"
                    class="st0"
                  ></path>
                </g>
              </svg>
            </figure>
            <span class="nav-text">{{ $t('CREDIT_SHORT') }}</span>
          </div>
          <b-tooltip
            v-if="hasErrorFields"
            class="error-tooltip"
            target="creditTabNav"
            :title="$t('VALIDATION.FIX_ERRORS')"
          />
        </template>
      </b-tab>

      <b-tab
        :title-item-class="{ 'hide-contract-title': !isInhouse }"
        :disabled="tabIndex !== 3 && hasErrorFields"
      >
        <template slot="title">
          <div
            @click="saveQuery()"
            id="actOfAcceptanceTabNav"
            class="tab-nav-button"
          >
            <figure class="nav-figure">
              <svg
                class="nav-image"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M478,262.8c-2.1-4.2-2.1-9,0-13.3l19.1-39.1c10.6-21.8,2.2-47.7-19.2-59L439.5,131c-4.1-2.2-7-6.1-7.8-10.7    l-7.5-42.8C420,53.7,398,37.6,374,41l-43.1,6.1c-4.6,0.7-9.2-0.8-12.6-4.1L287,12.8C269.6-4,242.4-4,225,12.8L193.7,43    c-3.4,3.3-8,4.8-12.6,4.1L138,41c-24-3.4-46,12.6-50.2,36.5l-7.5,42.8c-0.8,4.6-3.7,8.5-7.8,10.7l-38.4,20.4    c-21.4,11.3-29.8,37.3-19.2,59L34,249.6c2.1,4.2,2.1,9,0,13.3L14.9,302c-10.6,21.8-2.2,47.7,19.2,59l38.4,20.4    c4.1,2.2,7,6.1,7.8,10.7l7.5,42.8c3.8,21.7,22.4,36.9,43.8,36.9c2.1,0,4.2-0.1,6.4-0.5l43.1-6.1c4.6-0.7,9.2,0.8,12.6,4.1    l31.3,30.2c8.7,8.4,19.9,12.6,31,12.6c11.2,0,22.3-4.2,31-12.6l31.3-30.2c3.4-3.3,8-4.8,12.6-4.1l43.1,6.1    c24,3.4,46-12.6,50.2-36.5l7.5-42.8c0.8-4.6,3.7-8.5,7.8-10.7l38.4-20.4c21.4-11.3,29.8-37.3,19.2-59L478,262.8z M464,334.8    l-38.4,20.4c-12.2,6.5-20.6,18.1-23,31.7l-7.5,42.8c-1.4,8.1-8.9,13.5-17,12.3l-43.1-6c-13.7-1.9-27.3,2.5-37.3,12.1l-31.3,30.2    c-5.9,5.7-15.1,5.7-21,0l-31.3-30.2c-8.4-8.1-19.4-12.6-30.9-12.6c-2.1,0-4.2,0.1-6.4,0.4l-43.1,6.1c-8.1,1.1-15.6-4.3-17-12.3    l-7.5-42.8c-2.4-13.7-10.8-25.2-23-31.7L48,334.8c-7.2-3.8-10.1-12.6-6.5-20l19.1-39.1c6.1-12.5,6.1-26.7,0-39.2l-19.1-39.1    c-3.6-7.4-0.7-16.1,6.5-20L86.4,157c12.2-6.5,20.6-18.1,23-31.7l7.5-42.8c1.4-8.1,8.9-13.5,17-12.3l43.1,6.1    c13.7,1.9,27.3-2.5,37.3-12.1L245.6,34c5.9-5.7,15.1-5.7,21,0l31.3,30.2c10,9.6,23.6,14.1,37.3,12.1l43.1-6.1    c8.1-1.1,15.6,4.3,17,12.3l7.5,42.8c2.4,13.7,10.8,25.2,23,31.7l38.4,20.4c7.2,3.8,10.1,12.6,6.5,20l-19.1,39.1    c-6.1,12.5-6.1,26.7,0,39.2l19.1,39.1C474.1,322.2,471.3,331,464,334.8z"
                    class="st0"
                  ></path>
                </g>
                <polygon
                  points="256,136.9 294.8,215.4 381.5,228 318.7,289.2 333.6,375.5 256,334.8 178.4,375.5 193.3,289.2   130.5,228 217.2,215.4 "
                  class="st0"
                ></polygon>
              </svg>
            </figure>
            <span class="nav-text">{{ $t('AOA') }}</span>
          </div>
          <b-tooltip
            v-if="hasErrorFields"
            class="error-tooltip"
            target="actOfAcceptanceTabNav"
            :title="$t('VALIDATION.FIX_ERRORS')"
          />
        </template>
      </b-tab>

      <b-tab :disabled="tabIndex !== 4 && hasErrorFields">
        <template slot="title">
          <div @click="saveQuery()" id="statusTabNav" class="tab-nav-button">
            <figure class="nav-figure">
              <svg
                class="nav-image"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <circle cx="256.3" cy="59.1" r="14.8" class="st0"></circle>
                </g>
                <g>
                  <path
                    d="M452.9,118.2c0-21.8-17.6-39.4-39.4-39.4h-54.7c-7.3-8.1-16.7-14.3-27.4-17.4c-9.1-2.6-16-9.6-18.7-18.7    C305.7,18,283,0,256,0c-27,0-49.7,18-56.8,42.7c-2.6,9.1-9.6,16-18.7,18.7c-10.8,3.1-20.1,9.3-27.4,17.4H98.5    c-21.8,0-39.4,17.6-39.4,39.4v354.5c0,21.8,17.6,39.4,39.4,39.4c90.4,0,201.5,0,315.1,0c21.8,0,39.4-17.6,39.4-39.4    C452.9,360.8,452.9,253.7,452.9,118.2z M157.5,117.8c0.1-17.3,11.8-32.7,28.4-37.5c15.6-4.5,27.7-16.5,32.2-32.1    c4.8-16.8,20.4-28.5,37.9-28.5c17.5,0,33,11.7,37.9,28.5c4.5,15.6,16.5,27.6,32.1,32.1c16.8,4.8,28.5,20.4,28.5,37.9v19.7H157.5    V117.8z M413.5,492.3c-105.2,0-210.2,0-315.1,0c-10.9,0-19.7-8.8-19.7-19.7V118.2c0-10.9,8.8-19.7,19.7-19.7h42.8    c-2.1,6-3.3,12.5-3.4,19.2v39.9h236.3v-39.4c0-6.9-1.2-13.5-3.4-19.7h42.8c10.9,0,19.7,8.8,19.7,19.7c0,56.3,0,320.3,0,354.5    C433.2,483.5,424.4,492.3,413.5,492.3z"
                    class="st0"
                  ></path>
                </g>
                <g>
                  <polygon
                    points="337.9,239.4 219.5,357.8 167.3,305.6 153.4,319.5 219.5,385.6 351.8,253.3   "
                    class="st0"
                  ></polygon>
                </g>
              </svg>
            </figure>
            <span class="nav-text">{{ $t('STATUS') }}</span>
          </div>
          <b-tooltip
            v-if="hasErrorFields"
            class="error-tooltip"
            target="statusTabNav"
            :title="$t('VALIDATION.FIX_ERRORS')"
          />
        </template>
        <StatusTab
          v-model="currentQuery"
          :statusHistory="statusHistory"
          :documents="currentDocuments"
          :viewName="viewName"
        />
      </b-tab>
    </b-tabs>

    <ShopSendQueryModal />
  </div>
</template>

<script>
import { StatusTab } from '@/components';

import ShopClientTab from './ShopClientTab';
import ShopTransactionTab from './ShopTransactionTab';
import ShopSendQueryModal from './ShopSendQueryModal';

import { Header } from '@/components/common';
import { formatQuery } from '../../utils';
import { mapActions, mapState } from 'vuex';
import {
  SAVE_QUERY,
  GET_QUERY,
  GET_QUERY_STATUS_HISTORY,
  CHANGE_QUERY_STATUS,
  GET_QUICK_CONTRACT_STATUS,
} from '../../store/query';
import { SAVE_QASSETS } from '../../store/qasset';
import { SAVE_SURETIES } from '../../store/surety';
import { GET_QUERY_DOCUMENTS } from '../../store/document';
import { GET_QUERY_MESSAGES } from '../../store/message';

export default {
  name: 'ShopEditQuery',
  components: {
    Header,
    ShopClientTab,
    ShopTransactionTab,
    StatusTab,
    ShopSendQueryModal,
  },
  data: function() {
    return {
      currentQuery: {},
      currentDocuments: [],
      partnerSettings: null,
      alertMessage: '',
      alert: false,
      viewName: 'shop',
      pmtScheduleRows: [],
      tabIndex: 0,
      financier: '',
      intervalId: '0',
      inHousePartners: [],
      canCreateQuickContract: false,
      isInhouse: JSON.parse(window.localStorage.getItem('isInhouse')),
    };
  },
  watch: {
    query: {
      handler: function() {
        if (this.query) {
          this.currentQuery = JSON.parse(JSON.stringify(this.query));
        }
      },
      deep: true,
    },
    documents() {
      if (this.documents) {
        this.currentDocuments = JSON.parse(JSON.stringify(this.documents));
      }
    },
  },
  created: function() {
    this.$store
      .dispatch('getFinanciers')
      .then(response => {
        this.financiers = response.data.map(financier => {
          return {
            text: financier.name,
            value: financier.financierId,
          };
        });
      })
      .catch(error => {
        console.log(error);
      });
  },
  mounted: function() {
    this.GET_QUERY(this.$route.params.id)
      .then(() => {
        this.$store
          .dispatch('getUser', this.query.userId)
          .then(({ data: userResponse }) => {
            this.partnerSettings = userResponse.partner;
          })
          .catch(error => {
            console.log(error);
          })
          .then(() => {
            this.$store.dispatch('findAllInhousePartners').then(response => {
              this.inHousePartners = response.data;
            });
          });
      })
      .then(async () => {
        await this.getFinancier();
        await this.GET_QUERY_DOCUMENTS(this.query.queryId);
        await this.GET_QUERY_STATUS_HISTORY(this.query.queryId);
        if (this.isInhouse) {
          this.intervalId = setInterval(
            function() {
              this.getQueryMessages();
            }.bind(this),
            5000,
          );
        }
      });
  },
  beforeDestroy: function() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions('query', [
      GET_QUERY,
      SAVE_QUERY,
      GET_QUERY_STATUS_HISTORY,
      CHANGE_QUERY_STATUS,
      GET_QUICK_CONTRACT_STATUS,
    ]),
    ...mapActions('qasset', [SAVE_QASSETS]),
    ...mapActions('surety', [SAVE_SURETIES]),
    ...mapActions('document', [GET_QUERY_DOCUMENTS]),
    ...mapActions('message', [GET_QUERY_MESSAGES]),
    async getQueryMessages() {
      this.currentQuery.messages = await this.GET_QUERY_MESSAGES(
        this.currentQuery.queryId,
      );
    },
    async saveQuery(cb) {
      if (this.hasErrorFields) return;
      await this.SAVE_QUERY(formatQuery(this.currentQuery)).then(() => {
        this.GET_QUICK_CONTRACT_STATUS(this.currentQuery.queryId).then(r => {
          this.$emit('update:canCreateQuickContract', r.data);
        });
      });
      if (typeof cb === 'function') cb();
    },
    async changeStatus(payload) {
      try {
        this.currentQuery.status = await this.CHANGE_QUERY_STATUS(payload);
        // await this.saveAndGoNext();
        // await this.GET_QUERY_STATUS_HISTORY(this.query.queryId);
      } catch (e) {
        console.error(e);
      }
    },
    saveAndGoNext: function(index) {
      return this.saveQuery().then(() => {
        if (this.isInhouse) {
          this.tabIndex = this.tabIndex + 1;
        } else {
          this.tabIndex = this.tabIndex + (index || 3);
        }
        scroll(0, 0);
      });
    },
    getFinancier: function() {
      if (this.query.financierId === null) return;
      return this.$store
        .dispatch('getFinancier', this.query.financierId)
        .then(response => {
          this.financier = response.data;
        });
    },
  },
  computed: {
    ...mapState('query', ['query', 'statusHistory']),
    ...mapState('document', ['documents']),
    hasErrorFields() {
      return !!this.errors.items[0];
    },
  },
};
</script>

<style scoped></style>
