import axios from 'axios';

export const GET_BY_NAME = 'GET_BY_NAME';
export const GET_BY_COMPANY_CODE = 'GET_BY_COMPANY_CODE';
const SET_COMPANIES = 'SET_COMPANIES';

const state = {
  companies: [],
};

export const actions = {
  async [GET_BY_NAME]({ commit }, payload) {
    const response = await axios.get('/company/get-by-name', {
      params: {
        name: payload,
      },
      dataType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    commit(SET_COMPANIES, response.data);
  },
  async [GET_BY_COMPANY_CODE]({ commit }, payload) {
    const response = await axios.get('/company/get-by-company-code', {
      params: {
        companyCode: payload,
      },
      dataType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    commit(SET_COMPANIES, response.data);
  },
};

export const mutations = {
  [SET_COMPANIES](state, payload) {
    state.companies = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
