<template>
  <div>
    <Header :header="$t('QUERY_CLOSING_REASONS')" :role="role" showSettings />
    <b-container class="container-wide compliance-partner">
      <b-card class="mt-5">
        <b-table
          outlined
          striped
          :items="updatedReasons"
          :fields="fields"
          class="mt-2"
        >
          <template #cell(en)="row">
            <b-form-input
              :placeholder="$t('LANGUAGES.en')"
              class="table-input"
              v-model="row.item.en"
              type="text"
            />
          </template>
          <template #cell(et)="row">
            <b-form-input
              :placeholder="$t('LANGUAGES.et')"
              class="table-input"
              v-model="row.item.et"
              type="text"
            />
          </template>
          <template #cell(actions)="row">
            <div class="actions">
              <span v-b-tooltip.hover :title="$t('DELETE')">
                <a @click="handleDelete(row.item)" class="only-action mt-0">
                  <img src="../../assets/delete-icon.svg" />
                </a>
              </span>
            </div>
          </template>
        </b-table>
        <b-row class="mt-4" align-h="end">
          <b-col md="2">
            <b-btn
              variant="primary"
              size="md"
              block
              :disabled="hasEmptyFields"
              @click="addNewReason"
              exact
              >{{ $t('ADD_REASON') }}</b-btn
            >
          </b-col>
        </b-row>
      </b-card>

      <div class="mb-5 mt-4">
        <b-row align-h="center">
          <b-col md="4">
            <b-btn
              type="button"
              variant="secondary"
              size="lg"
              block
              :to="{ name: 'ComplianceHome' }"
              >{{ $t('BACK') }}</b-btn
            >
          </b-col>
          <b-col md="4">
            <b-btn
              :disabled="hasEmptyFields"
              @click="handleSubmit"
              type="button"
              variant="primary"
              size="lg"
              block
              >{{ $t('SAVE') }}</b-btn
            >
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { Header } from '@/components/common';
import { mapState, mapActions } from 'vuex';
import { FIND_ALL_REASONS, UPDATE_ALL_REASONS } from '../../store/reason';

export default {
  name: 'ManageReasons',
  components: {
    Header,
  },
  data() {
    return {
      fields: [
        {
          key: 'en',
          label: this.$t('LANGUAGES.en'),
        },
        {
          key: 'et',
          label: this.$t('LANGUAGES.et'),
        },
        {
          key: 'actions',
          label: this.$t('ACTIONS'),
        },
      ],
      updatedReasons: [],
    };
  },
  async mounted() {
    await this.FIND_ALL_REASONS();
    this.updatedReasons = this.reasons;
  },
  methods: {
    ...mapActions('reason', [FIND_ALL_REASONS, UPDATE_ALL_REASONS]),
    async handleSubmit() {
      this.UPDATE_ALL_REASONS(this.updatedReasons);
      this.updatedReasons = this.reasons;
    },
    handleDelete(reason) {
      this.updatedReasons.splice(this.updatedReasons.indexOf(reason), 1);
    },
    addNewReason() {
      this.updatedReasons.push({
        id: null,
        en: '',
        et: '',
      });
    },
  },
  computed: {
    ...mapState('reason', ['reasons']),
    role() {
      return localStorage.getItem('role');
    },
    hasEmptyFields() {
      return !!this.updatedReasons.find(item => !item.en || !item.et);
    },
  },
};
</script>

<style></style>
