<template>
  <div>
    <Header :header="$t('CREATE_OFFER')"> </Header>
    <b-container class="mt-4" v-if="messageBody">
      <b-alert show variant="success">
        <h4 class="alert-heading">{{ messageTitle }}</h4>
        <p class="mt-4">
          {{ messageBody }}
        </p>
      </b-alert>
    </b-container>

    <b-container class="mt-4" v-if="techError" id="techError">
      <b-alert show variant="danger">
        <h4 class="alert-heading">{{ $t('TECH_ERROR') }}</h4>
        <p class="mt-4">
          {{ techError }}
        </p>
      </b-alert>
    </b-container>

    <b-container class="mt-4" v-if="preview">
      <div class="border p-2 rounded">
        <iframe
          :srcdoc="preview"
          style="border: 0px;"
          height="1000"
          width="100%"
        >
        </iframe>
      </div>

      <b-button
        class="mt-4 mb-8"
        v-b-modal.modal-1
        size="lg"
        :disabled="
          !validToSend ||
            sendingOfferLink ||
            generatingOffer ||
            generatingOfferPdf
        "
        variant="primary"
        v-if="canSaveOffer"
        @click="sendOffer()"
        block
      >
        <span v-if="!sendingOfferLink">Saada pakkumine kliendile</span>
        <span v-if="sendingOfferLink">Edastan pakkumist. Palun oota... </span>
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          v-if="sendingOfferLink"
        ></span>
      </b-button>

      <b-button
        class="mt-4 mb-8"
        v-b-modal.modal-1
        size="lg"
        variant="primary"
        @click="backToEditOffer()"
        block
      >
        <span>Tagasi pakkumise koostamise juurde</span>
      </b-button>
    </b-container>

    <b-container class="mt-4" v-if="!preview">
      <b-form>
        <div class="card-title">
          <div>
            <span class="text-nowrap"
              ><h2>{{ $t('OFFER') }}</h2></span
            >
            <span v-if="modifiedDtime"
              >Viimati muudetud: {{ modifiedDtime }}</span
            >
          </div>

          <div class="align-items-end" v-if="totalPrice">
            <Kickback
              :partner="partner"
              :contractAmount="new Number(totalPrice)"
              :contractFee="new Number(contractFee)"
            />
          </div>
        </div>
        <b-card class="card-padding-md">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('SELLER_OFFER_NR') + ':'"
                label-for="offerNumber"
              >
                <b-form-input
                  id="offerNumber"
                  name="offerNumber"
                  maxlength="30"
                  type="text"
                  v-model.trim="offerNumber"
                  v-validate="'required'"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="this.partner && this.partner.editContractFee">
              <b-form-group label-for="contractFee">
                <label for="contractFee" class="d-block"
                  >{{ $t('SET_CONTRACT_FEE') + ':' }}
                  <b-icon class="pl-0" id="tt-13" icon="question-circle" />
                  <b-tooltip target="tt-13" variant="dark" triggers="hover">
                    {{ $t('CONTRACT_FEE_TOOLTIP') }}
                  </b-tooltip>
                </label>
                <b-form-input
                  id="contractFee"
                  name="contractFee"
                  type="text"
                  v-model.trim="contractFee"
                  v-validate="'required'"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('OFFER_PRICE_WITH_VAT') + ':'"
                :invalid-feedback="$t('VALIDATION.QASSET_PRICE_REQUIRED')"
                label-for="qassetPriceWithVat"
                :hidden="user && user.hideVatFields"
              >
                <b-form-input
                  id="totalPriceWithVat"
                  name="totalPriceWithVat"
                  v-model.trim="totalPriceWithVat"
                  type="text"
                  @input="calculateWithoutVat"
                  v-validate="'required'"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('SET_OFFER_PRICE_NO_VAT') + ':'"
                label-for="qassetPrice"
                :invalid-feedback="$t('VALIDATION.QASSET_PRICE_REQUIRED')"
              >
                <b-form-input
                  id="totalPrice"
                  name="totalPrice"
                  v-model.trim="totalPrice"
                  v-validate="'required'"
                  type="text"
                  @input="calculateWithVat"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <div class="mt-3" v-if="this.totalAllAssetsAmount > this.totalPrice">
          <b-alert show variant="info"
            >Taotluse summa peab olema suurem või võrdne varade
            koguväärtusega.<br /><br />
            - taotluse summa: {{ this.totalPrice || 0 }}<br />
            - varade koguväärtus:
            {{ this.totalAllAssetsAmount }}</b-alert
          >
        </div>

        <h2 class="card-title mt-4">
          <span class="text-nowrap">{{ $t('MAIN_ASSETS') }}</span>
        </h2>
        <b-card class="card-padding-md">
          <b-row v-for="(asset, index) in assets" v-bind:key="asset.id">
            <b-col md="6">
              <b-form-group
                :label="$t('CHOOSE_ASSET_GROUP') + ':'"
                label-for="assetSubGroup"
              >
                <multiselect
                  v-model="asset.group"
                  :options="assetGroups"
                  :allow-empty="false"
                  :show-labels="false"
                  :searchable="false"
                  placeholder="Vali väärtus"
                >
                  <template #option="{ option }">
                    {{ option.name }}
                  </template>
                  <template #singleLabel="{ option }">
                    {{ option.name }}
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('SET_ASSET_PRICE_NO_VAT') + ':'"
                label-for="assetPrice"
                v-b-tooltip.hover
              >
                <div style="display: flex; bottom 0; ">
                  <b-form-input
                    id="assetPrice"
                    name="assetPrice"
                    type="text"
                    v-model.trim="asset.assetPrice"
                    v-validate="'required'"
                    @change="replaceAssetCommas"
                  />
                  <div class="ml-2 mt-1" v-if="index > 0">
                    <b-button
                      variant="secondary"
                      @click="removeMainAsset(index)"
                    >
                      <b-icon id="tt-1" icon="trash" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-button variant="secondary" @click="addMainAsset()">
                <b-icon id="tt-1" icon="plus" />
              </b-button>
              <span class="ml-1">
                Lisa uus rida
              </span>
            </b-col>
          </b-row>

          <b-row v-if="this.partner && this.partner.editInsurance" class="mt-3">
            <b-col md="6">
              <b-form-group
                :label="$t('SET_INSURANCE_AMOUNT') + ':'"
                label-for="insuranceAmount"
                v-b-tooltip.hover
              >
                <div style="display: flex; bottom 0; ">
                  <b-form-input
                    id="insuranceAmount"
                    name="insuranceAmount"
                    v-model.trim="insuranceAmount"
                    type="text"
                    @change="replaceAssetCommas"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('SET_EXTENDED_WARRANTY') + ':'"
                label-for="extendedWarranty"
                v-b-tooltip.hover
              >
                <div style="display: flex; bottom 0; ">
                  <b-form-input
                    id="extendedWarranty"
                    name="extendedWarranty"
                    v-model.trim="extendedWarranty"
                    @change="replaceAssetCommas"
                    type="text"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="additionalAssetsPrice > 0 && (totalMainAssetsAmount || 0) > 0"
            class="mt-3"
          >
            <b-col md="6"></b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ADDITIONAL_ASSETS_COST') + ':'"
                label-for="additionalAssetPrice"
                v-b-tooltip.hover
              >
                <div style="display: flex; bottom 0; ">
                  <b-form-input
                    id="additionalAssetPrice"
                    name="additionalAssetPrice"
                    :value="additionalAssetsPrice"
                    type="text"
                    disabled
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <h2 class="card-title mt-4">
          <span class="text-nowrap">{{ $t('CLIENT') }}</span>
        </h2>
        <b-card class="card-padding-md">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('CUSTOMER_NAME_NOT_REQUIRED') + ':'"
                label-for="customerName"
                v-b-tooltip.hover
              >
                <multiselect
                  v-model="selected"
                  id="customerName"
                  :options="companies"
                  :loading="isLoading"
                  label="name"
                  @search-change="getCompaniesByName"
                  @close="setCompanyParams"
                  :placeholder="$t('SEARCH')"
                  :show-labels="false"
                >
                  <template slot="noResult">
                    {{ $t('NO_ELEMENTS_FOUND') }}
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('REGISTRY_CODE') + ':'"
                label-for="customerRegCode"
              >
                <div style="display: flex; bottom 0; ">
                  <multiselect
                    v-model="selected"
                    id="customerRegCode"
                    :options="companies"
                    :loading="isLoading"
                    label="companyCode"
                    @search-change="getCompaniesByCode"
                    @close="setCompanyParams"
                    :placeholder="$t('SEARCH')"
                    :show-labels="false"
                  >
                    <template slot="noResult">
                      {{ $t('NO_ELEMENTS_FOUND') }}
                    </template>
                  </multiselect>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('EMAIL') + ':'"
                label-for="contactEmail"
                v-b-tooltip.hover
              >
                <b-form-input
                  id="contactEmail"
                  name="contactEmail"
                  type="text"
                  v-model.trim="contactEmail"
                  v-validate="'required|email'"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group :label="$t('PHONE') + ':'" label-for="contactPhone">
                <div style="display: flex; bottom 0; ">
                  <b-input-group-prepend is-text>
                    +372
                  </b-input-group-prepend>

                  <b-form-input
                    id="contactPhone"
                    name="contactPhone"
                    type="text"
                    v-model.trim="contactPhone"
                    v-validate="'required'"
                    class="ml-1"
                  ></b-form-input>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <h2 class="card-title mt-4">
          <span class="text-nowrap">{{ $t('OFFER_ATTACHMENTS') }}</span>
        </h2>

        <b-card class="card-padding-md">
          <div
            v-for="attachment in attachments"
            :key="attachment.id"
            class="mb-2 d-flex justify-content-between p-2 border border-gray-300 rounded align-middle"
          >
            <div class="ml-1 d-flex align-items-center">
              <b
                @click="downloadAttachment(attachment)"
                style=" cursor: pointer; "
              >
                {{ attachment.fileName }}
              </b>
              <div
                @click="downloadAttachment(attachment)"
                style=" cursor: pointer; "
                class="ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path
                    d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"
                  />
                </svg>
              </div>
            </div>
            <div class="mr-1">
              <div
                @click="deleteAttachment(attachment)"
                style=" cursor: pointer; "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path
                    d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="drop-box">
            <label
              for="fileInput"
              :class="[
                'drop-box-holder',
                { 'drop-box-holder-dragging': dragged },
              ]"
              @dragover.prevent="toggleDragging"
              @dragleave="toggleDragging"
              @drop.prevent="uploadFiles"
            >
              <input
                type="file"
                id="fileInput"
                multiple
                class="file-input"
                @change.prevent="uploadFiles"
              />
              <template v-if="!uploading">
                <img src="../../assets/upload.svg" />
                {{ $t('DRAG_AND_DROP') }} <span>{{ $t('OR') }}</span>
                <span class="btn btn-secondary">{{ $t('CHOOSE_FILES') }}</span>
              </template>
              <template v-if="uploading">
                <b-icon
                  icon="cloud-upload"
                  animation="throb"
                  font-scale="3"
                ></b-icon>
              </template>
            </label>
          </div>
        </b-card>

        <b-button
          class="mt-4 mb-8"
          v-b-modal.modal-1
          size="lg"
          :disabled="
            !validToSend ||
              sendingOfferLink ||
              generatingOffer ||
              generatingOfferPdf
          "
          variant="primary"
          v-if="canSaveOffer"
          @click="sendOffer()"
          block
        >
          <span v-if="!sendingOfferLink">Saada pakkumine kliendile</span>
          <span v-if="sendingOfferLink">Edastan pakkumist. Palun oota... </span>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            v-if="sendingOfferLink"
          ></span>
        </b-button>
        <b-button
          class="mt-4 mb-8"
          v-b-modal.modal-1
          size="lg"
          :disabled="
            !validToSubmit ||
              generatingOffer ||
              generatingOfferPdf ||
              sendingOfferLink
          "
          variant="primary"
          @click="viewOffer()"
          block
        >
          <span v-if="!generatingOffer">Vaata pakkumist</span>
          <span v-if="generatingOffer">Koostan pakkumist. Palun oota... </span>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            v-if="generatingOffer"
          ></span>
        </b-button>
        <b-button
          class="mt-4 mb-8"
          v-b-modal.modal-1
          size="lg"
          :disabled="
            !validToSubmit ||
              generatingOffer ||
              generatingOfferPdf ||
              sendingOfferLink
          "
          variant="primary"
          @click="viewOfferPdf()"
          block
        >
          <span v-if="!generatingOfferPdf">Koosta pakkumise PDF</span>
          <span v-if="generatingOfferPdf"
            >Koostan pakkumise trükist. Palun oota...
          </span>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            v-if="generatingOfferPdf"
          ></span>
        </b-button>
        <br />
      </b-form>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { Header, Footer } from '@/components/common';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import { replaceComma, numerify, numerifyWithDecimals } from '@/utils';
import {
  getPriceWithoutVat,
  getPriceWithVat,
  downloadResponseBlob,
} from '../../utils';
import { mapActions, mapState } from 'vuex';
import { GET_BY_NAME, GET_BY_COMPANY_CODE } from '../../store/company';
import i18n from '../../i18n';
import Kickback from '@/components/common/Kickback';

export default {
  name: 'SelfServeOffer',
  components: {
    Header,
    Footer,
    Multiselect,
    Kickback,
  },
  async mounted() {
    await axios.get('user/get-logged-in-user').then(({ data: userData }) => {
      // if data.partner is null and queryId is not null then use partnerId from query
      if (userData.partnerId) {
        this.user = userData;
        this.partner = userData.partner;
        if (this.partner.editContractFee) {
          this.contractFee = this.partner.contractFee;
        }

        this.canSaveOffer = true;
        axios
          .get('/partner/' + userData.partnerId + '/asset-group/names')
          .then(({ data }) => {
            this.assetGroups = data;
          });
      }
      this.queryId = this.$route.params.id;
    });
  },
  methods: {
    ...mapActions('company', [GET_BY_NAME, GET_BY_COMPANY_CODE]),
    deleteAttachment(attachment) {
      if (attachment.docFileId) {
        axios
          .post('/document/delete', attachment.docFileId, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then(() => {
            this.attachments = this.attachments.filter(
              a => a.fileId !== attachment.fileId,
            );
          });
        return;
      } else {
        axios.put('/selfserve/attachments/' + attachment.fileId).then(() => {
          this.attachments = this.attachments.filter(
            a => a.fileId !== attachment.fileId,
          );
        });
      }
    },
    downloadAttachment(attachment) {
      axios
        .get('/selfserve/attachments/' + attachment.fileId, {
          responseType: 'blob',
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.setAttribute('download', attachment.fileName);
          document.body.appendChild(link);
          link.click();
        });
    },
    validateToSubmit() {},
    calculateWithoutVat(price) {
      this.totalPrice = getPriceWithoutVat(price);
      this.$validator
        .validateAll()
        .then(() => {})
        .catch(() => {});
    },
    calculateWithVat(price) {
      this.totalPriceWithVat = getPriceWithVat(price);
      this.$validator
        .validateAll()
        .then(() => {})
        .catch(() => {});
    },
    removeMainAsset(index) {
      this.assets.splice(index, 1);
    },
    addMainAsset() {
      this.assets.push({});
    },
    replaceAssetCommas() {
      this.assets = this.assets.map(asset => {
        return {
          ...asset,
          assetPrice: replaceComma(asset.assetPrice),
        };
      });
    },
    payload() {
      let optionalAssets = [];
      if (this.insuranceAmount > 0) {
        optionalAssets.push({
          name: 'Kindlustus',
          price: this.insuranceAmount,
          vatExempt: true,
        });
      }

      if (this.extendedWarranty > 0) {
        optionalAssets.push({
          name: 'Lisagarantii',
          price: this.extendedWarranty,
          vatExempt: true,
        });
      }

      if (this.additionalAssetsPrice > 0) {
        optionalAssets.push({
          name: 'Lisa',
          price: this.additionalAssetsPrice,
          vatExempt: false,
        });
      }

      return {
        mainAssets: this.assets.map(asset => {
          return {
            groupId: asset.group.assetGroupId,
            price: asset.assetPrice,
            vatExempt: false,
          };
        }),
        optionalAssets: optionalAssets,
        totalAmount: this.totalPrice,
        phone: this.contactPhone,
        email: this.contactEmail,
        offerNumber: this.offerNumber,
        customerName: this.customerName,
        customerRegCode: this.customerRegCode,
        contractFee: this.contractFee,
        attachments: this.attachments,
      };
    },
    async backToEditOffer() {
      this.preview = null;
    },

    async saveQuery() {
      if (this.queryId && !this.canSaveOffer) {
        // skip saving if user is not allowed to save and return with data.queryId
        return { data: { queryId: this.queryId } };
      }
      if (this.queryId) {
        return axios
          .put('/selfserve/offers/' + this.queryId, this.payload())
          .then(({ data }) => {
            this.submitSuccessful = true;
            return { data };
          });
      } else {
        return axios
          .post('/selfserve/offers', this.payload())
          .then(({ request }) => {
            const data = JSON.parse(request.response);
            this.submitSuccessful = true;
            this.queryId = data.queryId;
            return { data };
          });
      }
    },

    viewOfferPdf() {
      this.generatingOfferPdf = true;
      this.saveQuery()
        .then(({ data }) => {
          // open offer link in new tab
          const pdfToOpen = '/selfserve/offers/' + data.queryId + '.pdf';

          return axios
            .get(pdfToOpen, {
              responseType: 'blob',
            })
            .then(response => {
              downloadResponseBlob(response);
              this.generatingOfferPdf = false;
            })
            .catch(error => {
              this.generatingOfferPdf = false;
              this.techError = i18n.t('OFFER_CREATION_FAILED');
              // scroll to top to show error message
              this.scrollToTop();
            });
        })
        .catch(error => {
          this.generatingOffer = false;
          this.techError = i18n.t('OFFER_CREATION_FAILED');
          // scroll to top to show error message
          this.scrollToTop();
        });
    },
    viewOffer() {
      this.generatingOffer = true;
      this.saveQuery()
        .then(({ data }) => {
          this.generatingOffer = false;
          // use axios to fetch the html
          axios
            .get('/selfserve/offers/' + data.queryId + '.html')
            .then(response => {
              this.preview = response.data;
            });
        })
        .catch(error => {
          this.generatingOffer = false;
          this.techError = i18n.t('OFFER_CREATION_FAILED');
          // scroll to top to show error message
          this.scrollToTop();
        });
    },

    sendOffer() {
      this.sendingOfferLink = true;
      this.saveQuery()
        .then(({ data }) => {
          this.submitSuccessful = true;
          this.sendingOfferLink = false;
          axios
            .put('/selfserve/offers/' + data.queryId + '/send', this.payload())
            .then(({ data }) => {});
        })
        .then(() => {
          this.messageTitle = 'Pakkumine edastatud';
          // if phone is given, send sms, if email is given, send email if both are given, send both
          if (this.contactPhone && this.contactEmail) {
            this.messageBody =
              'Pakkumine on edastatud e-posti aadressile ' +
              this.contactEmail +
              ' ja telefoninumbrile ' +
              this.contactPhone;
          } else if (this.contactPhone) {
            this.messageBody =
              'Pakkumine on edastatud telefoninumbrile ' + this.contactPhone;
          } else if (this.contactEmail) {
            this.messageBody =
              'Pakkumine on edastatud e-posti aadressile ' + this.contactEmail;
          }

          this.scrollToTop();
        })
        .catch(error => {
          this.sendingOfferLink = false;
          this.techError = i18n.t('OFFER_SENDING_FAILED');
          // scroll to top to show error message
          this.scrollToTop();
        });
    },
    loadAssetGroups(queryId) {
      return axios.get('/query/' + queryId + '/partner').then(({ data }) => {
        axios
          .get('/partner/' + data.partnerId + '/asset-group/names')
          .then(({ data }) => {
            this.assetGroups = data;
          });
      });
    },

    loadOffer(queryId) {
      this.loadAssetGroups(queryId).then(() => {
        axios.get('/query/' + queryId + '/edit').then(({ data }) => {
          this.offerNumber = data.qassets[0].sellerOfferNr || '';
          this.totalPrice = 0;

          this.assets = [];

          for (var i = 0; i < data.qassets.length; i++) {
            this.totalPrice += data.qassets[i].qassetPrice;
            var asset = data.qassets[i];
            if (asset.assetGroupId !== null) {
              var assetGroupFound = this.assetGroups.find(
                group => group.assetGroupId === asset.assetSubgroupId,
              );
              if (assetGroupFound) {
                this.assets.push({
                  group: assetGroupFound,
                  assetPrice: asset.qassetPrice,
                });
              }
            }
          }

          // round this.totalPrice to 2 decimals using Math.round wit Number.EPSILON
          this.totalPrice =
            Math.round((this.totalPrice + Number.EPSILON) * 100) / 100;

          this.totalPriceWithVat = getPriceWithVat(this.totalPrice);

          // format date to estonian format like this 'dd.mm.yyyy hh24:mm'
          var date = new Date(data.modifiedDtime);
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          var hours = date.getHours();
          var minutes = date.getMinutes();
          if (day < 10) {
            day = '0' + day;
          }
          if (month < 10) {
            month = '0' + month;
          }
          if (hours < 10) {
            hours = '0' + hours;
          }
          if (minutes < 10) {
            minutes = '0' + minutes;
          }
          this.modifiedDtime =
            day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;

          this.contactEmail = data.contactEmail;
          this.contactPhone = data.contactPhone;

          // if contactPhone starts with +372, remove it
          if (this.contactPhone && this.contactPhone.startsWith('+372')) {
            this.contactPhone = this.contactPhone.substring(4);
          }

          this.customerName = data.custName;
          this.customerRegCode = data.custRegCode;

          this.selected = {
            name: this.customerName,
            companyCode: this.customerRegCode,
          };
        });

        axios
          .get('/document/' + queryId + '/find-all-by-query-id')
          .then(({ data }) => {
            this.attachments = data.map(attachment => {
              return {
                fileId: attachment.bucketFileId,
                fileName: attachment.fileName,
                docFileId: attachment.docFileId,
              };
            });
          });
      });
    },
    toggleDragging(event) {
      this.dragged = event.type === 'dragover';
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    uploadFiles(event) {
      this.uploading = true;
      const files = event.target.files || event.dataTransfer.files;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      axios
        .post('/selfserve/attachments', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data }) => {
          this.attachments.push(...data);
          this.uploading = false;
        });
    },
    setCompanyParams() {
      this.customerRegCode = this.selected.companyCode;
      this.customerName = this.selected.name;
    },
    async getCompaniesByName(query) {
      if (query.length > 2) {
        this.isLoading = true;
        this.GET_BY_NAME(query).then(() => {
          this.isLoading = false;
        });
      }
    },
    async getCompaniesByCode(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_COMPANY_CODE(query);
        this.isLoading = false;
      }
    },
  },
  data: function() {
    return {
      insuranceAmount: 0,
      extendedWarranty: 0,
      partner: null,
      user: null,
      contractFee: null,
      canSaveOffer: false,
      preview: null,
      totalPriceWithVat: null,
      totalPrice: null,
      offerNumber: '',
      assets: [{}],
      attachments: [],
      assetGroups: [],
      newAsset: {},
      selected: [],
      isLoading: false,
      contactEmail: null,
      contactPhone: null,
      generatingOffer: false,
      generatingOfferPdf: false,
      submitSuccessful: false,
      sendingOfferLink: false,
      customerRegCode: null,
      customerName: null,
      messageTitle: null,
      messageBody: null,
      queryId: null,
      modifiedDtime: null,
      dragged: false,
      customerInputStatus: 0,
      uploading: false,
      techError: null,
    };
  },
  watch: {
    totalPriceWithVat(value) {
      this.totalPriceWithVat = replaceComma(value);
    },
    totalPrice(value) {
      this.totalPrice = replaceComma(value);
    },
    queryId(value) {
      if (!value) {
        return;
      }
      if (value.length === 0) {
        return;
      }
      this.loadOffer(value);
    },
  },
  computed: {
    ...mapState('company', ['companies']),
    additionalAssetsPrice() {
      const additionalAssetsPrice =
        (this.totalPrice || 0) -
        (this.totalMainAssetsAmount || 0) -
        (this.insuranceAmount || 0) -
        (this.extendedWarranty || 0);

      return Number(additionalAssetsPrice.toFixed(2));
    },
    validToSubmit() {
      if (this.totalPrice <= 0) {
        return false;
      }

      if (
        this.totalMainAssetsAmount + this.totalAssetInsuranceAndWarrantyAmount >
        this.totalPrice
      ) {
        return false;
      }
      // check if asset groups are selected
      if (this.assets.length === 0) {
        return false;
      }

      if (this.offerNumber.length === 0) {
        return false;
      }

      // check if all asset groups have group selected
      if (this.assets.some(asset => !asset.group)) {
        return false;
      }

      // check if any asset has no price or price is 0 or less
      if (
        this.assets.some(asset => !asset.assetPrice || asset.assetPrice <= 0)
      ) {
        return false;
      }

      return true;
    },
    validToSend() {
      return (this.validToSubmit && this.contactPhone) || this.contactEmail;
    },
    numerifyWithDecimals() {
      return numerifyWithDecimals;
    },

    totalAssetInsuranceAndWarrantyAmount() {
      return (
        Number(this.insuranceAmount || 0) + Number(this.extendedWarranty || 0)
      );
    },

    totalAllAssetsAmount() {
      // summary of all main assets and additional assets
      return (
        this.totalAssetInsuranceAndWarrantyAmount + this.totalMainAssetsAmount
      );
    },

    totalMainAssetsAmount() {
      // summary of all main assets
      return this.assets.reduce((acc, asset) => {
        return acc + parseFloat(asset.assetPrice);
      }, 0);
    },
  },
};
</script>
