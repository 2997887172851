import axios from 'axios';
import i18n from '../../i18n';

export const GET_QUERY_DOCUMENTS = 'GET_QUERY_DOCUMENTS';
export const UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_CONTAINER = 'DOWNLOAD_CONTAINER';
export const DOWNLOAD_SELECTED_FILES = 'DOWNLOAD_SELECTED_FILES';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
const SET_DOCUMENTS = 'SET_DOCUMENTS';
const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
const RESET_DOCUMENTS = 'RESET_DOCUMENTS';
const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';

const state = {
  documents: [],
};

export const actions = {
  async [GET_QUERY_DOCUMENTS]({ commit }, payload) {
    const response = await axios.get(
      '/document/' + payload + '/find-all-by-query-id',
    );
    commit('SET_DOCUMENTS', response.data);
  },
  async [UPLOAD_DOCUMENTS]({ commit }, payload) {
    const formData = new FormData();
    for (let i = 0; i < payload.files.length; i++) {
      let file = payload.files[i];
      formData.append('files', file);
    }
    const response = await axios.post(
      '/document/' + payload.queryId + '/upload-documents',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    commit('UPDATE_DOCUMENTS', response.data);
  },
  async [DOWNLOAD_CONTAINER]({ commit }, { queryId, containerUUID, fileName }) {
    await axios
      .get('/query/' + queryId + '/containers/' + containerUUID, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
      });
  },
  async [DOWNLOAD_FILE]({ commit }, payload) {
    await axios
      .get('/document/' + payload + '/get-file', {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers.filename);
        link.click();
      });
  },
  async [DOWNLOAD_SELECTED_FILES]({ commit }, payload) {
    await axios
      .get('/document/get-selected-files/' + payload.toString(), {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers.filename);
        link.click();
      });
  },
  async [DELETE_DOCUMENT]({ commit, dispatch }, payload) {
    await axios.post('/document/delete', payload.id, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      'alert/ALERT',
      { message: i18n.t('DOCUMENT_DELETED'), type: 'success' },
      { root: true },
    );
    commit(REMOVE_DOCUMENT, payload.index);
  },
};

export const mutations = {
  [SET_DOCUMENTS](state, payload) {
    state.documents = payload;
  },
  [UPDATE_DOCUMENTS](state, payload) {
    state.documents = [...state.documents, ...payload];
  },
  [RESET_DOCUMENTS](state) {
    state.documents = [];
  },
  [REMOVE_DOCUMENT](state, payload) {
    state.documents.splice(payload, 1);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
