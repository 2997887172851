import axios from 'axios';
import i18n from '../../i18n';

export const SAVE_SURETIES = 'SAVE_SURETIES';
export const DELETE_SURETY = 'DELETE_SURETY';

const state = {};

export const actions = {
  async [SAVE_SURETIES]({ commit }, payload) {
    const response = await axios.post(
      '/surety/' + payload.queryId + '/save-sureties',
      payload.sureties,
    );
    commit('query/SET_QUERY_SURETIES', response.data, { root: true });
  },
  async [DELETE_SURETY]({ dispatch }, payload) {
    await axios.post('/surety/delete', payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      'alert/ALERT',
      { message: i18n.t('SURETY_DELETED'), type: 'success' },
      { root: true },
    );
  },
};

export const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
