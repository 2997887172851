var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mt-4 card-padding-md d-flex"},[_c('h2',{staticClass:"card-title card-title-pulled-left flex"},[_c('span',[_c('figure',{staticClass:"card-title-icon"},[_c('img',{staticClass:"card-title-image",attrs:{"src":require("../../assets/package-icon.svg"),"alt":"terms"}})]),_vm._v(" "+_vm._s(_vm.$t('TERMS'))+" ")]),_c('div',{staticClass:"card-title-pulled-right"},[_c('b-icon',{attrs:{"id":"tooltip-target-1","icon":"question-circle"}})],1)]),_c('b-list-group',{staticClass:"terms"},[_c('b-tooltip',{attrs:{"target":"tooltip-target-1","boundary-padding":"0","placement":"bottom","variant":"dark","triggers":"hover"}},[_c('b-table',{attrs:{"striped":"","items":_vm.refTable,"head-variant":"dark","dark":"","fields":['Periood', '12', '24', '36', '48']}})],1),(this.partnerSettings.regCode === '10069046')?_c('b-list-group-item',[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-right",attrs:{"md":"3"}},[_c('span',[_vm._v("0% kampaania")])]),_c('b-col',{attrs:{"md":"9"}},[_c('div',{staticClass:"btn-group"},[_c('b-form-checkbox',{staticClass:"mt-0",attrs:{"value":true,"unchecked-value":false,"disabled":_vm.disableOnStatus},on:{"change":function($event){return _vm.toggle0Interest()}},model:{value:(_vm.zeroInterestCampaign),callback:function ($$v) {_vm.zeroInterestCampaign=$$v},expression:"zeroInterestCampaign"}})],1)])],1)],1):_vm._e(),_c('b-list-group-item',[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-right",attrs:{"md":"3"}},[_c('span',[_vm._v(_vm._s(_vm.$t('PERIOD'))+" ("+_vm._s(_vm.$t('MONTHS'))+")")])]),_c('b-col',{attrs:{"md":"9"}},[_c('b-button-group',_vm._l((_vm.paymentPeriodOptions),function(option,index){return _c('b-button',{key:index,class:{ active: option.isSelected },attrs:{"disabled":_vm.disableOnStatus},on:{"click":function($event){_vm.changeOption(_vm.paymentPeriodOptions, option, 'paymentPeriod'),
                  _vm.calculatePmt()}}},[_c('span',[_vm._v(_vm._s(option.value))])])}),1)],1)],1)],1),_c('b-list-group-item',[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-right",attrs:{"md":"3"}},[_c('span',[_vm._v(_vm._s(_vm.$t('RESIDUAL'))+" (%)")])]),_c('b-col',{attrs:{"md":"9"}},[_c('b-button-group',[_vm._l((_vm.residualOptions),function(option,index){return _c('b-button',{key:index,class:{ active: option.isSelected },attrs:{"disabled":_vm.disableOnStatus},on:{"click":function($event){_vm.changeOption(_vm.residualOptions, option, 'residual'),
                  _vm.calculatePmt()}}},[_c('span',[_vm._v(_vm._s(option.value))])])}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.residualInput),expression:"residualInput",modifiers:{"trim":true}}],class:[
                'terms-input',
                { active: !_vm.updateActive(_vm.residualOptions, _vm.value.residual) } ],attrs:{"type":"text","placeholder":"...","disabled":_vm.disableOnStatus},domProps:{"value":(_vm.residualInput)},on:{"focusout":function($event){_vm.updateActive(
                  _vm.residualOptions,
                  _vm.residualInput,
                  'residual',
                  _vm.partnerSettings.residualOptions
                ),
                  _vm.calculatePmt()},"keyup":function($event){return _vm.numerifyWithDecimals('residualInput')},"input":function($event){if($event.target.composing){ return; }_vm.residualInput=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})],2)],1)],1)],1),_c('b-list-group-item',[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-right",attrs:{"md":"3"}},[_c('span',[_vm._v(_vm._s(_vm.$t('CONTRACT_FEE'))+" (€)")]),_c('b-icon',{staticClass:"pl-0 mb-2 ml-1",attrs:{"id":"tt-12","icon":"question-circle"}}),_c('b-tooltip',{attrs:{"target":"tt-12","variant":"dark","triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t('CONTRACT_FEE_TOOLTIP'))+" ")])],1),_c('b-col',{attrs:{"md":"9"}},[_c('b-button-group',[_vm._l((_vm.contractFeeOptions),function(option,index){return _c('b-button',{key:index,class:{ active: option.isSelected },attrs:{"disabled":_vm.disableOnStatus},on:{"click":function($event){_vm.changeOption(_vm.contractFeeOptions, option, 'contractFee'),
                  _vm.calculatePmt()}}},[_c('span',[_vm._v(_vm._s(option.value))])])}),(_vm.partnerSettings.editContractFee)?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.contractFeeInput),expression:"contractFeeInput",modifiers:{"trim":true}}],class:[
                'terms-input',
                {
                  active: !_vm.updateActive(
                    _vm.contractFeeOptions,
                    _vm.value.contractFee
                  ),
                } ],attrs:{"type":"text","placeholder":"...","disabled":_vm.disableOnStatus},domProps:{"value":(_vm.contractFeeInput)},on:{"focusout":function($event){_vm.updateActive(
                  _vm.contractFeeOptions,
                  _vm.contractFeeInput,
                  'contractFee',
                  _vm.partnerSettings.contractFeeOptions
                ),
                  _vm.calculatePmt()},"keyup":function($event){return _vm.numerifyWithDecimals('contractFeeInput')},"input":function($event){if($event.target.composing){ return; }_vm.contractFeeInput=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}):_vm._e()],2)],1)],1)],1)],1),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"total-terms-badge-group"},[_c('div',{staticClass:"total-terms-badge"},[_c('span',{staticClass:"total-terms-text"},[_vm._v(_vm._s(_vm.$t('TOTAL_MONTHLY')))]),_c('div',{staticClass:"total-terms-tag total-terms-tag-white"},[_vm._v(" "+_vm._s(_vm.paymentWithoutVat())+" € ")])]),_c('div',{staticClass:"total-terms-badge"},[_c('span',{staticClass:"total-terms-text"},[_vm._v(_vm._s(_vm.$t('VAT')))]),_c('div',{staticClass:"total-terms-tag total-terms-tag-white"},[_vm._v(" "+_vm._s(_vm.paymentVat())+" € ")])]),_c('div',{staticClass:"total-terms-badge"},[_c('span',{staticClass:"total-terms-text"},[_vm._v(_vm._s(_vm.$t('TOTAL')))]),_c('div',{staticClass:"total-terms-tag total-terms-tag-white"},[_vm._v(" "+_vm._s(_vm.paymentWithVat())+" € ")])])])]),_c('PaymentTableModal',{attrs:{"show":_vm.isPaymentModalOpen,"value":_vm.value,"lendifyInsurance":_vm.lendifyInsurance},on:{"hide":_vm.togglePaymentModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }