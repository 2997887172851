<template>
  <b-card class="mt-3 card-padding-md">
    <div class="d-flex justify-content-between">
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('ASSETS') }}
        </span>
      </h2>

      <div>
        <div class="badge bg-light p-2" v-if="assetDeliveredAt">
          Partneri poolt üle antud: <span>{{ assetDeliveredAt }}</span>
        </div>
        <div class="badge bg-light p-2 ml-2" v-if="assetReceivedAt">
          Kliendi poolt vastu võetud: <span>{{ assetReceivedAt }}</span>
        </div>
      </div>
    </div>
    <div class="table-box">
      <b-table
        striped
        hover
        :items="value.qassets"
        :fields="qassetFields"
        foot-clone
        no-footer-sorting
      >
        <template #cell(assetGroupId)="assetGroupId">
          {{
            (
              assetGroups.find(
                assetGroup => assetGroup.assetGroupId == assetGroupId.value,
              ) || {}
            ).name
          }}
        </template>
        <template #cell(assetSubgroupId)="subgroupId">
          {{
            (
              assetGroups.find(
                assetGroup => assetGroup.assetGroupId == subgroupId.value,
              ) || {}
            ).name
          }}
        </template>
        <template
          v-slot:[`foot(${row.key})`]="{}"
          v-for="(row, index) in qassetFields"
        >
          <div
            :key="index"
            v-if="row.key === 'qassetPrice'"
            class="total-price"
          >
            Total Price: <b>{{ totalQassetPmt }} €</b>
          </div>
          <div :key="index" v-else></div>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'AssetsInfo',
  props: ['value', 'totalQassetPmt'],
  data() {
    return {
      qassetFields: [
        {
          key: 'qassetName',
          label: this.$t('QASSET_NAME'),
          sortable: true,
        },
        {
          key: 'quantity',
          label: this.$t('QUANTITY'),
          sortable: true,
        },
        {
          key: 'assetGroupId',
          label: this.$t('ASSET_GROUP'),
        },
        {
          key: 'assetSubgroupId',
          label: this.$t('ASSET_SUB_GROUP'),
        },
        {
          key: 'qassetPrice',
          label: this.$t('QASSET_PRICE_NO_VAT'),
          sortable: true,
        },
      ],
      assetGroups: [],
    };
  },
  computed: {
    assetReceivedAt() {
      const qassets = this.value.qassets;
      const qasset = qassets.find(qasset => qasset.receivedDate);
      if (!qasset) return null;
      return qasset.receivedDate;
    },
    assetDeliveredAt() {
      const qassets = this.value.qassets;
      const qasset = qassets.find(qasset => qasset.deliveryDate);
      if (!qasset) return null;
      return qasset.deliveryDate;
    },
  },
  async mounted() {
    const { data: foundAssetGroups } = await this.$store.dispatch(
      'findAllAssetGroups',
    );
    this.assetGroups = foundAssetGroups;
  },
};
</script>

<style></style>
