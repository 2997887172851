<template>
  <b-card class="mt-4 mb-5">
    <h2 class="card-title card-title-pulled-left">
      <span>
        <figure class="card-title-icon">
          <img src="../../assets/package-icon.svg" class="card-title-image" />
        </figure>
        {{ $t('LENDIFY') }}
      </span>
    </h2>

    <div class="client-info">
      <b-row>
        <b-col>
          <b-list-group>
            <b-list-group-item>
              <div>{{ $t('LENDIFY_CONTRACT_NUMBER') }}:</div>
              <div>{{ value.lendifyRefno }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('LENDIFY_CONTRACT_END_DATE') }}:</div>
              <div>{{ value.lendifyEndDate }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('LENDIFY_CONTRACT_GUARANTOR_LIABILITY') }}:</div>
              <div>{{ value.lendifyGuarantorLiability }}</div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <div class="card-footer">
        <b-row align-h="center" class="mt-3">
          <b-col md="6">
            <b-btn
              class="btn-shadow-corner"
              @click="generateLendifyContract()"
              block
            >
              <img
                src="../../assets/docs1.svg"
                alt="download"
                class="btn-image"
              />
              {{ $t('GENERATE_LENDIFY_CONTRACT') }}</b-btn
            >
          </b-col>
        </b-row>
      </div>
    </div>
    <RenderDeleteModal
      :show="showLendifyModal"
      @ok="closeLendifyModal(true)"
      @cancel="closeLendifyModal()"
      :label="$t('ARE_YOU_SURE_GENERATE_ANOTHER_LENDIFY_CONTRACT')"
    />
  </b-card>
</template>

<script>
import { RenderDeleteModal } from '@/components/common';
import { GENERATE_LENDIFY_CONTRACT } from '../../store/query';
import { mapActions } from 'vuex';

export default {
  name: 'LendifyContract',
  props: ['value'],
  components: {
    RenderDeleteModal,
  },
  data() {
    return {
      showLendifyModal: false,
    };
  },
  methods: {
    ...mapActions('query', [GENERATE_LENDIFY_CONTRACT]),
    async generateLendifyContract() {
      if (this.value.lendifyRefno === null) {
        await this.GENERATE_LENDIFY_CONTRACT(this.value.queryId);
      } else {
        this.showLendifyModal = true;
      }
    },
    async closeLendifyModal(generateLendifyContract) {
      this.showLendifyModal = false;
      if (generateLendifyContract) {
        await this.GENERATE_LENDIFY_CONTRACT(this.value.queryId);
      }
    },
  },
};
</script>

<style></style>
