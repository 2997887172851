<template>
  <div class="table-box mt-3">
    <RenderDeleteModal
      :show="showDeleteModal"
      @ok="closeDeleteModal(true)"
      @cancel="closeDeleteModal()"
      :label="$t('ARE_YOU_SURE_DELETE_ASSET')"
    />
    <b-table
      striped
      :items="value.qassets"
      :fields="qassetFields"
      foot-clone
      no-footer-sorting
    >
      <template #cell(qassetPriceWithVat)="row">
        {{ getPriceWithVat(row.item.qassetPrice) }}
      </template>
      <template #cell(actions)="row">
        <div class="actions">
          <a
            :title="$t('EDIT')"
            @click.stop="row.toggleDetails"
            v-b-tooltip.hover
          >
            <img src="../../assets/edit-icon.svg" />
          </a>
          <a
            :class="{ 'action--disabled': disableOnStatus }"
            @click="openDeleteModal(row.item.qassetId, row.index)"
            v-b-tooltip.hover
            :title="$t('DELETE')"
          >
            <img src="../../assets/delete-icon.svg" />
          </a>
        </div>
      </template>
      <template
        v-slot:[`foot(${row.key})`]="{}"
        v-for="(row, index) in qassetFields"
      >
        <span v-if="row.key === 'qassetPrice'" :key="index" class="total-price">
          {{ $t('TOTAL_PRICE') }}:
          <strong>{{ totalQassetPmt }} &euro;</strong>
        </span>
        <span v-else :key="index"></span>
      </template>
      <template #row-details="row">
        <EditAssetForm
          :row="row"
          v-model="value"
          :partnerSettings="partnerSettings"
          :viewName="viewName"
          :isInhouse="isInhouse"
          :disableOnStatus="disableOnStatus"
          @saveQuery="saveQuery"
          :disableDeliveryAndSellerInfo="disableDeliveryAndSellerInfo"
          @calculatePmt="$emit('calculatePmt')"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { DELETE_QASSET } from '../../store/qasset';
import { EditAssetForm, RenderDeleteModal } from '@/components/common';
import { getPriceWithVat } from '../../utils';

export default {
  name: 'AssetsTable',
  components: {
    EditAssetForm,
    RenderDeleteModal,
  },
  props: {
    value: Object,
    partnerSettings: Object,
    viewName: String,
    disableOnStatus: Boolean,
    disableDeliveryAndSellerInfo: Boolean,
    isInhouse: Boolean,
    totalQassetPmt: String,
    saveQuery: Function,
  },
  data() {
    return {
      showDeleteModal: false,
      selectedQuery: 0,
      selectedQueryIndex: 0,
      qassetFields: [
        {
          key: 'qassetName',
          label: this.$t('QASSET_NAME'),
          sortable: true,
        },
        {
          key: 'quantity',
          label: this.$t('QUANTITY'),
          sortable: true,
        },
        {
          key: 'qassetPriceWithVat',
          label: this.$t('QASSET_PRICE_WITH_VAT_SHORT'),
          sortable: true,
        },
        {
          key: 'qassetPrice',
          label: this.$t('QASSET_PRICE_NO_VAT_SHORT'),
          sortable: true,
        },
        {
          key: 'sellerName',
          label: this.$t('SELLER_NAME'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('ACTIONS'),
        },
      ],
    };
  },
  methods: {
    ...mapActions('qasset', [DELETE_QASSET]),
    openDeleteModal(id, index) {
      this.showDeleteModal = true;
      this.selectedQuery = id;
      this.selectedQueryIndex = index;
    },
    closeDeleteModal(doDelete) {
      this.showDeleteModal = false;
      if (doDelete) {
        this.deleteQasset({
          id: this.selectedQuery,
          index: this.selectedQueryIndex,
        });
      }
      this.selectedQuery = 0;
      this.selectedQueryIndex = 0;
    },
    async deleteQasset(payload) {
      if (this.disableOnStatus) return;
      await this.DELETE_QASSET(payload.id);
      this.value.qassets.splice(payload.index, 1);
      this.$emit('calculatePmt');
    },
    getPriceWithVat(price) {
      return getPriceWithVat(price);
    },
  },
};
</script>
