<template>
  <b-form-group
    :label="$t('CUSTOMER_LIMIT')"
    label-for="limit"
    class="mb-0 ml-2"
    v-if="this.show"
  >
    <b-form-input
      readonly
      size="sm"
      type="text"
      id="limit"
      name="limit"
      style="width: 100px;"
      v-model.trim="limit"
    >
    </b-form-input>
  </b-form-group>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Limit',
  mixins: [],
  timer: null,
  props: {
    regno: String,
    queryStatus: String,
    startNewLimitRequest: Boolean,
    isSuretyRequired: Boolean,
    sureties: Array,
    representatives: Array,
    suretyRequiredCallback: Function,
    timeoutCallback: Function,
    availableLimit: Number,
    maxContractAmount: Number,
    limitBlocked: Boolean,
    lastResult: String,
    lastResultAt: String,
  },

  data: function() {
    return {
      show: false,
      limit: null,
      updatedAt: null,
    };
  },
  async mounted() {
    this.interval = setInterval(
      function() {
        this.loadData(this.regno);
      }.bind(this),
      2000,
    );
  },

  watch: {
    updatedAt(nv, ov) {
      if (nv !== ov) {
        this.$emit('update:startNewLimitRequest', false);
        if (this.timer) {
          clearTimeout(this.timer);
        }
      }
    },

    startNewLimitRequest(val) {
      if (val) {
        this.timer = setTimeout(() => {
          this.$emit('update:startNewLimitRequest', false);
          this.$emit('timeoutCallback');
        }, 9000);
      }
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    async loadData(val) {
      if (typeof val !== 'undefined' && val !== null && val.length > 7) {
        axios.get('limits/' + val).then(({ data }) => {
          if (data.available_limit !== null) {
            let blocked = data.status === 'blocked';
            this.limit = Math.max(0, data.available_limit) + ' €';
            this.show = true;
            this.$emit('update:limit', this.limit);
            this.$emit(
              'update:availableLimit',
              Math.max(0, data.available_limit),
            );
            this.$emit('update:maxContractAmount', data.max_contract_amount);
            this.$emit('update:limitBlocked', blocked);
            if (blocked) {
              this.limit = 'Blokeeritud';
            }
            if (data.sureties.length > 0) {
              this.$emit('update:isSuretyRequired', true);
              this.$emit('update:sureties', data.sureties);
              if (this.suretyRequiredCallback !== null) {
                this.$emit('suretyRequiredCallback');
              }
            } else {
              this.$emit('update:isSuretyRequired', false);
              this.$emit('update:sureties', []);
            }

            this.updatedAt = data.updated_at;
            this.$emit('update:updatedAt', data.updated_at);

            if (data.representatives && data.representatives.length > 0) {
              this.$emit('update:representatives', data.representatives);
            } else {
              this.$emit('update:representatives', []);
            }
          } else {
            this.show = false;
            this.$emit('update:isSuretyRequired', false);
            this.$emit('update:limitBlocked', false);
            this.$emit('update:sureties', []);
            this.$emit('update:availableLimit', null);
            this.$emit('update:maxContractAmount', null);
          }

          if (data.last_result !== null) {
            this.$emit('update:lastResult', data.last_result);
            this.$emit('update:lastResultAt', data.last_result_at);
          }
        });
      } else {
        this.limit = null;
        this.show = false;
        this.$emit('update:limitBlocked', false);
        this.$emit('update:availableLimit', null);
        this.$emit('update:maxContractAmount', null);
        this.$emit('update:isSuretyRequired', false);
        this.$emit('update:sureties', []);
      }
    },
  },
  computed: {},
};
</script>
