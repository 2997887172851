<template>
  <div>
    <Header :header="$t('CREATE_QUERY')"> </Header>
    <b-tabs v-model="tabIndex">
      <b-tab :disabled="tabIndex !== 0 && hasErrorFields">
        <template slot="title">
          <div @click="saveQuery()" id="clientTabNav" class="tab-nav-button">
            <figure class="nav-figure">
              <svg
                class="nav-image"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <path
                  d="M256,253.7c70.1,0.6,127.3-55.7,127.9-125.8C384.5,57.9,328.2,0.6,258.1,0C188-0.6,130.8,55.7,130.2,125.8  c0,0.3,0,0.7,0,1C130,196.6,186.3,253.3,256,253.7z M256,25.7c55.9-0.5,101.5,44.4,102,100.3c0.5,55.9-44.4,101.5-100.3,102  c-55.9,0.5-101.5-44.4-102-100.3c0-0.3,0-0.6,0-0.9C155.5,71.3,200.4,26,256,25.7L256,25.7z M256,305.1  c101.5,0.5,185.3,79.6,191.7,180.9H153.1c-7.1,0.3-12.6,6.3-12.3,13.4c0.3,6.7,5.6,12.1,12.3,12.3h307.8c7.1,0,12.8-5.8,12.7-12.9  c0,0,0,0,0,0c0-120.2-97.5-217.7-217.7-217.7S38.3,378.7,38.3,498.9c0,7.1,5.7,12.8,12.7,12.9l0,0h51c7.1-0.3,12.6-6.3,12.3-13.4  c-0.3-6.7-5.6-12.1-12.3-12.3H64.2C70.6,384.7,154.4,305.7,256,305.1L256,305.1L256,305.1z"
                  class="st0"
                ></path>
              </svg>
            </figure>
            <span class="nav-text">{{ $t('CLIENT') }}</span>
          </div>
          <b-tooltip
            v-if="hasErrorFields"
            class="error-tooltip"
            target="clientTabNav"
            :title="$t('VALIDATION.FIX_ERRORS')"
          />
        </template>
        <ShopClientTab
          v-model="currentQuery"
          @next="saveAndGoNext"
          @saveQuery="saveQuery"
          :partnerSettings="partnerSettings"
          :viewName="viewName"
          :inHousePartners="inHousePartners"
          :isInhouse="isInhouse"
        />
      </b-tab>

      <b-tab :disabled="tabIndex !== 1 && hasErrorFields">
        <template slot="title">
          <div
            @click="saveQuery()"
            id="transactionTabNav"
            class="tab-nav-button"
          >
            <figure class="nav-figure">
              <svg
                class="nav-image"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <path
                  id="Shape_5_copy"
                  d="M503.9,400.3c0-7.1-5.7-12.8-12.7-12.9c0,0,0,0,0,0H55.7l76.5-77c5-5,5-13.1,0-18.1  c-4.9-5-12.9-5-17.9-0.1c0,0-0.1,0.1-0.1,0.1l-98.3,98.9c-5,5-5,13.1,0,18.1l98.3,98.9c4.9,5,13,5,18,0.1c0,0,0.1-0.1,0.1-0.1  c5-5,5-13.1,0-18.1l-76.5-77h435.5C498.3,413,503.9,407.3,503.9,400.3L503.9,400.3z M500.1,102.6L401.8,3.8c-4.9-5-12.8-5-17.8-0.2  c-0.1,0.1-0.1,0.1-0.2,0.2c-5,5-5,13.1,0,18.1c0,0,0,0,0,0l76.5,77H24.9c-7.1,0.3-12.6,6.3-12.4,13.4c0.3,6.7,5.6,12.1,12.4,12.4  h435.5l-76.5,76.8c-5,5-5,13.1,0,18.1c0,0,0,0,0,0c4.9,5,13,5,18,0.1c0,0,0.1-0.1,0.1-0.1l98.3-98.9  C505.1,115.6,505.1,107.6,500.1,102.6z"
                  class="st0"
                ></path>
              </svg>
            </figure>
            <span class="nav-text">{{ $t('TRANSACTION') }}</span>
          </div>
          <b-tooltip
            v-if="hasErrorFields"
            class="error-tooltip"
            target="transactionTabNav"
            :title="$t('VALIDATION.FIX_ERRORS')"
          />
        </template>
        <ShopTransactionTab
          v-model="currentQuery"
          v-if="partnerSettings !== null && inHousePartners !== null"
          :partnerSettings="partnerSettings"
          :viewName="viewName"
          :documents="currentDocuments"
          :inHousePartners="inHousePartners"
          :canCreateQuickContract="canCreateQuickContract"
          @next="saveAndGoNext"
          @saveQuery="saveQuery"
          @changeStatus="changeStatus"
        >
        </ShopTransactionTab>
      </b-tab>

      <b-tab :disabled="tabIndex !== 4 && hasErrorFields">
        <template slot="title">
          <div @click="saveQuery()" id="statusTabNav" class="tab-nav-button">
            <figure class="nav-figure">
              <svg
                class="nav-image"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <circle cx="256.3" cy="59.1" r="14.8" class="st0"></circle>
                </g>
                <g>
                  <path
                    d="M452.9,118.2c0-21.8-17.6-39.4-39.4-39.4h-54.7c-7.3-8.1-16.7-14.3-27.4-17.4c-9.1-2.6-16-9.6-18.7-18.7    C305.7,18,283,0,256,0c-27,0-49.7,18-56.8,42.7c-2.6,9.1-9.6,16-18.7,18.7c-10.8,3.1-20.1,9.3-27.4,17.4H98.5    c-21.8,0-39.4,17.6-39.4,39.4v354.5c0,21.8,17.6,39.4,39.4,39.4c90.4,0,201.5,0,315.1,0c21.8,0,39.4-17.6,39.4-39.4    C452.9,360.8,452.9,253.7,452.9,118.2z M157.5,117.8c0.1-17.3,11.8-32.7,28.4-37.5c15.6-4.5,27.7-16.5,32.2-32.1    c4.8-16.8,20.4-28.5,37.9-28.5c17.5,0,33,11.7,37.9,28.5c4.5,15.6,16.5,27.6,32.1,32.1c16.8,4.8,28.5,20.4,28.5,37.9v19.7H157.5    V117.8z M413.5,492.3c-105.2,0-210.2,0-315.1,0c-10.9,0-19.7-8.8-19.7-19.7V118.2c0-10.9,8.8-19.7,19.7-19.7h42.8    c-2.1,6-3.3,12.5-3.4,19.2v39.9h236.3v-39.4c0-6.9-1.2-13.5-3.4-19.7h42.8c10.9,0,19.7,8.8,19.7,19.7c0,56.3,0,320.3,0,354.5    C433.2,483.5,424.4,492.3,413.5,492.3z"
                    class="st0"
                  ></path>
                </g>
                <g>
                  <polygon
                    points="337.9,239.4 219.5,357.8 167.3,305.6 153.4,319.5 219.5,385.6 351.8,253.3   "
                    class="st0"
                  ></polygon>
                </g>
              </svg>
            </figure>
            <span class="nav-text">{{ $t('STATUS') }}</span>
          </div>
          <b-tooltip
            v-if="hasErrorFields"
            class="error-tooltip"
            target="statusTabNav"
            :title="$t('VALIDATION.FIX_ERRORS')"
          />
        </template>
        <StatusTab
          v-model="currentQuery"
          :statusHistory="statusHistory"
          :documents="currentDocuments"
          :viewName="viewName"
        />
      </b-tab>
    </b-tabs>

    <ShopSendQueryModal />
  </div>
</template>

<script>
import { StatusTab } from '@/components';

import ShopClientTab from './ShopClientTab';
import ShopTransactionTab from './ShopTransactionTab';
import ShopSendQueryModal from './ShopSendQueryModal';

import { Header } from '@/components/common';
import { formatQuery } from '../../utils';
import { mapActions, mapState } from 'vuex';
import {
  GET_NEW_QUERY,
  SAVE_QUERY,
  GET_QUERY_STATUS_HISTORY,
  CHANGE_QUERY_STATUS,
  GET_QUICK_CONTRACT_STATUS,
} from '../../store/query';
import { SAVE_QASSETS } from '../../store/qasset';
import { SAVE_SURETIES } from '../../store/surety';
import axios from 'axios';

export default {
  name: 'ShopCreateQuery',
  components: {
    ShopClientTab,
    ShopTransactionTab,
    StatusTab,
    ShopSendQueryModal,
    Header,
  },
  data() {
    return {
      currentQuery: {},
      currentDocuments: [],
      partnerSettings: null,
      viewName: 'shop',
      pmtScheduleRows: [],
      alertMessage: '',
      alert: false,
      tabIndex: 0,
      inHousePartners: [],
      canCreateQuickContract: false,
      isInhouse: JSON.parse(window.localStorage.getItem('isInhouse')),
    };
  },
  watch: {
    query: {
      handler: function() {
        if (this.query) {
          this.currentQuery = JSON.parse(JSON.stringify(this.query));
        }
      },
      deep: true,
    },
    documents() {
      if (this.documents) {
        this.currentDocuments = JSON.parse(JSON.stringify(this.documents));
      }
    },
  },
  created() {
    axios.get('user/get-logged-in-user').then(({ data }) => {
      this.partnerSettings = data.partner;
      let payload = {
        product: 'ONSPOT',
        ...data.partner,
      };
      this.GET_NEW_QUERY(payload)
        .then(() => {
          this.$store.dispatch('findAllInhousePartners').then(response => {
            this.inHousePartners = response.data;
          });
        })
        .catch(error => {
          console.log(error);
        });
    });
  },
  methods: {
    ...mapActions('query', [
      GET_NEW_QUERY,
      SAVE_QUERY,
      GET_QUERY_STATUS_HISTORY,
      CHANGE_QUERY_STATUS,
      GET_QUICK_CONTRACT_STATUS,
    ]),
    ...mapActions('qasset', [SAVE_QASSETS]),
    ...mapActions('surety', [SAVE_SURETIES]),
    async saveQuery(cb) {
      if (this.hasErrorFields) return;
      await this.SAVE_QUERY(formatQuery(this.currentQuery)).then(() => {
        this.GET_QUICK_CONTRACT_STATUS(this.currentQuery.queryId).then(r => {
          this.canCreateQuickContract = r.data;
        });
      });
      if (typeof cb === 'function') cb();
    },
    generateSchedule: function() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('generatePmtSchedule', {
            assets: this.query.qassets,
            services: this.query.services,
            sureties: this.query.sureties,
            advancePmt: this.query.advance,
            advnPmtType: this.query.advnPmtType,
            rvValue: this.query.residual,
            intrTotal: this.query.interest,
            leasePeriod: this.query.paymentPeriod,
            pmtDate: this.query.pmtDate,
            contractValue: this.query.contractFee,
            insuranceValue: this.query.insurance,
          })
          .then(response => {
            this.pmtScheduleRows = response.data;
            resolve();
          })
          .catch(error => {
            this.alertMessage = error.response.data.message;
            this.alert = true;
            reject(error);
          });
      });
    },
    async changeStatus(payload) {
      try {
        this.currentQuery.status = await this.CHANGE_QUERY_STATUS(payload);
        await this.saveAndGoNext();
        await this.GET_QUERY_STATUS_HISTORY(this.currentQuery.queryId);
      } catch (e) {
        console.error(e);
      }
    },
    saveAndGoNext: function(index) {
      this.saveQuery().then(() => {
        if (this.isInhouse) {
          this.tabIndex = this.tabIndex + 1;
        } else {
          this.tabIndex = this.tabIndex + (index || 3);
        }
        scroll(0, 0);
      });
    },
  },
  computed: {
    ...mapState('query', ['query', 'statusHistory']),
    ...mapState('document', ['documents']),
    hasErrorFields() {
      return !!this.errors.items[0];
    },
  },
};
</script>

<style scoped></style>
