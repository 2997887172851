import axios from 'axios';
import store from '../store';
import i18n from '../../i18n';

export const GET_QUERY = 'GET_QUERY';
export const SAVE_QUERY = 'SAVE_QUERY';
export const GET_QUERY_STATUS_HISTORY = 'GET_QUERY_STATUS_HISTORY';
export const CHANGE_QUERY_STATUS = 'CHANGE_QUERY_STATUS';
export const GET_NEW_QUERY = 'GET_NEW_QUERY';
export const CHANGE_MANAGER = 'CHANGE_MANAGER';
export const CALCULATE_PAYMENT = 'CALCULATE_PAYMENT';
export const GET_ACTUAL_BENEFICIARY = 'GET_ACTUAL_BENEFICIARY';
export const GENERATE_LENDIFY_CONTRACT = 'GENERATE_LENDIFY_CONTRACT';
export const CREATE_SHOP_CONTRACT = 'CREATE_SHOP_CONTRACT';
export const GET_GUARANTOR = 'GET_GUARANTOR';
export const GET_QUICK_CONTRACT_STATUS = 'GET_QUICK_CONTRACT_STATUS';
export const SET_DELIVERY_DATE = 'SET_DELIVERY_DATE';

const SET_QUERY = 'SET_QUERY';
const SET_QUERY_ASSETS = 'SET_QUERY_ASSETS';
const SET_QUERY_SURETIES = 'SET_QUERY_SURETIES';
const SET_QUERY_STATUS_HISTORY = 'SET_QUERY_STATUS_HISTORY';
const SET_QUERY_STATUS = 'SET_QUERY_STATUS';
const SET_NEW_QUERY = 'SET_NEW_QUERY';
const SET_ACTUAL_BENEFICIARY = 'SET_ACTUAL_BENEFICIARY';
const SET_GUARANTOR = 'SET_GUARANTOR';

const state = {
  query: {},
  localQuery: {},
  statusHistory: [],
  canCreateQuickContract: false,
};

export const actions = {
  async [SET_DELIVERY_DATE]({ commit, dispatch }, payload) {
    return await axios.post(
      '/shop/' + payload.queryId + '/set-delivery-date',
      payload.deliveryDate,
    );
  },
  async [GET_QUICK_CONTRACT_STATUS]({ commit, dispatch }, queryId) {
    return await axios.get('/shop/' + queryId + '/can-create-quick-contract');
  },
  async [GET_QUERY]({ commit, dispatch }, payload) {
    const response = await axios.get('/query/' + payload + '/edit');
    await dispatch('GET_ACTUAL_BENEFICIARY', payload);
    await dispatch('GET_GUARANTOR', payload);
    commit(SET_QUERY, { ...state.localQuery, ...response.data });
  },
  async [SAVE_QUERY]({ commit, dispatch }, payload) {
    await axios.post('/query/save', payload).then(response => {
      state.localQuery = response.data;
    });

    await dispatch(
      'actualBeneficiary/SAVE_ACTUAL_BENEFICIARY',
      { ...payload.actualBeneficiary, queryId: state.localQuery.queryId },
      { root: true },
    );
    await dispatch(
      'guarantor/SAVE_GUARANTOR',
      { ...payload.guarantor, queryId: state.localQuery.queryId },
      { root: true },
    );
    await dispatch('qasset/SAVE_QASSETS', payload.qassets, { root: true });
    await dispatch(
      'surety/SAVE_SURETIES',
      { queryId: state.localQuery.queryId, sureties: payload.sureties },
      { root: true },
    );
    commit(SET_QUERY, state.localQuery);
  },
  async [GET_QUERY_STATUS_HISTORY]({ commit }, payload) {
    const response = await axios.get(
      '/status-history/' + payload + '/find-all-by-query-id',
    );
    commit(SET_QUERY_STATUS_HISTORY, response.data);
  },
  async [CHANGE_QUERY_STATUS]({ commit, dispatch }, payload) {
    store.state.loading = true;
    try {
      const response = await axios.get('/query/change-status', {
        params: {
          status: payload.status,
          queryId: payload.queryId,
          ...(payload.closingReason && {
            closingReason: payload.closingReason,
          }),
        },
      });
      dispatch(
        'alert/ALERT',
        { message: payload.alert, type: 'success' },
        { root: true },
      );
      return response.data;
    } catch (error) {
      dispatch('alert/ALERT', { message: error.response.data }, { root: true });
      throw new Error(error.response.data);
    } finally {
      store.state.loading = false;
    }
  },
  async [GET_NEW_QUERY]({ commit }, payload) {
    commit('document/RESET_DOCUMENTS', null, { root: true });
    commit(SET_NEW_QUERY, payload);
  },
  async [CHANGE_MANAGER]({ dispatch, commit }, payload) {
    store.state.loading = true;
    await axios
      .put('/query/' + payload.queryId + '/change-manager', null, {
        params: {
          managerId: payload.managerId,
        },
      })
      .then(() => {
        dispatch(
          'alert/ALERT',
          { message: i18n.t('QUERY_MANAGER_CHANGED'), type: 'success' },
          { root: true },
        );
      })
      .catch(error => {
        commit(
          'alert/SET_ALERT',
          { message: error.response.data },
          { root: true },
        );
      })
      .finally(() => {
        store.state.loading = false;
      });
  },
  async [CALCULATE_PAYMENT]({ commit }, payload) {
    state.localQuery = payload.currentQuery;
    const response = await axios.post('/query/pmt', payload.pmtObject);
    commit(SET_QUERY, { ...state.localQuery, ...response.data });
  },
  async [GET_ACTUAL_BENEFICIARY]({ commit }, queryId) {
    const response = await axios.get('/actual-beneficiary/find', {
      params: { queryId },
    });
    commit(SET_ACTUAL_BENEFICIARY, response.data);
  },
  async [CREATE_SHOP_CONTRACT]({ commit, dispatch }, queryId) {
    store.state.loading = true;
    return axios
      .get('/query/' + queryId + '/create-shop-contract')
      .then(() => dispatch(GET_QUERY, queryId))
      .then(() => {
        dispatch(
          'alert/ALERT',
          {
            message: 'Contract generated',
            type: 'success',
          },
          { root: true },
        );
      })
      .finally(() => (store.state.loading = false))
      .catch(error => {
        commit(
          'alert/SET_ALERT',
          { message: error.response.data },
          { root: true },
        );
      });
  },
  async [GENERATE_LENDIFY_CONTRACT]({ commit, dispatch }, queryId) {
    store.state.loading = true;
    return axios
      .get('/query/' + queryId + '/lendify-contract')
      .then(() =>
        dispatch(
          'alert/ALERT',
          {
            message: 'Lendify contract generated successfully',
            type: 'success',
          },
          { root: true },
        ),
      )
      .then(() => dispatch(GET_QUERY, queryId))
      .finally(() => (store.state.loading = false))
      .catch(error => {
        commit(
          'alert/SET_ALERT',
          { message: error.response.data },
          { root: true },
        );
      });
  },
  async [GET_GUARANTOR]({ commit }, queryId) {
    const response = await axios.get('/guarantor/' + queryId);
    commit(SET_GUARANTOR, response.data);
  },
};

export const mutations = {
  [SET_QUERY](state, payload) {
    state.query = payload;
  },
  [SET_QUERY_ASSETS](state, payload) {
    state.localQuery.qassets = payload;
  },
  [SET_QUERY_SURETIES](state, payload) {
    state.localQuery.sureties = payload;
  },
  [SET_QUERY_STATUS_HISTORY](state, payload) {
    state.statusHistory = payload;
  },
  [SET_QUERY_STATUS](state, payload) {
    state.query.status = payload;
  },
  [SET_NEW_QUERY](state, payload) {
    state.statusHistory = [];
    state.query = {
      custName: '',
      custRegCode: '',
      contactPersType: 'Law',
      qassets: [],
      services: [],
      sureties: [],
      country: 'EE',
      actualBeneficiary: {
        residency: 'EE',
        isPublicPerson: 'no',
      },
      guarantor: {
        name: '',
        registryCode: '',
        address: '',
        email: '',
        phone: '',
      },
      status: 'P',
      pmtDate: '25',
      pmtAmount: 0,
      insuranceValType: 1,
      servicesCostValType: 1,
      advnPmtType: '%',
      managerId: payload.managerId,
      interestMatrix: payload.interestMatrix,
      isSuretyRequired: true,
      product: payload.product || 'LEASING',
    };
  },
  [SET_ACTUAL_BENEFICIARY](state, payload) {
    state.localQuery.actualBeneficiary = {
      ...payload,
      beneficiaryNoPersonalCode: payload.dateOfBirth ? true : false,
    };
  },
  [SET_GUARANTOR](state, payload) {
    state.localQuery.guarantor = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
