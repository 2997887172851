<template>
  <div>
    <Header :header="$t('SETTINGS')" />
    <b-container class="mt-4" v-if="user !== null">
      <h2 class="card-title">Salasõna</h2>
      <b-form @submit.prevent="saveSettings">
        <input
          type="text"
          autocomplete="username email"
          name="username"
          v-model="user.email"
          class="d-none"
        />
        <b-card>
          <b-form-group
            :label="`${$t('NEW_PASSWORD')}:`"
            :state="validateState('password')"
            :invalid-feedback="$t('PASSWORD_LENGTH_ERROR')"
          >
            <b-input-group>
              <b-form-input
                id="password"
                v-model.trim="password"
                name="password"
                :type="passwordType"
                :placeholder="$t('ENTER_PASSWORD')"
                v-validate="'required|min:6'"
                autocomplete="new-password"
              />
              <b-input-group-append>
                <b-btn type="button" @click="changeType('password')">
                  <img height="20" src="../assets/eye-black.svg" />
                </b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label="`${$t('REPEAT_PASSWORD')}:`"
            :state="validateRepeatPassword"
            :invalid-feedback="$t('PASSWORD_MATCH_ERROR')"
          >
            <b-input-group>
              <b-form-input
                id="repeatPassword"
                v-model.trim="repeatPassword"
                name="repeatPassword"
                :type="repeatPasswordType"
                :placeholder="$t('ENTER_PASSWORD')"
                v-validate="'required|min:6'"
                autocomplete="new-password"
              />
              <b-input-group-append>
                <b-btn type="button" @click="changeType('repeatPassword')">
                  <img height="20" src="../assets/eye-black.svg" />
                </b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <div class="mb-0 mt-4">
            <b-row align-h="center">
              <b-col md="6">
                <b-btn
                  id="saveBtn"
                  variant="secondary"
                  size="lg"
                  block
                  @click="$router.go(-1)"
                  >{{ $t('BACK') }}</b-btn
                >
              </b-col>
              <b-col md="6">
                <b-btn
                  type="submit"
                  variant="primary"
                  size="lg"
                  block
                  :disabled="!validateRepeatPassword || password.length < 6"
                  >{{ $t('SAVE') }}</b-btn
                >
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-form>
    </b-container>

    <b-container class="mt-4" v-if="user !== null">
      <h2 class="card-title">Muud seaded</h2>
      <b-form @submit.prevent="saveSettings">
        <b-card>
          <b-form-group label-for="hideVATFields">
            <b-form-checkbox
              id="hideVATFields"
              class="mt-3"
              v-on:change="hideVATFieldsChanged"
              v-model.trim="user.hideVatFields"
            >
              {{ $t('HIDE_VAT_FIELDS') }}
              <span>
                <b-icon
                  class="pl-1"
                  id="hideVATFields-tt"
                  icon="question-circle"
                />
                <b-tooltip
                  target="hideVATFields-tt"
                  variant="dark"
                  triggers="hover"
                >
                  Vali siis kui Sa ei soovi näha käibemaksu välju lepingu ja
                  taotluse sisestamisel sisestamisel.
                </b-tooltip>
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Header } from '@/components/common';
import validateState from '../mixins/validateState';
import { ALERT } from '../store/alert';
import axios from 'axios';

export default {
  name: 'Settings',
  components: {
    Header,
  },
  mixins: [validateState],
  data: function() {
    return {
      user: {},
      password: '',
      repeatPassword: '',
      passwordType: 'password',
      repeatPasswordType: 'password',
    };
  },
  created: function() {
    this.$store.dispatch('getLoggedInUser').then(response => {
      this.user = response.data;
    });
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    saveSettings: function() {
      this.user.password = this.password;
      this.$store.dispatch('changePassword', this.user).then(() => {
        this.ALERT({ message: this.$t('SETTINGS_SAVED'), type: 'success' });
        this.password = '';
        this.repeatPassword = '';
        this.$nextTick().then(() => {
          this.$validator.reset();
        });
      });
    },
    hideVATFieldsChanged: function() {
      axios.put('/user/toggle-hide-vat-fields', {}).then(response => {
        this.ALERT({ message: this.$t('SETTINGS_SAVED'), type: 'success' });
      });
    },
    changeType: function(type) {
      this[`${type}Type`] =
        this[`${type}Type`] === 'password' ? 'text' : 'password';
    },
  },
  computed: {
    ...mapState(['logo']),
    ...mapState('alert', ['alert']),
    validateRepeatPassword() {
      if (this.password && this.repeatPassword) {
        return this.repeatPassword === this.password;
      }
    },
  },
};
</script>

<style scoped></style>
