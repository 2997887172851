<template>
  <div>
    <Header :header="$t('PAGE_NOT_FOUND')" />
    <div class="mt-5 text-center">
      <b-btn variant="secondary" size="md" to="/" exact>{{
        $t('GO_TO_DASHBOARD')
      }}</b-btn>
    </div>
  </div>
</template>

<script>
import { Header } from '@/components/common';

export default {
  name: 'ErrorPage',
  components: {
    Header,
  },
};
</script>
