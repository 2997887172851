<template>
  <div>
    <b-card class="card-padding-md">
      <b-form>
        <b-row>
          <b-col md="12" class="d-flex justify-content-between">
            <h3>{{ $t('ASSET') }}</h3>
          </b-col>
        </b-row>

        <b-collapse
          id="collapseAddAssetForm"
          v-model="showAddAssetForm"
          class="mt-2"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('QASSET_PRICE_WITH_VAT') + ':'"
                :invalid-feedback="$t('VALIDATION.QASSET_PRICE_REQUIRED')"
                :state="validateState('qassetPrice')"
                label-for="qassetPriceWithVat"
                :hidden="user && user.hideVatFields"
              >
                <b-form-input
                  id="qassetPriceWithVat"
                  name="qassetPriceWithVat"
                  v-model.trim="newAsset.qassetPriceWithVat"
                  @keyup.native="
                    numerifyWithDecimals('newAsset', 'qassetPriceWithVat')
                  "
                  type="text"
                  v-validate="'required'"
                  v-on:change="createOrUpdate"
                  @input="calculateWithoutVat"
                  :disabled="disableOnStatus || disabled"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('QASSET_PRICE_NO_VAT') + ':'"
                label-for="qassetPrice"
                :invalid-feedback="$t('VALIDATION.QASSET_PRICE_REQUIRED')"
                :state="validateState('qassetPrice')"
              >
                <b-form-input
                  id="qassetPrice"
                  name="qassetPrice"
                  v-model.trim="newAsset.qassetPrice"
                  v-validate="'required'"
                  @keyup.native="
                    numerifyWithDecimals('newAsset', 'qassetPrice')
                  "
                  type="text"
                  v-on:change="createOrUpdate"
                  @input="calculateWithVat"
                  :disabled="disableOnStatus || disabled"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('QASSET_NAME') + ':'"
                label-for="assetName"
                :invalid-feedback="$t('VALIDATION.QASSET_NAME_REQUIRED')"
                :state="validateState('assetName')"
              >
                <b-form-input
                  id="assetName"
                  name="assetName"
                  v-model.trim="newAsset.qassetName"
                  v-validate="'required'"
                  type="text"
                  v-on:change="createOrUpdate"
                  :disabled="disableOnStatus || disabled"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('ASSET_GROUP') + ':'"
                label-for="assetSubGroup"
              >
                <multiselect
                  v-model="newAsset.assetSubgroupId"
                  :options="
                    partnerAssetGroups
                      .filter(g => isValidToShowAssetGroup(g.assetGroupId))
                      .map(o => o.assetGroupId)
                  "
                  :allow-empty="false"
                  :show-labels="false"
                  :disabled="disableOnStatus || disabled"
                >
                  <template #option="{ option }">
                    {{ getAssetLabel(option) }}
                  </template>
                  <template #singleLabel="{ option }">
                    {{ getAssetLabel(option) }}
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-if="this.partnerSettings && this.partnerSettings.editInsurance"
          >
            <b-col md="6">
              <b-form-group
                :label="$t('SET_APPLICATION_INSURANCE_AMOUNT') + ':'"
                label-for="insuranceAmount"
                v-b-tooltip.hover
              >
                <b-form-input
                  id="insuranceAmount"
                  name="insuranceAmount"
                  v-model.trim="insuranceAmount"
                  v-on:change="createOrUpdate"
                  type="text"
                  :disabled="disableOnStatus || disabled"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('SET_APPLICATION_EXTENDED_WARRANTY') + ':'"
                label-for="extendedWarranty"
                v-b-tooltip.hover
              >
                <b-form-input
                  id="extendedWarranty"
                  name="extendedWarranty"
                  v-model.trim="extendedWarranty"
                  v-on:change="createOrUpdate"
                  type="text"
                  :disabled="disableOnStatus || disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label-for="handoverLocally">
                <b-form-checkbox
                  class="mt-4"
                  v-on:change="createOrUpdate"
                  :disabled="disableOnStatus || disabled"
                  v-model.trim="newAsset.handoverWhenSigned"
                >
                  {{ $t('HANDOVER_LOCALLY') }}
                  <span>
                    <b-icon class="pl-1" id="tt-1" icon="question-circle" />
                    <b-tooltip target="tt-1" variant="dark" triggers="hover">
                      Vali siis kui vara saab kohe peale lepingu allkirjastamist
                      kliendile kätte anda
                    </b-tooltip>
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import snakeCase from 'lodash.snakecase';
import Multiselect from 'vue-multiselect';
import { mapActions, mapState } from 'vuex';
import validateState from '../../mixins/validateState';
import { replaceComma, numerify, numerifyWithDecimals } from '@/utils';
import { GET_BY_NAME, GET_BY_COMPANY_CODE } from '../../store/company';
import { SAVE_QASSET, GET_ALL_ASSET_GROUPS } from '../../store/qasset';
import { getPriceWithoutVat, getPriceWithVat } from '../../utils';
import axios from 'axios';

export default {
  name: 'AddAssetForm',
  mixins: [validateState],
  components: {
    Multiselect,
  },
  props: {
    partnerSettings: Object,
    value: Object,
    disableOnStatus: Boolean,
    disableDeliveryAndSellerInfo: Boolean,
    viewName: String,
  },
  data() {
    return {
      newAsset: {
        sellerReprsReprType: this.$t('LAW'),
        quantity: 1,
        year: new Date().getFullYear(),
      },
      disabled: false,
      selected: [],
      isLoading: false,
      showManualInputs: false,
      showAddAssetForm: true,
      manualInputBtnText: this.$t('ENTER_MANUALLY'),
      options: ['Law', 'Power of Attorney'],
      partnerAssetGroups: [],
      loadingAssetsData: false,
      extendedWarranty: null,
      insuranceAmount: null,
      user: {},
    };
  },
  async mounted() {
    await this.GET_ALL_ASSET_GROUPS();
    this.loadingAssetsData = true;
    await this.$store
      .dispatch('getPartnerAssetGroups', this.partnerSettings.partnerId)
      .then(r => {
        this.partnerAssetGroups = r.data;
        this.initializeAsset();
      });
    this.loadingAssetsData = false;

    await axios.get('user/get-logged-in-user').then(({ data: userData }) => {
      this.user = userData;
    });
  },
  watch: {
    'newAsset.assetSubgroupId': function() {
      let assetGroup = this.allAssetGroups.find(
        ({ assetGroupId }) => assetGroupId === this.newAsset.assetSubgroupId,
      );
      this.$set(this.newAsset, 'assetGroupId', assetGroup.superiorGroupId);
      this.$set(
        this.newAsset,
        'assetSubgroupId',
        this.newAsset.assetSubgroupId,
      );
      let foundPartnerAssetGroup = this.partnerAssetGroups.filter(
        g => g.assetGroupId === this.newAsset.assetSubgroupId,
      );
      if (foundPartnerAssetGroup.length === 1) {
        if (foundPartnerAssetGroup[0].insurance) {
          this.$set(
            this.value,
            'insurance',
            foundPartnerAssetGroup[0].insurance,
          );
        } else {
          this.$set(this.value, 'insurance', this.partnerSettings.insurance);
        }
      }
      this.createOrUpdate();
    },
    valueArrivalPlaceholder: function() {
      if (this.value.custName) {
        this.selected = {
          name: this.value.custName,
          company_code: this.value.custRegCode,
        };
      }
    },
  },
  methods: {
    ...mapActions('company', [GET_BY_NAME, GET_BY_COMPANY_CODE]),
    ...mapActions('qasset', [SAVE_QASSET, GET_ALL_ASSET_GROUPS]),
    async createOrUpdate() {
      if (this.loadingAssetsData) {
        return;
      }
      if (
        this.newAsset.qassetPrice &&
        this.newAsset.qassetName &&
        this.newAsset.assetSubgroupId
      ) {
        const mainAsset = this.mainAsset();
        const mainAssetPrice =
          replaceComma(this.newAsset.qassetPrice) -
          (replaceComma(this.insuranceAmount) ?? 0) -
          (replaceComma(this.extendedWarranty) ?? 0);

        // replace this.newAsset price with mainAsset price. use copy of newAsset
        let newAssetCopy = { ...this.newAsset };
        newAssetCopy.qassetPrice = mainAssetPrice;

        if (mainAsset) {
          await this.updateQasset(newAssetCopy, mainAsset);
        } else {
          await this.addQasset(newAssetCopy);
        }

        const insuranceAsset = this.insuranceAsset();
        if (insuranceAsset) {
          await this.updateInsuranceQasset();
        } else {
          await this.addInsuranceQasset(newAssetCopy);
        }

        const extendedWarrantyAsset = this.extendedWarrantyAsset();
        if (extendedWarrantyAsset) {
          await this.updateExtendedWarrantyQasset();
        } else {
          await this.addExtendedWarrantyQasset(newAssetCopy);
        }

        this.$emit('saveQuery', () => {
          this.$emit('calculatePmt');
          this.$emit('assetUpdated');
        });
      }
    },
    async getCompaniesByName(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_NAME(query);
        this.isLoading = false;
      }
    },
    async getCompaniesByCode(query) {
      if (query.length > 2) {
        this.isLoading = true;
        await this.GET_BY_COMPANY_CODE(query);
        this.isLoading = false;
      }
    },
    isValidToShowAssetGroup(id) {
      let assetGroup = this.allAssetGroups.find(
        ({ assetGroupId }) => assetGroupId === id,
      );
      let result =
        assetGroup &&
        assetGroup.superiorGroupId !== null &&
        assetGroup.insurance !== null;
      if (result) {
        return result;
      }
      return false;
    },

    getAssetLabel(o) {
      let id;
      if (typeof o === 'number') {
        id = o;
      } else {
        id = o.id;
      }
      let assetGroup = this.allAssetGroups.find(
        ({ assetGroupId }) => assetGroupId === id,
      );
      if (assetGroup) {
        return assetGroup.name;
      }
      return ' - ' + id;
    },
    setCompanyParams() {
      this.$set(this.newAsset, 'sellerName', this.selected.name);
      this.$set(this.newAsset, 'sellerRegCode', this.selected.companyCode);
      this.$set(this.newAsset, 'vatNumber', this.selected.vatNumber);
      this.$set(this.newAsset, 'street', this.selected.address);
      this.$set(this.newAsset, 'zipCode', this.selected.postalCode);
      this.$set(this.newAsset, 'city', this.selected.city);
    },
    async updateQasset(qasset, qassetItem) {
      console.log('Update qasset:' + qasset.qassetName);
      this.$validator.validateAll().then(async isValid => {
        if (!isValid) return;
        qasset.queryId = this.value.queryId;
        qasset.qassetPrice = replaceComma(qasset.qassetPrice);

        this.$set(qassetItem, 'qassetPrice', qasset.qassetPrice);
        this.$set(qassetItem, 'qassetName', qasset.qassetName);
        this.$set(qassetItem, 'assetGroupId', qasset.assetGroupId);
        this.$set(qassetItem, 'assetSubgroupId', qasset.assetSubgroupId);
        this.$set(qassetItem, 'handoverWhenSigned', qasset.handoverWhenSigned);
        this.$set(qassetItem, 'vatExempt', qasset.vatExempt);

      });
    },
    async updateInsuranceQasset() {
      this.updateQasset(
        {
          qassetName: 'Kindlustus',
          qassetPrice: replaceComma(this.insuranceAmount) ?? 0,
          assetGroupId: null,
          assetSubgroupId: null,
          quantity: 1,
          vatExempt: true,
        },
        this.insuranceAsset(),
      );
    },
    async addInsuranceQasset(baseAsset) {
      // copy of qasset
      const qasset = { ...baseAsset };

      qasset.qassetPrice = replaceComma(this.insuranceAmount) ?? 0;
      qasset.qassetName = 'Kindlustus';
      qasset.assetGroupId = null;
      qasset.assetSubgroupId = null;
      qasset.quantity = 1;
      qasset.vatExempt = true;
      this.addQasset(qasset);
    },
    async addExtendedWarrantyQasset(baseAsset) {
      // copy of qasset
      const qasset = { ...baseAsset };
      qasset.qassetPrice = replaceComma(this.extendedWarranty) ?? 0;
      qasset.qassetName = 'Lisagarantii';
      qasset.assetGroupId = null;
      qasset.assetSubgroupId = null;
      qasset.quantity = 1;
      qasset.vatExempt = true;

      this.addQasset(qasset);
    },
    async updateExtendedWarrantyQasset() {
      this.updateQasset(
        {
          qassetName: 'Lisagarantii',
          qassetPrice: replaceComma(this.extendedWarranty) ?? 0,
          assetGroupId: null,
          assetSubgroupId: null,
          quantity: 1,
          vatExempt: true,
        },
        this.extendedWarrantyAsset(),
      );
    },
    async addQasset(qasset) {
      this.$validator.validateAll().then(async isValid => {
        if (!isValid) {
          console.log('Validation failed for qasset:' + JSON.stringify(qasset));
          return;
        }
        qasset.queryId = this.value.queryId;
        qasset.qassetPrice = replaceComma(qasset.qassetPrice);
        this.value.qassets.push(
          await this.SAVE_QASSET({
            ...qasset,
          }),
        );
        this.$emit('calculatePmt');
        this.$emit('assetAdded');
      });
    },
    initializeAsset() {
      if (this.value.qassets.length >= 1) {
        const mainAsset = this.mainAsset();

        const insuranceAsset = this.insuranceAsset();
        if (insuranceAsset) {
          this.insuranceAmount = insuranceAsset.qassetPrice;
        }
        const extendedWarrantyAsset = this.extendedWarrantyAsset();
        if (extendedWarrantyAsset) {
          this.extendedWarranty = this.extendedWarrantyAsset().qassetPrice;
        }

        const totalAssetPrice =
          mainAsset.qassetPrice +
          (this.insuranceAmount ?? 0) +
          (this.extendedWarranty ?? 0);

        this.$set(this.newAsset, 'qassetName', mainAsset.qassetName);
        this.$set(this.newAsset, 'qassetPrice', totalAssetPrice);
        this.$set(
          this.newAsset,
          'qassetPriceWithVat',
          getPriceWithVat(totalAssetPrice),
        );
        this.$set(this.newAsset, 'assetSubgroupId', mainAsset.assetSubgroupId);
        this.$set(this.newAsset, 'assetGroupId', mainAsset.assetGroupId);
        this.$set(
          this.newAsset,
          'handoverWhenSigned',
          mainAsset.handoverWhenSigned,
        );
      } else {
        this.$set(this.newAsset, 'handoverWhenSigned', true);
      }
      this.$set(this.newAsset, 'sellerOfferNr', 1);
      this.$set(this.newAsset, 'sellerOfferDate', new Date());
      this.$set(this.newAsset, 'sellerName', this.partnerSettings.businessName);
      this.$set(this.newAsset, 'sellerRegCode', this.partnerSettings.regCode);
      this.$set(this.newAsset, 'vatNumber', this.partnerSettings.vatNumber);
      this.$set(this.newAsset, 'vatExempt', false);
      this.$set(
        this.newAsset,
        'bankAccountNumber',
        this.partnerSettings.bankAccountNumber,
      );
      this.$set(this.newAsset, 'street', this.partnerSettings.street);
      this.$set(this.newAsset, 'zipCode', this.partnerSettings.zipCode);
      this.$set(this.newAsset, 'city', this.partnerSettings.city);
      this.$set(
        this.newAsset,
        'sellerPhone',
        this.partnerSettings.phoneNumber || '',
      );
      this.$set(this.newAsset, 'sellerEmail', this.partnerSettings.email);
      this.$set(
        this.newAsset,
        'sellerRepresentative',
        this.partnerSettings.representativeName,
      );
      this.$set(
        this.newAsset,
        'representativePersonalCode',
        this.partnerSettings.representativePersonalCode,
      );
      this.$set(
        this.newAsset,
        'representativePhone',
        this.partnerSettings.representativePhone,
      );
      this.$set(
        this.newAsset,
        'representativeEmail',
        this.partnerSettings.representativeEmail,
      );
    },
    calculateWithoutVat(price) {
      this.newAsset.qassetPrice = getPriceWithoutVat(price);
      this.$validator
        .validateAll()
        .then(() => {})
        .catch(() => {});
    },
    calculateWithVat(price) {
      this.newAsset.qassetPriceWithVat = getPriceWithVat(price);
      this.$validator
        .validateAll()
        .then(() => {})
        .catch(() => {});
    },
    insuranceAsset() {
      return this.value.qassets.find(a =>
        a.qassetName.toLowerCase().includes('kindlustus'),
      );
    },
    extendedWarrantyAsset() {
      return this.value.qassets.find(a =>
        a.qassetName.toLowerCase().includes('lisagarantii'),
      );
    },
    mainAsset() {
      // find first asset that does not have "Kindlustus" in name or "Lisagarantii"
      return this.value.qassets.find(
        a =>
          !a.qassetName.toLowerCase().includes('kindlustus') &&
          !a.qassetName.toLowerCase().includes('lisagarantii'),
      );
    },
  },
  computed: {
    ...mapState('company', ['companies']),
    ...mapState('qasset', ['allAssetGroups']),
    numerify() {
      return numerify;
    },
    numerifyWithDecimals() {
      return numerifyWithDecimals;
    },
    snakeCase() {
      return snakeCase;
    },
  },
};
</script>
