<template>
  <b-card class="mt-4 card-padding-md">
    <h2 class="card-title card-title-pulled-left">
      <span>
        <figure class="card-title-icon">
          <img src="../../assets/package-icon.svg" class="card-title-image" />
        </figure>
        {{ $t('SELLER') }}
      </span>
    </h2>

    <div class="client-info">
      <b-row>
        <b-col>
          <b-list-group>
            <h4>{{ $t('BUSINESS') }}</h4>
            <b-list-group-item>
              <div>{{ $t('NAME') }}</div>
              <div>{{ seller.sellerName }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('SELLER_REG_CODE') }}</div>
              <div>
                {{ seller.sellerRegCode }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('VAT_CODE') }}</div>
              <div>{{ seller.vatNumber }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('BANK_ACCOUNT_NUMBER') }}</div>
              <div>
                {{ seller.bankAccountNumber }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('ADDRESS') }}</div>
              <div>
                {{ seller.street + ', ' + seller.zipCode + ' ' + seller.city }}
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col>
          <b-list-group>
            <h4>{{ $t('REPRESENTATIVE') }}</h4>
            <b-list-group-item>
              <div>{{ $t('NAME') }}</div>
              <div>{{ seller.sellerRepresentative }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('ID_CODE') }}</div>
              <div>
                {{ seller.representativePersonalCode }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('PHONE') }}</div>
              <div>{{ seller.representativePhone }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('EMAIL') }}</div>
              <div>{{ seller.representativeEmail }}</div>
            </b-list-group-item>
            <b-list-group-item>
              <div>{{ $t('SELLER_REPRESENTATIVE_TYPE') }}</div>
              <div>{{ seller.sellerReprsReprType }}</div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'SellerInfo',
  props: ['seller'],
};
</script>

<style></style>
