import axios from 'axios';
import { deleteTemplateProps } from '../../utils';
import i18n from '../../i18n';

export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
const TEMPLATE_SAVED = 'TEMPLATE_SAVED';
const SET_TEMPLATES = 'SET_TEMPLATES';
const SET_TEMPLATE = 'SET_TEMPLATE';
const SET_DELETED_TEMPLATE = 'SET_DELETED_TEMPLATE';

const state = {
  templates: [],
  template: {},
  isSaved: false,
  isDeleted: false,
};

export const actions = {
  async [SAVE_TEMPLATE]({ commit, dispatch }, payload) {
    await axios.post('/template/save', payload);
    commit(TEMPLATE_SAVED, true);
    setTimeout(() => commit('TEMPLATE_SAVED', false), 2000);
    dispatch(
      'alert/ALERT',
      { message: i18n.t('TEMPLATE_SAVED'), type: 'success' },
      { root: true },
    );
  },
  async [GET_TEMPLATES]({ commit }) {
    const oldTemplates = [];
    const response = await axios.get('/template/find-all-for-partner');
    response.data.forEach(template => {
      oldTemplates.push(deleteTemplateProps(template));
    });
    commit(SET_TEMPLATES, oldTemplates);
  },
  async [GET_TEMPLATE]({ commit, dispatch }, payload) {
    const response = await axios.get('/template/' + payload);
    commit(SET_TEMPLATE, response.data);
  },
  async [DELETE_TEMPLATE]({ commit }, payload) {
    if (payload) {
      await axios.delete('/template/' + payload + '/delete');
      return commit(SET_DELETED_TEMPLATE, { id: payload, isDeleted: true });
    }

    commit(SET_DELETED_TEMPLATE, { isDeleted: false });
  },
};

export const mutations = {
  [TEMPLATE_SAVED](state, payload) {
    state.isSaved = payload;
  },
  [SET_TEMPLATES](state, payload) {
    state.templates = payload;
  },
  [SET_TEMPLATE](state, payload) {
    state.template = payload;
  },
  [SET_DELETED_TEMPLATE](state, { id, isDeleted }) {
    if (id) {
      state.templates = state.templates.filter(i => i.id !== +id);
    }

    state.isDeleted = isDeleted;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
