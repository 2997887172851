<template>
  <b-modal
    :title="$t('CLOSE_QUERY')"
    v-model="isOpen"
    centered
    @cancel="$emit('cancel')"
    @hide="$emit('cancel')"
  >
    <b-form-select id="reason-select" v-model="selected">
      <option :value="null">{{ $t('SELECT_REASON') }}</option>
      <option v-for="reason in reasons" :key="reason.id" :value="reason.id">{{
        reason[locale]
      }}</option>
      <option value="other">{{ $t('OTHER') }}</option>
    </b-form-select>

    <b-form-input
      class="mt-4"
      v-if="selected == 'other'"
      v-model="customText"
      :placeholder="$t('REASON')"
    />

    <template slot="modal-footer">
      <b-btn type="button" variant="secondary" @click="$emit('cancel')">{{
        $t('CANCEL')
      }}</b-btn>
      <b-btn
        type="button"
        variant="danger"
        @click="handleSubmit"
        :disabled="
          selected === null || (selected == 'other' && customText == '')
        "
        >{{ $t('CLOSE_QUERY') }}</b-btn
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'QueryCloseModal',
  props: {
    show: Boolean,
    reasons: Array,
    initialValue: Number,
  },
  mounted() {
    if (this.initialValue) this.selected = this.initialValue;
  },
  data() {
    return {
      isOpen: false,
      selected: null,
      customText: '',
    };
  },
  methods: {
    handleSubmit() {
      const reason =
        this.selected === 'other' ? this.customText : this.selected;
      this.$emit('closeQuery', reason);
    },
  },
  computed: {
    locale() {
      return localStorage.getItem('locale') || 'en';
    },
  },
  watch: {
    show(value) {
      this.isOpen = this.show;
    },
  },
};
</script>

<style></style>
