import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router/index';
import template from './template';
import alert from './alert';
import query from './query';
import qasset from './qasset';
import surety from './surety';
import document from './document';
import message from './message';
import company from './company';
import reason from './reason';
import actualBeneficiary from './actualBeneficiary';
import guarantor from './guarantor';
import { parseJwt, downloadResponseBlob } from '../utils';
import i18n from '../i18n';

Vue.use(Vuex);
/* eslint no-empty-pattern: "off" */
const store = new Vuex.Store({
  modules: {
    template,
    alert,
    query,
    qasset,
    surety,
    document,
    message,
    company,
    reason,
    actualBeneficiary,
    guarantor,
  },
  state: {
    response: {},
    headers: {},
    model: '',
    jwtToken: '',
    role: window.localStorage.getItem('role'),
    logo: null,
    queryStatus: {
      CA: 'Contract Activated',
      CF: 'Contract Ended',
      CR: 'Waiting for Contract',
      N: 'Denied',
      P: 'In Progress',
      QA: 'Query Approved',
      HO: 'Handover Approved',
      QAC: 'Query Approved With Changes',
      SCO: 'Sent to Compliance',
      SCR: 'Sent to Credit',
      YS: 'Waiting for Signatures',
      SM: 'Sent to Manager',
      SN: 'Signed on paper',
    },
    loading: false,
    redirectPath: '',
    languages: ['en', 'et'],
  },
  actions: {
    resendConfirmEmail: function({}, userId) {
      const url = '/register/' + userId + '/reset-activation-link';
      return axios.put(url);
    },
    changeLocale: function({ dispatch }, locale) {
      const url = '/user/change-language';
      axios
        .put(url, null, {
          params: {
            language: locale,
          },
        })
        .then(() => {
          dispatch('changeLocaleLocally', locale);
        })
        .then(() => {
          dispatch('changeLocaleLocally', locale);
        });
    },
    changeLocaleLocally: function({ commit }, locale) {
      commit('setLocale', { locale });
      location.reload();
    },
    getAllUsers: function({ commit }) {
      commit('setLoading', true);
      const url = '/user/find-all';
      return axios.get(url).finally(() => commit('setLoading', false));
    },
    getUser: function({}, id) {
      const url = '/user/' + id + '/edit';
      return axios.get(url);
    },
    updateUser: function({ commit }, user) {
      commit('setLoading', true);
      const url = '/user/update';
      return axios.put(url, user).finally(() => commit('setLoading', false));
    },
    saveUser: function({ commit }, user) {
      commit('setLoading', true);
      const url = '/user/save';
      return axios.post(url, user).finally(() => commit('setLoading', false));
    },
    deleteUser: function({}, id) {
      const url = '/user/delete';
      return axios.post(url, id, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    },
    createPassword: function({}, data) {
      const url = '/register/create-password/' + data.uuid;
      return axios.put(url, null, {
        params: {
          password: data.password,
        },
      });
    },
    changePassword: function({}, user) {
      const url = '/user/save-password';
      return axios.put(url, user);
    },
    getPartnerLogo: function({ commit }) {
      const url = '/user/get-logo';
      return axios.get(url).then(response => {
        commit('setLogo', { logo: response.data });
      });
    },
    qualifyCustomer: function({}, regCode) {
      const url = '/query/' + regCode + '/qualify-customer';
      return axios.get(url);
    },
    calculatePmt: function({}, pmtObject) {
      const url = '/query/pmt';
      return axios.post(url, pmtObject);
    },
    generatePmtSchedule: function({}, query) {
      const url = '/query/generate-pmt-schedule';
      return axios.post(url, query);
    },
    generateOffer: function({ commit, dispatch }, { queryId, offerType }) {
      commit('setLoading', true);
      const url = '/query/' + queryId + '/offer-pdf';
      return axios
        .get(url, {
          params: {
            offerType,
          },
          responseType: 'blob',
        })
        .then(response => {
          downloadResponseBlob(response);
          dispatch(
            'alert/ALERT',
            { message: 'Offer generated successfully', type: 'success' },
            { root: true },
          );
        })
        .catch(err => {
          err.response.data.text().then(message => {
            commit('alert/SET_ALERT', {
              message,
            });
          });
        })
        .finally(() => commit('setLoading', false));
    },
    generateRedemptionAgreement({ commit, dispatch }, queryId) {
      commit('setLoading', true);
      const url = '/query/' + queryId + '/redemption-agreement';
      return axios
        .get(url, {
          responseType: 'blob',
        })
        .then(response => {
          downloadResponseBlob(response);
          dispatch('alert/ALERT', {
            message: 'Redemption agreement generated successfully',
            type: 'success',
          });
        })
        .catch(err => {
          err.response.data.text().then(message => {
            commit('alert/SET_ALERT', {
              message,
            });
          });
        })
        .finally(() => commit('setLoading', false));
    },
    generateLeasingContract({ commit, dispatch }, queryId) {
      commit('setLoading', true);
      const url = '/query/' + queryId + '/leasing-contract';
      return axios
        .get(url, {
          responseType: 'blob',
        })
        .then(response => {
          downloadResponseBlob(response);
          dispatch(
            'alert/ALERT',
            {
              message: 'Leasing contract generated successfully',
              type: 'success',
            },
            { root: true },
          );
        })
        .catch(err => {
          err.response.data.text().then(message => {
            commit('alert/SET_ALERT', {
              message,
            });
          });
        })
        .finally(() => commit('setLoading', false));
    },
    printShopContract({ commit, dispatch }, { queryId }) {
      commit('setLoading', true);
      const url = '/query/' + queryId + '/print-shop-contract';
      return axios
        .get(url, {
          responseType: 'blob',
        })
        .then(response => {
          downloadResponseBlob(response);
        })
        .catch(err => {
          err.response.data.text().then(message => {
            commit('alert/SET_ALERT', {
              message,
            });
          });
        })
        .finally(() => commit('setLoading', false));
    },

    generateActOfAcceptance({ commit, dispatch }, actOfAcceptanceData) {
      commit('setLoading', true);
      const url =
        '/query/' + actOfAcceptanceData.queryId + '/act-of-acceptance';
      return axios
        .post(url, actOfAcceptanceData, {
          responseType: 'blob',
        })
        .then(response => {
          downloadResponseBlob(response);
          dispatch(
            'alert/ALERT',
            {
              message: 'Act of acceptance generated successfully',
              type: 'success',
            },
            { root: true },
          );
        })
        .catch(err => {
          err.response.data.text().then(message => {
            commit('alert/SET_ALERT', {
              message,
            });
          });
        })
        .finally(() => commit('setLoading', false));
    },
    saveContainers: function({}, payload) {
      const url = '/query/' + payload.queryId + '/save-containers';
      return axios.post(url, payload.containers, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    },
    saveDocument({ commit, dispatch }, document) {
      const url = '/document/update';
      commit('setLoading', true);
      return axios
        .post(url, document)
        .then(async () => {
          await dispatch('document/GET_QUERY_DOCUMENTS', document.queryId, {
            root: true,
          });
        })
        .finally(() => commit('setLoading', false));
    },
    saveIsSuretyRequired({ commit }, { queryId, isSuretyRequired }) {
      commit('setLoading', true);
      const url = '/query/' + queryId + '/is-surety-required';
      return axios
        .put(url, null, {
          params: {
            isSuretyRequired,
          },
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    setAllSigned: function({}, id) {
      const url = '/container/' + id + '/set-all-signed';
      return axios.put(url);
    },
    confirmSigned: function({ commit }, { queryId }) {
      const url = 'shop/' + queryId + '/confirm-signed';
      return axios
        .post(
          url,
          {},
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          },
        )
        .catch(error => {
          commit('alert/SET_ALERT', {
            message: error.response.data.message,
          });
        });
    },
    sendForShopSigning: function({ commit }, { queryId }) {
      const url = 'shop/' + queryId + '/send-for-signing';
      return axios
        .post(
          url,
          {},
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          },
        )
        .catch(error => {
          commit('alert/SET_ALERT', {
            message: error.response.data.message,
          });
        });
    },
    sendForSigning: function({ commit }, { containers, queryId }) {
      const url = 'query/' + queryId + '/send-for-signing';
      return axios
        .post(url, containers, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .catch(error => {
          commit('alert/SET_ALERT', {
            message: error.response.data.message,
          });
        });
    },
    getRequiredSigners: function({}, containerId) {
      const url = '/query/' + containerId + '/get-signers';
      return axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    },
    findAllQueries: function({ commit }, { statuses, params }) {
      commit('setLoading', true);
      const url = '/query/find-all-queries';
      return axios.post(url, statuses, { params }).finally(() => {
        commit('setLoading', false);
      });
    },
    getPartnerQueriesByStatus: function({ commit }, { statuses, params }) {
      commit('setLoading', true);
      const url = 'query/get-partner-queries-by-status';
      return axios.post(url, statuses, { params }).finally(() => {
        commit('setLoading', false);
      });
    },
    getFinanciers: function({ state }) {
      const url = '/financier/get';
      return axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    },
    annulQuery: function({ state }, id) {
      const url = '/query/' + id + '/annul';
      return axios.post(url);
    },
    getPartnerAssetGroups({}, partnerId) {
      const url = '/partner/' + partnerId + '/asset-group';
      return axios.get(url);
    },
    savePartnerAssetGroups({}, { partnerAssetGroups, partnerId }) {
      const selectedPartnerAssetGroups = [];
      partnerAssetGroups.forEach(mainAssetGroup => {
        mainAssetGroup.subgroups.forEach(subgroup => {
          if (subgroup.allowed) {
            selectedPartnerAssetGroups.push({
              assetGroupId: subgroup.assetGroupId,
              autoDecisionEnabled: subgroup.autoDecisionEnabled || false,
              insurance: subgroup.insurance || null,
            });
          }
        });
      });
      const url = '/partner/' + partnerId + '/asset-group';
      return axios.post(url, selectedPartnerAssetGroups);
    },
    getPartner({}, partnerId) {
      const url = '/compliance/partner/' + partnerId;
      return axios.get(url);
    },
    getPartnerDetails({}) {
      const url = '/partner';
      return axios.get(url);
    },
    getDashboardMessage({}) {
      const url = '/dashboard/message';
      return axios.get(url);
    },
    saveDashboardMessage({}, message) {
      const url = '/dashboard/message';
      console.log('Save message', message);
      return axios.post(url, message);
    },
    deletePartner({ dispatch, commit }, partnerId) {
      commit('setLoading', true);
      const url = '/compliance/partner/' + partnerId;
      return axios
        .delete(url)
        .catch(error => {
          dispatch(
            'alert/ALERT',
            { message: error.response.data },
            { root: true },
          );
        })
        .finally(() => commit('setLoading', false));
    },
    getPartnerSettings: function() {
      const url = '/partner-setting/get';
      return axios.get(url);
    },
    findAllInhousePartners: function() {
      const url = '/user/find-all-inhouse-partners';
      return axios.get(url);
    },
    findLocalContainers: function({}, queryId) {
      const url = 'query/' + queryId + '/containers';
      return axios.get(url);
    },
    getLoggedInUser: function() {
      const url = '/user/get-logged-in-user';
      return axios.get(url);
    },
    getPartners: function({ commit }) {
      commit('setLoading', true);
      const url = '/compliance/partner';
      return axios
        .get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .finally(() => commit('setLoading', false));
    },
    savePartnerSettings: function({ commit }, partnerSettings) {
      commit('setLoading', true);
      const url = '/compliance/partner';
      partnerSettings.residual = partnerSettings.residualOptions.toString();
      partnerSettings.advance = partnerSettings.advanceOptions.toString();
      partnerSettings.paymentPeriod = partnerSettings.paymentPeriodOptions.toString();
      partnerSettings.kickback = partnerSettings.kickbackOptions.toString();
      partnerSettings.interest = partnerSettings.interestOptions.toString();
      return axios
        .post(url, partnerSettings)
        .finally(() => commit('setLoading', false));
    },
    getFinancier: function({}, financierId) {
      const url = '/financier/' + financierId + '/find-name';
      return axios.get(url, financierId);
    },
    getFairownRepresentative() {
      return axios.get('/container/representative');
    },
    login: function({ state, commit, dispatch }, payload) {
      const url = '/login';
      axios
        .post(url, payload.user)
        .then(response => {
          const jwtToken = response.headers.authorization;
          const role = response.headers.role;
          const { exp } = parseJwt(jwtToken);
          const locale = response.headers.language;
          commit('setLocale', { locale });
          if (response.status === 200) {
            commit('setJwtToken', { jwtToken });
            commit('setRole', { role });
            window.localStorage.setItem('exp', exp);
            window.localStorage.setItem('jwtToken', jwtToken);
            window.localStorage.setItem('role', role);
            dispatch('getPartnerLogo');
            if (role === 'PARTNER') {
              axios.get('/user/is-partner-inhouse').then(response => {
                const isInhouse = response.data.isInhouse;
                window.localStorage.setItem('isInhouse', isInhouse);
                router.push(
                  state.redirectPath !== '' ? state.redirectPath : '/partner',
                );
                commit('setRedirectPath', { redirectPath: '' });
              });
            } else if (role === 'SHOP') {
              window.localStorage.setItem('isInhouse', false);
              router.push(
                state.redirectPath !== '' ? state.redirectPath : '/shop',
              );
              commit('setRedirectPath', { redirectPath: '' });
            } else if (role === 'COMPLIANCE') {
              window.localStorage.setItem('isInhouse', true);
              router.push(
                state.redirectPath !== ''
                  ? state.redirectPath
                  : '/compliance/home',
              );
              commit('setRedirectPath', { redirectPath: '' });
            } else if (role === 'CREDIT') {
              window.localStorage.setItem('isInhouse', true);
              router.push(
                state.redirectPath !== '' ? state.redirectPath : '/credit/home',
              );
              commit('setRedirectPath', { redirectPath: '' });
            }
          }
          commit('alert/RESET_ALERT');
        })
        .catch(error => {
          console.log(error);
          commit('alert/SET_ALERT', {
            message: 'Invalid username or password',
          });
        })
        .catch(error => {
          console.log(error);
          commit('alert/SET_ALERT', {
            message: 'Invalid username or password',
          });
        });
    },
    ssoLogin: function({ state, commit, dispatch }, payload) {
      const url = '/remote-login/' + payload;
      axios
        .post(url)
        .then(response => {
          const jwtToken = response.headers.authorization;
          const role = response.headers.role;
          const { exp } = parseJwt(jwtToken);
          const locale = response.headers.language;
          commit('setLocale', { locale });
          if (response.status === 200) {
            commit('setJwtToken', { jwtToken });
            commit('setRole', { role });
            window.localStorage.setItem('exp', exp);
            window.localStorage.setItem('jwtToken', jwtToken);
            window.localStorage.setItem('role', role);
            dispatch('getPartnerLogo');
            if (role === 'SHOP') {
              router.push(
                state.redirectPath !== '' ? state.redirectPath : '/shop',
              );
              commit('setRedirectPath', { redirectPath: '' });
            }
          }
          commit('alert/RESET_ALERT');
        })
        .catch(error => {
          console.log(error);
          commit('alert/SET_ALERT', {
            message: 'Invalid username or password',
          });
        })
        .catch(error => {
          console.log(error);
          commit('alert/SET_ALERT', {
            message: 'Invalid username or password',
          });
        });
    },
    logout: function({ commit }) {
      commit('logout');
      commit('alert/RESET_ALERT');
      window.localStorage.clear();
      router.push('/login');
    },
    findAllAssetGroups({ commit }) {
      commit('setLoading', true);
      const url = '/asset-group';
      return axios.get(url).finally(() => commit('setLoading', false));
    },
    saveAssetGroup({ commit }, assetGroup) {
      commit('setLoading', true);
      const url = '/asset-group';
      return axios
        .post(url, assetGroup)
        .finally(() => commit('setLoading', false));
    },
    findAssetGroupById({}, id) {
      const url = '/asset-group/' + id;
      return axios.get(url);
    },
    deleteAssetGroup({}, id) {
      const url = '/asset-group/' + id;
      return axios.delete(url);
    },
  },
  mutations: {
    setRedirectPath(state, { redirectPath }) {
      state.redirectPath = redirectPath;
    },
    setJwtToken: (state, { jwtToken }) => {
      state.jwtToken = jwtToken;
    },
    setRole: (state, { role }) => {
      state.role = role;
    },
    setLogo: (state, { logo }) => {
      state.logo = logo;
    },
    logout: state => {
      state.jwtToken = '';
      state.role = '';
      i18n.locale = 'en';
    },
    setLocale: (state, { locale }) => {
      localStorage.setItem('locale', locale);
      i18n.locale = locale;
    },
    setLoading: (state, value) => {
      state.loading = value;
    },
  },
});
export default store;
