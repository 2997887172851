<template>
  <div>
    <b-modal
      id="modal1"
      :title="titleText"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-only
      ok-title="Close"
      ok-variant="secondary"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="dark"
      body-text-variant="light"
    >
      <div class="row mb-1">
        <div class="col-sm-2">{{ bar.variant }}:</div>
        <div class="col-sm-10 pt-1">
          <b-progress
            :value="bar.value"
            :variant="bar.key"
            :key="bar.variant"
          ></b-progress>
          <div v-if="seen">{{ resultMessage }}</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ShopSendQueryModal',
  data() {
    return {
      bar: { key: 'success', variant: 'Progress', value: 0 },
      timer: null,
      titleText: 'Processing Query',
      resultMessage: '',
      seen: false,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.bar.value < 101) {
        this.bar.value = this.bar.value + (1 + Math.random() * 20);
      } else {
        this.titleText = 'Query Successfully Processed';
        clearInterval(this.timer);
        this.timer = null;
        this.resultMessage = 'Query Sent to Approvement Committee';
        this.seen = true;
      }
    }, 500);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped></style>
