<template>
  <div>
    <Header :role="role" :header="$t('ASSET_GROUPS')" showSettings />
    <b-container class="container-wide compliance-home">
      <b-row class="mt-4" align-h="end">
        <b-col md="4">
          <b-btn
            variant="primary"
            size="md"
            block
            :to="{ name: 'CreateAssetGroup' }"
            exact
            >{{ $t('CREATE_ASSET_GROUP') }}</b-btn
          >
        </b-col>
      </b-row>
      <b-card class="mt-5">
        <b-form>
          <b-form-group class="mb-0 search-form">
            <b-input-group class="search-input">
              <b-form-input
                v-model="filter"
                :placeholder="$t('TYPE_TO_SEARCH')"
              />
              <b-input-group-append>
                <b-btn
                  type="button"
                  variant="primary"
                  @click="filter = ''"
                  :disabled="!filter"
                  >{{ $t('CLEAR') }}</b-btn
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>
        <b-table
          outlined
          :items="assetGroups"
          show-empty
          :busy="loading"
          :fields="fields"
          :filter="filter"
          stacked="md"
          class="mt-2 mb-2"
          :empty-text="$t('NO_RECORDS')"
        >
          <template #table-busy>
            <RenderSpinner :fixed="false" />
          </template>
          <template #cell(name)="data">
            <h6>{{ data.item.name }}</h6>
          </template>
          <template #cell(subgroups)="data">
            <div
              v-for="subgroup in data.value"
              :key="subgroup.assetGroupId"
              :class="'status table-status'"
            >
              {{ subgroup.name }}
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="actions">
              <router-link
                :to="`/asset-group/${row.item.assetGroupId}`"
                v-b-tooltip.hover
                :title="$t('EDIT')"
              >
                <img alt="edit" src="../../assets/edit-icon.svg" />
              </router-link>
              <span v-b-tooltip.hover :title="$t('DELETE')">
                <a @click="openDeleteModal(row.item.assetGroupId)">
                  <img alt="delete" src="../../assets/delete-icon.svg" />
                </a>
              </span>
            </div>
          </template>
        </b-table>
        <RenderDeleteModal
          :show="showDeleteModal"
          @ok="closeDeleteModal(true)"
          @cancel="closeDeleteModal()"
          :label="$t('ARE_YOU_SURE_DELETE_ASSET_GROUP')"
        />
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { Header, RenderSpinner, RenderDeleteModal } from '@/components/common';
import { mapState, mapActions } from 'vuex';
import { ALERT } from '../../store/alert';

export default {
  name: 'AssetGroup',
  components: {
    Header,
    RenderSpinner,
    RenderDeleteModal,
  },
  data() {
    return {
      filter: '',
      assetGroups: [],
      selectedAssetGroup: '',
      showDeleteModal: false,
      fields: [
        {
          key: 'name',
          label: this.$t('ASSET_NAME'),
        },
        {
          key: 'subgroups',
          label: this.$t('ASSET_SUB_GROUPS'),
        },
        {
          key: 'actions',
          label: this.$t('ACTIONS'),
        },
      ],
    };
  },
  created() {
    this.fetchAssetGroups();
  },
  computed: {
    ...mapState(['loading']),
    role() {
      return localStorage.getItem('role');
    },
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    fetchAssetGroups() {
      this.$store
        .dispatch('findAllAssetGroups')
        .then(response => {
          this.assetGroups = response.data.filter(
            group => !group.superiorGroupId,
          );
        })
        .catch(error => {
          console.error(error);
        });
    },
    openDeleteModal(id) {
      this.showDeleteModal = true;
      this.selectedAssetGroup = id;
    },
    closeDeleteModal(doDelete) {
      this.showDeleteModal = false;
      if (doDelete) {
        this.deleteAssetGroup(this.selectedAssetGroup);
      }
    },
    deleteAssetGroup(id) {
      this.$store
        .dispatch('deleteAssetGroup', id)
        .then(() => {
          this.ALERT({
            message: this.$t('ASSET_GROUP_DELETED'),
            type: 'success',
          });
          this.fetchAssetGroups();
        })
        .catch(err => {
          console.error(err);
          this.ALERT({
            message: err.response.data,
          });
        });
    },
  },
};
</script>
