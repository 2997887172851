// import axios from 'axios'
import i18n from '../../i18n';

export const FIND_ALL_REASONS = 'FIND_ALL_REASONS';
export const UPDATE_ALL_REASONS = 'UPDATE_ALL_REASONS';
const SET_REASONS = 'SET_REASONS';

const state = {
  reasons: [],
};

export const actions = {
  async [FIND_ALL_REASONS]({ commit }) {
    // const response = await axios.get('/reasons/find-all')

    // Mockitud async response
    async function stall(stallTime = 500) {
      await new Promise(resolve => setTimeout(resolve, stallTime));
    }
    const getResponse = async () => {
      await stall();
      return {
        data: [
          {
            id: 1,
            en: 'Bought out',
            et: 'Ostis välja',
          },
          {
            id: 2,
            en: 'Expensive offer',
            et: 'Pakkumine kallis',
          },
          {
            id: 3,
            en: 'Chose competitor',
            et: 'Valis konkurendi',
          },
          {
            id: 4,
            en: 'Does not meet Täisteenusliisingu AS credit policy',
            et: 'Ei vasta Täisteenusliisingu AS krediidipoliitikale',
          },
        ],
      };
    };
    const response = await getResponse();
    //

    commit(SET_REASONS, response.data);
  },
  async [UPDATE_ALL_REASONS]({ commit, dispatch }, payload) {
    // const response = await axios.post('/reasons/update', payload)

    // Mockitud async response
    async function stall(stallTime = 500) {
      await new Promise(resolve => setTimeout(resolve, stallTime));
    }
    const getResponse = async () => {
      await stall();
      return {
        data: [
          {
            id: 1,
            en: 'No surety',
            et: 'Ei ole valmis käendama',
          },
          {
            id: 2,
            en: 'Tax debt',
            et: 'Maksuvõlg',
          },
          {
            id: 3,
            en: 'Company has loss',
            et: 'Ettevõte kahjumis',
          },
          {
            id: 4,
            en: 'Applies as private person',
            et: 'Taotleb eraisikuna',
          },
        ],
      };
    };
    const response = await getResponse();
    //

    commit(SET_REASONS, response.data);
    dispatch(
      'alert/ALERT',
      { message: i18n.t('REASONS_UPDATED'), type: 'success' },
      { root: true },
    );
  },
};

export const mutations = {
  [SET_REASONS](state, payload) {
    state.reasons = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
