<template>
  <b-container>
    <RenderSpinner v-if="$store.state.loading" />
    <h2 class="card-title">
      <div class="d-flex">
        <span class="text-nowrap">
          <figure class="card-title-icon">
            <img src="../../assets/loan.svg" class="card-title-image" />
          </figure>
          {{ $t('CREDIT_SHORT') }}
        </span>
        <b-form-group
          :label="$t('MANAGER_SHORT') + ':'"
          label-for="credit-query-manager"
          class="form-group-manager position-relative mb-0"
        >
          <b-form-select
            @change="changeManager()"
            :disabled="
              !isInhouse && viewName !== 'credit' && viewName !== 'compliance'
            "
            id="credit-query-manager"
            v-model="value.managerId"
            class="form-control number number-lg query-manager"
          >
            <option
              v-for="(partner, index) in inHousePartners"
              :key="index"
              :value="partner.accountUserId"
              >{{ partner.fullName }}</option
            >
          </b-form-select>
        </b-form-group>
      </div>
      <div class="info-holder">
        <span class="number number-lg mr-2"
          >{{ $t('CONTRACT_NUMBER') }}:
          <strong>{{ value.queryId }}</strong></span
        >
        <div :class="'status status-lg ' + statusStyle(value.status)">
          {{ $t(`QUERYSTATUS.${value.status}`) }}
        </div>
      </div>
    </h2>

    <ClientInfo :value="value" :viewName="viewName" />

    <AssetsInfo :value="value" :totalQassetPmt="totalQassetPmt" />

    <SellerInfo
      :seller="seller"
      :key="seller.sellerRegCode"
      v-for="seller in getDistinctSellers"
    />

    <b-card class="mt-4 card-padding-md">
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('SURETIES') }}
        </span>
      </h2>

      <div
        class="client-info"
        v-for="surety in value.sureties"
        :key="surety.suretyId"
      >
        <h5>{{ surety.name }}</h5>
        <b-row>
          <b-col>
            <b-list-group>
              <b-list-group-item
                ><div>{{ $t('ID_CODE') }}:</div>
                <div>{{ surety.personalCode }}</div></b-list-group-item
              >
              <b-list-group-item
                ><div>{{ $t('ADDRESS') }}:</div>
                <div>{{ surety.address }}</div></b-list-group-item
              >
            </b-list-group>
          </b-col>

          <b-col>
            <b-list-group>
              <b-list-group-item
                ><div>{{ $t('PHONE') }}:</div>
                <div>{{ surety.phone }}</div></b-list-group-item
              >
              <b-list-group-item
                ><div>{{ $t('EMAIL') }}:</div>
                <div>{{ surety.email }}</div></b-list-group-item
              >
            </b-list-group>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card class="mt-4 card-padding-md">
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('TERMS') }}
        </span>
      </h2>

      <div class="client-info">
        <b-row>
          <b-col>
            <b-list-group>
              <b-list-group-item
                ><div>{{ $t('PERIOD') }} ({{ $t('MONTHS') }}):</div>
                <div>{{ value.paymentPeriod }}</div></b-list-group-item
              >
              <b-list-group-item
                ><div>{{ $t('ADVANCE') }} (%):</div>
                <div>{{ value.advance }}</div></b-list-group-item
              >
              <b-list-group-item
                ><div>{{ $t('RESIDUAL') }} (%):</div>
                <div>{{ value.residual }}</div></b-list-group-item
              >
              <b-list-group-item v-if="viewName === 'credit'"
                ><div>{{ $t('COLLATERAL') }}:</div>
                <input
                  :disabled="disableOnStatus"
                  class="collateral-input"
                  :placeholder="$t('ENTER_COLLATERAL') + '...'"
                  v-model.trim="value.collateral"
                />
              </b-list-group-item>
              <b-list-group-item
                v-else-if="viewName !== 'credit' && value.collateral"
                ><div>{{ $t('COLLATERAL') }}:</div>
                <input
                  disabled
                  class="collateral-input"
                  :placeholder="$t('ENTER_COLLATERAL') + '...'"
                  v-model.trim="value.collateral"
                />
              </b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col>
            <b-list-group>
              <b-list-group-item
                ><div>{{ $t('INSURANCE') }} (%):</div>
                <div>
                  <div>{{ value.insurance }}</div>
                  <span v-if="value.insuranceValType === 1">{{
                    $t('PER_MONTH')
                  }}</span>
                  <span v-else>{{ $t('PER_YEAR') }}</span>
                </div>
              </b-list-group-item>
              <b-list-group-item>
                <div>{{ $t('INSURANCE_CATEGORY') }}:</div>
                <div>{{ value.insuranceProvider }}</div>
              </b-list-group-item>
              <b-list-group-item
                ><div>{{ $t('CONTRACT_FEE') }} (€):</div>
                <div>{{ value.contractFee }}</div></b-list-group-item
              >
              <b-list-group-item
                v-if="
                  isInhouse ||
                    viewName === 'credit' ||
                    viewName === 'compliance'
                "
              >
                <div>{{ $t('INTEREST') }} (%):</div>
                <div>{{ value.interest }}</div>
              </b-list-group-item>
              <b-list-group-item>
                <div>{{ $t('SPECIAL_TERMS') }}</div>
                <div>{{ value.specialTerms }}</div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row
          align-v="center"
          v-if="isInhouse || viewName === 'credit' || viewName === 'compliance'"
        >
          <b-col md="2">
            <span class="font-weight-bold">{{ $t('OFFER_TYPE') }}</span>
          </b-col>
          <b-col md="10">
            <b-form-select
              v-if="isInhouse"
              v-model="offerType"
              class="form-control number query-manager"
              :options="offerTypes"
            >
            </b-form-select>
          </b-col>
        </b-row>
      </div>

      <div class="card-footer">
        <div class="total-terms-badge-group">
          <div class="total-terms-badge">
            <span class="total-terms-text">{{ $t('TOTAL_MONTHLY') }}</span>
            <div class="total-terms-tag total-terms-tag-white">
              {{ value.pmtAmount }}&nbsp;€
            </div>
          </div>
          <div class="total-terms-badge">
            <span class="total-terms-text">Kickback</span>
            <div class="total-terms-tag total-terms-tag-white">
              {{ computedKickback }}&nbsp;€
            </div>
          </div>
        </div>
        <b-row align-h="center" class="mt-4">
          <b-col md="4">
            <b-btn @click="togglePaymentModal" block class="btn-shadow-corner">
              <img
                src="../../assets/small-calendar.svg"
                class="btn-image img-fluid"
              />
              {{ $t('PAYMENT_SCHEDULE') }}
            </b-btn>
          </b-col>
          <b-col md="4">
            <b-btn
              @click="generateOffer(offerType)"
              block
              class="btn-shadow-corner"
              :disabled="
                partnerSettings.accountUserId === 20 ||
                  partnerSettings.accountUserId === 19
              "
            >
              <img src="../../assets/medal.svg" class="btn-image img-fluid" />
              {{ $t('GENERATE_OFFER') }}
            </b-btn>
          </b-col>
        </b-row>
      </div>

      <payment-table-modal
        :show="isPaymentModalOpen"
        @hide="togglePaymentModal"
        :value="value"
        :lendifyInsurance="lendifyInsurance"
      />
    </b-card>

    <LendifyContract :value="value" />

    <b-card
      class="mt-4 card-padding-md"
      :hidden="viewName === 'partner' && !isInhouse && value.status === 'N'"
    >
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('SIGNED_DOCUMENTS') }}
        </span>
      </h2>

      <b-list-group class="files" v-if="localContainers.length > 0">
        <b-list-group-item
          href="#"
          v-for="(file, index) in localContainers"
          :key="index"
          @click="
            DOWNLOAD_CONTAINER({
              queryId: value.queryId,
              containerUUID: file.containerUUID,
              fileName: file.fileName,
            })
          "
        >
          <img src="../../assets/docs1.svg" />
          <span>{{ file.fileName }}</span>
          <span>Allkirjastatud: {{ format(file.allSigned) }}</span>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-card class="mt-4">
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('ATTACHED_FILES') }}
        </span>
      </h2>

      <b-list-group class="files">
        <b-btn
          v-if="documents.length > 0"
          class="btn-sm pt-0 pb-0 mb-1 align-self-end"
          @click="selectAllDocuments"
          >{{
            allDocumentsSelected ? $t('DESELECT_ALL') : $t('SELECT_ALL')
          }}</b-btn
        >
        <b-list-group-item v-for="(file, index) in documents" :key="index">
          <img src="../../assets/docs1.svg" />
          <span>{{ file.fileName }}</span>
          <div class="actions right-45" @click="DOWNLOAD_FILE(file.docFileId)">
            <a title="" data-original-title="Show" class="show"
              ><img src="../../assets/eye.svg" alt="download"
            /></a>
          </div>
          <b-form-checkbox
            class="float-right"
            v-model.trim="fileIds"
            :value="file.docFileId"
          ></b-form-checkbox>
        </b-list-group-item>
        <b-row align-h="center" class="mt-3">
          <b-col md="4">
            <b-btn
              v-if="documents.length > 0"
              type="button"
              class="btn-shadow-corner"
              block
              @click="DOWNLOAD_SELECTED_FILES(fileIds)"
              >{{ $t('DOWNLOAD') }}</b-btn
            >
          </b-col>
        </b-row>
      </b-list-group>
    </b-card>

    <Contracts
      v-model="value"
      v-if="partnerSettings !== null"
      @changeStatus="payload => $emit('changeStatus', payload)"
      :documents="documents"
      :viewName="viewName"
      :isInhouse="isInhouse"
      page="CreditTab"
    />

    <b-card
      class="mt-4 card-padding-md"
      :hidden="viewName === 'partner' && !isInhouse && value.status === 'N'"
    >
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('ADDITIONAL_INFO') }}
        </span>
      </h2>

      <div class="card-footer">
        <b-form-textarea
          v-model="value.additionalInfo"
          :placeholder="$t('ENTER_SOMETHING')"
          :rows="2"
          :max-rows="12"
          :disabled="disableOnStatus && disableAdditionalInfo"
        >
        </b-form-textarea>
        <b-row align-h="center" class="mt-3">
          <b-col md="4">
            <b-btn
              type="button"
              class="btn-shadow-corner"
              block
              @click="saveQuery()"
              >{{ $t('SAVE_INFORMATION') }}</b-btn
            >
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-row class="mt-2">
      <b-col
        md="6"
        v-if="(viewName === 'partner' && isInhouse) || viewName === 'credit'"
      >
        <b-button
          class="mt-3"
          @click="
            $emit('changeStatus', {
              status: 'CR',
              alert: $t('CONTRACT_REQUESTED'),
              queryId: value.queryId,
            })
          "
          :disabled="requestContractDisabled.includes(value.status)"
          variant="secondary"
          size="lg"
          block
          >{{ $t('REQUEST_CONTRACT') }}</b-button
        >
      </b-col>
      <b-col md="6" v-if="viewName === 'credit'">
        <b-button
          class="mt-3"
          @click="
            $emit('changeStatus', {
              status: value.aplink === null ? 'QA' : 'HO',
              alert: $t('QUERYSTATUS.QA'),
              queryId: value.queryId,
            })
          "
          :disabled="
            partnerStatuses.includes(value.status) ||
              complianceStatuses.includes(value.status)
          "
          variant="secondary"
          size="lg"
          block
          >{{ $t('APPROVE') }}</b-button
        >
      </b-col>
      <b-col md="6" v-if="viewName === 'credit'">
        <b-button
          class="mt-3"
          @click="
            $emit('changeStatus', {
              status: 'QAC',
              alert: $t('QUERYSTATUS.QAC'),
              queryId: value.queryId,
            })
          "
          :disabled="
            partnerStatuses.includes(value.status) ||
              complianceStatuses.includes(value.status)
          "
          variant="secondary"
          size="lg"
          block
          >{{ $t('APPROVE_WITH_CHANGES') }}</b-button
        >
      </b-col>
      <b-col md="6" v-if="viewName === 'credit'">
        <b-button
          class="mt-3"
          @click="
            $emit('changeStatus', {
              status: 'SCR',
              alert: $t('APPROVEMENT_CANCELLED'),
              queryId: value.queryId,
            })
          "
          :disabled="value.status !== 'QA' && value.status !== 'QAC'"
          variant="secondary"
          size="lg"
          block
          >{{ $t('CANCEL_APPROVEMENT') }}</b-button
        >
      </b-col>
      <b-col
        md="6"
        v-if="
          (viewName === 'partner' &&
            isInhouse &&
            ['P', 'SM'].includes(value.status)) ||
            viewName === 'credit'
        "
      >
        <b-button
          class="mt-3"
          @click="
            $emit('changeStatus', {
              status: 'N',
              alert: $t('QUERYSTATUS.N'),
              queryId: value.queryId,
            })
          "
          :disabled="
            partnerStatuses.includes(value.status) ||
              complianceStatuses.includes(value.status)
          "
          variant="danger"
          size="lg"
          block
        >
          {{ $t('CLOSE_QUERY') }}
        </b-button>
      </b-col>
      <b-col
        md="6"
        v-if="
          (viewName === 'partner' &&
            isInhouse &&
            ['N'].includes(value.status)) ||
            viewName === 'credit'
        "
      >
        <b-button
          class="mt-3"
          @click="
            $emit('changeStatus', {
              alert: $t('QUERY_REOPENED'),
              queryId: value.queryId,
            })
          "
          :disabled="value.status !== 'N'"
          variant="secondary"
          size="lg"
          block
          >{{ $t('REOPEN_QUERY') }}</b-button
        >
      </b-col>
    </b-row>

    <QueryCloseModal
      :initialValue="value.closingReason"
      :reasons="reasons"
      :show="isQueryCloseModalOpen"
      @cancel="isQueryCloseModalOpen = false"
      @closeQuery="handleQueryClose"
    />

    <b-card class="mt-4 mb-5">
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('CHAT') }}
        </span>
      </h2>

      <div
        class="chat-box"
        :class="{ right: index % 2 === 1 }"
        v-for="(message, index) in sortedMessages"
        :key="index"
      >
        <div class="chat-item">
          <div class="icon-box">
            <div class="icon">
              <span>{{ getInitials(message.createdBy) }}</span>
            </div>
            <div class="name">{{ message.createdBy }}</div>
            <div class="time-box">
              <div class="calender">
                <img src="../../assets/small-calendar.svg" />{{
                  message.createdDate
                }}
              </div>
              <div class="time">
                <img src="../../assets/clock.svg" />{{ message.createdTime }}
              </div>
            </div>
          </div>

          <div class="text-box">
            <div class="text">{{ message.content }}</div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <b-form-textarea
          v-model="newMessage.content"
          :placeholder="$t('ENTER_SOMETHING')"
          :rows="2"
          :max-rows="4"
        >
        </b-form-textarea>
        <b-row align-h="center" class="mt-3">
          <b-col md="4">
            <b-btn
              type="button"
              class="btn-shadow-corner"
              block
              @click="saveMessage()"
              >{{ $t('SEND_MESSAGE') }}</b-btn
            >
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ALERT } from '../../store/alert';
import { queryStatusClass, distinctSellers, disableOnStatus } from '@/utils';
import { Contracts } from '@/components';
import {
  RenderSpinner,
  PaymentTableModal,
  QueryCloseModal,
} from '@/components/common';

import {
  ClientInfo,
  AssetsInfo,
  SellerInfo,
  LendifyContract,
} from '@/components/creditInfo';
import {
  DOWNLOAD_FILE,
  DOWNLOAD_SELECTED_FILES,
  DOWNLOAD_CONTAINER,
} from '../../store/document';
import { FIND_ALL_REASONS } from '../../store/reason';
import { SAVE_MESSAGE } from '../../store/message';
import { CHANGE_MANAGER } from '../../store/query';

export default {
  name: 'CreditTab',
  props: [
    'value',
    'viewName',
    'partnerSettings',
    'documents',
    'financiers',
    'financier',
    'inHousePartners',
    'isInhouse',
  ],
  components: {
    RenderSpinner,
    PaymentTableModal,
    QueryCloseModal,
    ClientInfo,
    AssetsInfo,
    SellerInfo,
    LendifyContract,
    Contracts,
  },
  data: function() {
    return {
      partnerStatuses: ['MICR', 'MICO', 'N'],
      creditStatuses: ['SCR'],
      complianceStatuses: ['SCO'],
      requestContractDisabled: ['SCR', 'N', 'P', 'CA', 'YS', 'CR'],
      isPaymentModalOpen: false,
      fileIds: [],
      isQueryCloseModalOpen: false,
      localContainers: [],
      offerType: '8',
      offerTypes: [
        { value: '8', text: 'Default' },
        { value: '17', text: 'iDeal' },
        { value: '19', text: 'Standard' },
      ],
    };
  },
  mounted() {
    this.FIND_ALL_REASONS();
  },
  created() {
    this.$store
      .dispatch('findLocalContainers', this.value.queryId)
      .then(response => {
        this.localContainers = response.data;
      });
  },

  methods: {
    ...mapActions('alert', [ALERT]),
    ...mapActions('document', [
      DOWNLOAD_FILE,
      DOWNLOAD_SELECTED_FILES,
      DOWNLOAD_CONTAINER,
    ]),
    ...mapActions('message', [SAVE_MESSAGE]),
    ...mapActions('query', [CHANGE_MANAGER]),
    ...mapActions('reason', [FIND_ALL_REASONS]),
    handleQueryClose: function(closingReason) {
      this.isQueryCloseModalOpen = false;
      this.$emit('changeStatus', {
        status: 'N',
        closingReason,
        alert: this.$t('QUERYSTATUS.N'),
        queryId: this.value.queryId,
      });
    },
    format: function(dateStr) {
      let date = new Date(dateStr);

      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0'); // Note: months are 0-based in JavaScript
      let year = date.getFullYear();

      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
    getInitials: function(name) {
      return name
        .split(' ')
        .map(function(item) {
          return item[0];
        })
        .join('');
    },
    months: function() {
      let array = [];
      for (let i = 1; i <= 31; i++) {
        array.push(i);
      }
      return array;
    },
    statusStyle(status) {
      return queryStatusClass(status);
    },
    generateOffer(offerType) {
      this.$store.dispatch('generateOffer', {
        queryId: this.value.queryId,
        offerType,
      });
    },
    async saveMessage() {
      if (this.newMessage.content.length > 0) {
        const savedMessage = await this.SAVE_MESSAGE({
          message: this.newMessage,
          queryId: this.value.queryId,
        });
        this.value.messages.push(savedMessage);
        this.newMessage.content = '';
      }
    },
    saveQuery: function() {
      this.$nextTick(() => {
        this.$emit('saveQuery');
      });
    },
    async changeManager() {
      this.$nextTick(async () => {
        await this.CHANGE_MANAGER({
          queryId: this.value.queryId,
          managerId: this.value.managerId,
        });
      });
    },
    togglePaymentModal() {
      this.isPaymentModalOpen = !this.isPaymentModalOpen;
    },
    selectAllDocuments() {
      if (this.allDocumentsSelected) {
        this.fileIds = [];
      } else {
        this.documents.forEach(document => {
          if (this.fileIds.indexOf(document.docFileId) === -1) {
            this.fileIds.push(document.docFileId);
          }
        });
      }
    },
  },
  computed: {
    ...mapState('alert', ['message']),
    ...mapState('message', ['newMessage']),
    ...mapState('reason', ['reasons']),
    totalQassetPmt: function() {
      if (this.value.qassets) {
        return this.value.qassets
          .reduce((acc, item) => acc + item.qassetPrice * item.quantity, 0)
          .toFixed(2);
      }
    },
    totalPmt: function() {
      return this.totalQassetPmt;
    },
    computedInsurance: function() {
      if (this.value.insuranceValType === 1) {
        return (
          this.totalPmt *
          (parseFloat(this.value.insurance) / 100) *
          this.value.paymentPeriod
        );
      } else {
        return (
          this.totalPmt *
          (parseFloat(this.value.insurance) / 100 / 12) *
          this.value.paymentPeriod
        );
      }
    },
    lendifyInsurance: function() {
      return (this.computedKickback + this.computedInsurance).toFixed(2);
    },
    computedCommission: function() {
      return (
        ((this.lendifyInsurance - parseFloat(this.value.servicesCost)) /
          this.value.paymentPeriod) *
        12
      ).toFixed(2);
    },
    computedKickback: function() {
      return (
        ((parseFloat(this.value.kickback) / 100) * this.totalPmt).toFixed(2) / 1
      );
    },
    sortedMessages: function() {
      if (this.value.messages) {
        return this.value.messages.slice().sort(function(a, b) {
          var dateA = new Date(a.createdDtime);
          var dateB = new Date(b.createdDtime);
          return dateA > dateB ? 1 : -1;
        });
      }
    },
    disableOnStatus() {
      return disableOnStatus(
        this.viewName,
        this.partnerSettings,
        this.value,
        this.isInhouse,
      );
    },
    disableAdditionalInfo: function() {
      if (this.viewName && this.partnerSettings && this.value) {
        if (this.viewName === 'partner') return this.value.status === 'N';
      }
    },
    allDocumentsSelected: function() {
      return this.documents.length === this.fileIds.length;
    },
    getDistinctSellers() {
      return distinctSellers(this.value.qassets);
    },
  },
};
</script>

<style scoped></style>
