<template>
  <b-card class="card-padding-md mt-4">
    <h2 class="card-title card-title-pulled-left">
      <span>
        <figure class="card-title-icon">
          <img
            src="../../assets/package-icon.svg"
            class="card-title-image"
            alt="terms"
          />
        </figure>
        {{ $t('GUARANTOR') }}
      </span>
      <b-button v-b-toggle.toggleGuarantor
        >{{ $t('ADD') + ' ' + $t('GUARANTOR') }}
      </b-button>
    </h2>
    <b-collapse id="toggleGuarantor">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('NAME') + ':'" label-for="guarantorName">
              <b-form-input
                id="guarantorName"
                v-model.trim="(value.guarantor || {}).name"
                :disabled="disableOnStatus"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('REGISTRY_CODE') + ':'"
              label-for="guarantorRegistryCode"
            >
              <b-form-input
                id="guarantorRegistryCode"
                v-model.trim="(value.guarantor || {}).registryCode"
                :disabled="disableOnStatus"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('ADDRESS') + ':'"
              label-for="guarantorAddress"
            >
              <b-form-input
                id="guarantorAddress"
                v-model.trim="(value.guarantor || {}).address"
                :disabled="disableOnStatus"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('EMAIL') + ':'" label-for="guarantorEmail">
              <b-form-input
                id="guarantorEmail"
                v-model.trim="(value.guarantor || {}).email"
                :disabled="disableOnStatus"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('PHONE') + ':'" label-for="guarantorPhone">
              <b-form-input
                id="guarantorPhone"
                v-model.trim="(value.guarantor || {}).phone"
                :disabled="disableOnStatus"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3>{{ $t('REPRESENTATIVE') }}</h3>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('NAME') + ':'"
              label-for="guarantorRepresentativeName"
            >
              <b-form-input
                id="guarantorRepresentativeName"
                v-model.trim="(value.guarantor || {}).representativeName"
                :disabled="disableOnStatus"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('ID_CODE') + ':'"
              label-for="guarantorRepresentativeIdCode"
            >
              <b-form-input
                id="guarantorRepresentativeIdCode"
                v-model.trim="
                  (value.guarantor || {}).representativePersonalCode
                "
                :disabled="disableOnStatus"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('EMAIL') + ':'"
              label-for="guarantorRepresentativeEmail"
            >
              <b-form-input
                id="guarantorRepresentativeEmail"
                v-model.trim="(value.guarantor || {}).representativeEmail"
                :disabled="disableOnStatus"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="primary" block @click="saveGuarantor">
              {{ $t('SAVE') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: 'Guarantor',
  props: ['value', 'disableOnStatus'],
  data() {
    return {
      guarantor: {},
    };
  },
  methods: {
    saveGuarantor() {
      this.$store
        .dispatch(
          'guarantor/SAVE_GUARANTOR',
          { ...this.value.guarantor, queryId: this.value.queryId },
          { root: true },
        )
        .then(() => {
          this.$store.dispatch(
            'alert/ALERT',
            { message: 'Guarantor saved successfully', type: 'success' },
            { root: true },
          );
        });
    },
  },
};
</script>

<style></style>
