export const ALERT = 'ALERT';
export const LONG_ALERT = 'LONG_ALERT';
export const RESET_ALERT = 'RESET_ALERT';

const state = {
  message: null,
  type: 'error',
};

export const actions = {
  [LONG_ALERT]({ commit }, payload) {
    commit('LONG_ALERT', payload);
    setTimeout(() => commit('RESET_ALERT'), 15000);
  },
  [ALERT]({ commit }, payload) {
    commit('SET_ALERT', payload);
    setTimeout(() => commit('RESET_ALERT'), 6000);
  },
  [RESET_ALERT]({ commit }) {
    commit('RESET_ALERT', null);
  },
};

export const mutations = {
  LONG_ALERT(state, payload) {
    state.message = payload.message;
    state.type = payload.type || state.type;
  },
  SET_ALERT(state, payload) {
    state.message = payload.message;
    state.type = payload.type || state.type;
  },
  RESET_ALERT(state) {
    state.message = null;
    state.type = 'error';
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
