<template>
  <div>
    <b-form-input
      readonly
      type="text"
      class="form-control delivery-date-picker"
      placeholder=""
      :value="formatDeliveryDate(deliveryDate)"
      :id="id"
    ></b-form-input>
    <b-popover placement="auto" :target="id" triggers="focus" boundary="window">
      <datepicker
        :id="id"
        v-model.trim="deliveryDate"
        :disabled="disableOnStatus && disableDeliveryInfo"
        inline
        clear-button
      >
      </datepicker>
    </b-popover>
  </div>
</template>

<script>
import { timeConverter, disableOnStatus } from '../../utils';
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'RenderDatePicker',
  props: ['date', 'query', 'viewName', 'partnerSettings', 'id'],
  components: {
    Datepicker,
  },
  data: function() {
    return {
      deliveryDate: this.date,
    };
  },
  computed: {
    disableOnStatus() {
      return disableOnStatus(
        this.viewName,
        this.partnerSettings,
        this.value,
        JSON.parse(window.localStorage.getItem('isInhouse')),
      );
    },
    disableDeliveryInfo: function() {
      return this.value.status === 'CA';
    },
  },
  methods: {
    formatDeliveryDate(date) {
      this.$emit('changeDate', date);
      return timeConverter(date);
    },
  },
};
</script>

<style scoped></style>
