<template>
  <b-container>
    <RenderSpinner v-if="loading" />

    <h2 class="card-title">
      <div class="d-flex">
        <span class="text-nowrap">
          <figure class="card-title-icon">
            <img src="../../assets/loan.svg" class="card-title-image" />
          </figure>
          {{ $t('ACT_OF_ACCEPTANCE') }}
        </span>
      </div>
    </h2>

    <ClientInfo :value="value" :viewName="viewName" />

    <SellerInfo
      :seller="seller"
      :key="seller.sellerRegCode"
      v-for="seller in getDistinctSellers"
    />

    <AssetsInfo :value="value" :totalQassetPmt="totalQassetPmt" />

    <b-card class="my-4 card-padding-md">
      <h2 class="card-title card-title-pulled-left">
        <span>
          <figure class="card-title-icon">
            <img src="../../assets/package-icon.svg" class="card-title-image" />
          </figure>
          {{ $t('ACT_OF_ACCEPTANCE') }}
        </span>
      </h2>

      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('ASSET_DESCRIPTION')"
            label-for="assetDescription"
          >
            <b-form-input
              id="assetDescription"
              v-model.trim="actOfAcceptanceData.assetDescription"
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('SERIAL_NUMBER')" label-for="serialNumber">
            <b-form-input
              id="serialNumber"
              v-model.trim="actOfAcceptanceData.serialNumber"
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('DELIVERY_DATE')" label-for="deliveryDate">
            <RenderDatePicker
              @changeDate="e => $set(actOfAcceptanceData, 'deliveryDate', e)"
              :date="actOfAcceptanceData.deliveryDate"
              :viewName="viewName"
              :partnerSettings="partnerSettings"
              id="actOfAcceptance"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('DELIVERY_PLACE')" label-for="deliveryPlace">
            <b-form-input
              id="deliveryPlace"
              v-model.trim="actOfAcceptanceData.deliveryPlace"
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('YEAR')" label-for="deliveryYear">
            <b-form-input
              id="deliveryYear"
              v-model.trim="actOfAcceptanceData.year"
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-checkbox
            v-model="value.includeSellerInAoa"
            @change="handleCheckboxChnge"
            class="mt-4"
          >
            {{ $t('INCLUDE_SELLER_IN_CONTRACT') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>

    <Contracts
      v-model="value"
      v-if="partnerSettings !== null"
      @changeStatus="payload => $emit('changeStatus', payload)"
      :actOfAcceptanceData="actOfAcceptanceData"
      :documents="documents"
      :viewName="viewName"
      :isInhouse="isInhouse"
      page="ActOfAcceptanceTab"
    />
  </b-container>
</template>

<script>
import { Contracts } from '@/components';
import { ClientInfo, SellerInfo, AssetsInfo } from '@/components/creditInfo';
import { RenderSpinner, RenderDatePicker } from '@/components/common';
import { distinctSellers } from '@/utils';
import { mapState } from 'vuex';

export default {
  name: 'ActOfAcceptanceTab',
  props: [
    'value',
    'viewName',
    'partnerSettings',
    'documents',
    'isInhouse',
    'saveQuery',
    'changeStatus',
  ],
  components: {
    RenderSpinner,
    ClientInfo,
    SellerInfo,
    RenderDatePicker,
    AssetsInfo,
    Contracts,
  },
  data() {
    return {
      actOfAcceptanceData: {
        assetDescription: '',
        deliveryDate: null,
        deliveryPlace: '',
        serialNumber: '',
        year: '',
      },
    };
  },
  methods: {
    saveAssets() {
      this.$emit('saveQuery', () => {
        this.$store.dispatch(
          'alert/ALERT',
          { message: 'Saved', type: 'success' },
          { root: true },
        );
      });
    },
    handleCheckboxChnge() {
      this.$emit('saveQuery');
    },
    generateActOfAcceptance(actOfAcceptanceData) {
      this.$store.dispatch('generateActOfAcceptance', {
        ...actOfAcceptanceData,
        queryId: this.value.queryId,
      });
    },
  },
  computed: {
    ...mapState(['loading']),
    getDistinctSellers() {
      return distinctSellers(this.value.qassets);
    },
    totalQassetPmt: function() {
      if (this.value.qassets) {
        return this.value.qassets
          .reduce((acc, item) => acc + item.qassetPrice * item.quantity, 0)
          .toFixed(2);
      }
    },
  },
};
</script>

<style></style>
