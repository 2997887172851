import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import App from './App';
import router from './router';
import i18n from './i18n';
import VeeValidate, { Validator } from 'vee-validate';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store/store.js';
import { axiosSetup, validateId } from './utils';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import './assets/css/style.scss';

axiosSetup();

Validator.extend('idcode', value => validateId(value));

Vue.use(Vuex);
Vue.use(VueSession);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields', events: 'change' });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  store,
  router,
  i18n,
  template: '<App/>',
  components: { App },
});
