import Vue from 'vue';
import VueRouter from 'vue-router';
import { getCurrentUNIX } from '../utils';
import store from '../store/store';
import {
  CreatePassword,
  ManagePartners,
  EditPartners,
  ManageUsers,
  EditUser,
  ComplianceEditQuery,
  CreditEditQuery,
  PartnerCreateQuery,
  SelfServeApplication,
  SelfServeOffer,
  PartnerEditQuery,
  ShopCreateQuery,
  ShopEditQuery,
  ErrorPage,
  Login,
  Home,
  Settings,
  Templates,
  EditTemplate,
  ManageReasons,
  AssetGroups,
  EditAssetGroup,
  RemoteLogin,
} from '@/components';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: '/partner',
      name: 'PartnerHome',
      component: Home,
      meta: {
        requiresAuth: true,
        partnerAuth: true,
        shopAuth: false,
        complianceAuth: false,
        creditAuth: false,
      },
    },
    {
      path: '/shop',
      name: 'ShopHome',
      component: Home,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: true,
        complianceAuth: false,
        creditAuth: false,
      },
    },
    {
      path: '/selfserve/offers/:id',
      name: 'SelfServeOfferEdit',
      component: SelfServeOffer,
      meta: {
        requiresAuth: true,
        partnerAuth: true,
        shopAuth: true,
        complianceAuth: true,
        creditAuth: true,
      },
    },
    {
      path: '/selfserve/offers',
      name: 'SelfServeOffer',
      component: SelfServeOffer,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: true,
        complianceAuth: false,
        creditAuth: false,
      },
    },
    {
      path: '/selfserve/application',
      name: 'SelfServeApplication',
      component: SelfServeApplication,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: true,
        complianceAuth: false,
        creditAuth: false,
      },
    },
    {
      path: '/partner/queries/create',
      name: 'PartnerCreateQuery',
      component: PartnerCreateQuery,
      meta: {
        requiresAuth: true,
        partnerAuth: true,
        shopAuth: false,
        complianceAuth: false,
        creditAuth: false,
      },
    },
    {
      path: '/shop/queries/create',
      name: 'ShopCreateQuery',
      component: ShopCreateQuery,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: true,
        complianceAuth: false,
        creditAuth: false,
      },
    },
    {
      path: '/partner/query/:id/edit',
      name: 'PartnerEditQuery',
      component: PartnerEditQuery,
      meta: {
        requiresAuth: true,
        partnerAuth: true,
        shopAuth: false,
        complianceAuth: false,
        creditAuth: false,
      },
    },
    {
      path: '/shop/query/:id/edit',
      name: 'ShopEditQuery',
      component: ShopEditQuery,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: true,
        complianceAuth: false,
        creditAuth: false,
      },
    },
    {
      path: '/login',
      alias: '/',
      name: 'Login',
      component: Login,
    },
    {
      path: '/remote-login-landing',
      name: 'RemoteLogin',
      component: RemoteLogin,
    },
    {
      path: '/credit/home',
      name: 'CreditHome',
      component: Home,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: false,
        creditAuth: true,
      },
    },
    {
      path: '/credit/query/:id/edit',
      name: 'CreditEditQuery',
      component: CreditEditQuery,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: false,
        creditAuth: true,
      },
    },
    {
      path: '/compliance/home',
      name: 'ComplianceHome',
      component: Home,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/compliance/partner/:id/edit',
      name: 'EditPartners',
      component: EditPartners,
      props: { newPartner: false },
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/compliance/partner',
      name: 'ManagePartners',
      component: ManagePartners,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/compliance/partner/create',
      name: 'CreatePartner',
      component: EditPartners,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/users',
      name: 'ManageUsers',
      component: ManageUsers,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/user/:id/edit',
      name: 'EditUserSettings',
      component: EditUser,
      props: { newUser: false },
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/user/create',
      name: 'CreateUser',
      component: EditUser,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/asset-groups',
      name: 'ManageAssetGroups',
      component: AssetGroups,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/asset-groups/create',
      name: 'CreateAssetGroup',
      component: EditAssetGroup,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/asset-group/:id',
      name: 'EditAssetGroup',
      component: EditAssetGroup,
      props: { newAssetGroup: false },
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/register/create-password/:id',
      name: 'CreatePassword',
      component: CreatePassword,
      meta: { requiresAuth: false },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: {
        requiresAuth: true,
        partnerAuth: true,
        shopAuth: true,
        complianceAuth: true,
        creditAuth: true,
      },
    },
    {
      path: '/compliance/query/:id/edit',
      name: 'ComplianceEditQuery',
      component: ComplianceEditQuery,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '/templates',
      name: 'Templates',
      component: Templates,
      meta: {
        requiresAuth: true,
        partnerAuth: true,
        shopAuth: true,
        complianceAuth: true,
        creditAuth: true,
      },
    },
    {
      path: '/templates/:id/edit',
      name: 'EditTemplate',
      component: EditTemplate,
      meta: {
        requiresAuth: true,
        partnerAuth: true,
        shopAuth: true,
        complianceAuth: true,
        creditAuth: true,
      },
      props: true,
    },
    {
      path: '/reasons',
      name: 'ManageReasons',
      component: ManageReasons,
      meta: {
        requiresAuth: true,
        partnerAuth: false,
        shopAuth: false,
        complianceAuth: true,
        creditAuth: false,
      },
    },
    {
      path: '*',
      name: 'ErrorPage',
      component: ErrorPage,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const jwtToken = window.localStorage.getItem('jwtToken');
  const role = window.localStorage.getItem('role');
  const exp = window.localStorage.getItem('exp') || 0;

  if (to.name === 'CreatePassword') return next();
  if (to.name === 'RemoteLogin') return next();

  if (
    !['Login'].includes(from.name) &&
    !['Login'].includes(to.name) &&
    (jwtToken === '' || jwtToken === null)
  ) {
    store.commit('setRedirectPath', { redirectPath: to.fullPath });
  }

  if (!to.meta.requiresAuth) {
    if (to.name === 'ErrorPage') {
      return next();
    }
    next();
  }
  if (jwtToken === '' || jwtToken === null) {
    return next({ name: 'Login' });
  } else if (getCurrentUNIX() > +exp) {
    store.dispatch('logout');
  }

  switch (role) {
    case 'PARTNER':
      to.meta.partnerAuth ? next() : next({ name: 'PartnerHome' });
      break;
    case 'SHOP':
      to.meta.shopAuth ? next() : next({ name: 'ShopHome' });
      break;
    case 'COMPLIANCE':
      to.meta.complianceAuth ? next() : next({ name: 'ComplianceHome' });
      break;
    case 'CREDIT':
      to.meta.creditAuth ? next() : next({ name: 'CreditHome' });
      break;
  }
});

export default router;
