<template>
  <b-form @submit.prevent="handleSubmit" data-vv-scope="templatesModal">
    <b-modal
      v-model="isOpen"
      @hide="resetModal"
      size="lg"
      :title="$t('TEMPLATES')"
      ref="modal"
    >
      <template>
        <b-form-select
          class="mb-3 template-select-arrow"
          :disabled="disableOnStatus"
          v-model="selectedTemplate"
          :options="mappedTemplates"
          @change="chooseAction('existing')"
        >
          <template slot="first">
            <option :value="null" disabled>{{ $t('SELECT_TEMPLATE') }}</option>
          </template>
        </b-form-select>
        <template
          v-if="selectedTemplate !== 'null' && selectedAction !== 'new'"
        >
          <div
            v-for="(assets, index) in computedSelectedTemplate"
            :key="index"
            class="mt-4"
          >
            <h6>{{ $t(index.toUpperCase()) }}</h6>
            <template v-if="index === 'assets'">
              <ul
                class="list-group terms border-bottom"
                v-for="(asset, assetKey) in assets"
                :key="assetKey"
              >
                <li
                  v-for="(prop, index) in asset"
                  :key="index"
                  class="list-group-item"
                >
                  <b-row align-v="center">
                    <b-col md="4" class="text-right">
                      <span>{{ $t(snakeCase(index).toUpperCase()) }}</span>
                    </b-col>
                    <b-col md="8">
                      {{ prop }}
                    </b-col>
                  </b-row>
                </li>
              </ul>
            </template>
            <template v-if="index === 'terms'">
              <ul class="list-group terms">
                <li
                  v-for="(prop, index) in assets"
                  :key="index"
                  class="list-group-item"
                >
                  <b-row align-v="center">
                    <b-col md="4" class="text-right">
                      <span v-if="index === 'paymentPeriod'"
                        >{{ $t('PERIOD') }} ({{ $t('MONTHS') }})</span
                      >
                      <span v-else
                        >{{ $t(snakeCase(index).toUpperCase()) }} (%)</span
                      >
                    </b-col>
                    <b-col md="8">
                      {{ prop }}
                    </b-col>
                  </b-row>
                </li>
              </ul>
            </template>
          </div>
        </template>
      </template>

      <template v-if="selectedAction === 'new'">
        <b-card class="mt-3">
          <template>
            <b-form-group
              :label="$t('TEMPLATE_NAME')"
              label-for="newTemplateName"
              :invalid-feedback="$t('VALIDATION.TEMPLATE_NAME_REQUIRED')"
              :state="validateState('newTemplateName')"
            >
              <b-form-input
                type="text"
                v-model="newTemplateName"
                name="newTemplateName"
                id="newTemplateName"
                v-validate="'required'"
              />
            </b-form-group>

            <div v-for="(assets, index) in newData" :key="index" class="mt-4">
              <h6>{{ $t(index.toUpperCase()) }}</h6>
              <template v-if="index === 'assets'">
                <ul
                  class="list-group terms border-bottom"
                  v-for="(asset, assetKey) in assets"
                  :key="assetKey"
                >
                  <li
                    v-for="(prop, index) in asset"
                    :key="index"
                    class="list-group-item"
                  >
                    <b-row align-v="center">
                      <b-col md="4" class="text-right">
                        <span>{{ $t(snakeCase(index).toUpperCase()) }}</span>
                      </b-col>
                      <b-col md="8">
                        {{ prop }}
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </template>
              <template v-if="index === 'terms'">
                <ul class="list-group terms">
                  <li
                    v-for="(prop, index) in assets"
                    :key="index"
                    class="list-group-item"
                  >
                    <b-row align-v="center">
                      <b-col md="4" class="text-right">
                        <span v-if="index === 'paymentPeriod'"
                          >{{ $t('PERIOD') }} ({{ $t('MONTHS') }})</span
                        >
                        <span v-else
                          >{{ $t(snakeCase(index).toUpperCase()) }} (%)</span
                        >
                      </b-col>
                      <b-col md="8">
                        {{ prop }}
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </template>
            </div>
          </template>
        </b-card>
      </template>

      <template slot="modal-footer">
        <b-btn
          v-if="selectedAction !== 'new'"
          @click="chooseAction('new')"
          :variant="'secondary'"
        >
          {{ $t('SAVE_NEW') }}
        </b-btn>
        <b-btn @click="$refs.modal.hide()" v-if="selectedAction !== ''">
          {{ $t('CANCEL') }}
        </b-btn>
        <b-btn
          type="submit"
          variant="primary"
          :disabled="errors.any('templatesModal')"
          v-if="selectedAction !== ''"
        >
          {{ selectedAction === 'new' ? $t('SAVE') : $t('USE') }}
        </b-btn>
      </template>
    </b-modal>
  </b-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import snakeCase from 'lodash.snakecase';
import { SAVE_TEMPLATE, GET_TEMPLATES } from '../../../store/template';
import validateState from '../../../mixins/validateState';
import { DELETE_ALL_BY_QUERY_ID } from '../../../store/qasset';

export default {
  name: 'TemplatesModal',
  mixins: [validateState],
  props: {
    show: Boolean,
    value: Object,
    partnerSettings: Object,
    disableOnStatus: Boolean,
  },
  data() {
    return {
      isOpen: false,
      selectedAction: '',
      newTemplateName: '',
      selectedTemplate: 'null',
    };
  },
  watch: {
    show() {
      this.isOpen = this.show;
      if (this.show) {
        this.GET_TEMPLATES();
      }
    },
    isSaved() {
      if (this.isSaved) {
        this.resetModal();
      }
    },
  },
  methods: {
    ...mapActions('template', [SAVE_TEMPLATE, GET_TEMPLATES]),
    ...mapActions('qasset', [DELETE_ALL_BY_QUERY_ID]),
    handleSubmit() {
      switch (this.selectedAction) {
        case 'new':
          this.saveNew();
          break;
        case 'existing':
          this.applyExisting();
          break;
        default:
          break;
      }
    },
    saveNew() {
      this.$validator.validateAll('templatesModal').then(isValid => {
        if (!isValid) return;

        this.SAVE_TEMPLATE({
          name: this.newTemplateName,
          ...this.newData,
        });
      });
    },
    async applyExisting() {
      await this.DELETE_ALL_BY_QUERY_ID(this.value.queryId);

      const {
        assets,
        terms: { paymentPeriod, residual, kickback, advance },
      } = this.templates[this.selectedTemplate];

      const {
        insurance,
        contractFee,
        sellerName,
        sellerRegCode,
        sellerRepresentative,
        sellerReprsReprType,
        sellerAddress,
        sellerPhone,
        sellerEmail,
      } = this.partnerSettings;

      this.templates[this.selectedTemplate].assets.forEach(asset => {
        asset.queryId = this.value.queryId;
        asset.sellerName = sellerName;
        asset.sellerRegCode = sellerRegCode;
        asset.sellerRepresentative = sellerRepresentative;
        asset.sellerAddress = sellerAddress;
        asset.sellerPhone = sellerPhone;
        asset.sellerEmail = sellerEmail;
        asset.sellerReprsReprType = sellerReprsReprType;
      });

      this.value.qassets = [...assets];
      this.$set(this.value, 'paymentPeriod', paymentPeriod);
      this.$set(this.value, 'residual', residual);
      this.$set(this.value, 'kickback', kickback);
      this.$set(this.value, 'advance', advance);
      this.$set(this.value, 'contractFee', contractFee);
      this.$set(this.value, 'insurance', insurance);

      await this.$emit('updateTermInputs');
      this.resetModal();
    },
    chooseAction(action) {
      this.selectedAction = action;

      if (action === 'new') {
        this.selectedTemplate = 'null';
      }
    },
    resetModal() {
      this.selectedAction = '';
      this.newTemplateName = '';
      this.$emit('hide');
    },
  },
  computed: {
    ...mapState('template', ['templates', 'isSaved']),
    newData() {
      const {
        paymentPeriod,
        residual,
        kickback,
        advance,
        qassets,
      } = this.value;

      return {
        assets: [
          ...qassets.map(({ qassetName, quantity, qassetPrice }) => ({
            qassetName,
            quantity,
            qassetPrice,
          })),
        ],
        terms: {
          paymentPeriod,
          residual,
          kickback,
          advance,
        },
      };
    },
    mappedTemplates() {
      return this.templates.map((item, index) => ({
        value: index,
        text: item.name,
      }));
    },
    computedSelectedTemplate() {
      return Object.keys(this.templates[this.selectedTemplate]).reduce(
        (o, key) => ({
          ...o,
          ...(!['name', 'id'].includes(key) && {
            [key]: this.templates[this.selectedTemplate][key],
          }),
        }),
        {},
      );
    },
    snakeCase() {
      return snakeCase;
    },
  },
};
</script>
