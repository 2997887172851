<template>
  <div>
    <Header :header="$t('PARTNERS')" />

    <RenderSpinner v-if="loading" />
    <b-container class="mt-5">
      <h2 class="card-title">
        {{ newPartner ? $t('NEW_PARTNER') : partnerSettings.businessName }}
      </h2>

      <PartnerSettingsForm
        v-model="partnerSettings"
        ref="partnerSettings"
        :inHousePartners="inHousePartners"
      />

      <div class="mb-5 mt-4">
        <b-row align-h="center">
          <b-col md="4">
            <b-btn
              id="backBtn"
              variant="secondary"
              size="lg"
              block
              :to="{ name: 'ManagePartners' }"
              >{{ $t('BACK') }}</b-btn
            >
          </b-col>
          <b-col md="4">
            <b-btn
              id="saveBtn"
              variant="primary"
              size="lg"
              block
              @click="savePartnerSettings"
              >{{ $t('SAVE') }}</b-btn
            >
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { Header, RenderSpinner } from '@/components/common';
import PartnerSettingsForm from './PartnerSettingsForm.vue';
import { replaceComma } from '../../utils';
import { ALERT } from '../../store/alert';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EditPartners',
  components: {
    Header,
    PartnerSettingsForm,
    RenderSpinner,
  },
  props: {
    newPartner: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.fetchInHousePartners();
    if (!this.newPartner) {
      this.fetchPartnerSettings();
    } else {
      this.fetchPartnerAssetGroups(this.partnerSettings, null);
    }
  },
  data() {
    return {
      partnerSettings: {
        partnerId: null,
        accountUserId: null,
        advance: '',
        commission: '',
        residual: '',
        interest: '',
        paymentPeriod: '',
        comment: '',
        creditLimit: null,
        automaticDecision: false,
        lendifyLimit: false,
        manualSigning: false,
        insurance: '',
        contractFee: null,
        kickback: '',
        offerValidTime: null,
        servicesCost: null,
        inhouse: false,
        notifyOnDecision: false,
        notifyOnDecisionEmail: '',
        businessName: '',
        regCode: '',
        vatNumber: '',
        bankAccountNumber: '',
        phoneNumber: '',
        email: '',
        street: '',
        zipCode: '',
        city: '',
        county: '',
        country: '',
        representativeName: '',
        representedBy: '',
        representativePersonalCode: '',
        representativePhone: '',
        representativeEmail: '',
        defaultAdvance: null,
        defaultInterest: null,
        defaultKickback: null,
        defaultPaymentPeriod: null,
        defaultResidual: null,
        managerId: null,
        interestMatrix: null,
        advanceOptions: [],
        paymentPeriodOptions: [],
        allPmtPeriodOptions: ['12', '24', '36', '48', '60'],
        residualOptions: [],
        interestOptions: [],
        kickbackOptions: [],
      },
      inHousePartners: [],
    };
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    savePartnerSettings: function() {
      this.$refs.partnerSettings.$validator.validate().then(value => {
        if (value) {
          this.$store
            .dispatch('savePartnerSettings', this.formattedPartnerSettings)
            .then(({ data: { partnerId } }) => {
              this.$store.dispatch('savePartnerAssetGroups', {
                partnerAssetGroups: this.formattedPartnerSettings
                  .partnerAssetGroups,
                partnerId,
              });
              this.ALERT({
                message: this.newPartner
                  ? this.$t('PARTNER_CREATED')
                  : this.$t('PARTNER_SAVED'),
                type: 'success',
              });
              this.$router.push({ name: 'ManagePartners' });
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },
    fetchPartnerSettings() {
      const partnerId = this.$route.params.id;
      this.$store
        .dispatch('getPartner', partnerId)
        .then(({ data: partnerSettings }) => {
          this.fetchPartnerAssetGroups(partnerSettings, partnerId);
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchInHousePartners() {
      this.$store.dispatch('findAllInhousePartners').then(response => {
        this.inHousePartners = response.data;
      });
    },
    fetchPartnerAssetGroups(partnerSettings, partnerId) {
      let partnerAssetGroups = [];
      if (partnerId) {
        this.$store
          .dispatch('getPartnerAssetGroups', partnerId)
          .then(({ data }) => {
            partnerAssetGroups = data;
          });
      }
      this.$store
        .dispatch('findAllAssetGroups')
        .then(({ data: allAssetGroups }) => {
          const mainAssetGroups = allAssetGroups.filter(
            group => group.superiorGroupId === null,
          );
          mainAssetGroups.forEach(mainAssetGroup => {
            mainAssetGroup.subgroups.forEach(subGroup => {
              const partnerAssetGroup = partnerAssetGroups.find(
                ({ assetGroupId }) => assetGroupId === subGroup.assetGroupId,
              );
              if (partnerAssetGroup) {
                subGroup.allowed = true;
                subGroup.autoDecisionEnabled =
                  partnerAssetGroup.autoDecisionEnabled;
                subGroup.insurance = partnerAssetGroup.insurance;
              }
            });
          });
          partnerSettings.partnerAssetGroups = mainAssetGroups;
          this.partnerSettings = partnerSettings;
        });
    },
  },
  computed: {
    ...mapState(['loading']),
    formattedPartnerSettings() {
      if (this.partnerSettings) {
        const partnerSettings = this.partnerSettings;
        return {
          ...partnerSettings,
          advanceOptions: partnerSettings.advanceOptions.map(option => {
            return replaceComma(option);
          }),
          defaultAdvance: replaceComma(partnerSettings.defaultAdvance),
          interestOptions: partnerSettings.interestOptions.map(option => {
            return replaceComma(option);
          }),
          defaultInterest: replaceComma(partnerSettings.defaultInterest),
          kickbackOptions: partnerSettings.kickbackOptions.map(option => {
            return replaceComma(option);
          }),
          defaultKickback: replaceComma(partnerSettings.defaultKickback),
          residualOptions: partnerSettings.residualOptions.map(option => {
            return replaceComma(option);
          }),
          defaultResidual: replaceComma(partnerSettings.defaultResidual),
          insurance: replaceComma(partnerSettings.insurance),
          contractFee: replaceComma(partnerSettings.contractFee),
        };
      }
    },
  },
};
</script>

<style scoped></style>
