import axios from 'axios';
import store from './store/store';

export function queryStatusClass(status) {
  switch (status) {
    case 'P':
    case 'SCR':
    case 'SCO':
    case 'MICO':
    case 'MICR':
    case 'YS':
    case 'QA':
    case 'QAC':
    case 'CR':
    case 'SM':
      return 'pending';
    case 'CA':
      return 'approved';
    case 'N':
    case 'CF':
      return 'terminated';
  }
}

export const parseJwt = token => {
  const clearToken = token.split(' ')[1];
  const base64Url = clearToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

export function getCurrentUNIX() {
  return Math.floor(Date.now() / 1000);
}

export function axiosSetup() {
  axios.interceptors.request.use(config => {
    config.headers.Authorization = window.localStorage.getItem('jwtToken');
    return config;
  });

  axios.interceptors.response.use(
    response => {
      return response;
    },
    function(error) {
      if (error.response.status === 401) {
        store.dispatch('logout');
      }
      return Promise.reject(error);
    },
  );
}

export function replaceComma(number) {
  if (typeof number !== 'undefined' && number !== null) {
    return number.toString().replace(',', '.');
  }
}

export function timeConverter(UNIX) {
  if (UNIX === null || typeof UNIX === 'undefined') return null;
  let d = new Date(UNIX);
  let year = d.getFullYear();
  let month = ('0' + (d.getMonth() + 1)).slice(-2);
  let date = ('0' + d.getDate()).slice(-2);
  return date + '.' + month + '.' + year;
}

export function validateId(value) {
  if (!value || value.length !== 11) {
    return false;
  }

  const numbers = (value || '0').split('').map(Number);
  const lastDigit = numbers[10];
  const checksumNumbers = numbers.slice(0, 10);

  const controlNumber = [
    calculateControlNumber(checksumNumbers, [1, 2, 3, 4, 5, 6, 7, 8, 9, 1]),
    calculateControlNumber(checksumNumbers, [3, 4, 5, 6, 7, 8, 9, 1, 2, 3]),
    0,
  ].find(controlNumber => controlNumber !== 10);

  return lastDigit === controlNumber;
}

function calculateControlNumber(numbers, weights) {
  return numbers.reduce((sum, num, i) => sum + num * weights[i], 0) % 11;
}

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {},
  );

export function numerify(param1, param2) {
  if (typeof param2 !== 'undefined') {
    if (typeof this[param1][param2] !== 'undefined') {
      this[param1][param2] = this[param1][param2].replace(/[^\d]+/g, '');
    }
  } else {
    this[param1] = this[param1].replace(/[^\d]+/g, '');
  }
}

export function numerifyWithDecimals(param1, param2, param3) {
  if (typeof param3 !== 'undefined') {
    if (typeof this[param1][param2][param3] !== 'undefined') {
      this[param1][param2][param3] = replaceComma(
        this[param1][param2][param3].toString().replace(/[^\d,.]+/g, ''),
      );
    }
  } else if (typeof param2 !== 'undefined') {
    if (typeof this[param1][param2] !== 'undefined') {
      this[param1][param2] = this[param1][param2]
        .toString()
        .replace(/[^\d,.]+/g, '');
    }
  } else {
    this[param1] = this[param1].toString().replace(/[^\d,.]+/g, '');
  }
}

export function numerifyOnEdit(param1, param2, param3, param4) {
  this[param1][param2][param3][param4] = this[param1][param2][param3][
    param4
  ].replace(/[^\d]+/g, '');
}

export function numerifyOnEditWithDecimals(param1, param2, param3, param4) {
  this[param1][param2][param3][param4] = replaceComma(
    this[param1][param2][param3][param4].replace(/[^\d,.]+/g, ''),
  );
}

export function deleteTemplateProps(template, edit) {
  const oldAssets = [];
  const {
    terms: {
      id,
      name,
      contractFee,
      insurance,
      insuranceValType,
      createdDtime,
      modifiedDtime,
      createdBy,
      formattedCreatedDtime,
      formattedModifiedDtime,
      ...oldTerms
    },
  } = template;

  template.assets.forEach(asset => {
    oldAssets.push(deleteTemplateAssetProps(asset, edit));
  });

  return {
    id: template.id,
    name: template.name,
    assets: oldAssets,
    terms: {
      ...oldTerms,
    },
  };
}

function deleteTemplateAssetProps(asset, edit) {
  if (typeof edit === 'undefined') {
    const {
      id,
      templateId,
      createdDtime,
      modifiedDtime,
      createdBy,
      formattedCreatedDtime,
      formattedModifiedDtime,
      ...oldAsset
    } = asset;
    return oldAsset;
  } else {
    return asset;
  }
}

export function replaceObjectValuesComma(object, fieldsToReplace) {
  const clone = { ...object };
  fieldsToReplace.forEach(key => {
    clone[key] = +replaceComma(clone[key]);
  });
  return clone;
}

export function formatQuery(query) {
  const fieldsToFormat = [
    'advance',
    'contractFee',
    'insurance',
    'interest',
    'kickback',
    'residual',
    'servicesCost',
  ];
  const formattedQuery = replaceObjectValuesComma(query, fieldsToFormat);
  formattedQuery.qassets.map(qasset => ({
    ...qasset,
    qassetPrice: replaceComma(qasset.qassetPrice),
  }));
  return formattedQuery;
}

export function checkIfValueIsInOptions(options, value) {
  let valueInOptions = false;
  options.forEach(option => {
    if (option.value === value) {
      valueInOptions = true;
    }
  });
  return valueInOptions;
}

export function distinctSellers(assets) {
  const uniqueSellers = [];
  assets.forEach(seller => {
    if (
      uniqueSellers.find(
        uniqueSeller => uniqueSeller.sellerRegCode == seller.sellerRegCode,
      )
    ) {
      return;
    }
    uniqueSellers.push(seller);
  });
  return uniqueSellers;
}

export function downloadResponseBlob(response) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', response.headers.filename);
  link.click();
}

export function getPriceWithoutVat(price) {
  return Math.round((price / 1.22) * 100) / 100;
}
export function getPriceWithVat(price) {
  return Math.round(price * 122) / 100;
}
export function disableOnStatus(viewName, partnerSettings, value, isInhouse) {
  if (viewName && partnerSettings && value) {
    switch (viewName) {
      case 'partner':
      case 'partnerCreate':
        if (isInhouse) {
          return !['P', 'SM', 'SCR', 'QA', 'QAC'].includes(value.status);
        } else {
          return value.status !== 'P';
        }
      case 'credit':
        return value.status !== 'SCR' && value.status !== 'P';
      case 'compliance':
        if (value.status === 'CR' || value.status === 'YS') {
          return true;
        } else {
          return value.status === 'CA';
        }
    }
  }
  return undefined;
}
