<template>
  <b-card>
    <RenderSpinner v-if="$store.state.loading" />
    <h2 class="card-title card-title-pulled-left">
      <span>
        <figure class="card-title-icon">
          <img src="../../assets/package-icon.svg" class="card-title-image" />
        </figure>
        {{ $t('CONTRACTS') }}
      </span>
    </h2>

    <b-row align-v="center" class="card-title">
      <b-col md="6">
        <div class="text-right" v-if="viewName !== 'compliance'">
          <span class="number number-lg"
            >{{ $t('YOUR_CONTRACT_NUMBER') }}:
            <strong>{{ value.contractNr }}</strong></span
          >
        </div>
        <b-form-group
          v-if="viewName === 'compliance'"
          class="mb-0"
          :label="$t('YOUR_CONTRACT_NUMBER') + ':'"
          :label-for="`contract-${page}`"
        >
          <b-form-input
            :id="`contract-${page}`"
            v-model.trim="value.contractNr"
            type="text"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card class="contract-box">
      <b-row>
        <b-col>
          <div class="to-be-signed">
            <h2>{{ $t('CONTRACTS_TO_BE_SIGNED') }}</h2>
            <b-list-group v-if="isInhouse" class="files">
              <b-list-group-item>
                <span class="title">Avokaado {{ $t('DOCUMENTS') }}</span>
              </b-list-group-item>
              <b-list-group-item>
                <img src="../../assets/docs1.svg" alt="download" />
                <span>{{ $t('LEASING_CONTRACT') }}</span>
                <div class="actions">
                  <a
                    :class="{ disabled: value.qassets.length == 0 }"
                    @click="generateLeasingContract()"
                    title="Download"
                    ><img src="../../assets/download.svg" alt="download"
                  /></a>
                </div>
              </b-list-group-item>
              <b-list-group-item>
                <img src="../../assets/docs1.svg" />
                <span>{{ $t('SALES_CONTRACT') }}</span>
                <div class="actions">
                  <a
                    :class="{ disabled: value.qassets.length == 0 }"
                    @click="generateSalesContracts('taisteenusliisingu')"
                    title="Download"
                    ><img src="../../assets/download.svg"
                  /></a>
                </div>
              </b-list-group-item>
              <b-list-group-item>
                <img src="../../assets/docs1.svg" alt="contract" />
                <span>{{ $t('SURETY_AGREEMENT') }}</span>
                <div class="actions">
                  <a
                    :class="{ disabled: value.sureties.length == 0 }"
                    @click="generateSurety()"
                    title="Download"
                    ><img src="../../assets/download.svg" alt="download" />
                  </a>
                </div>
              </b-list-group-item>
              <b-list-group-item>
                <img src="../../assets/docs1.svg" />
                <span>{{ $t('GUARANTEE_LETTER') }}</span>
                <div class="actions">
                  <a
                    :class="{
                      disabled: value.guarantor.representativeName == null,
                    }"
                    @click="generateAvokaadoContract('guarantee')"
                    title="Download"
                    ><img src="../../assets/download.svg"
                  /></a>
                </div>
              </b-list-group-item>
              <b-list-group-item>
                <img src="../../assets/docs1.svg" alt="contract" />
                <span>{{ $t('REDEMPTION_AGREEMENT') }}</span>
                <div class="actions">
                  <a
                    @click="
                      $store.dispatch(
                        'generateRedemptionAgreement',
                        value.queryId,
                      )
                    "
                    title="Download"
                    ><img src="../../assets/download.svg" alt="download" />
                  </a>
                </div>
              </b-list-group-item>
              <b-list-group-item>
                <img src="../../assets/docs1.svg" />
                <span>{{ $t('ACT_OF_ACCEPTANCE') }}</span>
                <div class="actions">
                  <a
                    @click="
                      $store.dispatch('generateActOfAcceptance', {
                        ...actOfAcceptanceData,
                        queryId: value.queryId,
                      })
                    "
                    :class="{ disabled: value.qassets.length == 0 }"
                    title="Download"
                    ><img src="../../assets/download.svg"
                  /></a>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>

          <b-list-group class="files uploaded-documents mt-3">
            <b-table
              class="files uploaded-documents mt-3"
              outlined
              responsive
              :items="documents"
              :fields="uploadedDocumentsFields"
              sort-by="docFileId"
            >
              <template #cell(fileName)="row">
                <div class="uploaded-document-container">
                  <img
                    class="mb-2"
                    height="22px"
                    src="../../assets/docs1.svg"
                    alt="document"
                  />
                  <span class="pt-2 pr-4 uploaded-filename">
                    {{ row.item.fileName }}
                  </span>
                </div>
              </template>
              <template #cell(isSignable)="row">
                <b-list-group-item
                  class="d-flex justify-content-between border-0 p-0"
                >
                  <b-form-checkbox
                    v-model="row.item.isSignable"
                    @change="handleIsSignableChange(row.item)"
                    :id="'isSignable-' + row.item.docFileId"
                    :disabled="loading"
                  />
                  <div class="actions top-0">
                    <a
                      @click="DOWNLOAD_FILE(row.item.docFileId)"
                      title="Download"
                    >
                      <img src="../../assets/download.svg" alt="download" />
                    </a>
                  </div>
                </b-list-group-item>
              </template>
            </b-table>
          </b-list-group>
        </b-col>
        <b-col>
          <div class="actions-list">
            <h2>
              {{ $t('SIGNING_CONTAINERS') }}
              <b-btn
                @click="addContainer()"
                v-if="viewName === 'compliance'"
                class="btn-icon"
              >
                <img src="../../assets/plus.svg" class="btn-image" />
              </b-btn>
            </h2>

            <b-form
              v-if="newContainers.length > 0 && viewName === 'compliance'"
              @submit.prevent="saveContainers()"
              data-vv-scope="newContainer"
            >
              <h3>{{ $t('NEW_CONTAINER') }}</h3>
              <b-alert :show="errors.any('newContainer')" variant="danger">{{
                $t('VALIDATION.FIX_REQUIRED_ERRORS')
              }}</b-alert>
              <b-list-group
                v-for="(newContainer, index) in newContainers"
                :key="index"
                class="files mt-4"
              >
                <b-list-group-item class="contract-container-header">
                  <b-btn
                    size="sm"
                    class="btn-close btn-delete-absolute"
                    @click="deleteContainer({ containerIndex: index })"
                  >
                    <img
                      src="../../assets/delete-icon-w.svg"
                      class="img-fluid"
                    />
                  </b-btn>
                  <b-form-group
                    class="mb-0"
                    :label="$t('CONTAINER') + ' #:'"
                    :label-for="`containerId-${index}`"
                  >
                    <b-form-input
                      :id="`containerId-${index}`"
                      v-model.trim="newContainer.id"
                      type="text"
                      class="container-title-input"
                      v-validate="'required'"
                      :name="`containerId-${index}`"
                    />
                  </b-form-group>
                </b-list-group-item>
                <b-form-select
                  v-model="newContainer.files"
                  multiple
                  :select-size="9 + documents.length"
                  v-validate="'required'"
                  :name="`containerFiles-${index}`"
                  aria-required="true"
                  :aria-invalid="
                    errors.has(`newContainer.containerFiles-${index}`)
                  "
                >
                  <option @click="handleSigners(newContainer)" :value="8">
                    {{ $t('LEASE_OFFER') }}
                  </option>
                  <option @click="handleSigners(newContainer)" :value="17">
                    {{ $t('IDEAL_LEASE_OFFER') }}
                  </option>
                  <option @click="handleSigners(newContainer)" :value="19">
                    {{ $t('STANDARD_LEASE_OFFER') }}
                  </option>
                  <option @click="handleSigners(newContainer)" :value="12">
                    {{ $t('LEASING_CONTRACT') }}
                  </option>
                  <option
                    @click="handleSigners(newContainer)"
                    :class="{ disabled: value.qassets.length == 0 }"
                    :disabled="value.qassets.length == 0"
                    :value="6"
                  >
                    Täisteenusliisingu {{ $t('SALES_CONTRACT') }}
                  </option>
                  <option
                    @click="handleSigners(newContainer)"
                    :class="{ disabled: value.sureties.length == 0 }"
                    :disabled="value.sureties.length == 0"
                    :value="7"
                  >
                    {{ $t('SURETY_AGREEMENT') }}
                  </option>
                  <option @click="handleSigners(newContainer)" :value="9">
                    {{ $t('GUARANTEE_LETTER') }}
                  </option>
                  <option @click="handleSigners(newContainer)" :value="13">
                    {{ $t('REDEMPTION_AGREEMENT') }}
                  </option>
                  <option @click="handleSigners(newContainer)" :value="11">
                    {{ $t('ACT_OF_ACCEPTANCE') }}
                  </option>
                  <option
                    v-for="file in documents"
                    @click="handleSigners(newContainer)"
                    :key="file.docFileId"
                    :value="'c' + file.docFileId"
                  >
                    {{ file.fileName }}
                  </option>
                </b-form-select>
                <b-list-group-item class="no-top-border">
                  <b-row align-h="between" align-v="center">
                    <b-col
                      ><h3 class="mb-0">{{ $t('SIGNERS') }}</h3></b-col
                    >
                    <b-col class="text-right">
                      <b-btn
                        size="sm"
                        @click="
                          addSigner({
                            containerId: newContainer.id,
                            index: index,
                          })
                        "
                        >{{ $t('ADD_SIGNER') }}</b-btn
                      >
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="(signer, id) in newContainer.signers"
                  :key="signer.signerId"
                >
                  <b-btn
                    v-if="newContainer.signers.length > 1"
                    size="sm"
                    class="btn-close btn-delete-absolute"
                    @click="
                      deleteSigner({ containerIndex: index, signerId: id })
                    "
                  >
                    <img
                      src="../../assets/delete-icon-w.svg"
                      class="img-fluid"
                    />
                  </b-btn>
                  <b-form-input
                    v-model="signer.name"
                    type="text"
                    v-if="signer.id !== 'surety'"
                    :placeholder="$t('SIGNER_FULL_NAME')"
                    v-validate="'required'"
                    name="signerName"
                    class="mb-1"
                  />
                  <multiselect
                    v-if="signer.id === 'surety'"
                    :value="signer"
                    :options="value.sureties"
                    @input="surety => setSuretyAsSigner(surety, signer)"
                    :show-labels="false"
                    label="name"
                    track-by="suretyId"
                  >
                    <template slot="noResult">{{
                      $t('NO_ELEMENTS_FOUND')
                    }}</template>
                  </multiselect>
                  <b-form-input
                    v-model.trim="signer.personalCode"
                    type="text"
                    :placeholder="$t('SIGNER_ID_CODE')"
                    v-validate="'required|idcode'"
                    name="signerPersonalCode"
                    class="mb-1"
                  />
                  <b-form-input
                    v-model="signer.email"
                    type="text"
                    :placeholder="$t('SIGNER_EMAIL')"
                    v-validate="'required|email'"
                    name="signerEmail"
                    class="mb-1"
                  />
                </b-list-group-item>
              </b-list-group>
              <b-btn
                v-if="newContainers.length > 0 && viewName === 'compliance'"
                type="submit"
                class="mt-3 mb-3"
                variant="primary"
                block
              >
                {{ $t('SAVE_CONTAINERS') }}
              </b-btn>
            </b-form>
          </div>

          <div
            v-if="sentContainers && sentContainers.length > 0"
            class="actions-list mt-3"
          >
            <h3 class="mb-4">{{ $t('SENT_CONTAINERS') }}</h3>

            <div v-for="container in sentContainers" :key="container.id">
              <b-list-group
                @click="pickForSigning(container)"
                :class="[
                  'selectable',
                  'files',
                  { selected: container.isSelected },
                ]"
              >
                <b-list-group-item
                  :class="[
                    'position-relative',
                    { 'all-signed': container.hasAllSigned },
                  ]"
                >
                  <span class="title contract-container-title">
                    <b-btn
                      v-if="container.sentForSigning && !container.hasAllSigned"
                      @click="openAllSignedModal($event, container)"
                      class="set-all-signed btn-icon"
                    >
                      <img src="../../assets/success.svg" />
                    </b-btn>
                    {{ $t('CONTAINER') }} #{{ container.title }}
                  </span>
                </b-list-group-item>
                <b-form-select
                  :select-size="
                    container.files.length > 1
                      ? container.files.length
                      : container.files.length + 1
                  "
                >
                  <option
                    disabled
                    v-for="file in container.files"
                    :key="file.id"
                  >
                    {{ file.file_name }}
                  </option>
                </b-form-select>
                <br />
              </b-list-group>
              <div class="signatures" v-if="container.isSelected">
                <h2>{{ $t('SIGNATURES') }}</h2>

                <div
                  class="name sign"
                  v-for="signer in container.signers.filter(
                    signer => signer.signing_status === 'signed',
                  )"
                  :key="signer.signerId"
                >
                  <i class="icon"><img src="../../assets/signature.svg"/></i>
                  <div class="person">
                    <span>{{ signer.name }}</span>
                    <b>{{ signer.ssn }}</b>
                  </div>
                  <div class="signed">
                    {{ $t('SIGNED') }} {{ signer.signed_at }}
                  </div>
                </div>
                <div
                  class="name"
                  v-for="(signer, index) in container.unsignedSigners"
                  :key="index"
                >
                  <i class="icon"><img src="../../assets/signature.svg"/></i>
                  <div class="person">
                    <span>{{ signer }}</span>
                  </div>
                  <div v-if="!container.hasAllSigned" class="signed">
                    {{ $t('NOT_SIGNED_YET') }}
                  </div>
                  <div
                    v-else
                    class="signed"
                    v-b-tooltip.hover
                    :title="$t('SIGNED_OUTSIDE_OF_SYSTEM')"
                  >
                    {{ $t('SIGNED') }}*
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>

          <div
            class="actions-list mt-3"
            v-if="unsentContainers && unsentContainers.length > 0"
          >
            <h3 class="mb-4">{{ $t('UNSENT_CONTAINERS') }}</h3>

            <div v-for="container in unsentContainers" :key="container.id">
              <b-list-group
                @click="pickForSigning(container)"
                :class="[
                  'selectable',
                  'files',
                  { selected: container.isSelected },
                ]"
              >
                <b-list-group-item>
                  <span class="title contract-container-title "
                    >{{ $t('CONTAINER') }} #{{ container.title }}</span
                  >
                </b-list-group-item>
                <b-form-select
                  :select-size="
                    container.files.length > 1
                      ? container.files.length
                      : container.files.length + 1
                  "
                >
                  <option
                    disabled
                    v-for="file in container.files"
                    :key="file.id"
                  >
                    {{ file.file_name }}
                  </option>
                </b-form-select>
                <br />
              </b-list-group>

              <div class="signatures" v-if="container.isSelected">
                <h2>{{ $t('SIGNATURES') }}</h2>

                <div
                  class="name sign"
                  v-for="signer in container.signers.filter(
                    signer => signer.signing_status === 'signed',
                  )"
                  :key="signer.signerId"
                >
                  <i class="icon"><img src="../../assets/signature.svg"/></i>
                  <div class="person">
                    <span>{{ signer.name }}</span>
                    <b>{{ signer.ssn }}</b>
                  </div>
                  <div class="signed">
                    {{ $t('SIGNED') }} {{ signer.signed_at }}
                  </div>
                </div>
                <div
                  class="name"
                  v-for="(signer, index) in container.unsignedSigners"
                  :key="index"
                >
                  <i class="icon"><img src="../../assets/signature.svg"/></i>
                  <div class="person">
                    <span>{{ signer }}</span>
                  </div>
                  <div class="signed">
                    {{ $t('NOT_SIGNED_YET') }}
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <div class="mb-5 mt-2">
      <b-row>
        <b-col md="6">
          <b-button
            v-if="viewName === 'compliance'"
            @click="sendForSigning()"
            class="mt-3"
            variant="secondary"
            size="lg"
            block
          >
            {{ $t('SEND_FOR_SIGNING') }}
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button
            v-if="viewName === 'compliance'"
            @click="
              $emit('changeStatus', {
                status: 'CA',
                alert: $t('QUERYSTATUS.CA'),
                queryId: value.queryId,
              })
            "
            class="mt-3"
            :disabled="
              partnerStatuses.includes(value.status) ||
                creditStatuses.includes(value.status)
            "
            variant="secondary"
            size="lg"
            block
          >
            {{ $t('ACTIVATE_CONTRACT') }}
          </b-button>
        </b-col>

        <b-col md="6">
          <b-button
            v-if="viewName === 'compliance'"
            @click="
              $emit('changeStatus', {
                status: 'N',
                alert: $t('QUERYSTATUS.N'),
                queryId: value.queryId,
              })
            "
            class="mt-3"
            :disabled="
              partnerStatuses.includes(value.status) ||
                creditStatuses.includes(value.status)
            "
            variant="danger"
            size="lg"
            block
          >
            {{ $t('CLOSE_QUERY') }}
          </b-button>
        </b-col>

        <b-col md="6">
          <b-button
            v-if="viewName === 'compliance' && value.status === 'SM'"
            @click="
              $emit('changeStatus', {
                status: 'P',
                alert: $t('QUERYSTATUS.P'),
                queryId: value.queryId,
              })
            "
            class="mt-3"
            :disabled="
              partnerStatuses.includes(value.status) ||
                creditStatuses.includes(value.status)
            "
            variant="secondary"
            size="lg"
            block
          >
            {{ $t('BACK_TO_PARTNER') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <QueryCloseModal
      :initialValue="value.closingReason"
      :reasons="reasons"
      :show="isQueryCloseModalOpen"
      @cancel="isQueryCloseModalOpen = false"
      @closeQuery="handleQueryClose"
    />
    <RenderAllSignedModal
      :label="$t('ARE_YOU_SURE_CONTAINER_SIGNED')"
      :show="isAllSignedModalOpen"
      @cancel="closeAllSignedModal"
      @ok="closeAllSignedModal(true)"
    />
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ALERT } from '../../store/alert';
import axios from 'axios';
import { downloadResponseBlob } from '../../utils';
import groupBy from 'lodash.groupby';
import {
  RenderSpinner,
  QueryCloseModal,
  RenderAllSignedModal,
} from '@/components/common';
import Multiselect from 'vue-multiselect';
import { DOWNLOAD_FILE } from '../../store/document';
import { FIND_ALL_REASONS } from '../../store/reason';

export default {
  name: 'Contracts',
  props: [
    'value',
    'documents',
    'viewName',
    'isInhouse',
    'generateActOfAcceptance',
    'actOfAcceptanceData',
    'page',
  ],
  components: {
    RenderSpinner,
    QueryCloseModal,
    RenderAllSignedModal,
    Multiselect,
  },
  data: function() {
    return {
      partnerStatuses: ['P', 'MICR', 'MICO', 'N'],
      creditStatuses: ['SCR'],
      complianceStatuses: ['SCO'],
      newContainers: [],
      containers: [],
      chosenSentContainer: {},
      chosenUnsentContainer: {},
      toSigningContainers: [],
      isQueryCloseModalOpen: false,
      isAllSignedModalOpen: false,
      selectedContainer: null,
      fairownRepresentative: {},
      clientRepresentative: {},
      uploadedDocumentsFields: [
        {
          key: 'fileName',
          label: this.$t('UPLOADED_DOCUMENTS'),
        },
        {
          key: 'isSignable',
          label: this.$t('IS_SIGNABLE'),
        },
      ],
    };
  },
  methods: {
    ...mapActions('alert', [ALERT]),
    ...mapActions('document', [DOWNLOAD_FILE]),
    ...mapActions('reason', [FIND_ALL_REASONS]),
    handleQueryClose: function(closingReason) {
      this.isQueryCloseModalOpen = false;
      this.$emit('changeStatus', {
        status: 'N',
        closingReason,
        alert: this.$t('QUERYSTATUS.N'),
        queryId: this.value.queryId,
      });
    },
    generateSurety: function() {
      let that = this;
      this.value.sureties.forEach(function(surety) {
        that.$store.state.loading = true;
        axios
          .post('query/' + that.value.queryId + '/surety-pdf', surety, {
            responseType: 'blob',
          })
          .then(response => downloadResponseBlob(response))
          .catch(err => {
            err.response.data.text().then(message => {
              that.$store.commit('alert/SET_ALERT', {
                message,
              });
            });
          })
          .finally(() => {
            that.$store.state.loading = false;
          });
      });
    },
    generateSalesContracts: function(type) {
      const url =
        type === 'ufe'
          ? '/query/ufe-sales-pdf'
          : '/query/taisteenusliisingu-sales-pdf';
      const that = this;
      const qassetsBySellers = groupBy(this.value.qassets, 'sellerName');
      Object.entries(qassetsBySellers).forEach(function(value) {
        that.$store.state.loading = true;
        const query = { ...that.value, qassets: value[1] };
        axios
          .post(url, query, {
            responseType: 'blob',
          })
          .then(response => downloadResponseBlob(response))
          .catch(err => {
            err.response.data.text().then(message => {
              that.$store.commit('alert/SET_ALERT', {
                message,
              });
            });
          })
          .finally(() => {
            that.$store.state.loading = false;
          });
      });
    },
    generateAvokaadoContract: function(type) {
      let url = '/query/' + this.value.queryId;
      this.$store.state.loading = true;
      switch (type) {
        case 'leaseback':
          url += '/taisteenusliisingu-leaseback-pdf';
          break;
        case 'guarantee':
          url += '/guarantee-letter-pdf';
          break;
        case 'credit decision':
          url += '/credit-decision-pdf';
          break;
      }
      axios
        .get(url, {
          responseType: 'blob',
        })
        .then(response => downloadResponseBlob(response))
        .catch(err => {
          err.response.data.text().then(message => {
            this.$store.commit('alert/SET_ALERT', {
              message,
            });
          });
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },
    generateLeasingContract() {
      this.$store.dispatch('generateLeasingContract', this.value.queryId);
    },
    addContainer: function() {
      this.newContainers.push({
        id: '',
        signers: [
          { container_id: '', ...this.fairownRepresentative, id: 'fairown' },
          {
            container_id: '',
            name: this.value.contactName,
            personalCode: this.value.contactPersCode,
            email: this.value.contactEmail,
            id: 'client',
          },
        ],
        files: [],
      });
    },
    deleteContainer: function(payload) {
      this.newContainers.splice(payload.containerIndex, 1);
    },
    addSigner: function(payload) {
      this.newContainers[payload.index].signers.push({
        container_id: payload.containerId,
      });
    },
    deleteSigner: function(payload) {
      this.newContainers[payload.containerIndex].signers.splice(
        payload.signerId,
        1,
      );
    },
    setAllSigned: function(container) {
      this.$store.state.loading = true;
      this.$store
        .dispatch('setAllSigned', container.id)
        .then(() => {
          this.getFolderInfo();
          this.ALERT({
            message: this.$t('CONTAINER_SET_ALL_SIGNED'),
            type: 'success',
          });
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },
    openAllSignedModal(e, container) {
      e.stopPropagation();
      this.isAllSignedModalOpen = true;
      this.selectedContainer = container;
    },
    closeAllSignedModal(setAllSigned) {
      this.isAllSignedModalOpen = false;
      if (setAllSigned) {
        this.setAllSigned(this.selectedContainer);
      }
      this.selectedContainer = null;
    },
    pickForSigning: function(container) {
      const containersIndex = this.containers.indexOf(
        this.containers.find(el => el.id === container.id),
      );
      if (this.toSigningContainers.includes(container)) {
        const index = this.toSigningContainers.indexOf(container);
        this.toSigningContainers.splice(index, 1);
        this.$set(this.containers[containersIndex], 'isSelected', false);
      } else {
        this.toSigningContainers.push(container);
        this.$set(this.containers[containersIndex], 'isSelected', true);
      }
    },
    saveContainers: function() {
      this.$validator.validateAll('newContainer').then(isValid => {
        if (isValid) {
          this.$store.state.loading = true;
          this.$store
            .dispatch('saveContainers', {
              containers: this.newContainers,
              queryId: this.value.queryId,
            })
            .then(() => {
              this.getFolderInfo();
              this.ALERT({
                message: this.$t('CONTAINER_SAVED'),
                type: 'success',
              });
              this.newContainers = [];
              this.toSigningContainers = [];
            })
            .catch(error => {
              this.$store.dispatch(
                'alert/ALERT',
                { message: error.response.data },
                { root: true },
              );
            })
            .finally(() => {
              this.$store.state.loading = false;
            });
        }
      });
    },
    sendForSigning: function() {
      if (this.toSigningContainers.length === 0) {
        this.ALERT({ message: this.$t('CHOOSE_ONE_CONTAINER') });
        return;
      }
      this.$store.state.loading = true;
      this.$store
        .dispatch('sendForSigning', {
          containers: this.toSigningContainers,
          queryId: this.value.queryId,
        })
        .then(response => {
          if (response.status === 200) {
            this.$emit('changeStatus', {
              status: 'YS',
              alert: this.$t('SENT_FOR_SIGNING'),
              queryId: this.value.queryId,
            });
          }
        })
        .then(() => {
          for (const container of this.toSigningContainers) {
            container.sentForSigning = true;
            container.isSelected = false;
          }
          this.toSigningContainers = [];
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },
    getFolderInfo() {
      return axios
        .get('/query/' + this.value.queryId + '/get-folder-info', {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then(({ data: { contracts } }) => {
          if (contracts != null) {
            contracts.map(container => {
              for (const signer of container.signers) {
                if (
                  signer.signing_status === 'not_signed' &&
                  signer.email !== 'avokaado@fairown.com'
                ) {
                  if (
                    container.unsignedSigners === undefined ||
                    container.unsignedSigners === null
                  ) {
                    container.unsignedSigners = [];
                  }
                  container.unsignedSigners.push(signer.name);
                }
              }
            });
          }
          this.containers = contracts;
        });
    },
    handleSigners(newContainer) {
      const { files, signers } = newContainer;
      const { guarantor } = this.value;
      const asset = this.value.qassets[0];
      const sellerPosition = signers.findIndex(signer => {
        return signer.id === 'seller';
      });
      const clientPosition = signers.findIndex(signer => {
        return signer.id === 'client';
      });
      const fairownPosition = signers.findIndex(signer => {
        return signer.id === 'fairown';
      });
      const guarantorPosition = signers.findIndex(signer => {
        return signer.id === 'guarantor';
      });

      if (clientPosition === -1) {
        signers.push({
          container_id: '',
          ...this.clientRepresentative,
          id: 'client',
        });
      }
      if (fairownPosition === -1) {
        signers.push({
          container_id: '',
          ...this.fairownRepresentative,
          id: 'fairown',
        });
      }

      if (!files.includes(6) && sellerPosition !== -1) {
        signers.splice(sellerPosition, 1);
      } else {
        if (
          (files.includes(6) || files.includes(11)) &&
          sellerPosition === -1
        ) {
          signers.push({
            container_id: '',
            id: 'seller',
            name: asset.sellerRepresentative,
            personalCode: asset.representativePersonalCode,
            email: asset.representativeEmail,
          });
        }
      }

      if (files.includes(11)) {
        newContainer.additionalInfo = this.actOfAcceptanceData;
      }

      if (files.includes(7)) {
        newContainer.signers = [
          this.fairownRepresentative,
          {
            container_id: '',
            name: '',
            personalCode: '',
            email: '',
            id: 'surety',
          },
        ];
      }

      if (files.includes(9)) {
        newContainer.signers = [
          {
            container_id: '',
            name: guarantor.representativeName,
            personalCode: guarantor.representativePersonalCode,
            email: guarantor.representativeEmail,
            id: 'guarantor',
          },
        ];
      } else {
        if (guarantorPosition !== -1) {
          signers.splice(guarantorPosition, 1);
        }
      }
      if (files.includes(8) || files.includes(17) || files.includes(19)) {
        newContainer.signers = [this.clientRepresentative];
      }
    },
    setSuretyAsSigner(surety, signer) {
      signer.name = surety.name;
      signer.personalCode = surety.personalCode;
      signer.email = surety.email;
    },
    handleIsSignableChange(val) {
      this.$store.dispatch('saveDocument', val);
    },
  },
  async mounted() {
    if (this.value && this.viewName !== 'partnerCreate') {
      this.getFolderInfo();
    }
    this.FIND_ALL_REASONS();
    const { data } = await this.$store.dispatch('getFairownRepresentative');
    this.fairownRepresentative = data;
    this.clientRepresentative = {
      container_id: '',
      name: this.value.contactName,
      personalCode: this.value.contactPersCode,
      email: this.value.contactEmail,
      id: 'client',
    };
  },
  computed: {
    ...mapState('alert', ['message']),
    ...mapState('reason', ['reasons']),
    ...mapState(['loading']),
    sentContainers: function() {
      if (this.containers !== null) {
        return this.containers.filter(container => container.sentForSigning);
      }
    },
    unsentContainers: function() {
      if (this.containers !== null) {
        return this.containers.filter(container => !container.sentForSigning);
      }
    },
  },
};
</script>

<style scoped></style>
